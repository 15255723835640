import { OverviewCardLabels } from './Overview';

export const overallMetricsLabels: OverviewCardLabels[] = [
  {
    label: 'totalSales',
    isCurrency: true,
  },
  {
    label: 'orders',
    footerLabel: 'itemPerOrder',
  },
  {
    label: 'items',
    footerLabel: 'salesPerItem',
    isCurrency: true,
  },
  {
    label: 'averageTicket',
    isCurrency: true,
  },
];
