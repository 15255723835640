import { render, staticRenderFns } from "./ParentLayout.vue?vue&type=template&id=57872cb3&"
import script from "./ParentLayout.vue?vue&type=script&lang=js&"
export * from "./ParentLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/thur/Documents/Projects/Pleez/optimizations-dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57872cb3')) {
      api.createRecord('57872cb3', component.options)
    } else {
      api.reload('57872cb3', component.options)
    }
    module.hot.accept("./ParentLayout.vue?vue&type=template&id=57872cb3&", function () {
      api.rerender('57872cb3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/ParentLayout.vue"
export default component.exports