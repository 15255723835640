











































import Vue from 'vue';

import PlatformIcon from '@/components/PlatformIcon/PlatformIconComponent.vue';

import { MobileMenuChangesComponent } from './TableMenuChanges';

/**
 * When rich text is passed, small margins can make the content
 * overflow by a couple of pixels which would make the see more
 * button appear even if no more lines exist
 */
const offsetMargin = 5;

export default (Vue as MobileMenuChangesComponent).extend({
  components: {
    PlatformIcon,
  },
  props: {
    menuChange: {
      type: Object,
      required: true,
    },
    maxInsightDescriptionHeight: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.checkIfHasOverflow();

    this.$refs.insightInfoBox.style.setProperty(
      '--max-description-height',
      `${this.maxInsightDescriptionHeight}px`
    );
  },
  data() {
    return {
      hasEllipsis: false,
      hasOverflow: false,
      insightHeight: this.maxInsightDescriptionHeight,
    };
  },
  methods: {
    handleSeeMoreClick() {
      let newHeight = this.maxInsightDescriptionHeight;

      if (this.hasEllipsis) {
        newHeight = this.insightHeight;
      }

      this.hasEllipsis = !this.hasEllipsis;
      this.$refs.insightInfoBox.style.setProperty('--max-description-height', `${newHeight}px`);
    },
    checkIfHasOverflow() {
      if (this.$refs.insightInfoBox) {
        const descriptionElement = this.$refs.insightInfoBox;

        this.hasOverflow = false;
        this.insightHeight = descriptionElement.scrollHeight;

        if (
          this.insightHeight > this.maxInsightDescriptionHeight ||
          descriptionElement.clientWidth + offsetMargin < descriptionElement.scrollWidth
        ) {
          this.hasOverflow = true;
        }
      }
      if (!this.hasOverflow && this.$refs.insightRestaurant) {
        const restaurantElement = this.$refs.insightRestaurant;

        if (restaurantElement.clientWidth < restaurantElement.scrollWidth) {
          this.hasOverflow = true;
        }
      }

      this.hasEllipsis = true;
    },
  },
  watch: {
    '$store.getters.currentViewPort'(newCurrentViewPort, oldCurrentViewPort) {
      if (newCurrentViewPort !== oldCurrentViewPort) {
        this.hasEllipsis = false;
        this.$nextTick(() => this.checkIfHasOverflow());
      }
    },
  },
});
