import HttpRequest from '@/http/httpClass';
import { Actions } from '@/store/store';

const actions: Pick<Actions, 'getTermsAndConditions' | 'setTermsAndConditions'> = {
  getTermsAndConditions() {
    return new HttpRequest(`/users/terms`, 'GET', true).send();
  },
  setTermsAndConditions(_, options) {
    const { termsId } = options;
    const request = new HttpRequest(`/users/terms/${termsId}`, 'POST', true);

    return request.send();
  },
};

export default actions;
