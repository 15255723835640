var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ask-email" }, [
    _c("div", { staticClass: "ask-email__content" }, [
      _c("p", { staticClass: "ask-email__description" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$translations.forgotPassword.description[
                _vm.$store.getters.currentLanguage
              ]
            ) +
            " "
        ),
      ]),
      _c(
        "form",
        {
          staticClass: "ask-email__input-wrapper",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.handleSendEmailClick.apply(null, arguments)
            },
          },
        },
        [
          _c("Input", {
            staticClass: "ask-email__input",
            attrs: {
              placeholderLabel: _vm.$t("forgotPassword.email"),
              label: "E-mail",
            },
            on: { change: _vm.handleEmailInputChange },
          }),
          _c(
            "ButtonDefault",
            {
              staticClass: "ask-email__button",
              attrs: {
                type: "submit",
                "is-round": "",
                isDisabled: !_vm.isEmailValid,
                isLoading: _vm.isLoading,
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("forgotPassword.send")) + " ")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "ask-email__footer" }, [
      _c("p", { staticClass: "ask-email__no-account" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("forgotPassword.alreadyHaveAnAccount")) + " "
        ),
        _c(
          "a",
          {
            staticClass: "ask-email__no-account-link",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.$router.push("/")
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("forgotPassword.login")) + " ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }