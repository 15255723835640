var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.page
    ? _c("div", { staticClass: "bread-crumb" }, [
        _c("div", { staticClass: "bread-crumb__headers" }, [
          _c("h1", { staticClass: "bread-crumb__header" }, [
            _vm._v(" " + _vm._s(_vm.page) + " "),
          ]),
          _vm.pageMeta.breadcrumbs.subPage.length === 1
            ? _c("h2", { staticClass: "bread-crumb__subPage" }, [
                _vm._v(" " + _vm._s(_vm.subPage) + " "),
              ])
            : _c(
                "div",
                { staticClass: "bread-crumb__subPages" },
                _vm._l(
                  _vm.pageMeta.breadcrumbs.subPage,
                  function (subPageItem, index) {
                    return _c(
                      "div",
                      { key: subPageItem + " " + index },
                      [
                        !_vm.isSuperUserLocked(_vm.pageMeta) || _vm.isSuperUser
                          ? _c(
                              "router-link",
                              {
                                staticClass: "bread-crumb__subPage--link",
                                attrs: {
                                  to:
                                    "/" +
                                    _vm.pageMeta.root +
                                    "/" +
                                    _vm.pageMeta.breadcrumbs.subPagesIdentifier[
                                      index
                                    ],
                                },
                              },
                              [
                                _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "bread-crumb__subPage bread-crumb__subPage--tab",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "bread-crumb__active-tab":
                                            _vm.isSubPageActive(index),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            subPageItem[
                                              _vm.$store.state.selectedLanguage
                                            ]
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
        ]),
        _c(
          "div",
          { staticClass: "bread-crumb__date-picker" },
          [
            _vm.shouldShowDatePicker
              ? _c("Datepicker", {
                  attrs: { "is-range-calendar": "", "is-sales-calendar": "" },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }