var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-changes" },
    [
      _vm.isSuperUser && !_vm.filters
        ? _c("div", { staticClass: "menu-changes__banner" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("shared.superUserRequiredFilters")) + " "
            ),
          ])
        : _vm._e(),
      _c("CommonFilters", {
        staticClass: "menu-changes__filters",
        attrs: {
          filters: _vm.filtersData,
          "has-select-all": true,
          "first-as-default": "",
        },
        on: { filtersApplied: _vm.handleOnFilter },
      }),
      _vm.showModalMenuChangesCreate
        ? _c(
            "Modal",
            {
              attrs: {
                title: _vm.$t("menuChanges.modal.title"),
                description: _vm.changeModalDescription,
                "is-loading": _vm.isRequestLoading,
              },
              on: { close: _vm.handleCancelCreateMenuChangesModal },
            },
            [
              !_vm.shouldShowRequestResults
                ? [
                    _c(
                      "div",
                      { staticClass: "menu-changes__actions" },
                      [
                        _c("CommonFilters", {
                          staticClass: "menu-changes__modal-selectors",
                          attrs: {
                            filters: _vm.modalFiltersData,
                            "has-select-all": false,
                            "has-set-button": false,
                            "has-select-filters": false,
                          },
                          on: { selectChange: _vm.handleModalFiltersChange },
                        }),
                        _c(
                          "div",
                          { staticClass: "menu-changes__modal-selectors" },
                          [
                            _c("DatePicker", {
                              attrs: {
                                "is-inside-modal": "",
                                "allow-only-forward": "",
                                "initial-date": _vm.dueDate,
                              },
                              on: {
                                handleDateSelected: _vm.handleDateSelected,
                              },
                            }),
                            _c("p", { staticClass: "menu-changes__reminder" }, [
                              _vm._v(
                                _vm._s(_vm.$t("menuChanges.modal.reminder"))
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("vue-editor", {
                      staticClass: "menu-changes__editor",
                      attrs: { "editor-toolbar": _vm.richTextToolbar },
                      model: {
                        value: _vm.requestingChanges,
                        callback: function ($$v) {
                          _vm.requestingChanges = $$v
                        },
                        expression: "requestingChanges",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "menu-changes__modal-footer-buttons" },
                      [
                        _c(
                          "ButtonDefault",
                          {
                            staticClass:
                              "menu-changes__modal-footer-cancel-button",
                            on: {
                              click: _vm.handleCancelCreateMenuChangesModal,
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("shared.cancel")) + " ")]
                        ),
                        _c(
                          "ButtonDefault",
                          {
                            attrs: { disabled: _vm.isCreateButtonDisabled },
                            on: { click: _vm.handleSaveChange },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("shared.send")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _c("div", [
                    _vm.menuChangeResponseMatch.length > 0
                      ? _c(
                          "div",
                          { staticClass: "menu-changes__modal-result-list" },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "menu-changes__modal-result-label",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "menuChanges.modal.menuChangesSuccess"
                                      )
                                    ) +
                                    ": "
                                ),
                              ]
                            ),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.menuChangeResponseMatch,
                                function (restaurant) {
                                  return _c("li", [
                                    _vm._v(_vm._s(restaurant) + ";"),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.menuChangeResponseUnMatch.length > 0
                      ? _c(
                          "div",
                          { staticClass: "menu-changes__modal-result-list" },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "menu-changes__modal-result-label",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "menuChanges.modal.menuChangesError"
                                      )
                                    ) +
                                    ": "
                                ),
                              ]
                            ),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.menuChangeResponseUnMatch,
                                function (restaurant) {
                                  return _c("li", [
                                    _vm._v(_vm._s(restaurant) + ";"),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
            ],
            2
          )
        : _vm._e(),
      _vm.showModalMenuChanges
        ? _c(
            "Modal",
            {
              attrs: { title: _vm.$t("menuChanges.modal.changesRequested") },
              on: {
                close: function ($event) {
                  _vm.showModalMenuChanges = false
                },
              },
            },
            [
              _vm.viewingMenuChanges
                ? [
                    _c("div", { staticClass: "menu-changes__body" }, [
                      _c("div", {
                        staticClass: "menu-changes__textarea",
                        attrs: { readonly: "", cols: "30", rows: "10" },
                        domProps: {
                          innerHTML: _vm._s(_vm.viewingMenuChanges.description),
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "menu-changes__modal-footer" }, [
                      _c("div", { staticClass: "menu-changes__modal-info" }, [
                        _c(
                          "span",
                          { staticClass: "menu-changes__modal-footer-label" },
                          [_vm._v("Delivery: ")]
                        ),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.platforms[
                                  _vm.$store.getters.subHeaderSelectedPlatform
                                ]
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "menu-changes__modal-footer-info" },
                        [
                          _c(
                            "span",
                            { staticClass: "menu-changes__modal-footer-label" },
                            [_vm._v("Due Date")]
                          ),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatDate(
                                    _vm.viewingMenuChanges.deadline
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "menu-changes__modal-footer-info" },
                        [
                          _c(
                            "span",
                            { staticClass: "menu-changes__modal-footer-label" },
                            [_vm._v("Status")]
                          ),
                          _c("p", [
                            _vm._v(_vm._s(_vm.viewingMenuChanges.status)),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c("TableMenuChangesComponent", {
        attrs: {
          menuChanges: _vm.menuChanges,
          title: _vm.$t("menuChanges.title"),
          description: _vm.$t("menuChanges.description"),
          "is-loading": _vm.isLoading,
          "has-load-more": _vm.totalMenuChanges > _vm.menuChanges.length,
        },
        on: {
          handleViewMenuChanges: _vm.openMenuChangesModal,
          handleSortClick: _vm.handleSort,
          handleOpenCreateMenuChangesModal: _vm.openCreateMenuChangesModal,
          handleLoadMore: _vm.handleLoadMore,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }