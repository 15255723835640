import { ApexOptions } from 'apexcharts';

import { TableDataProp } from '@/components/Table/Table';
import { ViewPort } from '@/utils/_enums/global';
import { BreakdownSellers } from './DashboardCard';

export const getBreakdownTableData = (data: BreakdownSellers[]): TableDataProp => {
  const breakdownSellers = data || [];
  const idSuffix = 'breakdown-sellers';

  return {
    headers: [
      {
        label: 'salesDashboard.tables.categories',
        id: `category-${idSuffix}`,
        minViewPort: ViewPort.Mobile,
      },
      {
        label: 'salesDashboard.tables.numberOfDishes',
        id: `items-${idSuffix}`,
        minViewPort: ViewPort.Tablet,
      },
      {
        label: 'salesDashboard.tables.avgPrice',
        id: `avgPrice-${idSuffix}`,
        minViewPort: ViewPort.Tablet,
      },
      {
        label: 'salesDashboard.tables.totalSales',
        id: `total-sales-${idSuffix}`,
        minViewPort: ViewPort.Mobile,
      },
      {
        label: 'salesDashboard.tables.percentageProductsNotOrdered',
        id: `percentage-not-ordered-${idSuffix}`,
        minViewPort: ViewPort.Tablet,
      },
    ],
    body: breakdownSellers
      .sort((item1, item2) => item1.sales - item2.sales)
      .map((dataItem, index) => {
        const averagePrice = dataItem.avgPrice?.toFixed(2) || '-';
        const notSoldPercentage = dataItem.notSoldPercentage?.toFixed(2) || '-';

        return {
          id: dataItem.category + index,
          values: [
            {
              label: dataItem.category,
              id: `${dataItem.category}-item-category-${index}`,
              minViewPort: ViewPort.Mobile,
            },
            {
              label: dataItem.dishesNumber,
              id: `${dataItem.category}-item-number-${index}`,
              minViewPort: ViewPort.Tablet,
            },
            {
              label: `€${averagePrice}`,
              id: `${dataItem.category}-item-average-price-${index}`,
              minViewPort: ViewPort.Tablet,
            },
            {
              label: `€${dataItem.sales.toFixed(2)}`,
              id: `${dataItem.category}-item-sales-${index}`,
              minViewPort: ViewPort.Mobile,
            },
            {
              label: `${notSoldPercentage}%`,
              id: `${dataItem.category}-item-not-ordered-${index}`,
              minViewPort: ViewPort.Tablet,
            },
          ],
        };
      }),
  };
};

export const analysisChartOptions: ApexOptions = {
  chart: {
    type: 'pie',
  },
  colors: [],
  labels: [],
};

export const getWeekday = (weekDayIndex: number) => {
  if (weekDayIndex >= 0 && weekDayIndex <= 6) {
    const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    return 'shared.daysOfTheWeek.' + weekDays[weekDayIndex];
  }

  return '';
};

export const footerTextForUberOrGlovo = (currentPlatform: string) => {
  if (currentPlatform === 'UberEats') {
    return 'footerUberEats';
  } else if (currentPlatform === 'Glovo') {
    return 'footerGlovo';
  }

  return '';
};
