var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "price-comparison-view" }, [
    _c("h1", { staticClass: "price-comparison-view__title" }, [
      _vm._v(_vm._s(_vm.$t("priceComparison.title"))),
    ]),
    _c("p", { staticClass: "price-comparison-view__description" }, [
      _vm._v(_vm._s(_vm.$t("priceComparison.description"))),
    ]),
    _c(
      "div",
      { staticClass: "price-comparison-view__body" },
      [
        _c("Filters", {
          attrs: {
            filters: {
              restaurantToCompare: true,
            },
            "no-labels": false,
          },
        }),
        _c("PriceAnalysisPerCategory"),
        _c("BrandMetrics"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }