import { MarketTrendsFilters } from './MarketInsights';

export const filtersData = (
  cities: string[],
  platforms: string[],
  defaultValue: string
): MarketTrendsFilters => ({
  platform: {
    value: platforms,
    label: 'filters.restaurantPicker.deliveryPlatform',
    firstAsDefault: true,
  },
  city: {
    value: cities,
    label: 'filters.restaurantPicker.city',
    firstAsDefault: true,
    defaultValue: defaultValue,
  },
});
