<template>
  <modal ref="modal" :open-calculator-modal="openModal" @input="$emit('input', $event)">
    <template slot="title">
      <div>
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 7V11M11 15H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
            stroke="#232859"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h3>
          {{
            $translations.promotions.calculators.promotion.modal.information.title[
              $store.state.selectedLanguage
            ]
          }}
        </h3>
      </div>
    </template>

    <template slot="body">
      <div class="body-container">
        <h4>
          {{
            $translations.promotions.calculators.promotion.modal.information.sections.first.title[
              $store.state.selectedLanguage
            ]
          }}
        </h4>
        <span>
          {{
            $translations.promotions.calculators.promotion.modal.information.sections.first
              .description[$store.state.selectedLanguage]
          }}
        </span>

        <h4>
          {{
            $translations.promotions.calculators.promotion.modal.information.sections.second.title[
              $store.state.selectedLanguage
            ]
          }}
        </h4>
        <span>
          {{
            $translations.promotions.calculators.promotion.modal.information.sections.second
              .description[$store.state.selectedLanguage]
          }}
        </span>

        <h4>
          {{
            $translations.promotions.calculators.promotion.modal.information.sections.third.title[
              $store.state.selectedLanguage
            ]
          }}
        </h4>
        <span>
          {{
            $translations.promotions.calculators.promotion.modal.information.sections.third
              .description[$store.state.selectedLanguage]
          }}
        </span>
      </div>
    </template>
    <template v-if="showSkip" slot="footer">
      <div class="footer-container" @click="$emit('skip')">
        <span>
          {{
            $translations.promotions.calculators.promotion.modal.information.skip[
              $store.state.selectedLanguage
            ]
          }}
        </span>
        <svg
          width="9"
          height="15"
          viewBox="0 0 9 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1.4L8 7.7L1 14"
            stroke="#232859"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </template>
  </modal>
</template>

<script>
import modal from './CalculatorModalBase';

export default {
  name: 'PleezPromotiondisclaimermodal',
  components: {
    modal,
  },
  model: {
    prop: 'openModal',
    event: 'input',
  },
  props: {
    openModal: {
      type: Boolean,
    },
    showSkip: {
      type: Boolean,
      default: () => true,
    },
    showClose: {
      type: Boolean,
      default: () => true,
    },
  },
  watch: {
    openModal() {
      this.$nextTick(() => {
        if (!this.showClose) {
          this.$refs.modal.$el.querySelector('i').style.display = 'none';
        } else {
          this.$refs.modal.$el.querySelector('i').style.display = 'block';
        }
      });
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.calculator-modal ::v-deep .p-modal {
  height: 403px;
}

.title-container > div {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 32px;
}
h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #232859;
  display: flex;
  justify-content: center;
  padding-left: 16px;
}

h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 16px;
  color: #5f25e0;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-left: 32px;
}

.body-container {
  width: 100%;
  span {
    padding-left: 32px;
    padding-right: 48px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
    color: #232859;
    flex: none;
    order: 1;
    flex-grow: 0;
    display: inline-block;
  }
}

.footer-container {
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #232859;
  }
}
</style>
