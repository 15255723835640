



















import Vue from 'vue';
import { FooterComponent } from './Footer';

import { socialMedia } from './Footer.config';

export default (Vue as FooterComponent).extend({
  data() {
    return {
      socialMedia,
    };
  },
  methods: {
    handleOpenTermsAndConditions() {
      this.$emit('termsAndConditionsClick');
    },
  },
});
