var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "performance" },
    [
      _c("Filters", {
        staticClass: "performance__filters",
        attrs: { filters: { platform: true } },
      }),
      _c("div", { staticClass: "performance__chart" }, [
        _c("h2", { staticClass: "performance__chart-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("salesDashboard.title")) + " "),
        ]),
        _c("p", { staticClass: "performance__chart-description" }, [
          _vm._v(" " + _vm._s(_vm.$t("salesDashboard.description")) + " "),
        ]),
        _c("p", { staticClass: "performance__chart-description-filter" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("salesDashboard.filtersDescription")) + " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "performance__chart-buttons" },
          [
            _c(
              "div",
              { staticClass: "performance__chart-categories" },
              _vm._l(_vm.analysisGraphButtons, function (button) {
                return _c(
                  "ButtonDefault",
                  {
                    key: button,
                    staticClass: "performance__chart-button",
                    attrs: { "is-primary": _vm.adaptiveChartType === button },
                    on: {
                      click: function ($event) {
                        _vm.adaptiveChartType = button
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "salesDashboard.salesPerPeriod." + button + ".title"
                          )
                        ) +
                        " "
                    ),
                  ]
                )
              }),
              1
            ),
            _c(
              "Select",
              {
                staticClass: "performance__chart-period-select",
                attrs: {
                  "is-clearable": false,
                  "has-search": false,
                  name: _vm.$t("salesDashboard.salesPerPeriod.selectLabel"),
                  label: _vm.$t(
                    "salesDashboard.salesPerPeriod." +
                      _vm.adaptiveChartType +
                      ".title"
                  ),
                  values: _vm.analysisGraphButtons,
                  "default-value": _vm.adaptiveChartType,
                },
                on: { change: _vm.handleSelectedChartType },
                scopedSlots: _vm._u([
                  {
                    key: "custom-label",
                    fn: function (ref) {
                      var value = ref.value
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "salesDashboard.salesPerPeriod." +
                                  value.label +
                                  ".title"
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "custom-selected-option",
                    fn: function (ref) {
                      var value = ref.value
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "salesDashboard.salesPerPeriod." +
                                  value.label +
                                  ".title"
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              },
              [_c("div", {}, [_c("CoinIcon")], 1)]
            ),
            _c(
              "p",
              { staticClass: "performance__chart-period-buttons-label" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("salesDashboard.salesPerPeriod.viewBy") + ":"
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "performance__chart-period-buttons" },
              [
                _c(
                  "ButtonDefault",
                  {
                    staticClass: "performance__chart-button",
                    attrs: { "is-primary": !_vm.isWeekDays },
                    on: {
                      click: function ($event) {
                        _vm.isWeekDays = false
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "salesDashboard.salesPerPeriod." +
                              _vm.chartDateRangeDayOrMonth
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "ButtonDefault",
                  {
                    staticClass: "performance__chart-button",
                    attrs: {
                      "is-primary": _vm.isWeekDays,
                      "is-loading": !_vm.currentDateRange,
                    },
                    on: {
                      click: function ($event) {
                        _vm.isWeekDays = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("salesDashboard.salesPerPeriod.weekdays")
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "performance__card performance__analysis-chart" },
          [
            _c("SalesAnalysisPerPeriod", {
              attrs: {
                "chart-category": _vm.adaptiveChartType,
                "current-date-range": _vm.currentDateRange,
                "chart-period-type": _vm.chartDateRangeLabel,
                "is-loading": _vm.isMetricLoading,
              },
            }),
          ],
          1
        ),
      ]),
      _c("h2", { staticClass: "performance__chart-title" }, [
        _vm._v(_vm._s(_vm.$t("performancePage.label"))),
      ]),
      _c("p", { staticClass: "performance__chart-description" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("performancePage.performanceDescription")) + " "
        ),
      ]),
      _c("div", { staticClass: "performance__cards performance__wrapper" }, [
        _c(
          "div",
          { staticClass: "performance__card performance__analysis-chart" },
          [
            _c("ConversionRate", {
              attrs: {
                "current-date-range": _vm.currentDateRange,
                "chart-period-type": _vm.chartDateRangeLabel,
                "is-loading": _vm.isMetricLoading,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "performance__card performance__analysis-chart" },
          [
            _c("Visibility", {
              attrs: {
                "current-date-range": _vm.currentDateRange,
                "is-loading": _vm.isMetricLoading,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }