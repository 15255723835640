/**
 * Here is imported all steps to be used at the forgot password view.
 */

import AskEmailStep from './AskEmailStep';

import CheckEmailStep from './CheckEmailStep';

import NewPasswordStep from './NewPasswordStep';

import SuccessfulStep from './SuccessfulStep';

const steps = {
  'forgot-step-1': AskEmailStep,
  'forgot-step-2': CheckEmailStep,
  'forgot-step-3': NewPasswordStep,
  'forgot-step-4': SuccessfulStep,
};

export default steps;
