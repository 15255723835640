var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown" },
    [
      _vm._t("default"),
      _vm.shouldBeVisible
        ? _c(
            "ul",
            { staticClass: "dropdown__selected" },
            _vm._l(_vm.parsedSelectedValues, function (filterItem, index) {
              return _c(
                "li",
                {
                  key: _vm.slotKey(index),
                  on: {
                    click: function ($event) {
                      return _vm.handleFilterItemClick(filterItem)
                    },
                  },
                },
                [
                  _c(
                    "button",
                    { staticClass: "dropdown__selected-item" },
                    [
                      _c("CloseIcon"),
                      _vm._v(" " + _vm._s(filterItem.value) + " "),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }