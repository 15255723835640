var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      attrs: { "open-calculator-modal": _vm.openModal },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event)
        },
      },
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", [
          _c(
            "svg",
            {
              attrs: {
                width: "22",
                height: "22",
                viewBox: "0 0 22 22",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M11 7V11M11 15H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z",
                  stroke: "#232859",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
          _c("h3", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$translations.promotions.calculators.promotion.modal
                    .information.title[_vm.$store.state.selectedLanguage]
                ) +
                " "
            ),
          ]),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "body-container" }, [
          _c("h4", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$translations.promotions.calculators.promotion.modal
                    .information.sections.first.title[
                    _vm.$store.state.selectedLanguage
                  ]
                ) +
                " "
            ),
          ]),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$translations.promotions.calculators.promotion.modal
                    .information.sections.first.description[
                    _vm.$store.state.selectedLanguage
                  ]
                ) +
                " "
            ),
          ]),
          _c("h4", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$translations.promotions.calculators.promotion.modal
                    .information.sections.second.title[
                    _vm.$store.state.selectedLanguage
                  ]
                ) +
                " "
            ),
          ]),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$translations.promotions.calculators.promotion.modal
                    .information.sections.second.description[
                    _vm.$store.state.selectedLanguage
                  ]
                ) +
                " "
            ),
          ]),
          _c("h4", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$translations.promotions.calculators.promotion.modal
                    .information.sections.third.title[
                    _vm.$store.state.selectedLanguage
                  ]
                ) +
                " "
            ),
          ]),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$translations.promotions.calculators.promotion.modal
                    .information.sections.third.description[
                    _vm.$store.state.selectedLanguage
                  ]
                ) +
                " "
            ),
          ]),
        ]),
      ]),
      _vm.showSkip
        ? _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticClass: "footer-container",
                on: {
                  click: function ($event) {
                    return _vm.$emit("skip")
                  },
                },
              },
              [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$translations.promotions.calculators.promotion.modal
                          .information.skip[_vm.$store.state.selectedLanguage]
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "9",
                      height: "15",
                      viewBox: "0 0 9 15",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M1 1.4L8 7.7L1 14",
                        stroke: "#232859",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }