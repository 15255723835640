<template>
  <div class="promotion-calculator-modal">
    <p-modal
      :value="openPromotionCalculator"
      @input="
        ($event) => {
          resetForm();
          $emit('input', $event);
        }
      "
    >
      <template slot="title">
        <div class="title-container">
          <h3>
            {{
              $translations.promotions.calculators.promotion.modal.calculator.title[
                $store.state.selectedLanguage
              ]
            }}
          </h3>
        </div>
        <div class="bar"></div>
      </template>

      <template slot="body">
        <div class="subtitle-container">
          <h4>
            {{
              $translations.promotions.calculators.promotion.modal.calculator.selected_promotion[
                $store.state.selectedLanguage
              ]
            }}
          </h4>
          <small>
            {{
              $translations.promotions.calculators.promotion.modal.calculator.warn[
                $store.state.selectedLanguage
              ]
            }}</small
          >
        </div>

        <div class="platforms-container">
          <span>
            {{
              $translations.promotions.calculators.promotion.modal.calculator.select_platform[
                $store.state.selectedLanguage
              ]
            }}
          </span>
          <Platforms />
        </div>

        <div class="form-group">
          <div style="display: flex; flex-direction: column; gap: 15px">
            <span>
              {{
                $translations.promotions.calculators.promotion.modal.calculator[
                  'sale_price_of_the_menu_item_€'
                ][$store.state.selectedLanguage]
              }}
            </span>
            <span>
              {{
                $translations.promotions.calculators.promotion.modal.calculator[
                  'comission_delivery_platform_%'
                ][$store.state.selectedLanguage]
              }}
            </span>
            <span>
              {{
                $translations.promotions.calculators.promotion.modal.calculator[
                  'cost_of_the_menu_item_€'
                ][$store.state.selectedLanguage]
              }}
            </span>
            <span>
              {{
                $translations.promotions.calculators.promotion.modal.calculator[
                  'vat_of_this_item_%'
                ][$store.state.selectedLanguage]
              }}
            </span>
          </div>
          <div style="display: flex; flex-direction: column; gap: 15px">
            <input v-model="labelItemPrice" @keypress="isNumber($event)" />
            <input v-model="labelItemComission" @keypress="isNumber($event)" />
            <input v-model="labelItemCost" @keypress="isNumber($event)" />
            <input v-model="labelItemVAT" @keypress="isNumber($event)" />
          </div>
        </div>

        <div class="total-container">
          <span>{{
            $translations.promotions.calculators.promotion.modal.calculator[
              'total_you’ll_receive_€'
            ][$store.state.selectedLanguage]
          }}</span>
          <input readonly :value="total" />
        </div>
      </template>

      <template slot="footer">
        <p-button
          style="
            width: 136px;
            border-radius: 8px;
            font-size: 14px;
            line-height: 24px;
            margin-right: 1em;
            color: #ffffff;
            background: #e6e6e6;
            cursor: default;
          "
          :class="{ 'btn-clear-form-active': canClear }"
          label="Cancel"
          @click="resetForm"
          >{{
            $translations.promotions.calculators.promotion.modal.calculator.clear_form[
              $store.state.selectedLanguage
            ]
          }}</p-button
        >
        <p-button
          :class="{ 'btn-calculate-active': canCalc }"
          style="
            width: 136px;
            border-radius: 8px;
            font-size: 14px;
            line-height: 24px;
            color: #ffffff;
            background: #e6e6e6;
            cursor: default;
          "
          label="Calculate"
          @click="calc"
          >{{
            $translations.promotions.calculators.promotion.modal.calculator.calculate[
              $store.state.selectedLanguage
            ]
          }}</p-button
        >
      </template>
    </p-modal>
  </div>
</template>

<script>
import Platforms from '../Buttons/Platforms';

export default {
  name: 'PleezPromotioncalculatormodal',
  components: {
    Platforms,
  },
  model: {
    prop: 'openPromotionCalculator',
    event: 'input',
  },
  props: {
    openPromotionCalculator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemPrice: 0,
      itemCost: 0,
      itemComission: 0,
      itemVAT: 0,
      total: null,
    };
  },
  computed: {
    canClear() {
      if (this.itemPrice || this.itemCost || this.itemComission || this.itemVAT) return true;
      return false;
    },
    canCalc() {
      return this.itemPrice && this.itemCost && this.itemComission && this.itemVAT ? true : false;
    },
    labelItemPrice: {
      get() {
        return this.formatInputText(this.itemPrice, '€');
      },
      set(value) {
        this.itemPrice = this.clearInputData(value);
      },
    },
    labelItemComission: {
      get() {
        return (this.formatInputText(this.itemComission, '%') || '').split(' ').reverse().join('');
      },
      set(value) {
        this.itemComission = this.clearInputData(value);
      },
    },
    labelItemCost: {
      get() {
        return this.formatInputText(this.itemCost, '€');
      },
      set(value) {
        this.itemCost = this.clearInputData(value);
      },
    },
    labelItemVAT: {
      get() {
        return (this.formatInputText(this.itemVAT, '%') || '').split(' ').reverse().join('');
      },
      set(value) {
        this.itemVAT = this.clearInputData(value);
      },
    },
  },
  methods: {
    calc() {
      if (!this.canCalc) return;

      const num =
        this.itemPrice / 2 -
        (((100 + this.itemVAT) / 100) * (this.itemComission / 100) * this.itemPrice) / 2 -
        this.itemCost;

      this.total = Math.round(num * 100) / 100;
    },
    resetForm() {
      this.itemPrice = 0;
      this.itemCost = 0;
      this.itemComission = 0;
      this.itemVAT = 0;
      this.total = null;
    },
    clearInputData(value) {
      return Number(value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'));
    },
    formatInputText(val, prefix) {
      if (!val) return;

      return prefix + ' ' + val;
    },
    isNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.promotion-calculator-modal {
  &::v-deep .p-modal-body {
    margin-top: 16px;
    height: 450px;
  }

  &::v-deep .p-modal-footer {
    right: 20px;
    justify-content: flex-end;
  }

  &::v-deep .p-modal {
    width: 511px;
    padding: unset;
  }

  &::v-deep .p-modal-header {
    height: 62px;
    position: relative;
    padding: unset;
  }

  &::v-deep .p-modal-close-icon {
    color: #5f25e0;
    margin: 16px;
  }

  .bar {
    width: 100%;
    height: 1px;
    background: #f2f2f2;
    position: absolute;
    bottom: 0;
  }

  h3 {
    height: 22px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #232859;
    display: flex;
    justify-content: center;
    padding-left: 16px;
  }

  .title-container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .subtitle-container {
    display: flex;
    align-items: center;
    gap: 10px;
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #232859;
    }
    small {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #969dbb;
    }
  }

  .form-group {
    margin-top: 40px;
    display: flex;
    height: auto;
    gap: 40px;
    padding-right: 10px;
  }
  span {
    height: 32px;
    align-items: center;
    display: flex;
    gap: 15px;
  }

  input {
    background: #ffffff;
    border: 1.00139px solid #d6dfe8;
    box-shadow: 0px 15px 35px rgba(122, 137, 254, 0.05);
    border-radius: 10px;
    box-sizing: border-box;
    height: 32px;
    outline: none;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #232859;
  }

  .total-container {
    display: flex;
    gap: 15px;
    margin-top: 25px;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .button {
    width: 136px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 24px;
    margin-right: 1em;
  }

  .btn-clear-form-active {
    background: #ff5e00 !important;
    cursor: pointer !important;
  }

  .btn-calculate-active {
    background: #5f25e0 !important;
    cursor: pointer !important;
  }

  .platforms-container {
    display: flex;
    align-items: center;
    gap: 151px;

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #232859;
    }
  }
}
</style>
