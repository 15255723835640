


















































































import Vue from 'vue';

import { TableMenuChangesComponent } from './TableMenuChanges';

import Table from '@/components/Table/TableComponent.vue';

import Card from '@/components/Cards/CardComponent.vue';
import ActionsButton from '@/components/Buttons/ActionsButton.vue';
import PlatformIcon from '@/components/PlatformIcon/PlatformIconComponent.vue';
import ButtonDefault from '@/components/Buttons/ButtonDefault.vue';
import MenuChangesListMobile from './MenuChangesListMobile.vue';

import resizeMixin from '@/mixins/resize';
import { getTableData } from './MenuChanges.config';
import { CustomElement } from '@/utils/_enums/global';

export default (Vue as TableMenuChangesComponent).extend({
  components: {
    Table,
    ActionsButton,
    Card,
    PlatformIcon,
    ButtonDefault,
    MenuChangesListMobile,
  },
  props: {
    menuChanges: {
      type: Array,
      default: () => [],
    },
    hasLoadMore: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  mixins: [resizeMixin],
  data() {
    return {
      CustomElement,
    };
  },
  methods: {
    handleSeeMoreClick(insight) {
      this.$emit('handleViewInsight', insight);
    },
    parseText(item) {
      return item.value ? item.value : '';
    },
  },
  computed: {
    tableData() {
      return getTableData(this.menuChanges);
    },
  },
});
