var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calculator-modal" },
    [
      _c(
        "p-modal",
        {
          attrs: { value: _vm.openCalculatorModal },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event)
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "title-container" }, [_vm._t("title")], 2),
            _c("div", { staticClass: "bar" }),
          ]),
          _c("template", { slot: "body" }, [_vm._t("body")], 2),
          _c("template", { slot: "footer" }, [_vm._t("footer")], 2),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }