/**
 * Request is an abstract class to make HTTTP/HTTPS requests
 * @constructor
 */
import VueApp from '@/main';

import { Session } from '@/globals';
import translations from '@/plugins/i18n/translations.json';

class HttpRequest {
  url: string;
  method: 'GET' | 'POST' | 'PUT';
  headers: Headers;
  baseURL: string | undefined;
  body = '';

  constructor(
    url: string,
    method: 'GET' | 'POST' | 'PUT',
    isAuthenticated: boolean,
    body?: Record<string, unknown>
  ) {
    this.baseURL = process.env.VUE_APP_API_BASE_URL;
    this.url = url;
    this.method = method;
    this.headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });

    if (isAuthenticated) {
      this.headers.append('Authorization', `Bearer ${Session.getSession('pleez-token')}`);
    }

    if (body) {
      this.body = JSON.stringify(body);
    }
  }

  send<T>(): Promise<T> {
    return new Promise((resolve, reject) => {
      const finalURL = this.baseURL + this.url;
      const requestOptions: RequestInit = {
        method: this.method,
        headers: this.headers,
        body: this.body || null,
        redirect: 'follow',
      };

      fetch(finalURL, requestOptions)
        .then(async (response) => {
          if (response.status > 399) {
            throw {
              statusText: response.statusText,
              status: response.status,
              body: await response.json(),
            };
          }
          return response?.text() || {};
        })
        .then((result) => {
          try {
            resolve(JSON.parse(result));
          } catch (err) {
            if (result === 'OK' || result === 'Created') {
              resolve({} as T);
            } else {
              console.log(err);

              throw translations.errors.defaultHttpError['en-GB'];
            }
          }
        })
        .catch((error) => {
          /**
           * Should be revised when unauthorized resources start being used
           */
          if (
            !(error.body?.message === 'Username or Password Invalid') &&
            (error.status === 403 || error.status === 401)
          ) {
            if (VueApp.$route.path !== '/') {
              VueApp.$toast.error(VueApp.$t('errors.sessionExpired'));

              setTimeout(() => VueApp.$router.push('/logout'), 1000);

              reject();
            }

            /**
             * Types don't appear to be correct on the vue toast component.
             * First argument is still needed even if it is being replaced by the message property
             */
            VueApp.$toast.info('', { message: VueApp.$t('warnings.loginRequest'), duration: 1000 });

            resolve({} as T);
          } else {
            console.error(error);

            reject({
              error: error,
              url: this.url,
              requestOptions: requestOptions,
            });
          }
        });
    });
  }
}

export default HttpRequest;
