import Translations from '@/plugins/i18n/translations.json';
import Routes from '@/routes/routes';
import { RouteRoot } from '@/routes/routes';
import { SideBarRoute } from './Sidebar';

const { market, analytics, optimization } = Translations.sidebar;
const mainRoots: { [key in RouteRoot]?: SideBarRoute[] } = {};

Routes.forEach((route) => {
  const root = route.meta.root;

  let children: {
    path: string;
    component: () => Promise<unknown>;
    meta?: {
      title?: { 'en-GB': string; 'pt-PT': string; 'es-ES': string };
    };
    title?: string;
  }[] = [];

  if (route.children) {
    children = route.children;
  }

  if (root) {
    if (!mainRoots[root]) {
      mainRoots[root] = [];
    }

    mainRoots[root]?.push({ url: route.path, ...route.meta, children });
  }
});

export const categoriesList = (currentLanguage: 'en-GB' | 'pt-PT' | 'es-ES') => [
  {
    links: mainRoots[RouteRoot.HOME]?.map((link) => {
      return {
        ...link,
        title: link.title ? link.title[currentLanguage] : '',
      };
    }),
  },
  {
    title: market.label[currentLanguage],
    links: mainRoots[RouteRoot.MARKET]?.map((link) => {
      return {
        ...link,
        title: link.title ? link.title[currentLanguage] : '',
        children: link.children?.map((child) => {
          return {
            ...child,
            translatedTitle: child.meta?.title ? child.meta.title[currentLanguage] : '',
          };
        }),
      };
    }),
    root: RouteRoot.MARKET,
  },
  {
    title: analytics.label[currentLanguage],
    links: mainRoots[RouteRoot.ANALYTICS]?.map((link) => {
      return {
        ...link,
        title: link.title ? link.title[currentLanguage] : '',
      };
    }),
    root: RouteRoot.ANALYTICS,
  },
  {
    title: optimization.label[currentLanguage],
    links: mainRoots[RouteRoot.OPTIMIZATION]?.map((link) => {
      return {
        ...link,
        title: link.title ? link.title[currentLanguage] : '',
      };
    }),
    root: RouteRoot.OPTIMIZATION,
  },
];
