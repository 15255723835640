<template>
  <div class="promotions">
    <div class="promotions__filters">
      <Select
        class="promotions__filter"
        displayKey="address_name"
        :is-clearable="false"
        :defaultValue="defaultRestaurant"
        :isLoading="locations.length === 0"
        :values="locations"
        :label="$t('promotions.searchLocation')"
        @change="searchPromotions"
        @search="handleSearch"
      >
        <div class="promotion__filter-icon">
          <MagnifyingGlassIcon />
        </div>

        <template #custom-no-options>
          {{ $t('promotions.searchLocation') }}
        </template>
      </Select>

      <Select
        class="promotions__filter"
        :isLoading="isLoading"
        :values="restaurantTags"
        :label="$t('shared.labels.restaurantTypes')"
        :defaultValue="selectedTags"
        multiple
        @change="handleTagChange"
      >
        <div class="promotion__filter-icon">
          <TagIcon />
        </div>
      </Select>

      <Select
        class="promotions__filter"
        :isLoading="isLoading"
        :values="restaurants"
        :label="$t('promotions.pickRestaurant')"
        :defaultValue="selectedRestaurants"
        multiple
        @change="handleRestaurantChange"
      >
        <div class="promotion__filter-icon">
          <HouseIcon />
        </div>
      </Select>
    </div>

    <div class="promotions__calculator">
      <button class="promotions__calculator-btn" @click="openAdReturnModal = true">
        <HandIcon class="promotions__calculator-icon" />
        {{ $t('promotions.calculators.adTitle') }}
      </button>

      <button
        class="promotions__calculator-btn"
        @click="showPromotionsDisclaimerModalHandler(true, false)"
      >
        <MoneyStackIcon class="promotions__calculator-icon" />
        {{ $t('promotions.calculators.promotion.title') }}
      </button>
    </div>

    <div class="promotions__table">
      <LoaderComponent v-if="isLoading" />
      <PromotionsList v-else-if="list.length || list.length" :data="allData" />

      <p v-else>{{ $t('promotions.noAvailable') }}</p>
    </div>

    <PromotionCalculatorModal v-model="openPromotionCalculator" />
    <PromotionDisclaimerModal
      v-model="openPromotionDisclaimer"
      :show-skip="showSkip"
      :show-close="showCloseButtonPromotionCalculatorModal"
      @skip="handleOnClickSkip"
    />
    <AdReturnCalculatorModal v-if="openAdReturnModal" @close="openAdReturnModal = false" />
  </div>
</template>

<script>
import LoaderComponent from '@/components/Loader/LoaderComponent.vue';
import Select from '@/components/Inputs/Select.vue';
import PromotionsList from './PromotionsList.vue';

import PromotionCalculatorModal from '@/components/Modal/PromotionCalculatorModal';
import PromotionDisclaimerModal from '@/components/Modal/PromotionDisclaimerModal';
import AdReturnCalculatorModal from '@/components/Modal/AdReturnCalculatorModal';

import { getPromotionsForPlatform } from '../competitorTracker.config';

export default {
  name: 'PleezPromotionview',
  components: {
    Select,
    LoaderComponent,
    PromotionsList,
    PromotionCalculatorModal,
    PromotionDisclaimerModal,
    AdReturnCalculatorModal,
  },
  data() {
    return {
      defaultRestaurant: '',
      openPromotionCalculator: false,
      openAdReturnModal: false,
      showSkip: false,
      showCloseButtonPromotionCalculatorModal: true,
      openPromotionDisclaimer: false,
      isLoading: false,
      list: [],
      locations: [],
      selectedTags: [],
      restaurantTags: [],
      selectedRestaurants: [],
      restaurants: [],
    };
  },
  computed: {
    allData() {
      const selectedRestaurants = this.selectedRestaurants;
      const selectedTags = this.selectedTags;

      if (this.selectedRestaurants.length) {
        return this.list.filter(({ restaurant }) => selectedRestaurants.includes(restaurant));
      }

      if (this.selectedTags.length) {
        return this.list.filter(
          ({ tags }) => !!selectedTags.filter((st) => tags.includes(st)).length
        );
      }

      return this.list;
    },
  },
  methods: {
    handleTagChange(tags) {
      this.selectedTags = tags;
      this.selectedRestaurants = [];
    },
    handleRestaurantChange(restaurants) {
      this.selectedRestaurants = restaurants;
      this.selectedTags = [];
    },
    async handleSearch(searchQuery) {
      const response = await this.$store.dispatch('searchLocation', searchQuery);

      this.locations = response.data.suggestions;
    },
    handleOnClickSkip() {
      this.openPromotionDisclaimer = false;
      this.openPromotionCalculator = true;
    },
    showPromotionsDisclaimerModalHandler(
      showSkip = false,
      showCloseButtonPromotionCalculatorModal = false
    ) {
      this.showCloseButtonPromotionCalculatorModal = showCloseButtonPromotionCalculatorModal;
      this.showSkip = showSkip;
      this.openPromotionDisclaimer = true;
    },
    timeout() {
      return setTimeout(() => {
        this.isLoading = false;
        this.$toast.error(this.$t('shared.promotion_not_found'), {
          duration: 3000,
        });
      }, 15000);
    },
    clearFilters() {
      this.list = [];
      this.selectedTags = [];
      this.selectedRestaurants = [];
      this.restaurantTags = [];
      this.restaurants = [];
    },
    defineIcon(promotionType) {
      const type = promotionType.toLowerCase();

      switch (type) {
        case 'percentage_discount':
        case 'save':
          return 'SavingIcon';
        case 'flat_delivery':
        case 'free_delivery':
          return 'FreeDeliveryIcon';
        case 'rewards':
          return 'RewardIcon';
        default:
          return 'BuyOneGetTwoIcon';
      }
    },
    async searchPromotions(location) {
      this.isLoading = true;
      this.clearFilters();

      let timeout = this.timeout();

      try {
        let promotions = await getPromotionsForPlatform(location.full_address);
        clearTimeout(timeout);

        this.setPromotionsList(promotions);
      } catch (error) {
        clearTimeout(timeout);

        this.$toast.error(this.$t('shared.promotion_not_found'), {
          duration: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    setPromotionsList(promotionsList) {
      this.list = promotionsList.map((promotion) => {
        const { tags, restaurant } = promotion;

        if (!this.restaurants.includes(restaurant)) {
          this.restaurants.push(restaurant);
        }

        tags?.forEach((tag) => {
          if (!this.restaurantTags.includes(tag)) {
            this.restaurantTags.push(tag);
          }
        });

        return { ...promotion, iconName: this.defineIcon(promotion.type) };
      });
    },
  },
  watch: {
    '$store.getters.currentUserRestaurant': {
      handler(restaurant) {
        if (restaurant) {
          this.$store.dispatch('searchLocation', restaurant.address).then((response) => {
            this.locations = response.data.suggestions;

            if (this.locations.length > 0) {
              this.defaultRestaurant = this.locations[0].address_name;
              this.searchPromotions(this.locations[0]);
            }
          });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.promotions {
  --max-filter-size: 360px;

  margin-top: var(--spacing-s);

  &__filter {
    margin-bottom: var(--spacing-xxs);
  }
  &__calculator-btn {
    display: none;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: var(--spacing-l);

    margin-bottom: var(--spacing-xxs);

    border-radius: var(--spacing-xxs);

    background-color: var(--brand-black);
    color: var(--color-white);
  }
  &__calculator-icon {
    margin-right: var(--spacing-xxs);

    stroke: var(--color-white);
  }
  &__table {
    position: relative;

    min-height: var(--spacing-3xl);

    margin-top: var(--spacing-m);
  }
  @include breakpoint-from('mobile') {
    &__calculator-btn {
      display: flex;
    }
  }
  @include breakpoint-from('tablet') {
    &__filters {
      display: flex;
    }
    &__filter {
      flex: 1;

      max-width: var(--max-filter-size);

      margin-right: var(--spacing-xxs);

      &:first-child {
        flex: 2;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &__calculator {
      display: flex;
    }
    &__calculator-btn {
      &:first-child {
        margin-right: var(--spacing-xxs);
      }
    }
  }
  @include breakpoint-from('desktop') {
    &__filter {
      max-width: unset;
    }
  }
}
</style>
