<template>
  <section class="price-comparison-view">
    <h1 class="price-comparison-view__title">{{ $t('priceComparison.title') }}</h1>
    <p class="price-comparison-view__description">{{ $t('priceComparison.description') }}</p>

    <div class="price-comparison-view__body">
      <Filters
        :filters="{
          restaurantToCompare: true,
        }"
        :no-labels="false"
      >
      </Filters>

      <PriceAnalysisPerCategory />

      <BrandMetrics />
    </div>
  </section>
</template>

<script>
import PriceAnalysisPerCategory from './PriceAnalysisPerCategory.vue';
import BrandMetrics from './BrandMetrics';
import Filters from '@/components/Filters/FiltersComponent.vue';

export default {
  components: {
    PriceAnalysisPerCategory,
    BrandMetrics,
    Filters,
  },
};
</script>

<style lang="scss" scoped>
.price-comparison-view {
  &__title {
    @include brand-font-l;

    margin-bottom: var(--spacing-xxs);
  }
  &__description {
    @include brand-font-xm;

    margin-bottom: var(--spacing-xs);
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
  }
}
</style>
