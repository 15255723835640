



























import Vue from 'vue';
import Calendar from './Calendar.vue';

import { DatePickerComponent } from './DatePicker';
import LoaderComponent from '@/components/Loader/LoaderComponent.vue';

export default (Vue as DatePickerComponent).extend({
  components: {
    Calendar,
    LoaderComponent,
  },
  props: {
    isRangeCalendar: {
      type: Boolean,
      default: false,
    },
    isSalesCalendar: {
      type: Boolean,
      default: false,
    },
    isInsideModal: {
      type: Boolean,
      default: false,
    },
    allowOnlyForward: {
      type: Boolean,
      default: false,
    },
    initialDate: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      shouldShowPicker: false,
      selectedDate: undefined,
      selectableRange: null,
      highlightDates: [],
    };
  },
  mounted() {
    if (this.initialDate) {
      this.setDate(this.initialDate);
    }
  },
  computed: {
    isLoading() {
      return Boolean(!this.selectableRange);
    },
  },
  methods: {
    setDate(initialDate) {
      const date = initialDate
        ? { fromDate: this.initialDate }
        : this.$store.getters.selectedDateRange;

      if (date.selectedDate && this.isRangeCalendar) {
        this.selectableRange = { toDate: date.toDate, fromDate: date.fromDate };
        this.selectedDate = `${date.fromDate.replaceAll('-', '/')} - ${date.toDate.replaceAll(
          '-',
          '/'
        )}`;
        this.highlightDates = date.highlightDates;
      } else if (date.fromDate) {
        this.selectableRange = { toDate: '', fromDate: date.fromDate };
        this.selectedDate = date.fromDate.replaceAll('-', '/');
      }
    },
    togglePicker() {
      this.shouldShowPicker = !this.shouldShowPicker;

      if (this.shouldShowPicker && this.isRangeCalendar) {
        window.addEventListener('click', this.closePicker.bind(undefined, undefined));
      }
    },
    closePicker(selectedDate) {
      this.shouldShowPicker = false;

      if (selectedDate && this.isRangeCalendar) {
        const { fromDate, toDate } = selectedDate;
        const visualDateString = `${fromDate.replaceAll('-', '/')} - ${toDate.replaceAll(
          '-',
          '/'
        )}`;

        if (visualDateString !== this.selectedDate) {
          this.selectedDate = visualDateString;

          this.$store.commit('setUserOrdersDates', {
            selectedDate: this.selectedDate,
            dateRange: { fromDate, toDate },
          });

          this.setDate();
        }
      } else if (selectedDate) {
        const { fromDate } = selectedDate;

        this.selectedDate = fromDate.replaceAll('-', '/');
        this.selectableRange = { toDate: '', fromDate: fromDate };

        this.handleDateSelected(fromDate);
      }
    },
    handleDateSelected(date) {
      this.$emit('handleDateSelected', date);
    },
    handleDatePickerClick(event) {
      event.stopPropagation();
    },
  },
  watch: {
    '$store.getters.isDateAlreadySet': {
      handler() {
        this.setDate();
      },
      immediate: true,
    },
    shouldShowPicker(shouldShow) {
      if (!shouldShow) {
        window.removeEventListener('click', this.closePicker.bind(undefined, undefined));
      }
    },
  },
});
