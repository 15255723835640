


















































































import Vue from 'vue';
import Card from '@/components/Cards/CardComponent.vue';
import CommonFilters from '@/components/Filters/CommonFiltersComponent.vue';

import { MarketInsightsComponent, MarketTrendsResponse } from './MarketInsights';
import { filtersData } from './marketInsights.config';

export default (Vue as MarketInsightsComponent).extend({
  components: {
    Card,
    CommonFilters,
  },
  data() {
    return {
      filters: '',
      isLoading: true,
      cardData: [],
      filtersData: null,
      orderEstimation: null,
    };
  },
  async mounted() {
    await this.$store.dispatch('getCityAndPlatforms');

    const cityAndPlatforms = this.$store.getters.getCityAndPlatforms;

    this.filtersData = filtersData(
      cityAndPlatforms.cities,
      cityAndPlatforms.platforms,
      cityAndPlatforms.defaultCity
    );
  },
  computed: {
    currentPlatform() {
      const currentPlatformIndex = this.currentFilters.findIndex((item) =>
        item.includes('platform:')
      );

      if (currentPlatformIndex > -1) {
        return this.currentFilters[currentPlatformIndex].split('platform:')[1];
      }

      return '';
    },
    currentCity() {
      const currentPlatformIndex = this.currentFilters.findIndex((item) => item.includes('city:'));

      if (currentPlatformIndex > -1) {
        return this.currentFilters[currentPlatformIndex].split('city:')[1];
      }

      return '';
    },
    currentFilters() {
      return this.filters.split(';');
    },
  },
  methods: {
    isPositive(value) {
      return value >= 0;
    },
    getVariation(value) {
      return this.isPositive(value) ? '+' + value.toFixed(0) : value.toFixed(0);
    },
    handleOnFilter(filters) {
      this.filters = filters.filtersStr;
      this.getData();
    },
    async getData() {
      this.isLoading = true;

      this.$store
        .dispatch('getMarketInsights', this.filters)
        .then((response: MarketTrendsResponse) => {
          this.cardData = response.cardPeriods;
          this.orderEstimation = response.sampleSize;
        })
        .catch(() => this.$toast.error(this.$t('errors.marketTrends')))
        .finally(() => (this.isLoading = false));
    },
  },
});
