import Vue from 'vue';
import translations from './translations.json';
import Store from '@/store';

Vue.prototype.$translations = translations;

let lang = navigator.language;

if (lang == 'pt-BR' || lang == 'pt-PT' || lang == 'pt') {
  lang = 'pt-PT';
} else if (lang == 'es-ES' || lang == 'es') {
  lang = 'es-ES';
} else {
  lang = 'en-GB';
}

// set language
Store.state.selectedLanguage = lang;

export const getTranslation = (labelPath, templateStrings) => {
  try {
    const currentLanguage = Store.getters.currentLanguage;
    const label = labelPath.split('.').reduce((path, child) => path && path[child], translations);
    let translateLabel = label[currentLanguage];

    if (templateStrings?.length > 0) {
      templateStrings.forEach(
        (replaceString, index) =>
          (translateLabel = translateLabel.replace(`$${index}`, replaceString))
      );
    }

    return translateLabel;
  } catch (error) {
    console.error("Couldn't find translation for " + labelPath);
  }

  return '';
};

Vue.prototype.$t = getTranslation;
