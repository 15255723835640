

















import Vue from 'vue';
import { RadioComponent } from './Inputs';

export default (Vue as RadioComponent).extend({
  props: {
    groupName: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    selectedValue: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      radioValue: this.selectedValue,
    };
  },
  methods: {
    handleRadioClick() {
      this.$emit('change', { group: this.groupName, value: this.radioValue });
    },
  },
  watch: {
    selectedValue() {
      this.radioValue = this.selectedValue;
    },
  },
});
