import { render, staticRenderFns } from "./CardComponent.vue?vue&type=template&id=ab8ad8fe&scoped=true&"
import script from "./CardComponent.vue?vue&type=script&lang=ts&"
export * from "./CardComponent.vue?vue&type=script&lang=ts&"
import style0 from "./CardComponent.vue?vue&type=style&index=0&id=ab8ad8fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab8ad8fe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/thur/Documents/Projects/Pleez/optimizations-dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ab8ad8fe')) {
      api.createRecord('ab8ad8fe', component.options)
    } else {
      api.reload('ab8ad8fe', component.options)
    }
    module.hot.accept("./CardComponent.vue?vue&type=template&id=ab8ad8fe&scoped=true&", function () {
      api.rerender('ab8ad8fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Cards/CardComponent.vue"
export default component.exports