





























import Vue from 'vue';
import DashboardCard from './DashboardCardComponent.vue';
import Input from '@/components/Inputs/InputComponent.vue';

import { footerTextForUberOrGlovo, getTopSellingTableData } from './dashboardCard.config';
import { TopSellers, TopSellersComponent } from './DashboardCard';

export default (Vue as TopSellersComponent).extend({
  components: {
    DashboardCard,
    Input,
  },
  props: {
    currentDateRange: {
      type: Object,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      tableData: null,
      topSellers: null,
      filteredTopSellers: [],
      isRequestLoading: true,
    };
  },
  computed: {
    footerText() {
      const text = footerTextForUberOrGlovo(this.$store.getters.platformById?.name);

      return text && this.tableData?.body?.length !== 0
        ? this.$t(`salesDashboard.topSellers.${text}`)
        : '';
    },
  },
  methods: {
    handleSearchInputChange(searchValue) {
      if (this.topSellers) {
        let topSellers = this.topSellers.topSellers || [];

        this.filteredTopSellers = topSellers?.filter((item) => {
          return item.title.toLowerCase().includes(searchValue.toLowerCase());
        });

        this.tableData = getTopSellingTableData({
          ...this.topSellers,
          topSellers: this.filteredTopSellers,
        });
      }
    },
  },
  watch: {
    currentDateRange: {
      handler(newDataRange) {
        if (newDataRange) {
          this.$store
            .dispatch('getUserRestaurantTopSellers', this.currentDateRange)
            .then((response: TopSellers) => {
              if (response.total.totalSales !== 0) {
                this.topSellers = response;
                this.filteredTopSellers = response.topSellers || [];
                this.tableData = getTopSellingTableData(response);
              } else {
                this.tableData = null;
                this.topSellers = null;
                this.filteredTopSellers = [];
              }
            })
            .finally(() => (this.isRequestLoading = false));
        }
      },
      immediate: true,
    },
  },
});
