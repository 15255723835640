var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-view" },
    [
      _c(
        "Card",
        {
          staticClass: "settings-view__card",
          attrs: { isLoading: _vm.isLoadingUserDetails },
          scopedSlots: _vm._u([
            {
              key: "custom-header",
              fn: function () {
                return [
                  _c("SettingsCardHeader", {
                    attrs: {
                      "form-id": "settings-form",
                      title: _vm.$t("settings.loginDetails"),
                      description: _vm.$t("settings.loginDetailsDescription"),
                      "is-edit-mode": _vm.accountInEditMode,
                      "is-able-to-save":
                        _vm.isPasswordValid && !_vm.emailErrorMessage,
                    },
                    on: { editing: _vm.handleAccountEdit },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoadingUserDetails
            ? _c(
                "form",
                {
                  staticClass: "settings-view__form",
                  attrs: { id: "settings-form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.saveAccountDetails.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("Input", {
                    class: [
                      "settings-view__input",
                      {
                        "settings-view__input--disabled":
                          !_vm.accountInEditMode,
                      },
                    ],
                    attrs: {
                      type: "email",
                      "has-default-size": "",
                      label: _vm.$t("settings.email"),
                      defaultValue: _vm.email,
                      "is-disabled": !_vm.accountInEditMode,
                      "error-message": _vm.emailErrorMessage,
                    },
                    on: { change: _vm.handleEmailChange },
                  }),
                  _c("Input", {
                    key: _vm.accountInEditMode ? "password-edit" : "password",
                    class: [
                      "settings-view__input",
                      {
                        "settings-view__input--disabled":
                          !_vm.accountInEditMode,
                      },
                    ],
                    attrs: {
                      type: "password",
                      "has-default-size": "",
                      autocomplete: "new-password",
                      label: _vm.accountInEditMode
                        ? _vm.$t("settings.new_password")
                        : _vm.$t("settings.password"),
                      defaultValue: _vm.passwordInputDefaultValue,
                      "is-disabled": !_vm.accountInEditMode,
                      "error-message": _vm.shouldShowMismatchError
                        ? _vm.$t("shared.navbar.passwordMismatch")
                        : "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handlePasswordInputChange($event, false)
                      },
                    },
                  }),
                  _c(
                    "Transition",
                    { attrs: { name: "input-fade", appear: "" } },
                    [
                      _vm.accountInEditMode
                        ? _c("Input", {
                            staticClass: "settings-view__input",
                            attrs: {
                              type: "password",
                              "has-default-size": "",
                              autocomplete: "new-password",
                              label: _vm.$t("settings.confirm_password"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handlePasswordInputChange(
                                  $event,
                                  true
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "Card",
        {
          staticClass: "settings-view__card",
          attrs: { isLoading: _vm.isLoadingBusinessDetails },
          scopedSlots: _vm._u([
            {
              key: "custom-header",
              fn: function () {
                return [
                  _c("SettingsCardHeader", {
                    attrs: {
                      "form-id": "business-form",
                      title: _vm.$t("settings.business_contact"),
                      description: _vm.$t(
                        "settings.businessContactDescription"
                      ),
                      "is-edit-mode": _vm.businessInEditMode,
                      "is-able-to-save": _vm.isPhoneValid,
                      "is-loading": _vm.isLoadingBusinessDetails,
                    },
                    on: { editing: _vm.handleBusinessEdit },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoadingBusinessDetails
            ? _c(
                "form",
                {
                  staticClass: "settings-view__form",
                  attrs: { id: "business-form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.saveBusinessDetails.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "settings-view__stand-alone-label",
                      attrs: {
                        for: _vm.businessInEditMode
                          ? "business-email-input"
                          : "",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("settings.email")) + " ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "settings-view__email-wrapper" },
                    [
                      !_vm.businessInEditMode
                        ? _c(
                            "p",
                            { staticClass: "settings-view__setting-not-edit" },
                            [_vm._v(" " + _vm._s(_vm.email) + " ")]
                          )
                        : _c(
                            "Transition",
                            { attrs: { name: "input-fade", appear: "" } },
                            [
                              _c("Input", {
                                staticClass: "settings-view__input",
                                attrs: {
                                  id: "business-email-input",
                                  type: "text",
                                  "is-disabled": "",
                                  "has-default-size": "",
                                  "is-label-visible": false,
                                  label: _vm.$t("settings.email"),
                                  "default-value": _vm.email,
                                  description: _vm.emailDescription,
                                  "info-message": _vm.emailInfoMessage,
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "settings-view__section-wrapper" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "settings-view__stand-alone-label",
                          attrs: {
                            for: _vm.businessInEditMode
                              ? "business-phone-input"
                              : "",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("settings.phone_number")) + " "
                          ),
                        ]
                      ),
                      !_vm.businessInEditMode
                        ? _c(
                            "p",
                            { staticClass: "settings-view__setting-not-edit" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.visualPhonePrefix) +
                                  " " +
                                  _vm._s(
                                    _vm.phone.number ? _vm.phone.number : "-"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _c(
                            "Transition",
                            { attrs: { name: "input-fade", appear: "" } },
                            [
                              _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "settings-view__setting-description",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("settings.whatsAppDescription")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "settings-view__phone-inputs",
                                  },
                                  [
                                    _c("Select", {
                                      staticClass:
                                        "settings-view__phone-prefix",
                                      attrs: {
                                        "is-clearable": false,
                                        "is-label-visible": false,
                                        disabled: !_vm.businessInEditMode,
                                        "default-value": _vm.visualPhonePrefix,
                                        values: _vm.countryPhoneCodes,
                                        label: _vm.$t("settings.prefix"),
                                      },
                                      on: { change: _vm.handlePrefixChange },
                                    }),
                                    _c("Input", {
                                      ref: "phoneInput",
                                      attrs: {
                                        id: "business-phone-input",
                                        type: "tel",
                                        label: _vm.$t("settings.phone_number"),
                                        "is-label-visible": false,
                                        "error-message": _vm.phoneErrorMessage,
                                        "default-value": _vm.phone.number,
                                        "is-disabled": !_vm.businessInEditMode,
                                      },
                                      on: { change: _vm.handlePhoneChange },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "settings-view__section-wrapper settings-view__section-notification",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "settings-view__stand-alone-label settings-view__stand-alone-label--no-margin",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("settings.frequencyNotification")) +
                              " "
                          ),
                          _vm.businessInEditMode
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "settings-view__setting-description",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("settings.frequencyDescription")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("SettingsRadioGroup", {
                            attrs: {
                              "notifications-settings":
                                _vm.notificationsSettings,
                              "is-disabled": !_vm.businessInEditMode,
                            },
                            on: { change: _vm.handleNotificationChange },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }