

























































import Vue from 'vue';
import PlatformIcon from '@/components/PlatformIcon/PlatformIconComponent.vue';

import { PlatformCardComponent } from './PlatformCard.d';

export default (Vue as PlatformCardComponent).extend({
  components: {
    PlatformIcon,
  },
  props: {
    overviewData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shouldShowHint: false,
    };
  },
  computed: {
    notAvailable() {
      return this.overviewData.values[0].value === '-';
    },
    platform() {
      return this.overviewData.platform;
    },
    info() {
      return this.$t(`overview.${this.platform}Info`);
    },
    platformValues() {
      return this.overviewData.values;
    },
  },
});
