<template>
  <div @mouseover="isHovering = true" @mouseleave="isHovering = false">
    <button class="sidebar-link__button">
      <router-link
        :to="redirectTo"
        @click.native="handleLinkClick"
        :class="[
          'sidebar-link',
          'router-link',
          'teste',
          {
            'router-link-active': isActive,
            'sidebar-link--root-active': isActive,
            'sidebar-link--root-active--no-child': !hasChildren && isActive,
          },
        ]"
      >
        <div class="sidebar-link__label-wrapper">
          <span
            class="sidebar-link__icon"
            :class="{
              'sidebar-link__icon--has-child': hasChildren,
            }"
          >
            <slot
          /></span>
          <p class="sidebar-link__label">{{ label }}</p>
        </div>
      </router-link>
    </button>
    <!-- Children routes -->
    <ul>
      <router-link
        tag="li"
        @click.native="handleLinkClick"
        :class="[
          'sidebar-link',
          'router-link',
          'sidebar-link--child',
          {
            'sidebar-link--child-active': isActiveChildren(ch),
          },
        ]"
        v-if="hasChildren && (currentPathIsChildrenParent || isHovering)"
        v-for="ch in link.children"
        :key="ch.path"
        :to="`/${link.root}/${ch.path}`"
      >
        {{ ch.translatedTitle }}
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    link: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isHovering: false,
    };
  },
  methods: {
    isActiveChildren(ch) {
      return this.$route.path === `/${this.link.root}/${ch.path}`;
    },
    handleLinkClick() {
      if (!this.hasChildren) {
        this.$emit('close');
      }
    },
  },
  computed: {
    redirectTo() {
      if (this.link.children && this.link.children.length > 0) {
        return `/${this.link.root}/${this.link.children[0].path}`;
      } else {
        return this.url;
      }
    },
    isActive() {
      if (this.hasChildren) {
        return this.link.children.some((ch) => this.$route.path.includes(ch.path));
      } else {
        return this.$route.path == this.url;
      }
    },
    hasChildren() {
      return this.link.children?.length > 0;
    },
    currentPathIsChildrenParent() {
      if (this.hasChildren) {
        // Find the current path in the children array
        const currentChildrenPath = this.link.children.find(
          (child) => this.$route.path === `/${this.link.root}/${child.path}`
        );
        return currentChildrenPath;
      }

      return false;
    },
  },
};
</script>

<style lang="scss">
.sidebar-link {
  @include brand-font-m;

  display: flex;
  align-items: center;

  margin: var(--spacing-3xs) 0;
  padding: var(--spacing-xxs) 0;

  color: var(--brand-primary);

  text-decoration: none;

  &__button {
    width: 100%;
  }

  &__label-wrapper {
    display: flex;
    align-items: center;
  }
  &__icon {
    width: var(--spacing-s);

    fill: var(--brand-primary);
    stroke: var(--brand-primary);
  }
  &__label {
    margin-left: var(--spacing-xxs);
  }
  &:hover {
    color: var(--brand-secondary);
    cursor: pointer;

    .sidebar-link__icon {
      stroke: var(--brand-secondary);
      fill: var(--brand-secondary);
    }
  }

  &.router-link-active {
    color: var(--brand-secondary);
  }

  &.sidebar-link--child {
    margin-left: var(--spacing-m);
  }

  @include breakpoint-from('smallDesktop') {
    padding: var(--spacing-xxs) var(--spacing-s);

    &.sidebar-link--child-active {
      background-color: transparent;
      color: var(--brand-secondary);
    }

    &.sidebar-link--root-active {
      background-color: var(--brand-grey);
      color: var(--color-black);

      &--no-child {
        color: var(--brand-secondary);
      }
    }

    &.router-link-active {
      position: relative;

      .sidebar-link__icon {
        stroke: var(--brand-secondary);
        fill: var(--brand-secondary);

        &--has-child {
          stroke: var(--color-black);
          fill: var(--color-black);
        }
      }
    }
  }
}
</style>
