





























































































import Vue from 'vue';

import { CustomElement } from '@/utils/_enums/global';
import { TableInsightsComponent } from './TableInsights';
import { getTableData } from './PleezInsights.config';

import PleezInsightsListMobile from '@/views/insights/pleez/PleezInsightsListMobile.vue';
import Table from '@/components/Table/TableComponent.vue';
import ActionsButton from '@/components/Buttons/ActionsButton.vue';
import PlatformIcon from '@/components/PlatformIcon/PlatformIconComponent.vue';
import Card from '@/components/Cards/CardComponent.vue';

export default (Vue as TableInsightsComponent).extend({
  components: {
    PleezInsightsListMobile,
    Card,
    Table,
    ActionsButton,
    PlatformIcon,
  },
  props: {
    insights: {
      type: Array,
      default: () => [],
    },
    isNewInsights: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    dispatchName: {
      type: String,
      default: '',
    },
    hasLoadMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CustomElement,
    };
  },
  computed: {
    tableData() {
      return getTableData(this.insights);
    },
    approveEDText() {
      if (this.isNewInsights) {
        return this.$t('pleezInsights.approve');
      } else {
        return this.$t('pleezInsights.approved');
      }
    },
    rejectEDText() {
      if (this.isNewInsights) {
        return this.$t('pleezInsights.reject');
      } else {
        return this.$t('pleezInsights.rejected');
      }
    },
  },
  methods: {
    expiredOrRejectedText(status) {
      if (status === 'expired') {
        return this.$t('pleezInsights.expired');
      } else if (status === 'rejected') {
        return this.rejectEDText;
      }
    },
    isExpiredOrRejected(status) {
      return status === 'expired' || status === 'rejected';
    },
    handleActionClick(insight, isApprove) {
      if (this.isNewInsights) {
        this.$emit('handleUpdateInsight', insight, isApprove);
      }
    },
    handleSeeMoreClick(insight) {
      this.$emit('handleViewInsight', insight);
    },
    parseText(item) {
      return item.value ? item.value : '';
    },
  },
});
