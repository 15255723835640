



































import Vue from 'vue';
import ApexChart from 'vue-apexcharts';
import DashboardCard from '@/views/dashboard/cards/DashboardCardComponent.vue';
import { PerformanceGraphComponent, PerformanceGraphChartData } from './DashboardCard';
import { tertiaryColorHex } from '@/utils/constants';

const chartWidthColumnThreshold = 10;

export default (Vue as PerformanceGraphComponent).extend({
  components: {
    DashboardCard,
    ApexChart,
  },

  watch: {
    currentDateRange: {
      handler() {
        this.getChartData();
      },
      immediate: true,
    },
    chartPeriodType() {
      this.getChartData();
    },
    '$store.state.selectedLanguage'() {
      this.setupChart();
    },
  },
  props: {
    currentDateRange: {
      type: Object,
      required: false,
    },
    chartPeriodType: {
      type: String,
      default: 'days',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    downloadableFileName: {
      type: String,
      default: new Date().toString() + '.csv',
    },
    downloadableDataFormatter: {
      type: Function,
      default: () => null,
    },
    dispatchAction: {
      type: String,
      required: true,
    },
    graphType: {
      default: 'bar',
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    chartColors: {
      type: Array,
      default: () => [tertiaryColorHex],
    },
    chartDataLabelsStyle: {
      type: Object,
      default: () => ({
        enabled: true,
      }),
    },
    labelFormatter: {
      type: Function,
    },
  },
  data() {
    return {
      chartSeries: {
        name: this.dispatchAction,
        data: [],
      },
      chartData: [],
      chartAverage: 0,
      chartTotal: '',
      isRequestLoading: true,
      chartOptions: {
        chart: {
          type: this.graphType,
          toolbar: {
            show: false,
          },
        },
        colors: this.chartColors,
        fill: {
          colors: this.chartColors,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: this.chartDataLabelsStyle,
        yaxis: {
          forceNiceScale: true,
          decimalsInFloat: 2,
          labels: {
            formatter: this.labelFormatter,
          },
        },
      },
    };
  },
  computed: {
    isExtendedChart() {
      return this.chartSeries.data.length > chartWidthColumnThreshold;
    },
    downloadableData() {
      const labels = this.chartOptions.labels || [];
      const data = this.chartSeries.data || [];

      return this.downloadableDataFormatter(labels, data);
    },
  },
  methods: {
    chartLabels(chartData) {
      const currentLanguage = this.$store.state.selectedLanguage;

      return [
        ...chartData.map((item) => {
          const newDate = new Date(item.date);

          if (this.chartPeriodType === 'days') {
            return newDate.toLocaleDateString(currentLanguage, {
              month: 'short',
              day: 'numeric',
            });
          } else {
            return newDate.toLocaleDateString(currentLanguage, {
              month: 'long',
            });
          }
        }),
      ];
    },
    setupChart() {
      this.chartSeries.data = [];

      if (this.chartData.length !== 0) {
        const sortedChartData = this.chartData.sort((date1, date2) =>
          date1.date.localeCompare(date2.date)
        );
        let total = 0;

        sortedChartData.forEach((item) => {
          const value = Math.round(Number(item[this.dataKey]));

          if (value >= 0) {
            total += value;

            this.chartSeries.data.push(value);
          }
        });

        /**
         * Parse total to 1.000,00 format
         */
        this.chartTotal = this.labelFormatter(total.toLocaleString('de-DE'));
        this.chartAverage = total / this.chartSeries.data.length;

        this.chartOptions = {
          ...this.chartOptions,
          labels: this.chartLabels(sortedChartData),
          annotations: {
            yaxis: [
              {
                y: this.chartAverage,
                borderColor: '#000',
                strokeDashArray: 8,
                borderWidth: 2,
                offsetY: 8,
                label: {
                  borderColor: '#000',
                  style: {
                    fontSize: '16px',
                    fontFamily: 'Poppins, sans-serif',
                    color: '#ffffff',
                    background: '#000',
                  },
                  text: this.labelFormatter(this.chartAverage.toFixed(2)),
                },
              },
            ],
          },
        };
      }
    },
    getChartData() {
      if (!this.currentDateRange) {
        return;
      }

      this.isRequestLoading = true;

      this.$store
        .dispatch(this.dispatchAction, {
          ...this.currentDateRange,
          periodType: this.chartPeriodType,
        })
        .then((response: PerformanceGraphChartData[]) => {
          this.chartData = response;
          this.setupChart();
        })
        .finally(() => (this.isRequestLoading = false));
    },
  },
});
