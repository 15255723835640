var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "header" }, [
    _c(
      "div",
      { staticClass: "header__temp-mobile" },
      [
        _c("MenuIcon", {
          staticClass: "header__mobile-menu-icon",
          on: {
            click: function ($event) {
              return _vm.$emit("menuClick")
            },
          },
        }),
        _c(
          "router-link",
          { staticClass: "header__logo", attrs: { to: "/home" } },
          [_c("PleezLogo", { staticClass: "header__logo" })],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "header__actions" },
      [
        _vm.shouldShowMenu
          ? _c(
              "a",
              {
                staticClass: "header__menu-button",
                attrs: { href: _vm.menuUrl, target: "_blank" },
              },
              [
                _c("EyeIcon", { staticClass: "header__menu-icon" }),
                _vm._v(" " + _vm._s(_vm.$t("shared.navbar.see_menu")) + " "),
              ],
              1
            )
          : _vm._e(),
        _c(
          "Select",
          {
            staticClass: "header__button header__restaurants-button",
            attrs: {
              displayKey: "name",
              "is-clearable": false,
              defaultValue: _vm.selectedRestaurant,
              isLoading: _vm.restaurants.length === 0,
              values: _vm.restaurants,
              label: _vm.$t("shared.navbar.restaurants"),
            },
            on: { change: _vm.handleRestaurantChanged },
            scopedSlots: _vm._u([
              {
                key: "custom-selected-option",
                fn: function (ref) {
                  var value = ref.value
                  return [
                    _c(
                      "div",
                      { staticClass: "header__selected-option-wrapper" },
                      [
                        _c(
                          "p",
                          { staticClass: "header__selected-option-name" },
                          [_vm._v(" " + _vm._s(value.name) + " ")]
                        ),
                        _c(
                          "p",
                          { staticClass: "header__selected-option-address" },
                          [_vm._v(" " + _vm._s(value.address) + " ")]
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "custom-label",
                fn: function (ref) {
                  var value = ref.value
                  return [
                    _c("p", { staticClass: "header__restaurant-name" }, [
                      _vm._v(_vm._s(value.name)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "header__restaurants-address-wrapper" },
                      [
                        _c("PinIcon", {
                          staticClass: "header__restaurant-icon",
                        }),
                        _c("p", { staticClass: "header__restaurant-address" }, [
                          _vm._v(" " + _vm._s(value.address) + " "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          },
          [
            _c(
              "div",
              { staticClass: "header__action-icon" },
              [_c("HouseIcon")],
              1
            ),
          ]
        ),
        _c(
          "Select",
          {
            staticClass: "header__button header__account-button",
            attrs: {
              values: _vm.accountDropdownOptions,
              label: _vm.$t("shared.navbar.account"),
              placeholder: _vm.$t("shared.navbar.account"),
              "has-search": false,
              "is-selectable": false,
              "is-clearable": false,
            },
            on: { change: _vm.handleAccountOptionClick },
            scopedSlots: _vm._u([
              {
                key: "custom-label",
                fn: function (ref) {
                  var value = ref.value
                  return [
                    _c(
                      "div",
                      { staticClass: "header__account-option" },
                      [
                        _c(value.icon, {
                          tag: "component",
                          staticClass: "header__option-icon",
                        }),
                        _c("p", [_vm._v(_vm._s(_vm.$t(value.label)))]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "custom-footer",
                fn: function () {
                  return [
                    _c("span", { staticClass: "header__flags" }, [
                      _c("span", {
                        staticClass: "fi fi-pt",
                        on: {
                          click: function ($event) {
                            _vm.$store.state.selectedLanguage = "pt-PT"
                          },
                        },
                      }),
                      _c("span", {
                        staticClass: "fi fi-gb",
                        on: {
                          click: function ($event) {
                            _vm.$store.state.selectedLanguage = "en-GB"
                          },
                        },
                      }),
                      _c("span", {
                        staticClass: "fi fi-es",
                        on: {
                          click: function ($event) {
                            _vm.$store.state.selectedLanguage = "es-ES"
                          },
                        },
                      }),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "div",
              { staticClass: "header__action-icon" },
              [_c("ProfileIcon")],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }