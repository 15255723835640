var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "footer" },
    [
      _c("PleezLogo", { staticClass: "footer__logo" }),
      _c("p", { staticClass: "footer__text" }, [
        _vm._v(" Pleez 2023 - "),
        _c(
          "button",
          {
            staticClass: "footer__terms-and-conditions",
            on: { click: _vm.handleOpenTermsAndConditions },
          },
          [_vm._v(" Terms & Conditions ")]
        ),
      ]),
      _c(
        "ul",
        { staticClass: "footer__social-media" },
        _vm._l(_vm.socialMedia, function (item) {
          return _c("li", [
            _c(
              "a",
              { staticClass: "footer__button", attrs: { href: item.url } },
              [_c(item.icon, { tag: "component" })],
              1
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }