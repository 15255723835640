var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "menu-quality",
      attrs: { "aria-label": _vm.$t("menuMetrics.menuQuality") },
    },
    [
      _c(
        "Card",
        {
          staticClass: "menu-quality__card",
          attrs: {
            title: _vm.$t("menuMetrics.menuQuality"),
            "is-loading": _vm.isLoading,
            "no-padding": "",
          },
        },
        [
          _vm.menuQualityMetrics
            ? _c(
                "ul",
                _vm._l(_vm.metrics, function (ref, index) {
                  var metricName = ref[0]
                  var metricValue = ref[1]
                  return _c(
                    "li",
                    {
                      key: metricName,
                      class: [
                        "menu-quality__list-item",
                        {
                          "menu-quality__list-item--active":
                            _vm.activeItem === index,
                        },
                      ],
                      on: {
                        click: function ($event) {
                          _vm.activeItem = index
                        },
                      },
                    },
                    [
                      _c(
                        "button",
                        { staticClass: "menu-quality__list-button" },
                        [
                          _c("p", { staticClass: "menu-quality__list-name" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("menuMetrics." + metricName + ".name")
                              )
                            ),
                          ]),
                          _c(
                            "p",
                            { staticClass: "menu-quality__list-value" },
                            [
                              metricValue.isBarChart
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(metricValue.metrics.self) +
                                        " "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          metricValue.metrics.self +
                                            "/" +
                                            metricValue.metrics.market
                                        ) +
                                        " "
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _c("p", { staticClass: "menu-quality__no-metrics" }, [
                _vm._v(_vm._s(_vm.$t("menuMetrics.noMetrics"))),
              ]),
        ]
      ),
      _vm.menuQualityMetrics
        ? _c(
            "Card",
            {
              staticClass: "menu-quality__card menu-quality__chart",
              attrs: { "is-loading": _vm.isLoading },
              scopedSlots: _vm._u(
                [
                  {
                    key: "custom-header",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "menu-quality__chart-title" },
                          [
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("menuMetrics.metrics"))),
                            ]),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "menu-quality__chart-title--active",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "menuMetrics." +
                                          _vm.currentMetricTitle +
                                          ".name"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3092660585
              ),
            },
            [
              _c("ApexChart", {
                attrs: {
                  height: "100%",
                  type: _vm.currentMetricChart,
                  series: _vm.currentSeriesData,
                  options: _vm.chartOptions,
                },
              }),
              !_vm.isBarChart
                ? _c("p", { staticClass: "menu-quality__chart-legend" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.currentMetricValue.metrics.self +
                            "/" +
                            _vm.currentMetricValue.metrics.market
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.metrics.length > 0
        ? _c("MenuProgressIndicator", {
            attrs: {
              menuMetrics: _vm.metrics,
              selectedMetricName: _vm.currentMetricTitle,
              selectedMetricValue: _vm.currentMetricValue,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }