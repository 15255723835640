var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardCard",
    {
      staticClass: "sales-analysis",
      attrs: {
        title: _vm.$t("salesDashboard.salesAnalysisPerCategory.title"),
        description: _vm.$t(
          "salesDashboard.salesAnalysisPerCategory.description"
        ),
        "is-loading": _vm.isLoading,
        "is-table-card": false,
      },
    },
    [
      _vm.chartSeries.length > 0
        ? _c(
            "div",
            { staticClass: "sales-analysis__chart" },
            [
              _c("ApexChart", {
                ref: "chart",
                staticClass: "sales-analysis__chart-svg",
                attrs: {
                  type: "pie",
                  height: "auto",
                  options: _vm.chartOptions,
                  series: _vm.chartSeries,
                },
              }),
            ],
            1
          )
        : _c("p", { staticClass: "sales-analysis--no-data" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("salesDashboard.noDataAvailable")) + " "
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }