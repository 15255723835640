var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sales" },
    [
      _c("Filters", {
        staticClass: "sales__filters",
        attrs: { filters: { platform: true } },
      }),
      _c(
        _vm.isMobileViewPort ? "div" : "ul",
        { tag: "component", staticClass: "sales__overall" },
        [
          _vm.isMetricLoading
            ? _c("LoaderComponent")
            : _vm.isMobileViewPort
            ? _c("Slider", {
                attrs: {
                  id: "dashboard-slider",
                  sliderItems: _vm.overallMetrics,
                },
                scopedSlots: _vm._u([
                  {
                    key: "slider-item",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c("DashboardMetricCard", { attrs: { item: item } }),
                      ]
                    },
                  },
                ]),
              })
            : _vm._l(_vm.overallMetrics, function (item, index) {
                return _c(
                  "li",
                  { key: "metrics-" + index },
                  [_c("DashboardMetricCard", { attrs: { item: item } })],
                  1
                )
              }),
        ],
        2
      ),
      _c("div", { staticClass: "sales__chart" }, [
        _c("h2", { staticClass: "sales__chart-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("salesDashboard.title")) + " "),
        ]),
        _c("p", { staticClass: "sales__chart-description" }, [
          _vm._v(" " + _vm._s(_vm.$t("salesDashboard.description")) + " "),
        ]),
        _c("p", { staticClass: "sales__chart-description-filter" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("salesDashboard.filtersDescription")) + " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "sales__chart-buttons" },
          [
            _c(
              "div",
              { staticClass: "sales__chart-categories" },
              _vm._l(_vm.analysisGraphButtons, function (button) {
                return _c(
                  "ButtonDefault",
                  {
                    key: button,
                    staticClass: "sales__chart-button",
                    attrs: { "is-primary": _vm.adaptiveChartType === button },
                    on: {
                      click: function ($event) {
                        _vm.adaptiveChartType = button
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "salesDashboard.salesPerPeriod." + button + ".title"
                          )
                        ) +
                        " "
                    ),
                  ]
                )
              }),
              1
            ),
            _c(
              "Select",
              {
                staticClass: "sales__chart-period-select",
                attrs: {
                  "is-clearable": false,
                  "has-search": false,
                  name: _vm.$t("salesDashboard.salesPerPeriod.selectLabel"),
                  label: _vm.$t(
                    "salesDashboard.salesPerPeriod." +
                      _vm.adaptiveChartType +
                      ".title"
                  ),
                  values: _vm.analysisGraphButtons,
                  "default-value": _vm.adaptiveChartType,
                },
                on: { change: _vm.handleSelectedChartType },
                scopedSlots: _vm._u([
                  {
                    key: "custom-label",
                    fn: function (ref) {
                      var value = ref.value
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "salesDashboard.salesPerPeriod." +
                                  value.label +
                                  ".title"
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "custom-selected-option",
                    fn: function (ref) {
                      var value = ref.value
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "salesDashboard.salesPerPeriod." +
                                  value.label +
                                  ".title"
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              },
              [_c("div", {}, [_c("CoinIcon")], 1)]
            ),
            _c("p", { staticClass: "sales__chart-period-buttons-label" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("salesDashboard.salesPerPeriod.viewBy") + ":") +
                  " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "sales__chart-period-buttons" },
              [
                _c(
                  "ButtonDefault",
                  {
                    staticClass: "sales__chart-button",
                    attrs: { "is-primary": !_vm.isWeekDays },
                    on: {
                      click: function ($event) {
                        _vm.isWeekDays = false
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "salesDashboard.salesPerPeriod." +
                              _vm.chartDateRangeDayOrMonth
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "ButtonDefault",
                  {
                    staticClass: "sales__chart-button",
                    attrs: {
                      "is-primary": _vm.isWeekDays,
                      "is-loading": !_vm.currentDateRange,
                    },
                    on: {
                      click: function ($event) {
                        _vm.isWeekDays = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("salesDashboard.salesPerPeriod.weekdays")
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "sales__card sales__analysis-chart" },
          [
            _c("SalesAnalysisPerPeriod", {
              attrs: {
                "chart-category": _vm.adaptiveChartType,
                "current-date-range": _vm.currentDateRange,
                "chart-period-type": _vm.chartDateRangeLabel,
                "is-loading": _vm.isMetricLoading,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "sales__cards" }, [
        _c(
          "div",
          { staticClass: "sales__card sales__analysis-chart" },
          [
            _c("SalesAnalysisPerCategory", {
              attrs: {
                categoriesBreakdown: [].concat(_vm.categoriesBreakdown),
                isLoading: _vm.isBreakdownLoading,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "sales__card" },
          [
            _c("BreakdownOfCategories", {
              attrs: {
                categoriesBreakdown: [].concat(_vm.categoriesBreakdown),
                isLoading: _vm.isBreakdownLoading,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "sales__card" },
          [
            _c("TopSellers", {
              attrs: {
                isLoading: _vm.isMetricLoading,
                currentDateRange: _vm.currentDateRange,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "sales__card" },
          [
            _c("NonSelling", {
              attrs: {
                isLoading: _vm.isMetricLoading,
                currentDateRange: _vm.currentDateRange,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }