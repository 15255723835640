/**
 * Here contains the routes of the application.
 * And is imported in the main.js file.
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: 'smooth' };
  },
});

const superUserLockedRoutes = routes
  .filter((route) => {
    const isSuperUserLocked = route.meta.isSuperUserLocked;

    if (Array.isArray(isSuperUserLocked)) {
      return isSuperUserLocked[route.meta.breadcrumbs.activeSubPageIndex];
    } else {
      return isSuperUserLocked;
    }
  })
  .map((route) => route.path);

router.beforeEach((to, _, next) => {
  if (
    Vue.prototype.$session.getSession('pleez-token') &&
    Vue.prototype.$session.getSession('isSuperUser') === 'false' &&
    superUserLockedRoutes.findIndex((route) => route === to.path) !== -1
  ) {
    next('/home');
  } else {
    next();
  }
});

router.afterEach((_, from) => {
  /**
   * If page is not being refreshed
   * scroll authenticated layout content
   * to the top
   */
  if (from.path !== '/') {
    Vue.nextTick(() => {
      const body = document.querySelector('.content__body');
      body?.scrollTo(0, 0);
    });
  }
});

export default router;
