





















































































































import Vue from 'vue';
import CheckBox from '@/components/Inputs/Checkbox.vue';

import { SelectComponent } from './Inputs';

export default (Vue as SelectComponent).extend({
  name: 'Select',
  components: {
    CheckBox,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    values: {
      type: [],
      default: () => [],
    },
    filterKey: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    displayKey: {
      type: String,
      default: 'label',
    },
    allowNewOptions: {
      type: Boolean,
      required: false,
    },
    defaultValue: {
      type: [String, Object, Array],
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLabelVisible: {
      type: Boolean,
      default: false,
    },
    isClearable: {
      type: Boolean,
      default: true,
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    hasDivider: {
      type: Boolean,
      default: false,
    },
    hasCheckmark: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: true,
    },
    hasSelectAll: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  data() {
    return {
      selected: this.multiple ? [] : '',
      currentSearch: '',
      selectAllLabel: this.$t('filters.selectAll'),
      itemLimit: 20,
      observer: null,
      isSelectAll: false,
      isSimpleDropDown: false,
      dropDownListElement: null,
    };
  },
  computed: {
    hasCustomLabel() {
      return Boolean(this.$scopedSlots['custom-label']);
    },
    customBodyIsMultiLines() {
      try {
        let customLabel = this.$scopedSlots['custom-label']
          ? this.$scopedSlots['custom-label']({})
          : [];

        if (!customLabel) {
          customLabel = [];
        }

        return customLabel.length > 1;
      } catch {
        return false;
      }
    },
    hasSelectIcon() {
      const slots = this.$slots.default;

      return Boolean(slots && slots.length > 0);
    },
    hasCustomFooter() {
      return Boolean(this.$slots['custom-no-options']);
    },
    cleanedValues() {
      return this.values.filter(Boolean);
    },
    filtered() {
      const lowercaseSearch = this.removeAccents(this.currentSearch.toLowerCase());
      const filteredValues = this.cleanedValues.filter((value) => {
        if (typeof value === 'string') {
          const lowercaseValue = this.removeAccents(value.toLowerCase());
          return lowercaseValue.includes(lowercaseSearch);
        } else if (this.displayKey) {
          const lowercaseValue = this.removeAccents(value[this.displayKey].toLowerCase());
          return lowercaseValue.includes(lowercaseSearch);
        }
        return false;
      });

      if (this.hasSelectAll) {
        filteredValues.unshift(this.selectAllValue);
      }

      return filteredValues;
    },
    paginated() {
      return this.filtered.slice(0, this.itemLimit);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
    currentLanguage() {
      return this.$store.state.selectedLanguage;
    },
    multipleSelectionCount() {
      const selectedLength = this.selected.length;

      if (selectedLength > 1) {
        return this.multiple && !this.isSelectAll ? `+${selectedLength - 1}` : '';
      }

      return '';
    },
    selectPlaceHolder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    selectAllValue() {
      const valuesPropertyName = this.displayKey ? this.displayKey : 'label';

      this.isSimpleDropDown = typeof this.values[0] === 'string';

      return this.isSimpleDropDown
        ? this.selectAllLabel
        : { [valuesPropertyName]: this.selectAllLabel, noCustomStyle: true };
    },
    selectAllKey() {
      return this.displayKey ? this.displayKey : 'label';
    },
  },
  methods: {
    removeAccents(str) {
      return str.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    },
    async handleOnOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer?.observe(this.$refs.load);
      }
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const htmlTarget = target as HTMLElement;
        const ul = htmlTarget.offsetParent as HTMLElement;
        const scrollTop = ul.scrollTop;

        this.itemLimit += 50;

        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    searchParser(options, search) {
      return options.filter((option: Record<string, unknown>) => {
        let label = option[this.displayKey || 'label'];

        if (typeof label === 'number') {
          label = label.toString();
        }

        if (!label) {
          label = option;
        }

        return this.removeAccents((label as string).toLocaleLowerCase() || '').includes(
          this.removeAccents(search).toLocaleLowerCase()
        );
      });
    },
    handleOnClose() {
      this.observer?.disconnect();
      this.$emit('change', this.isSelectAll ? this.cleanedValues : this.selected);

      if (!this.isSelectable) {
        this.selected = this.placeholder;
      }
    },
    handleNewOption(newOption) {
      this.values.push(newOption);
    },
    handleSearch(searchQuery) {
      this.currentSearch = this.removeAccents(searchQuery.toLowerCase());
      this.$emit('search', searchQuery);
    },
    handleOptionSelecting(selectedOption) {
      if (this.hasSelectAll) {
        const optionLabel = this.isSimpleDropDown
          ? selectedOption
          : selectedOption[this.selectAllKey];

        this.isSelectAll = optionLabel === this.selectAllLabel;
      }
    },
    handleOptionSelected() {
      if (this.isSelectAll) {
        const valuesToAdd = structuredClone(this.cleanedValues);

        this.selected = valuesToAdd;
        this.selected.unshift(this.selectAllValue);
      }
    },
    /**
     * Selected All value is always at the bottom of the array
     * if it was clicked, deselect all values
     * if another value was clicked, deselect only the 'select all'
     */
    handleOptionDeselected(deselectedOption) {
      if (this.multiple) {
        const isSelectAllChecked = this.isOptionAtIndex(this.selectAllLabel, 0);
        const optionLabel = this.isSimpleDropDown
          ? deselectedOption
          : deselectedOption[this.selectAllKey];

        if (this.isSelectAll && optionLabel === this.selectAllLabel) {
          this.selected = [];
        } else if (isSelectAllChecked) {
          this.selected.shift();
        }

        this.isSelectAll = false;
      }
    },
    generateFilterKey(option) {
      if (this.filterKey) {
        return option[this.filterKey];
      } else {
        return JSON.stringify(option);
      }
    },
    isOptionAtIndex(optionName, index) {
      if (this.selected.length !== 0) {
        const selectedValueAtIndex = this.selected[index];

        if (typeof selectedValueAtIndex === 'string') {
          return optionName === selectedValueAtIndex;
        }

        return optionName === selectedValueAtIndex[this.displayKey || 'label'];
      }

      return false;
    },
    visuallySelectedOption(option) {
      if (option[this.selectAllKey] === this.selectAllLabel) {
        return this.$t('filters.selectAllLabel');
      }

      return option[this.selectAllKey];
    },
  },
  watch: {
    disabled: {
      handler(isDisable) {
        if (isDisable) {
          this.selected = [];
        }
      },
    },
    defaultValue: {
      handler(defaultValue) {
        if (this.multiple && defaultValue) {
          this.selected = [...defaultValue] || [];

          if (
            this.hasSelectAll &&
            this.selected.length > 0 &&
            this.selected.length === this.cleanedValues.length
          ) {
            this.selected.unshift(this.selectAllValue);
            this.isSelectAll = true;
          } else {
            this.isSelectAll = false;
          }
        } else {
          this.selected = defaultValue || '';
        }
      },
      immediate: true,
    },
  },
});
