






































import Vue from 'vue';
import Input from '@/components/Inputs/InputComponent.vue';

import { AskEmailComponent, SendEmailError } from './AskEmail';
import SendEmailMixin from './mixin';
import { emailRegex } from '@/utils/constants';
import ButtonDefault from '@/components/Buttons/ButtonDefault.vue';

export default (Vue as AskEmailComponent).extend({
  components: {
    Input,
    ButtonDefault,
  },
  mixins: [SendEmailMixin],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isEmailValid() {
      return emailRegex.test(this.email);
    },
  },
  methods: {
    handleEmailInputChange(inputEmail) {
      this.email = inputEmail;
    },
    handleSendEmailClick() {
      this.isLoading = true;

      this.sendEmail(true)
        .catch((error: SendEmailError) => {
          if (error?.error?.status) {
            this.$toast.error(this.$t('errors.userNotFound'));
          } else {
            this.$toast.error(this.$t('errors.requestNewPassword'));
          }
        })
        .finally(() => (this.isLoading = false));
    },
  },
});
