import Vue from 'vue';

//FIXME: Remove this and rotate arrowIcon
Vue.component('RewindIcon', () => import('@/assets/icons/rewind.svg?inline'));
//FIXME: Remove and replace with normal eye
Vue.component('EyeIconWhite', () => import('@/assets/icons/eye-white.svg?inline'));

Vue.component('ApproveIcon', () => import('@/assets/icons/approve-icon.svg?inline'));
Vue.component('ArrowIcon', () => import('@/assets/icons/forward.svg?inline'));
Vue.component('BuyOneGetTwoIcon', () => import('@/assets/icons/buy-one-get-two.svg?inline'));
Vue.component('CalendarIcon', () => import('@/assets/icons/calendar.svg?inline'));
Vue.component('ChevronIcon', () => import('@/assets/icons/chevron.svg?inline'));
Vue.component('CirclePlusIcon', () => import('@/assets/icons/circle-plus.svg?inline'));
Vue.component('CoinIcon', () => import('@/assets/icons/coin.svg?inline'));
Vue.component('CrosshairIcon', () => import('@/assets/icons/crosshair.svg?inline'));
Vue.component('DisapproveIcon', () => import('@/assets/icons/disapprove-icon.svg?inline'));
Vue.component('DownloadIcon', () => import('@/assets/icons/download.svg?inline'));
Vue.component('EllipseIcon', () => import('@/assets/icons/ellipse-icon.svg?inline'));
Vue.component('EyeIcon', () => import('@/assets/icons/eye.svg?inline'));
Vue.component('EyeClosedIcon', () => import('@/assets/icons/eye-close.svg?inline'));
Vue.component('FreeDeliveryIcon', () => import('@/assets/icons/free-delivery.svg?inline'));
Vue.component('GrowthIcon', () => import('@/assets/icons/growth.svg?inline'));
Vue.component('HandIcon', () => import('@/assets/icons/hand.svg?inline'));
Vue.component('HouseIcon', () => import('@/assets/icons/house.svg?inline'));
Vue.component('InfoIcon', () => import('@/assets/icons/info.svg?inline'));
Vue.component('KeyIcon', () => import('@/assets/icons/key.svg?inline'));
Vue.component('LogoutIcon', () => import('@/assets/icons/logout.svg?inline'));
Vue.component('MagnifyingGlassIcon', () => import('@/assets/icons/magnifying-glass.svg?inline'));
Vue.component('MaximizeIcon', () => import('@/assets/icons/maximize.svg?inline'));
Vue.component('MenuIcon', () => import('@/assets/icons/menu.svg?inline'));
Vue.component('MoneyStackIcon', () => import('@/assets/icons/money-stack.svg?inline'));
Vue.component('PinIcon', () => import('@/assets/icons/pin.svg?inline'));
Vue.component('PleezIcon', () => import('@/assets/icons/pleez.svg?inline'));
Vue.component('PleezLogo', () => import('@/assets/icons/pleez-logo.svg?inline'));
Vue.component('PoundIcon', () => import('@/assets/icons/pound.svg?inline'));
Vue.component('ProfileIcon', () => import('@/assets/icons/profile.svg?inline'));
Vue.component('PulseIcon', () => import('@/assets/icons/pulse.svg?inline'));
Vue.component('QuestionMarkIcon', () => import('@/assets/icons/question-mark.svg?inline'));
Vue.component('ReportIcon', () => import('@/assets/icons/report.svg?inline'));
Vue.component('RewardIcon', () => import('@/assets/icons/reward.svg?inline'));
Vue.component('SavingIcon', () => import('@/assets/icons/saving.svg?inline'));
Vue.component('SettingsIcon', () => import('@/assets/icons/settings.svg?inline'));
Vue.component('SquaresIcon', () => import('@/assets/icons/squares.svg?inline'));
Vue.component('TagIcon', () => import('@/assets/icons/tag.svg?inline'));
Vue.component('TargetIcon', () => import('@/assets/icons/target.svg?inline'));
Vue.component('PieChartIcon', () => import('@/assets/icons/pie-chart.svg?inline'));
Vue.component('DangerIcon', () => import('@/assets/icons/danger.svg?inline'));
Vue.component('CheckmarkIcon', () => import('@/assets/icons/checkmark.svg?inline'));
Vue.component('ChartDownIcon', () => import('@/assets/icons/chart-down.svg?inline'));
Vue.component('CloseIcon', () => import('@/assets/icons/close.svg?inline'));
Vue.component('ChartIcon', () => import('@/assets/icons/chart.svg?inline'));
Vue.component('BellIcon', () => import('@/assets/icons/bell.svg?inline'));
Vue.component('HomeIcon', () => import('@/assets/icons/home.svg?inline'));
Vue.component('PercentageIcon', () => import('@/assets/icons/percentage.svg?inline'));
Vue.component('EditIcon', () => import('@/assets/icons/edit.svg?inline'));
Vue.component('SortIcon', () => import('@/assets/icons/sort.svg?inline'));

Vue.component('UberEatsIcon', () => import('@/assets/icons/uber-eats-platform.svg?inline'));
Vue.component('BoltIcon', () => import('@/assets/icons/bolt-platform.svg?inline'));
Vue.component('GlovoIcon', () => import('@/assets/icons/glovo-platform.svg?inline'));
Vue.component('JustEatIcon', () => import('@/assets/icons/just-eat-platform.svg?inline'));
Vue.component('YouIcon', () => import('@/assets/icons/you-platform.svg?inline'));

Vue.component('CogIcon', () => import('@/assets/icons/cog.svg?inline'));
Vue.component('LinkedinIcon', () => import('@/assets/icons/linkedin.svg?inline'));
Vue.component('InstagramIcon', () => import('@/assets/icons/instagram.svg?inline'));
Vue.component('FacebookIcon', () => import('@/assets/icons/facebook.svg?inline'));
Vue.component('WhatsappIcon', () => import('@/assets/icons/whatsapp.svg?inline'));
Vue.component('EmailIcon', () => import('@/assets/icons/email.svg?inline'));
