






























import Vue from 'vue';
import { PlatformFilterComponent } from './Filters';

import PlatformIcon from '@/components/PlatformIcon/PlatformIconComponent.vue';
import Checkbox from '@/components/Inputs/Checkbox.vue';

import { platforms } from './filters.utils';
import LoaderComponent from '../Loader/LoaderComponent.vue';

export default (Vue as PlatformFilterComponent).extend({
  components: {
    PlatformIcon,
    Checkbox,
    LoaderComponent,
  },
  props: {
    showAllPlatforms: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    availablePlatforms() {
      const restaurantPlatforms = this.$store.getters.restaurantPlatforms;

      const mappedPlatforms = restaurantPlatforms.map(
        (platform: { name: string }) => platform.name
      );

      return platforms.filter((platform) => mappedPlatforms.includes(platform.name));
    },
    currentLanguage() {
      return this.$store.getters.currentLanguage;
    },
  },
  methods: {
    isCurrentlyActivePlatform(platformIndex) {
      return Number(this.$store.state.subHeaderSelectedPlatform) === platformIndex;
    },
    toggleAllPlatforms(checkboxStatus) {
      this.$store.commit('setAllPlatforms', checkboxStatus);
    },
  },
});
