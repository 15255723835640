import Vue from 'vue';

import { mobileMaxWidth, tabletMaxWidth, smallDesktopMinWidth, viewPort } from '@/utils/constants';
import { ResizeComponent } from './ResizeMixin';

export default (Vue as ResizeComponent).extend({
  data() {
    return {
      currentViewPortWidth: 0,
    };
  },
  computed: {
    currentViewPort() {
      return this.currentViewPortWidth < mobileMaxWidth
        ? viewPort.Mobile
        : this.currentViewPortWidth < tabletMaxWidth
        ? viewPort.Tablet
        : this.currentViewPortWidth < smallDesktopMinWidth
        ? viewPort.SmallDesktop
        : viewPort.Desktop;
    },
    isMobileViewPort() {
      return this.currentViewPort === viewPort.Mobile;
    },
    isTabletViewPort() {
      return this.currentViewPort === viewPort.Tablet;
    },
  },
  watch: {
    '$store.getters.currentViewPort': {
      handler(newCurrentViewPort, oldCurrentViewPort) {
        if (newCurrentViewPort !== oldCurrentViewPort) {
          this.currentViewPortWidth = newCurrentViewPort;
        }
      },
      immediate: true,
    },
  },
});
