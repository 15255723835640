










































import Vue from 'vue';
import ApexChart from 'vue-apexcharts';
import DashboardCard from './DashboardCardComponent.vue';
import { SalesAnalysisPerPeriodComponent, SalesAnalysisChartData } from './DashboardCard';
import { textSecondary2ColorHex } from '@/utils/constants';
import { getWeekday } from './dashboardCard.config';

const chartWidthColumnThreshold = 10;

export default (Vue as SalesAnalysisPerPeriodComponent).extend({
  components: {
    DashboardCard,
    ApexChart,
  },
  props: {
    chartCategory: {
      type: String,
      default: 'sales',
    },
    currentDateRange: {
      type: Object,
      required: false,
    },
    chartPeriodType: {
      type: String,
      default: 'days',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartSeries: {
        name: 'salesPerPeriod',
        data: [],
      },
      chartData: [],
      chartAverage: 0,
      chartTotal: '',
      isRequestLoading: true,
      chartOptions: {
        chart: {
          type: 'bar',
          fontFamily: 'Poppins, sans-serif',
        },
        fill: {
          colors: ['#9d44fc'],
          opacity: 1,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -48,
          style: {
            colors: [textSecondary2ColorHex],
            fontSize: '16px',
            fontWeight: 300,
          },
        },
        yaxis: {
          forceNiceScale: true,
          decimalsInFloat: 2,
          labels: {
            formatter: this.labelFormatter,
            style: {
              fontSize: '16px',
              fontWeight: 600,
            },
          },
        },
        xaxis: {
          labels: {
            style: {
              fontSize: '16px',
              fontWeight: 600,
            },
          },
        },
      },
    };
  },
  computed: {
    chartTitle() {
      return this.$t('salesDashboard.salesPerPeriod.' + this.chartCategory + '.title');
    },
    parsedChartCategory() {
      return this.chartCategory === 'averageTicket' ? 'avg_ticket' : this.chartCategory;
    },
    footerText() {
      const shouldShowFooter = this.$store.getters.platformById?.name === 'UberEats';

      return shouldShowFooter && this.isDataAvailable
        ? `*${this.$t('salesDashboard.salesPerPeriod.footer')}`
        : '';
    },
    isExtendedChart() {
      return this.chartSeries.data.length > chartWidthColumnThreshold;
    },
    isDataAvailable() {
      return this.chartSeries.data.length > 0;
    },
  },
  methods: {
    chartLabels(chartData) {
      const currentLanguage = this.$store.state.selectedLanguage;

      return [
        ...chartData.map((item) => {
          if (this.chartPeriodType === 'weekdays') {
            const weekDay = Number(item.sales_date);

            if (weekDay >= 0) {
              return this.$t(getWeekday(weekDay));
            }
          }

          const newDate = new Date(item.sales_date);

          if (this.chartPeriodType === 'days') {
            return newDate.toLocaleDateString(currentLanguage, {
              month: 'short',
              day: 'numeric',
            });
          } else {
            return newDate.toLocaleDateString(currentLanguage, {
              month: 'long',
            });
          }
        }),
      ];
    },
    labelFormatter(value) {
      if (this.parsedChartCategory === 'sales' || this.parsedChartCategory === 'avg_ticket') {
        value = `€${value}`;
      }

      return value.toString();
    },
    setupChart() {
      const isAverageTotal = this.parsedChartCategory === 'avg_ticket';

      this.chartSeries.data = [];

      if (this.chartData.length !== 0) {
        const sortedChartData = this.chartData.sort((date1, date2) => {
          return String(date1.sales_date).localeCompare(date2.sales_date);
        });
        let total = 0;

        sortedChartData.forEach((item) => {
          const value = Math.round(Number(item[this.parsedChartCategory]));

          if (value >= 0) {
            total += value;

            this.chartSeries.data.push(value);
          }
        });
        this.chartAverage = total / this.chartSeries.data.filter(Boolean).length;

        /**
         * Parse total to 1.000,00 format
         */
        const chartAverageToString = this.chartAverage.toLocaleString('de-DE', {
          maximumFractionDigits: 2,
        });
        const chartTotal = total.toLocaleString('de-DE', {
          maximumFractionDigits: 2,
        });

        this.chartTotal = this.labelFormatter(isAverageTotal ? chartAverageToString : chartTotal);

        this.chartOptions = {
          ...this.chartOptions,
          labels: this.chartLabels(sortedChartData),
          annotations: {
            yaxis: [
              {
                y: this.chartAverage,
                borderColor: '#000',
                strokeDashArray: 8,
                borderWidth: 2,
                offsetY: 8,
                label: {
                  borderColor: '#000',
                  style: {
                    fontSize: '16px',
                    fontFamily: 'Poppins, sans-serif',
                    color: '#ffffff',
                    background: '#000',
                  },
                  text: this.labelFormatter(chartAverageToString),
                },
              },
            ],
          },
        };
      }
    },
    getChartData() {
      if (!this.currentDateRange) {
        return;
      }

      this.isRequestLoading = true;

      this.$store
        .dispatch('getUserRestaurantMetric', {
          ...this.currentDateRange,
          periodType: this.chartPeriodType,
          metricType: this.chartCategory,
        })
        .then((response: SalesAnalysisChartData[]) => {
          this.chartData = response;
          this.setupChart();
        })
        .finally(() => (this.isRequestLoading = false));
    },
  },
  watch: {
    currentDateRange: {
      handler() {
        this.getChartData();
      },
      immediate: true,
    },
    chartCategory() {
      this.getChartData();
    },
    chartPeriodType() {
      this.getChartData();
    },
    '$store.state.selectedLanguage'() {
      this.setupChart();
    },
  },
});
