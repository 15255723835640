var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: [
        "button-default",
        { "button-default--is-round": _vm.isRound },
        { "button-default--is-primary": _vm.isPrimary },
        { "button-default--is-loading": _vm.isLoading },
      ],
      attrs: { disabled: _vm.isDisabled },
      on: { click: _vm.handleClick },
    },
    [
      _c("LoaderComponent", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        attrs: { "is-mini": "" },
      }),
      _c(
        "div",
        { staticClass: "button-default__slot" },
        [_vm._t("icon"), _vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }