
































import Vue from 'vue';
import RadioGroup from '@/components/Inputs/RadioGroup.vue';

import { SettingsRadioComponent } from './SettingsAccount';
import resizeMixin from '@/mixins/resize';
import Tooltip from '@/components/Tooltip/TooltipComponent.vue';

export default (Vue as SettingsRadioComponent).extend({
  components: {
    RadioGroup,
    Tooltip,
  },
  props: {
    isDisabled: {
      type: Boolean,
      required: true,
    },
    notificationsSettings: {
      type: Object,
      required: true,
    },
  },
  mixins: [resizeMixin],
  data() {
    return {
      /**
       * There's a dependency between this
       * and notificationType inside SettingsAccountView.vue
       * Ensure that the values match between them
       */
      notificationFrequency: [
        {
          label: 'settings.notificationFrequency.dontSend',
          value: 'DONT_SEND',
          tooltip: 'settings.notificationFrequency.dontSendTooltip',
        },
        {
          label: 'settings.notificationFrequency.oncePerDay',
          value: 'ONCE_PER_DAY',
          tooltip: 'settings.notificationFrequency.oncePerDayTooltip',
        },
        {
          label: 'settings.notificationFrequency.realTime',
          value: 'REAL_TIME',
          tooltip: 'settings.notificationFrequency.realTimeTooltip',
        },
      ],
      notificationType: [
        {
          label: 'settings.notificationType.email',
          value: 'email',
        },
        {
          label: 'settings.notificationType.whatsApp',
          value: 'whatsApp',
        },
      ],
    };
  },
  computed: {
    settingsGroup() {
      if (this.isMobileViewPort || this.isTabletViewPort) {
        return this.notificationFrequency;
      }

      return this.notificationType;
    },
    optionsGroup() {
      if (this.isMobileViewPort || this.isTabletViewPort) {
        return this.notificationType;
      }

      return this.notificationFrequency;
    },
    numberOfOptions() {
      return this.notificationType.length;
    },
  },
  methods: {
    /**
     * Gets the notification group for this
     * particular index. This name is then used
     * by the html to group radio buttons together
     * so that only one is selected at a time
     */
    getNotificationGroup(index) {
      return this.notificationType[index].value;
    },
    /**
     * Gets the frequency value for this
     * particular index. This is using the
     * division rest to get the correct choice.
     * Meaning that this depends on the order of the frequency's array
     *
     * e.g: frequency array has length 3 (index % length)
     * 0 % 3 = 0   4 % 3 = 0
     * 1 % 3 = 1   5 % 3 = 1
     * 2 % 3 = 2   6 % 3 = 2
     */
    getFrequencyValue(index) {
      const frequencyIndex = index % this.notificationFrequency.length;

      return this.notificationFrequency[frequencyIndex].value;
    },
    currentlyActiveInGroup(index) {
      return Object.values(this.notificationsSettings)[index];
    },
    handleRadioButtonClick(clickedRadioElement) {
      this.$emit('change', clickedRadioElement);
    },
  },
});
