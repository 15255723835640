<template>
  <div class="sales">
    <Filters class="sales__filters" :filters="{ platform: true }" />

    <component :is="isMobileViewPort ? 'div' : 'ul'" class="sales__overall">
      <LoaderComponent v-if="isMetricLoading" />

      <Slider v-else-if="isMobileViewPort" id="dashboard-slider" :sliderItems="overallMetrics">
        <template #slider-item="{ item }">
          <DashboardMetricCard :item="item" />
        </template>
      </Slider>

      <template v-else>
        <li v-for="(item, index) in overallMetrics" :key="`metrics-${index}`">
          <DashboardMetricCard :item="item" />
        </li>
      </template>
    </component>

    <div class="sales__chart">
      <h2 class="sales__chart-title">
        {{ $t('salesDashboard.title') }}
      </h2>
      <p class="sales__chart-description">
        {{ $t('salesDashboard.description') }}
      </p>

      <p class="sales__chart-description-filter">
        {{ $t('salesDashboard.filtersDescription') }}
      </p>
      <div class="sales__chart-buttons">
        <!-- Shown on tablet and up-->
        <div class="sales__chart-categories">
          <ButtonDefault
            v-for="button in analysisGraphButtons"
            class="sales__chart-button"
            :key="button"
            :is-primary="adaptiveChartType === button"
            @click="adaptiveChartType = button"
          >
            {{ $t(`salesDashboard.salesPerPeriod.${button}.title`) }}
          </ButtonDefault>
        </div>

        <!--Shown on mobile only-->
        <Select
          class="sales__chart-period-select"
          :is-clearable="false"
          :has-search="false"
          :name="$t('salesDashboard.salesPerPeriod.selectLabel')"
          :label="$t(`salesDashboard.salesPerPeriod.${adaptiveChartType}.title`)"
          :values="analysisGraphButtons"
          :default-value="adaptiveChartType"
          @change="handleSelectedChartType"
        >
          <div class="">
            <CoinIcon />
          </div>

          <template #custom-label="{ value }">
            <p>{{ $t(`salesDashboard.salesPerPeriod.${value.label}.title`) }}</p>
          </template>

          <template #custom-selected-option="{ value }">
            <p>{{ $t(`salesDashboard.salesPerPeriod.${value.label}.title`) }}</p>
          </template>
        </Select>

        <p class="sales__chart-period-buttons-label">
          {{ `${$t('salesDashboard.salesPerPeriod.viewBy')}:` }}
        </p>
        <div class="sales__chart-period-buttons">
          <ButtonDefault
            class="sales__chart-button"
            :is-primary="!isWeekDays"
            @click="isWeekDays = false"
          >
            {{ $t('salesDashboard.salesPerPeriod.' + chartDateRangeDayOrMonth) }}
          </ButtonDefault>

          <ButtonDefault
            class="sales__chart-button"
            :is-primary="isWeekDays"
            :is-loading="!currentDateRange"
            @click="isWeekDays = true"
          >
            {{ $t('salesDashboard.salesPerPeriod.weekdays') }}
          </ButtonDefault>
        </div>
      </div>

      <div class="sales__card sales__analysis-chart">
        <SalesAnalysisPerPeriod
          :chart-category="adaptiveChartType"
          :current-date-range="currentDateRange"
          :chart-period-type="chartDateRangeLabel"
          :is-loading="isMetricLoading"
        />
      </div>
    </div>

    <div class="sales__cards">
      <div class="sales__card sales__analysis-chart">
        <SalesAnalysisPerCategory
          :categoriesBreakdown="[...categoriesBreakdown]"
          :isLoading="isBreakdownLoading"
        />
      </div>

      <div class="sales__card">
        <BreakdownOfCategories
          :categoriesBreakdown="[...categoriesBreakdown]"
          :isLoading="isBreakdownLoading"
        />
      </div>

      <div class="sales__card">
        <TopSellers :isLoading="isMetricLoading" :currentDateRange="currentDateRange" />
      </div>

      <div class="sales__card">
        <NonSelling :isLoading="isMetricLoading" :currentDateRange="currentDateRange" />
      </div>
    </div>
  </div>
</template>

<script>
import Filters from '@/components/Filters/FiltersComponent.vue';
import Select from '@/components/Inputs/Select.vue';
import TopSellers from './cards/TopSellers.vue';
import NonSelling from './cards/NonSelling.vue';
import BreakdownOfCategories from './cards/BreakdownOfCategories.vue';
import SalesAnalysisPerCategory from './cards/SalesAnalysisPerCategory.vue';
import SalesAnalysisPerPeriod from './cards/SalesAnalysisPerPeriod.vue';
import Slider from '@/components/Slider/SliderComponent.vue';
import LoaderComponent from '@/components/Loader/LoaderComponent.vue';
import ButtonDefault from '@/components/Buttons/ButtonDefault.vue';
import DashboardMetricCard from './cards/DashboardMetricCard.vue';

import resizeMixin from '@/mixins/resize';
import { overallMetricsLabels, analysisGraphButtons } from './dashboard.config';

export default {
  components: {
    BreakdownOfCategories,
    Filters,
    Select,
    SalesAnalysisPerCategory,
    SalesAnalysisPerPeriod,
    TopSellers,
    NonSelling,
    Slider,
    DashboardMetricCard,
    LoaderComponent,
    ButtonDefault,
  },
  mixins: [resizeMixin],
  data() {
    return {
      adaptiveChartType: 'sales',
      showAdaptiveChartType: 'default',
      currentDateRange: null,
      overallMetrics: [],
      categoriesBreakdown: [],
      isMetricLoading: true,
      isBreakdownLoading: true,
      isWeekDays: false,
      analysisGraphButtons,
    };
  },
  computed: {
    chartDateRangeLabel() {
      return !this.isWeekDays ? this.chartDateRangeDayOrMonth : 'weekdays';
    },
    chartDateRangeDayOrMonth() {
      if (this.currentDateRange) {
        const { fromDate, toDate } = this.currentDateRange;

        const beginningDate = new Date(fromDate);
        const endDate = new Date(toDate);
        const diffInTime = endDate.getTime() - beginningDate.getTime();
        const diffInDays = diffInTime / (1000 * 3600 * 24);

        return diffInDays <= 31 ? 'days' : 'months';
      }

      return 'days';
    },
  },
  methods: {
    buildOverallMetrics(metricsData) {
      const noData = metricsData.length === 0;

      return overallMetricsLabels.map((metric) => {
        const parsedMetric = {};
        const metricLabel = metric.label;
        const metricFooterLabel = metric.footerLabel;
        const currency = metric.isCurrency ? '€' : '';

        parsedMetric.label = this.$t('overviewMetrics.' + metricLabel);
        parsedMetric.value = noData ? '-' : `${metricsData[metricLabel].toFixed(2)} ${currency}`;

        if (!noData && metricFooterLabel) {
          parsedMetric.footerLabel = this.$t('overviewMetrics.' + metricFooterLabel);
          parsedMetric.footerValue = `${metricsData[metricFooterLabel]} ${currency}`;
        }

        return parsedMetric;
      });
    },
    handleSelectedChartType(type) {
      if (type) {
        this.adaptiveChartType = type;
      }
    },
  },
  watch: {
    '$store.getters.selectedDateRange': {
      async handler(newDate) {
        if (newDate.fromDate) {
          try {
            this.isMetricLoading = true;
            this.isBreakdownLoading = true;

            await this.$store.dispatch('updateMetrics');
          } catch (error) {
            this.$toast.warning(this.$t('warnings.updateMatch'));
          } finally {
            this.currentDateRange = { fromDate: newDate.fromDate, toDate: newDate.toDate };
            this.$store
              .dispatch('getUseOverallMetrics', this.currentDateRange)
              .then((headlinesResponse) => {
                this.overallMetrics = this.buildOverallMetrics(headlinesResponse);
              })
              .finally(() => {
                this.isMetricLoading = false;
              });

            this.$store
              .dispatch('getUserRestaurantSellersBreakdown', this.currentDateRange)
              .then((response) => (this.categoriesBreakdown = response))
              .finally(() => (this.isBreakdownLoading = false));
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sales {
  //Enough to show at least 2 lines of a table
  --card-min-size: 480px;
  --card-size: 80vh;
  --card-chart-size: 70vh;

  &__overall,
  &__chart {
    margin-bottom: var(--spacing-m);
  }
  &__chart-title {
    @include brand-font-l;

    margin: var(--spacing-xl) 0 var(--spacing-xxs) 0;

    font-weight: 500;
  }
  &__chart-description,
  &__chart-description-filter {
    @include brand-font-m;

    color: var(--text-color);
  }
  &__chart-description {
    margin-bottom: var(--spacing-m);
  }
  &__chart-description-filter {
    margin-bottom: var(--spacing-xs);
  }
  &__overall {
    position: relative;

    min-height: var(--loading-min-height);
  }
  &__cards {
    display: grid;
    gap: var(--spacing-s);
  }
  &__card {
    min-width: 0;
    min-height: var(--card-min-size);
    max-height: var(--card-size);
  }
  &__analysis-chart {
    height: var(--card-size);
  }
  &__chart-buttons {
    display: flex;
    flex-direction: column;

    margin-bottom: var(--spacing-xs);
  }
  &__chart-period-select {
    width: 100%;
    margin-bottom: var(--spacing-s);
  }
  &__chart-period-buttons-label {
    @include brand-font-m;

    margin-bottom: var(--spacing-xxs);

    color: var(--text-color);
  }
  &__chart-period-buttons {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-xxs);
  }
  &__chart-button {
    width: 100%;
    margin-right: var(--spacing-xxs);
    padding: 0 var(--spacing-xs);

    &:last-child {
      margin-right: 0;
    }
  }
  &__chart-categories {
    display: none;
    align-items: center;
    flex: 1;
  }

  @include breakpoint-from('mobile') {
    &__overall {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(2, var(--spacing-3xl));

      gap: var(--spacing-xs);
    }
    &__analysis-chart {
      height: var(--card-chart-size);
    }
  }

  @include breakpoint-from('tablet') {
    &__chart-buttons {
      flex-direction: row;
    }
    &__chart-button {
      width: fit-content;
    }
    &__chart-categories {
      display: flex;
    }
    &__chart-period-select,
    &__chart-period-buttons-label {
      display: none;
    }
  }

  @include breakpoint-from('desktop') {
    --card-chart-size: 80vh;

    &__overall {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: var(--spacing-3xl);
    }

    &__cards {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content;
    }
  }
}
</style>
