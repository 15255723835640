var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "terms-of-conditions" },
    [
      _c("div", {
        class: [
          "terms-of-conditions__overlay",
          { "terms-of-conditions__overlay--is-new-user": !_vm.isAccepted },
        ],
      }),
      _vm.showTermsAndConditionModal
        ? _c("Modal", { on: { close: _vm.handleCloseClick } }, [
            _vm.termsAndConditions
              ? _c("div", {
                  staticClass: "terms-of-conditions__modal-content",
                  domProps: {
                    innerHTML: _vm._s(_vm.formatTermsAndConditions()),
                  },
                })
              : _vm._e(),
            !_vm.isAccepted
              ? _c(
                  "div",
                  { staticClass: "terms-of-conditions__modal-footer" },
                  [
                    _c("ActionsButton", {
                      attrs: { actionColorType: "approve" },
                      on: { handleClick: _vm.onTermsClick },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }