var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "metric-card__slide" }, [
    _c("div", { staticClass: "metric-card__item" }, [
      _c("div", { staticClass: "metric-card__item-header" }, [
        _c("div", { staticClass: "metric-card__item-value" }, [
          _vm._v(_vm._s(_vm.item.value)),
        ]),
        _c("div", { staticClass: "metric-card__item-label" }, [
          _vm._v(" " + _vm._s(_vm.item.label) + " "),
        ]),
      ]),
      _vm.item.footerLabel
        ? _c("div", { staticClass: "metric-card__item-footer" }, [
            _c("div", { staticClass: "metric-card__item-label" }, [
              _vm._v(_vm._s(_vm.item.footerLabel)),
            ]),
            _c("div", { staticClass: "metric-card__item-value" }, [
              _vm._v(_vm._s(_vm.item.footerValue)),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }