var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Table", {
    staticClass: "promotions-list",
    attrs: {
      "is-custom-body": "",
      "table-data": _vm.tableData,
      caption: _vm.$t("promotions.promotionsTable"),
      "no-data": _vm.$t("salesDashboard.noDataAvailable"),
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var rowBody = ref.rowBody
          return [
            _vm.CustomElement.Icon === rowBody.customElement
              ? _c("div", { staticClass: "promotions-list__promotion" }, [
                  _c(
                    "span",
                    { staticClass: "promotions-list__icon" },
                    [_c(rowBody.rawData.iconName, { tag: "component" })],
                    1
                  ),
                  _c("p", [
                    _vm._v(_vm._s(rowBody.label || rowBody.rawData.promotion)),
                  ]),
                ])
              : _c("p", [_vm._v(" " + _vm._s(rowBody.label) + " ")]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }