var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calendar__wrapper" }, [
    _c(
      "div",
      {
        ref: "calendar",
        class: ["calendar", { "calendar--is-inside-modal": _vm.isInsideModal }],
      },
      [
        _c(
          "nav",
          { staticClass: "calendar__header" },
          [
            _c("ChevronIcon", {
              staticClass: "calendar__header-icon--left",
              attrs: { "aria-hidden": "true" },
              on: {
                click: function ($event) {
                  return _vm.handleChangeMonth("left")
                },
              },
            }),
            _c("div", { staticClass: "calendar__header-selectors" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedMonth,
                      expression: "selectedMonth",
                    },
                  ],
                  staticClass: "calendar__header-selector",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedMonth = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.months, function (month, index) {
                  return _c("option", { domProps: { value: index } }, [
                    _vm._v(_vm._s(month)),
                  ])
                }),
                0
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedYear,
                      expression: "selectedYear",
                    },
                  ],
                  staticClass: "calendar__header-selector",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedYear = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.availableYears, function (year) {
                  return _c(
                    "option",
                    { key: year, domProps: { value: year } },
                    [_vm._v(" " + _vm._s(year) + " ")]
                  )
                }),
                0
              ),
            ]),
            _c("ChevronIcon", {
              staticClass: "calendar__header-icon--right",
              attrs: { "aria-hidden": "true" },
              on: {
                click: function ($event) {
                  return _vm.handleChangeMonth("right")
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "calendar__body" },
          [
            _vm._l(_vm.parsedDaysOfTheWeek, function (weekDay, index) {
              return _c(
                "header",
                { key: weekDay + index, staticClass: "calendar__week-day" },
                [_vm._v(" " + _vm._s(weekDay) + " ")]
              )
            }),
            _vm._l(_vm.calendar, function (day, index) {
              return _c(
                "button",
                {
                  key: index,
                  class: [
                    "calendar__day",
                    { "calendar__day--disabled": !_vm.isDateAvailable(day) },
                    { "calendar__day--active": _vm.isDaySelected(day) },
                    { "calendar__day--in-range": _vm.isDateInOrdersRange(day) },
                    { "calendar__day--has-sales": _vm.isDayWithSales(day) },
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.handleCalendarDayClick(day)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.showOnlyDayFromDate(day)) + " ")]
              )
            }),
          ],
          2
        ),
        _c("footer", { staticClass: "calendar__footer" }, [
          _vm.isRangeCalendar
            ? _c(
                "div",
                { staticClass: "calendar__footer-presets" },
                _vm._l(_vm.buttonPresets, function (button) {
                  return _c(
                    "button",
                    {
                      key: button.label,
                      staticClass: "calendar__footer-button",
                      on: { click: button.event },
                    },
                    [_vm._v(" " + _vm._s(button.label) + " ")]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.isSalesCalendar
            ? _c("p", { staticClass: "calendar__footer-legend" }, [
                _c("span", { staticClass: "calendar__footer-icon" }, [
                  _vm._v("●"),
                ]),
                _vm._v(
                  " " + _vm._s(_vm.$t("shared.inputs.calendar.legend")) + " "
                ),
              ])
            : _vm._e(),
          _vm.isRangeCalendar
            ? _c("div", { staticClass: "calendar__footer-actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "calendar__footer-button",
                    on: {
                      click: function () {
                        return _vm.handleClose(false)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("shared.cancel")) + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass:
                      "calendar__footer-button calendar__footer-button--primary",
                    on: {
                      click: function () {
                        return _vm.handleClose(true)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("shared.inputs.calendar.setDate")) +
                        " "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.isRangeCalendar && _vm.selectedDate1
            ? _c("div", { staticClass: "calendar__footer-selected" }, [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatDate(_vm.selectedDate1) +
                          " - " +
                          (_vm.selectedDate2
                            ? _vm.formatDate(_vm.selectedDate2)
                            : "")
                      ) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }