var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "reports" },
        [
          _c("Filters", {
            staticClass: "reports__filters",
            attrs: { filters: { platform: true, allPlatforms: true } },
          }),
          _c("div", { staticClass: "reports__content" }, [
            _c("h2", { staticClass: "reports__title" }, [
              _vm._v(" " + _vm._s(_vm.$t("sidebar.analytics.reporting")) + " "),
            ]),
            _c("p", { staticClass: "reports__description" }, [
              _vm._v(" " + _vm._s(_vm.$t("reporting.description")) + " "),
            ]),
            _c(
              "button",
              {
                staticClass: "reports__bulk-button",
                attrs: { disabled: !_vm.isAnyCheckboxSelected },
                on: { click: _vm.downloadSelected },
              },
              [
                _c(
                  "span",
                  { staticClass: "reports__bulk-button-icon" },
                  [_c("DownloadIcon")],
                  1
                ),
                _vm._v(" " + _vm._s(_vm.$t("reporting.bulkDownload")) + " "),
              ]
            ),
            _c(
              "div",
              { staticClass: "reports__table-wrapper" },
              [
                _vm.isLoading
                  ? _c("LoaderComponent")
                  : _vm.reports.length === 0
                  ? _c("p", { staticClass: "reports--no-reports" }, [
                      _vm._v(" " + _vm._s(_vm.$t("reporting.noReports")) + " "),
                    ])
                  : _c("Table", {
                      staticClass: "reports__table",
                      attrs: {
                        "is-custom-body": "",
                        caption: _vm.$t("reporting.caption"),
                        tableData: _vm.tableData,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "custom-header-item",
                          fn: function () {
                            return [
                              _c("Checkbox", {
                                attrs: { isChecked: _vm.isAllCheckboxSelected },
                                on: { change: _vm.toggleAllCheckboxes },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "default",
                          fn: function (ref) {
                            var rowBody = ref.rowBody
                            var index = ref.index
                            return [
                              rowBody.customElement ===
                              _vm.CustomElement.Checkbox
                                ? _c("Checkbox", {
                                    attrs: {
                                      isChecked: _vm.selectedCheckboxes[index],
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.toggleCheckbox($event, index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              rowBody.customElement ===
                              _vm.CustomElement.Platform
                                ? _c("PlatformIcon", {
                                    attrs: { platform: rowBody.value },
                                  })
                                : rowBody.customElement ===
                                  _vm.CustomElement.Button
                                ? _c(
                                    "button",
                                    {
                                      class: [
                                        "reports__button",
                                        {
                                          "reports__button--disabled":
                                            !_vm.isVideo(rowBody.value),
                                        },
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewVideo(rowBody.value)
                                        },
                                      },
                                    },
                                    [_c("EyeIcon")],
                                    1
                                  )
                                : rowBody.customElement ===
                                  _vm.CustomElement.Download
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "reports__button-download",
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(
                                            _vm.reports[index]
                                          )
                                        },
                                      },
                                    },
                                    [_c("DownloadIcon")],
                                    1
                                  )
                                : rowBody.value
                                ? _c(
                                    "div",
                                    {
                                      class: {
                                        reports__text:
                                          rowBody.customElement ===
                                          _vm.CustomElement.Description,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(rowBody.value) + " ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.shouldSeeReportView
        ? _c(
            "Modal",
            {
              on: {
                close: function ($event) {
                  _vm.shouldSeeReportView = false
                },
              },
            },
            [
              _c(
                "video",
                {
                  staticStyle: { width: "100%", height: "650px" },
                  attrs: { controls: "", autoplay: "" },
                },
                [
                  _c("source", {
                    attrs: { src: _vm.videoUrl, type: "video/mp4" },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }