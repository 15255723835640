import Vue from 'vue';

import Card from './Cards/CardComponentOld';
import Checkbox from './Inputs/Checkbox';
import ButtonComponent from './Buttons/Normal/ButtonsComponent';
import ModalComponent from './Modal/ModalComponentOld';

Vue.component('Card', Card);
Vue.component('p-card', Card);
Vue.component('p-checkbox', Checkbox);
Vue.component('p-button', ButtonComponent);
Vue.component('p-modal', ModalComponent);
