var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pleez-insights" },
    [
      _c("h3", { staticClass: "pleez-insights__title" }, [
        _vm._v(_vm._s(_vm.$t("shared.insights"))),
      ]),
      _c("p", { staticClass: "pleez-insights__description" }, [
        _vm._v(_vm._s(_vm.$t("pleezInsights.pageDescription"))),
      ]),
      _vm.isSuperUser && _vm.filtersData
        ? _c("div", { staticClass: "pleez-insights__banner" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("shared.superUserRequiredFilters")) + " "
            ),
          ])
        : _vm._e(),
      _c("CommonFiltersComponent", {
        attrs: { filters: _vm.filtersData },
        on: { filtersApplied: _vm.handleOnFilter },
      }),
      _c("TableInsightsComponent", {
        attrs: {
          "is-new-insights": "",
          title: _vm.$t("pleezInsights.newInsights"),
          description: _vm.$t("pleezInsights.new_insights_description"),
          "is-loading": _vm.isNewInsightsLoading,
          insights: _vm.insights,
        },
        on: {
          handleViewInsight: function ($event) {
            return _vm.openViewInsightModal($event, true)
          },
          handleUpdateInsight: _vm.updateInsight,
          handleSortClick: _vm.handleSort,
        },
      }),
      _c("TableInsightsComponent", {
        attrs: {
          title: _vm.$t("pleezInsights.allInsights"),
          description: _vm.$t("pleezInsights.allInsightsDescription"),
          "is-loading": _vm.isOldInsightsLoading,
          insights: _vm.historicInsights,
          "has-load-more": _vm.totalOldInsights > _vm.historicInsights.length,
        },
        on: {
          handleViewInsight: _vm.openViewInsightModal,
          handleSortClick: _vm.handleSort,
          handleLoadMore: _vm.handleLoadMore,
        },
      }),
      _vm.shouldViewInsightModal && _vm.viewingInsight
        ? _c(
            "Modal",
            {
              attrs: { title: _vm.$t("pleezInsights.modalTitle") },
              on: {
                close: function ($event) {
                  _vm.shouldViewInsightModal = false
                },
              },
            },
            [
              _c("p", {
                staticClass: "pleez-insights__modal-text",
                domProps: { innerHTML: _vm._s(_vm.viewingInsight.description) },
              }),
              _c(
                "div",
                { staticClass: "pleez-insights__modal-footer" },
                [
                  _vm.actionsButton || _vm.viewingInsight.status === "approved"
                    ? _c("ActionsButton", {
                        attrs: {
                          "is-filled": "",
                          actionColorType: _vm.actionsButton ? "approve" : "",
                          "is-disabled": !_vm.actionsButton,
                        },
                        on: {
                          handleClick: function ($event) {
                            return _vm.updateInsight(_vm.viewingInsight, true)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.actionsButton || _vm.viewingInsight.status === "rejected"
                    ? _c("ActionsButton", {
                        attrs: {
                          "is-filled": "",
                          actionColorType: _vm.actionsButton
                            ? "disapprove"
                            : "",
                          "is-disabled": !_vm.actionsButton,
                        },
                        on: {
                          handleClick: function ($event) {
                            return _vm.updateInsight(_vm.viewingInsight, false)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }