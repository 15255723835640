export const state = {
  isLoading: false,
  headerSelectedRestaurant: null,
  selectedLanguage: 'pt-PT',
  subHeaderSelectedPlatform: 1,
  subHeaderSelectedProduct: null,
  subHeaderSelectedDate: null,
  isDateAlreadySet: false,
  allPlatformsSelected: false,
  restaurantPlatforms: [],
  restaurants: [],
  openSelectors: [],
  restaurantFilters: {
    keywords: [],
    labels: [],
    restaurants: [],
  },
  criticalErrors: {
    filters: false,
  },
  selectedDateRange: null,
  userOrderDates: [],
  pleezInsights: null,
  viewportWidth: 0,
  commonFilters: {
    platforms: [],
    addresses: [],
  },
  cityAndPlatforms: {
    cities: [],
    platforms: [],
  },
};
