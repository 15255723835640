























import Vue from 'vue';

import DashboardCard from './DashboardCardComponent.vue';
import { NonSellers, NonSellersComponent } from './DashboardCard';
import { footerTextForUberOrGlovo, getNonSellingTableData } from './dashboardCard.config';

export default (Vue as NonSellersComponent).extend({
  components: {
    DashboardCard,
  },
  props: {
    currentDateRange: {
      type: Object,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      tableData: null,
      nonSellers: [],
      nonSellersPercentage: '-',
      isRequestLoading: true,
    };
  },
  computed: {
    footerText() {
      const text = footerTextForUberOrGlovo(this.$store.getters.platformById?.name);

      return text && this.tableData?.body?.length !== 0
        ? this.$t('salesDashboard.nonSelling.' + text)
        : '';
    },
  },
  watch: {
    currentDateRange: {
      handler(newDataRange) {
        if (newDataRange) {
          this.$store
            .dispatch('getUserRestaurantNonSellers', this.currentDateRange)
            .then((response: NonSellers) => {
              const { nonSellingsData, notSoldPercentage } = response;

              this.nonSellers = nonSellingsData || [];
              this.nonSellersPercentage = notSoldPercentage?.toFixed(2) || '-';
              this.tableData = getNonSellingTableData(response);
            })
            .finally(() => (this.isRequestLoading = false));
        }
      },
      immediate: true,
    },
  },
});
