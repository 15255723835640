var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "card",
        style:
          "max-height: " +
          _vm.maxHeight +
          "px;min-height: " +
          _vm.minHeight +
          "px;",
      },
      [
        _vm.hasHeaderSlot
          ? _c("div", { staticClass: "card-header" }, [_vm._t("header")], 2)
          : _vm._e(),
        _vm.hasBodySlot && !_vm.isLoading
          ? _c(
              "div",
              {
                staticClass: "card-content",
                class: { invisible: _vm.scroll == "invisible" },
                style:
                  "height: " +
                  _vm.height +
                  ";max-height: " +
                  _vm.contentMaxHeight +
                  "px;max-width: " +
                  _vm.maxWidth +
                  "%;min-width: " +
                  _vm.minWidth +
                  "%;" +
                  _vm.scrollType,
              },
              [_vm._t("body")],
              2
            )
          : _c("LoaderComponent"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }