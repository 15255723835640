export const platforms = [
  {
    name: 'UberEats',
    color: '#05A357',
    id: 1,
  },
  {
    name: 'Glovo',
    color: '#ffcc1b',
    id: 2,
  },
  {
    name: 'Bolt',
    color: '#c4c2c2',
    id: 3,
  },
  {
    name: 'JustEat',
    color: '#ff8000',
    id: 4,
  },
];
