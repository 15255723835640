var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "promotions" },
    [
      _c(
        "div",
        { staticClass: "promotions__filters" },
        [
          _c(
            "Select",
            {
              staticClass: "promotions__filter",
              attrs: {
                displayKey: "address_name",
                "is-clearable": false,
                defaultValue: _vm.defaultRestaurant,
                isLoading: _vm.locations.length === 0,
                values: _vm.locations,
                label: _vm.$t("promotions.searchLocation"),
              },
              on: { change: _vm.searchPromotions, search: _vm.handleSearch },
              scopedSlots: _vm._u([
                {
                  key: "custom-no-options",
                  fn: function () {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$t("promotions.searchLocation")) + " "
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                { staticClass: "promotion__filter-icon" },
                [_c("MagnifyingGlassIcon")],
                1
              ),
            ]
          ),
          _c(
            "Select",
            {
              staticClass: "promotions__filter",
              attrs: {
                isLoading: _vm.isLoading,
                values: _vm.restaurantTags,
                label: _vm.$t("shared.labels.restaurantTypes"),
                defaultValue: _vm.selectedTags,
                multiple: "",
              },
              on: { change: _vm.handleTagChange },
            },
            [
              _c(
                "div",
                { staticClass: "promotion__filter-icon" },
                [_c("TagIcon")],
                1
              ),
            ]
          ),
          _c(
            "Select",
            {
              staticClass: "promotions__filter",
              attrs: {
                isLoading: _vm.isLoading,
                values: _vm.restaurants,
                label: _vm.$t("promotions.pickRestaurant"),
                defaultValue: _vm.selectedRestaurants,
                multiple: "",
              },
              on: { change: _vm.handleRestaurantChange },
            },
            [
              _c(
                "div",
                { staticClass: "promotion__filter-icon" },
                [_c("HouseIcon")],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "promotions__calculator" }, [
        _c(
          "button",
          {
            staticClass: "promotions__calculator-btn",
            on: {
              click: function ($event) {
                _vm.openAdReturnModal = true
              },
            },
          },
          [
            _c("HandIcon", { staticClass: "promotions__calculator-icon" }),
            _vm._v(
              " " + _vm._s(_vm.$t("promotions.calculators.adTitle")) + " "
            ),
          ],
          1
        ),
        _c(
          "button",
          {
            staticClass: "promotions__calculator-btn",
            on: {
              click: function ($event) {
                return _vm.showPromotionsDisclaimerModalHandler(true, false)
              },
            },
          },
          [
            _c("MoneyStackIcon", {
              staticClass: "promotions__calculator-icon",
            }),
            _vm._v(
              " " +
                _vm._s(_vm.$t("promotions.calculators.promotion.title")) +
                " "
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "promotions__table" },
        [
          _vm.isLoading
            ? _c("LoaderComponent")
            : _vm.list.length || _vm.list.length
            ? _c("PromotionsList", { attrs: { data: _vm.allData } })
            : _c("p", [_vm._v(_vm._s(_vm.$t("promotions.noAvailable")))]),
        ],
        1
      ),
      _c("PromotionCalculatorModal", {
        model: {
          value: _vm.openPromotionCalculator,
          callback: function ($$v) {
            _vm.openPromotionCalculator = $$v
          },
          expression: "openPromotionCalculator",
        },
      }),
      _c("PromotionDisclaimerModal", {
        attrs: {
          "show-skip": _vm.showSkip,
          "show-close": _vm.showCloseButtonPromotionCalculatorModal,
        },
        on: { skip: _vm.handleOnClickSkip },
        model: {
          value: _vm.openPromotionDisclaimer,
          callback: function ($$v) {
            _vm.openPromotionDisclaimer = $$v
          },
          expression: "openPromotionDisclaimer",
        },
      }),
      _vm.openAdReturnModal
        ? _c("AdReturnCalculatorModal", {
            on: {
              close: function ($event) {
                _vm.openAdReturnModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }