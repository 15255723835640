









































































































import Vue from 'vue';
import Card from '@/components/Cards/CardComponent.vue';
import TimeSavedCalculator from '@/components/TimeSavedCalculator/TimeSavedCalculator.vue';
import LoaderComponent from '@/components/Loader/LoaderComponent.vue';

import { HomeComponent, NetworkHomeResponse } from './Home';
import { shortcutCards } from './homepage.config';

export default (Vue as HomeComponent).extend({
  components: {
    Card,
    TimeSavedCalculator,
    LoaderComponent,
  },
  data() {
    return {
      timeSaver: null,
      performance: null,
      newInsights: null,
      shortcutCards,
    };
  },
  mounted() {
    this.$store.dispatch('getHomePageDetails').then((response: NetworkHomeResponse) => {
      this.timeSaver = response.timeSaver;
      this.newInsights = response.waitingRecommendations;
    });
  },
});
