

























































import Vue from 'vue';
import Card from '@/components/Cards/CardComponent.vue';
import Table from '@/components/Table/TableComponent.vue';

import { DashboardCardComponent } from './DashboardCard';

export default (Vue as DashboardCardComponent).extend({
  components: {
    Table,
    Card,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    /**
     * This value is mandatory
     * is a table is present
     */
    caption: {
      type: String,
      required: false,
    },
    tableData: {
      type: Object,
      required: false,
    },
    downloadData: {
      type: Array,
      required: false,
    },
    downloadDataFileName: {
      type: String,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    isTableCard: {
      type: Boolean,
      default: true,
    },
    footerText: {
      type: String,
      required: false,
    },
  },
});
