var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { title: _vm.$t("promotions.calculators.adTitle") },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "body-container" }, [
        _c("div", { staticClass: "icon-container" }, [
          _c("img", {
            staticClass: "top",
            attrs: { src: require("@/assets/images/icons/hourglass-top.svg") },
          }),
          _c("img", {
            staticClass: "bottom",
            attrs: {
              src: require("@/assets/images/icons/hourglass-bottom.svg"),
            },
          }),
        ]),
        _c("h4", [
          _vm._v(
            " " + _vm._s(_vm.$t("promotions.calculators.adDescription")) + " "
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }