import { ViewPort, CustomElement } from '@/utils/_enums/global';

import { Invoice } from './SettingsPayments';
import { TableFormated } from './SettingsPayments';

const fixedHeaders = [
  {
    label: 'invoices.label',
    minViewPort: ViewPort.Mobile,
  },
  {
    label: 'shared.date',
    minViewPort: ViewPort.SmallDesktop,
  },
  {
    label: 'shared.view',
    minViewPort: ViewPort.Mobile,
  },
  {
    label: 'shared.status',
    minViewPort: ViewPort.SmallDesktop,
  },
];

export const getTableData = (data: Invoice[]): TableFormated => {
  const body = data.map((dataItem, index) => {
    const date = dataItem.date === 'undefined' ? '-' : dataItem.date; // Sad backend noises
    return {
      id: dataItem.id,
      values: [
        {
          customElement: CustomElement.Description,
          value: dataItem.name.length > 50 ? dataItem.name.slice(0, 50) + '...' : dataItem.name,
          rowData: dataItem,
          id: `${dataItem.id}-name-${index}`,
          minViewPort: ViewPort.Mobile,
        },
        {
          value: date ? date : '-',
          id: `${dataItem.id}-date-${index}`,
          minViewPort: ViewPort.SmallDesktop,
        },
        {
          customElement: CustomElement.Button,
          rowData: dataItem.url,
          id: `${dataItem.id}-button-${index}`,
          minViewPort: ViewPort.Mobile,
        },
        {
          value: dataItem.status || '-',
          customElement: CustomElement.Status,
          id: `${dataItem.id}-status-${index}`,
          minViewPort: ViewPort.SmallDesktop,
        },
      ],
    };
  });

  return { headers: fixedHeaders, body };
};
