import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import { state } from './state';
import Getters from './getters';
import Mutations from './mutations';
import Actions from './actions';

const store = new Vuex.Store({
  state,
  mutations: Mutations,
  actions: Actions,
  getters: Getters,
});

export default store;
