var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "checkbox" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.checkboxValue,
          expression: "checkboxValue",
        },
      ],
      staticClass: "checkbox__input",
      attrs: { type: "checkbox", disabled: _vm.isDisabled },
      domProps: {
        checked: Array.isArray(_vm.checkboxValue)
          ? _vm._i(_vm.checkboxValue, null) > -1
          : _vm.checkboxValue,
      },
      on: {
        change: [
          function ($event) {
            var $$a = _vm.checkboxValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checkboxValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checkboxValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checkboxValue = $$c
            }
          },
          _vm.handleCheckboxClick,
        ],
      },
    }),
    _vm._v(" " + _vm._s(_vm.label) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }