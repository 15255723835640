import HttpRequest from '@/http/httpClass';
import { AskEmailResponse } from './AskEmail';
import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    email: '',
  }),
  methods: {
    sendEmail(nextStep?: boolean) {
      const request = new HttpRequest('/users/generateResetPasswordURL', 'POST', false, {
        email: this.email,
      });

      return request.send<AskEmailResponse>().then((response) => {
        if (response.message) {
          this.$toast.success(this.$t('forgotPassword.emailSent'));

          if (nextStep) {
            this.$emit('nextStep', { email: this.email });
          }
        }
      });
    },
  },
});
