







import Vue from 'vue';
import { TooltipComponent } from './Tooltip';

export default (Vue as TooltipComponent).extend({
  props: {
    isDeconstructed: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: true,
    },
  },
});
