


















import Vue from 'vue';

import PerformanceGraphs from './PerformanceGraphs.vue';

import { secondaryColorHex, textSecondary2ColorHex } from '@/utils/constants';

import { ConversionRateComponent } from './ConversionRateComponent';

export default (Vue as ConversionRateComponent).extend({
  components: {
    PerformanceGraphs,
  },
  props: {
    currentDateRange: {
      type: Object,
      required: false,
    },
    chartPeriodType: {
      type: String,
      default: 'days',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    labelFormatter(value) {
      return `${value}%`;
    },
    downloadableDataFormatter: (labels, data) => {
      return labels.map((label, index) => {
        return {
          date: label,
          conversionRate: data[index] + '%',
        };
      });
    },
  },
  data() {
    return {
      chartColors: [secondaryColorHex],
      chartDataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          colors: [textSecondary2ColorHex],
        },
        formatter: this.labelFormatter,
      },
    };
  },
});
