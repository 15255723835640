var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-changes-mobile" },
    [
      _c("Select", {
        staticClass: "menu-changes-mobile__sort",
        attrs: {
          "no-label": "",
          "is-clearable": false,
          "has-search": false,
          name: _vm.$t("pleezInsights.sortBy"),
          label: _vm.$t("pleezInsights.sortBy"),
          values: _vm.sortOptions,
          "default-value": _vm.$t("pleezInsights.sortBy"),
        },
        on: { change: _vm.handleSortOption },
      }),
      _vm.menuChanges.length > 0
        ? _c(
            "ul",
            { staticClass: "menu-changes-mobile__list" },
            _vm._l(_vm.menuChanges, function (change) {
              return _c(
                "MenuChangesMobile",
                _vm._g(
                  {
                    key: change.id,
                    staticClass: "menu-changes-mobile__insight",
                    attrs: {
                      menuChange: change,
                      maxInsightDescriptionHeight:
                        _vm.maxInsightDescriptionHeight,
                      "has-load-more": _vm.hasLoadMore,
                    },
                  },
                  _vm.$listeners
                )
              )
            }),
            1
          )
        : _c("div", { staticClass: "menu-changes-mobile__no-insights" }, [
            _vm._v(" " + _vm._s(_vm.$t("pleezInsights.noInsights")) + " "),
          ]),
      _vm.hasLoadMore
        ? _c(
            "div",
            { staticClass: "menu-changes-mobile__load-more" },
            [
              _c(
                "ButtonDefault",
                {
                  staticClass: "menu-changes-mobile__load-more-button",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleLoadMore")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("shared.loadMore")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }