


































import Vue from 'vue';

import HttpRequest from '@/http/httpClass';
import { ForgotPasswordComponent, TokenCheckResponse } from './ForgotPassword';

import Steps from './Steps/';

export default (Vue as ForgotPasswordComponent).extend({
  components: {
    ...Steps,
  },
  data: () => ({
    step: 1,
    email: '',
    token: '',
    titles: [
      '',
      'forgotPassword.title',
      'forgotPassword.nowCheckYourEmail',
      'forgotPassword.createNewPassword',
      'forgotPassword.successfulPasswordReset',
    ],
  }),
  async mounted() {
    let { token } = this.$route.query;

    if (token) {
      this.step = -1;

      const request = new HttpRequest('/users/validateToken', 'POST', false, {
        token,
      });

      const response: TokenCheckResponse = (await request.send().catch((error) => {
        // TODO TRANSLATED ERROR MESSAGE
        this.$toast.error(error.response.message);
      })) as TokenCheckResponse;

      if (response.message && response.email) {
        this.step = 3;
        this.email = response.email;
        this.token = token;
      } else if (response.error) {
        this.$router.replace({ query: {} });
        this.$toast.error(response.error);
      }
    }
  },
  methods: {
    goBack() {
      if (this.step > 1) {
        this.step--;
      } else {
        this.$router.go(-1);
      }
    },
    nextStep({ email }) {
      this.step++;

      if (email) {
        this.email = email;
      }
    },
  },
});
