<template>
  <Table
    class="promotions-list"
    is-custom-body
    v-slot="{ rowBody }"
    :table-data="tableData"
    :caption="$t('promotions.promotionsTable')"
    :no-data="$t('salesDashboard.noDataAvailable')"
  >
    <div v-if="CustomElement.Icon === rowBody.customElement" class="promotions-list__promotion">
      <span class="promotions-list__icon">
        <component :is="rowBody.rawData.iconName" />
      </span>
      <p>{{ rowBody.label || rowBody.rawData.promotion }}</p>
    </div>

    <p v-else>
      {{ rowBody.label }}
    </p>
  </Table>
</template>

<script>
import Table from '@/components/Table/TableComponent.vue';
import { CustomElement } from '@/utils/_enums/global';

import { getTableData } from '@/views/market/promotions/promotions.config';

export default {
  name: 'PromotionsList',
  components: {
    Table,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      CustomElement,
    };
  },
  computed: {
    tableData() {
      return getTableData(this.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.promotions-list {
  &__promotion {
    display: flex;
    align-items: center;
    gap: var(--spacing-xxs);
  }
  &__icon {
    display: none;
  }

  ::v-deep {
    .table-component__header {
      background-color: var(--grey-background);
    }

    td,
    th {
      &:nth-child(2) {
        text-align: left;

        .table-component__header-item-content {
          justify-content: flex-start;
        }
      }
      &:last-child {
        padding-right: var(--spacing-s);
      }
    }
  }

  @include breakpoint-from('mobile') {
    &__icon {
      display: block;
    }
  }
}
</style>
