







































import Vue from 'vue';

export default Vue.extend({
  props: {
    isEditMode: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isAbleToSave: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    formId: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
});
