












import Vue from 'vue';
import DashboardCard from './DashboardCardComponent.vue';

import { BreakdownSellersComponent } from './DashboardCard';
import { getBreakdownTableData } from './dashboardCard.config';

export default (Vue as BreakdownSellersComponent).extend({
  components: {
    DashboardCard,
  },
  props: {
    categoriesBreakdown: {
      type: Array,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tableData: null,
      breakdownSellers: [],
    };
  },
  watch: {
    categoriesBreakdown: {
      handler(categories) {
        if (categories) {
          this.breakdownSellers = categories;
          this.tableData = getBreakdownTableData(categories);
        }
      },
      immediate: true,
    },
  },
});
