var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardCard",
    {
      staticClass: "performance",
      attrs: {
        title: _vm.title,
        description: _vm.description,
        "is-table-card": false,
        "is-loading": _vm.isLoading || _vm.isRequestLoading,
        "download-data": _vm.downloadableData,
        "download-data-file-name": _vm.downloadableFileName,
      },
    },
    [
      _vm.chartSeries.data.length > 0
        ? [
            _c("p", { staticClass: "performance__drag-hint" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("salesDashboard.dragAvailable")) + " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "performance__chart" },
              [
                _c("ApexChart", {
                  class: [
                    "performance__chart-svg",
                    { "performance__chart-svg--extended": _vm.isExtendedChart },
                  ],
                  attrs: {
                    height: "100%",
                    options: _vm.chartOptions,
                    series: [_vm.chartSeries],
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "performance__chart-legend" }),
          ]
        : _c("p", { staticClass: "performance--no-data" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("salesDashboard.noDataAvailable")) + " "
            ),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }