var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "check-email" },
    [
      _c("p", { staticClass: "check-email__description" }, [
        _vm._v(" " + _vm._s(_vm.$t("forgotPassword.weSentAlinkTo")) + " "),
        _c("span", { staticClass: "check-email__description-email" }, [
          _vm._v(" " + _vm._s(_vm.email) + " "),
        ]),
        _vm._v(" . "),
      ]),
      _c("p", { staticClass: "check-email__didnt-receive" }, [
        _vm._v(" " + _vm._s(_vm.$t("forgotPassword.didntReceived")) + " "),
      ]),
      _c(
        "ButtonDefault",
        {
          staticClass: "check-email__button",
          attrs: { "is-round": "" },
          on: { click: _vm.handleSendAgainClick },
        },
        [_vm._v(" " + _vm._s(_vm.$t("forgotPassword.sendAgain")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }