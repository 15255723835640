




















































import Vue from 'vue';
import LoaderComponent from '@/components/Loader/LoaderComponent.vue';
import Slider from '@/components/Slider/SliderComponent.vue';
import PlatformCard from './PlatformCard.vue';

//Naming and positioning of this component will change in a future update
import DashboardMetricCard from '@/views/dashboard/cards/DashboardMetricCard.vue';

import { overallMetricsLabels } from './Overview.config';
import { OverviewCardData, OverviewComponent, OverviewBackendData } from './Overview';

import resizeMixin from '@/mixins/resize';
import { viewPort } from '@/utils/constants';

export default (Vue as OverviewComponent).extend({
  components: {
    LoaderComponent,
    Slider,
    DashboardMetricCard,
    PlatformCard,
  },
  mixins: [resizeMixin],
  data() {
    return {
      overallProviderMetrics: [],
      overallMetrics: [],
      isMetricLoading: true,
    };
  },
  methods: {
    /**
     * A copy from the dashboard.
     * The cards component will be updated soon and centralized here
     * so for now it stays duplicated
     */
    buildOverallMetrics(metricsData) {
      const noData = metricsData.orders === 0;

      if (!noData) {
        metricsData.totalSales = Number(metricsData.totalSales.toFixed(2));
      }

      return overallMetricsLabels.map((metric) => {
        const parsedMetric = {} as OverviewCardData;
        const metricLabel = metric.label;
        const metricFooterLabel = metric.footerLabel;
        const currency = metric.isCurrency ? '€' : '';

        parsedMetric.label = this.$t('overviewMetrics.' + metricLabel);
        parsedMetric.value = noData ? '-' : `${metricsData[metricLabel]} ${currency}`;
        if (!noData && metricFooterLabel) {
          parsedMetric.footerLabel = this.$t('overviewMetrics.' + metricFooterLabel);
          parsedMetric.footerValue = `${metricsData[metricFooterLabel]} ${currency}`;
        }

        return parsedMetric;
      });
    },
    buildPlatformMetrics(metricsData) {
      let platforms = ['Glovo', 'UberEats', 'Bolt'];

      const metricsByPlatform = metricsData.map((platformData) => {
        platforms = platforms.filter((platform) => platformData.platform !== platform);

        return {
          values: this.buildOverallMetrics(platformData),
          platform: platformData.platform,
        };
      });

      return [
        ...metricsByPlatform,
        ...platforms.map((platformName) => {
          return {
            values: this.buildOverallMetrics(this.emptyPlatformData),
            platform: platformName,
          };
        }),
      ];
    },
  },
  computed: {
    isDesktop() {
      return this.currentViewPort === viewPort.Desktop;
    },
    emptyPlatformData() {
      return {
        averageTicket: 0,
        itemPerOrder: 0,
        items: 0,
        netSales: 0,
        orders: 0,
        salesPerItem: 0,
        totalSales: 0,
      };
    },
  },
  watch: {
    '$store.getters.selectedDateRange': {
      handler(newDate) {
        if (newDate?.selectedDate) {
          this.isMetricLoading = true;

          const { fromDate, toDate } = this.$store.getters.selectedDateRange;

          this.$store
            .dispatch('getUserRestaurantOverallMetrics', { fromDate, toDate })
            .then((response: OverviewBackendData) => {
              this.overallProviderMetrics = this.buildPlatformMetrics(response.byProvider);
              this.overallMetrics = this.buildOverallMetrics(response.total);
            })
            .finally(() => (this.isMetricLoading = false));
        }
      },
      immediate: true,
    },
  },
});
