<template>
  <div class="new-password-success">
    <p class="new-password-success_description">
      {{ $t('forgotPassword.newPasswordSuccess') }}
    </p>

    <ButtonDefault type="submit" class="new-password-success__button" is-round>
      <a class="new-password-success__button-link" href="/">{{ $t('forgotPassword.gotoLogin') }}</a>
    </ButtonDefault>
  </div>
</template>

<script>
import ButtonDefault from '@/components/Buttons/ButtonDefault.vue';

export default {
  components: {
    ButtonDefault,
  },
};
</script>
<style lang="scss" scoped>
.new-password-success {
  display: flex;
  flex-direction: column;

  height: 100%;

  color: var(--brand-primary);

  &__title {
    margin-bottom: var(--spacing-xxs);

    font-weight: bold;
  }
  &__button {
    width: 80%;
    margin: var(--spacing-m) auto;
  }
  &__button-link {
    color: var(--color-white);
  }

  @include breakpoint-from('mobile') {
    height: auto;
    justify-content: center;

    &__pulse {
      margin-top: 0;
    }
    &__title {
      font-size: 2rem;
    }
    &__button {
      width: 50%;
      margin: var(--spacing-xl) auto;
    }
  }
}
</style>
