























import Vue from 'vue';
import Modal from '@/components/Modal/ModalComponent.vue';

import { TermsAndConditionsComponent } from './TermsAndConditions';

import ActionsButton from '@/components/Buttons/ActionsButton.vue';
import ApproveIcon from '@/assets/icons/approve-icon.svg?inline';
import DisapproveIcon from '@/assets/icons/disapprove-icon.svg?inline';

export default (Vue as TermsAndConditionsComponent).extend({
  components: {
    Modal,
    ActionsButton,
    ApproveIcon,
    DisapproveIcon,
  },
  data: () => ({
    showTermsAndConditionModal: false,
    closedWithoutAgree: true,
  }),
  props: {
    termsAndConditions: {
      type: Object,
      default: null,
    },
    isAccepted: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    termsAndConditions: {
      handler(newVal) {
        if (newVal) {
          this.showTermsAndConditionModal = true;
        }
      },
      immediate: true,
    },
    showTermsAndConditionModal(newVal) {
      if (this.closedWithoutAgree && !newVal) {
        this.showTermsAndConditionModal = true;
      }
    },
  },
  methods: {
    onTermsClick() {
      if (!this.termsAndConditions) {
        return;
      }

      this.$store.dispatch('setTermsAndConditions', { termsId: this.termsAndConditions.terms.id });
      this.closedWithoutAgree = false;
      this.showTermsAndConditionModal = false;
      this.$emit('termsAgreed');
    },
    handleCloseClick() {
      if (this.isAccepted) {
        this.$emit('closeClick');
      }
    },
    formatTermsAndConditions() {
      if (!this.termsAndConditions) {
        return '';
      }
      // FIXME: Why currentLanguage is not returning its type?
      let currentLanguage: string = this.$store.getters.currentLanguage;
      return this.termsAndConditions.terms[currentLanguage];
    },
  },
});
