var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "insight-mobile" }, [
    _c(
      "div",
      {
        class: [
          "insight-mobile__platform",
          { "insight-mobile__platform--new": _vm.isNewInsight },
        ],
      },
      [
        _c("PlatformIcon", {
          staticClass: "insight-mobile__platform-icon",
          attrs: { platform: _vm.insight.deliveryPlatform },
        }),
        _c("p", [_vm._v(_vm._s(_vm.insight.deliveryPlatform))]),
      ],
      1
    ),
    _c(
      "div",
      { ref: "insightInfoBox", staticClass: "insight-mobile__info-box" },
      [
        _c("p", { staticClass: "insight-mobile__restaurant-name" }, [
          _vm._v(_vm._s(_vm.insight.restaurantName)),
        ]),
        _c(
          "p",
          {
            ref: "insightRestaurant",
            class: [
              "insight-mobile__restaurant-address",
              {
                "insight-mobile__restaurant-address--with-ellipsis":
                  _vm.hasEllipsis,
              },
            ],
          },
          [_vm._v(" " + _vm._s(_vm.insight.restaurantAddress) + " ")]
        ),
        _c("p", {
          ref: "insightDescription",
          class: ["insight-mobile__insight-description"],
          domProps: { innerHTML: _vm._s(_vm.insight.description) },
        }),
      ]
    ),
    _vm.hasOverflow
      ? _c(
          "button",
          {
            staticClass: "insight-mobile__see-more",
            on: { click: _vm.handleSeeMoreClick },
          },
          [
            _vm.hasEllipsis
              ? _c("span", [
                  _vm._v("+ " + _vm._s(_vm.$t("pleezInsights.seeMore"))),
                ])
              : _c("span", [
                  _vm._v("- " + _vm._s(_vm.$t("pleezInsights.hide"))),
                ]),
          ]
        )
      : _vm._e(),
    _c("p", { staticClass: "insight-mobile__date" }, [
      _vm._v(" " + _vm._s(_vm.$t("shared.date")) + ": "),
      _c("span", { staticClass: "insight-mobile__date--value" }, [
        _vm._v(
          " " + _vm._s(new Date(_vm.insight.date).toLocaleDateString()) + " "
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "insight-mobile__buttons" },
      [
        _vm.isNewInsight || _vm.insight.status === "approved"
          ? _c("ActionsButton", {
              staticClass: "insight-mobile__button",
              attrs: {
                actionColorType: "approve",
                "is-disabled":
                  _vm.insight.status === "approved" && !_vm.isNewInsight,
                "is-filled": _vm.isNewInsight,
                label: _vm.approveEDText,
              },
              on: {
                handleClick: function ($event) {
                  return _vm.handleInsightActionClick(_vm.insight, true)
                },
              },
            })
          : _vm._e(),
        _vm.isNewInsight || _vm.isExpiredOrRejected(_vm.insight.status)
          ? _c("ActionsButton", {
              staticClass: "insight-mobile__button",
              attrs: {
                actionColorType: "disapprove",
                "is-disabled":
                  _vm.isExpiredOrRejected(_vm.insight.status) &&
                  !_vm.isNewInsight,
                "is-filled": _vm.isNewInsight,
                label: _vm.expiredOrRejectedText(_vm.insight.status),
              },
              on: {
                handleClick: function ($event) {
                  return _vm.handleInsightActionClick(_vm.insight, false)
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }