





































import Vue from 'vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

import { SliderComponent } from './Slider';

export default (Vue as SliderComponent).extend({
  components: {
    Splide,
    SplideSlide,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    sliderItems: {
      type: Array,
      required: true,
    },
    heightRatio: {
      type: Number,
      default: 1,
    },
    offset: {
      type: Number,
      default: 16,
    },
  },
  data() {
    return {
      sliderOptions: {
        arrows: false,
        pagination: false,
        heightRatio: this.heightRatio,
        padding: this.offset + '%',
        height: 'auto',
        gap: 'var(--spacing-xs)',
      },
      currentSliderIndex: 1,
    };
  },
  computed: {
    isArrowPrevDisabled() {
      return this.currentSliderIndex === 1;
    },
    isArrowNextDisabled() {
      return this.currentSliderIndex === this.sliderItems.length;
    },
  },
  methods: {
    handleArrowClick(isForward = false) {
      this.$refs.slider.go(isForward ? '>' : '<');
      this.currentSliderIndex = this.$refs.slider.index + 1;
    },
    handleDotClick(index) {
      this.$refs.slider.go(index - 1);
      this.currentSliderIndex = this.$refs.slider.index + 1;
    },
  },
});
