var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c(
        "div",
        { staticClass: "platform-icon__loader" },
        [_c("LoaderComponent", { attrs: { isMini: "" } })],
        1
      )
    : _vm.platform === "UberEats"
    ? _c(
        "div",
        {
          class: [
            "platform-icon",
            "platform-icon--uber",
            { "platform-icon--big": _vm.isBig },
          ],
        },
        [_c("UberEatsIcon", { attrs: { "aria-label": "Uber eats" } })],
        1
      )
    : _vm.platform === "Bolt"
    ? _c(
        "div",
        {
          class: [
            "platform-icon",
            "platform-icon--bolt",
            { "platform-icon--big": _vm.isBig },
          ],
        },
        [_c("BoltIcon", { attrs: { "aria-label": "Bolt food" } })],
        1
      )
    : _vm.platform === "Glovo"
    ? _c(
        "div",
        {
          class: [
            "platform-icon",
            "platform-icon--glovo",
            { "platform-icon--big": _vm.isBig },
          ],
        },
        [_c("GlovoIcon", { attrs: { "aria-label": "Glovo" } })],
        1
      )
    : _vm.platform === "JustEat"
    ? _c(
        "div",
        {
          class: [
            "platform-icon",
            "platform-icon--justEat",
            { "platform-icon--big": _vm.isBig },
          ],
        },
        [_c("JustEatIcon", { attrs: { "aria-label": "Just eat" } })],
        1
      )
    : _vm.platform === "User"
    ? _c(
        "div",
        {
          class: [
            "platform-icon",
            "platform-icon--user",
            { "platform-icon--big": _vm.isBig },
          ],
        },
        [_c("YouIcon", { attrs: { "aria-label": "You" } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }