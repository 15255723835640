import { ViewPort, CustomElement } from '@/utils/_enums/global';

import { ReportFile } from '@/store/store';

const getDataForMobile = (data: ReportFile[]) => {
  return data.map((dataItem, index) => {
    return {
      id: dataItem.title + index,
      values: [
        {
          customElement: CustomElement.Checkbox,
          id: `${dataItem.title}-checkbox-${index}`,
          minViewPort: ViewPort.Mobile,
        },
        {
          customElement: CustomElement.Description,
          value: dataItem.title,
          id: `${dataItem.title}-item-${index}`,
          minViewPort: ViewPort.Mobile,
        },
        {
          customElement: CustomElement.Button,
          value: dataItem.downloadLink,
          id: `${dataItem.title}-button-${index}`,
          minViewPort: ViewPort.Mobile,
        },
      ],
    };
  });
};

const getDataForTablet = (data: ReportFile[]) => {
  return data.map((dataItem, index) => {
    return {
      id: dataItem.title + index,
      values: [
        {
          customElement: CustomElement.Checkbox,
          id: `${dataItem.title}-checkbox-${index}`,
          minViewPort: ViewPort.Mobile,
        },
        {
          customElement: CustomElement.Platform,
          value: dataItem.platformName,
          id: `${dataItem.title}-platform-${index}`,
          minViewPort: ViewPort.Tablet,
        },
        {
          customElement: CustomElement.Description,
          value: dataItem.title,
          id: `${dataItem.title}-item-${index}`,
          minViewPort: ViewPort.Mobile,
        },
        {
          customElement: CustomElement.Button,
          value: dataItem.downloadLink,
          id: `${dataItem.title}-button-${index}`,
          minViewPort: ViewPort.Mobile,
        },
      ],
    };
  });
};

const getDataForDesktop = (data: ReportFile[]) => {
  return data.map((dataItem, index) => {
    const date = dataItem.date === 'undefined' ? '-' : dataItem.date; // Sad backend noises

    return {
      id: dataItem.title + index,
      values: [
        {
          customElement: CustomElement.Checkbox,
          id: `${dataItem.title}-checkbox-${index}`,
          minViewPort: ViewPort.Mobile,
        },
        {
          customElement: CustomElement.Platform,
          value: dataItem.platformName,
          id: `${dataItem.title}-platform-${index}`,
          minViewPort: ViewPort.Tablet,
        },
        {
          customElement: CustomElement.Description,
          value: dataItem.title,
          id: `${dataItem.title}-item-${index}`,
          minViewPort: ViewPort.Mobile,
        },
        { value: date, id: `${dataItem.title}-date-${index}`, minViewPort: ViewPort.SmallDesktop },
        {
          customElement: CustomElement.Button,
          value: dataItem.downloadLink,
          id: `${dataItem.title}-button-${index}`,
          minViewPort: ViewPort.Mobile,
        },
        {
          customElement: CustomElement.Download,
          value: dataItem.downloadLink,
          id: `${dataItem.title}-download-${index}`,
          minViewPort: ViewPort.SmallDesktop,
        },
      ],
    };
  });
};

export const getTableData = (viewPort: ViewPort, data: ReportFile[]) => {
  const headers = [
    {
      label: 'shared.delivery',
      id: 'delivery-header',
      minViewPort: ViewPort.Tablet,
    },
    {
      label: 'reporting.reportsForDownload.report',
      id: 'report-header',
      minViewPort: ViewPort.Mobile,
    },
    {
      label: 'reporting.reportsForDownload.date',
      id: 'date-header',
      minViewPort: ViewPort.SmallDesktop,
    },
    {
      label: 'reporting.reportsForDownload.see',
      id: 'see-header',
      minViewPort: ViewPort.Mobile,
    },
    {
      label: 'reporting.reportsForDownload.download',
      id: 'download-header',
      minViewPort: ViewPort.SmallDesktop,
    },
  ];
  let body = null;

  switch (viewPort) {
    case ViewPort.Mobile:
      body = getDataForMobile(data);
      break;
    case ViewPort.Tablet:
      body = getDataForTablet(data);
      break;
    default:
      body = getDataForDesktop(data);
      break;
  }

  return { headers, body };
};
