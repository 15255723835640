export const overallMetricsLabels = [
  {
    label: 'sales',
    isCurrency: true,
  },
  {
    label: 'orders',
    footerLabel: 'itemByOrder',
  },
  {
    label: 'itemsSold',
    footerLabel: 'salesPerItem',
  },
  {
    label: 'averageTicket',
    isCurrency: true,
  },
];

export const analysisGraphButtons = ['sales', 'items', 'orders', 'averageTicket'];
