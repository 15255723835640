var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new-password-success" },
    [
      _c("p", { staticClass: "new-password-success_description" }, [
        _vm._v(" " + _vm._s(_vm.$t("forgotPassword.newPasswordSuccess")) + " "),
      ]),
      _c(
        "ButtonDefault",
        {
          staticClass: "new-password-success__button",
          attrs: { type: "submit", "is-round": "" },
        },
        [
          _c(
            "a",
            {
              staticClass: "new-password-success__button-link",
              attrs: { href: "/" },
            },
            [_vm._v(_vm._s(_vm.$t("forgotPassword.gotoLogin")))]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }