











































































import Vue from 'vue';
import Select from '@/components/Inputs/Select.vue';
import Checkbox from '@/components/Inputs/Checkbox.vue';
import ButtonDefault from '../Buttons/ButtonDefault.vue';
import Dropdown from './Dropdown.vue';

import { RestaurantFilterComponent, Restaurant } from './Filters';

export default (Vue as RestaurantFilterComponent).extend({
  components: {
    Select,
    Checkbox,
    ButtonDefault,
    Dropdown,
  },
  props: {
    noFilters: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      restaurants: [],
      labels: [],
      selectedRestaurants: [],
      selectedLabels: [],
      selectedKeywords: [],
      isLoading: true,
      selectedFilters: {
        restaurants: [],
        keywords: [],
        labels: [],
      },
    };
  },
  computed: {
    isLabelsLoading() {
      return this.restaurants.length > 0 && this.labels.length === 0 && !this.isLabelDisabled;
    },
    isKeywordsDisabled() {
      return this.selectedRestaurants.length === 0 || this.selectedLabels?.length > 0;
    },
    isLabelDisabled() {
      return this.selectedRestaurants.length === 0 || this.selectedKeywords?.length > 0;
    },
  },
  methods: {
    /**
     * Could've been a switch but i feel like
     * it is more readable this way
     */
    handleDropdownChange(dropdownItem) {
      if (dropdownItem.key == 'restaurants') {
        this.handleSelectedRestaurantChanged(
          this.selectedRestaurants.filter((restaurant) => restaurant.name !== dropdownItem.value)
        );
      } else if (dropdownItem.key == 'keywords') {
        this.handleSelectedKeywords(
          this.selectedKeywords.filter((keyword) => keyword !== dropdownItem.value)
        );
      } else {
        this.handleSelectedLabelsChanged(
          this.selectedLabels.filter((label) => label !== dropdownItem.value)
        );
      }
    },
    handleSelectedRestaurantChanged(selectedRestaurants) {
      this.selectedRestaurants = selectedRestaurants || [];
      this.selectedFilters.restaurants = selectedRestaurants.map((restaurant) => restaurant.name);

      if (selectedRestaurants.length > 0) {
        this.$store
          .dispatch('getRestaurantsLabels')
          .then((labelsResponse: string[]) => (this.labels = labelsResponse))
          .catch(() => this.$toast.error(this.$t('errors.getCategories')));
      } else {
        this.selectedFilters.keywords = [];
        this.selectedFilters.labels = [];
      }
    },
    handleSelectedLabelsChanged(selectedLabels) {
      this.selectedLabels = selectedLabels || [];
      this.selectedFilters.labels = selectedLabels;
    },
    handleSelectedKeywords(selectedKeywords) {
      this.selectedKeywords = selectedKeywords || [];
      this.selectedFilters.keywords = selectedKeywords;
    },
    handleFormSubmit() {
      const labels = this.selectedLabels.map((label) => {
        return { name: label };
      });

      this.$store.commit('setRestaurantFilter', {
        keywords: this.selectedKeywords,
        labels,
        restaurants: this.selectedRestaurants,
      });
    },
  },
  watch: {
    '$store.getters.restaurantPlatforms': {
      handler(platforms) {
        if (platforms.length > 0) {
          this.isLoading = true;

          this.$store
            .dispatch('getRestaurantsFromPlatform')
            .then((restaurantResponse: Restaurant[]) => {
              this.restaurants = restaurantResponse || [];

              if (this.restaurants.length === 0) {
                throw new Error();
              }
            })
            .catch(() => this.$toast.error(this.$t('errors.getRestaurants')))
            .finally(() => (this.isLoading = false));
        }
      },
      immediate: true,
    },
    '$store.getters.restaurantFilters': {
      handler(restaurantFilters) {
        this.selectedRestaurants = restaurantFilters.restaurants;
      },
    },
  },
});
