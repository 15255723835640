var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-password" }, [
    _c(
      "form",
      {
        staticClass: "new-password__form",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.resetPassword.apply(null, arguments)
          },
        },
      },
      [
        _c("Input", {
          staticClass: "new-password__input",
          attrs: {
            type: "password",
            label: _vm.$t("forgotPassword.newPassword"),
          },
          on: {
            change: function ($event) {
              return _vm.handlePasswordInputChange($event, false)
            },
          },
        }),
        _c("Input", {
          attrs: {
            type: "password",
            label: _vm.$t("forgotPassword.confirmNewPassword"),
          },
          on: {
            change: function ($event) {
              return _vm.handlePasswordInputChange($event, true)
            },
          },
        }),
        _c(
          "ButtonDefault",
          {
            staticClass: "new-password__button",
            attrs: {
              type: "submit",
              "is-round": "",
              isDisabled: !_vm.isPasswordValid,
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("forgotPassword.confirm")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }