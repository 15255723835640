

























































import Vue from 'vue';

import PlatformFilter from '@/components/Filters/PlatformFilter.vue';
import RestaurantSelectFilter from '@/components/Filters/RestaurantSelectFilter.vue';
import Modal from '@/components/Modal/ModalComponent.vue';

import { FilterComponent } from './Filters';

export default (Vue as FilterComponent).extend({
  components: {
    PlatformFilter,
    RestaurantSelectFilter,
    Modal,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    isStandAlone: {
      type: Boolean,
      default: false,
    },
    noLabels: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      newPlatform: 0,
      shouldShowConfirmationModal: false,
      hasCriticalErrors: false,
    };
  },
  computed: {
    currentLanguage() {
      return this.$store.getters.currentLanguage;
    },
    shouldShowPlatforms() {
      return Boolean(this.filters.platform || this.filters.allPlatforms);
    },
    isFullWidth() {
      return !this.shouldShowPlatforms;
    },
  },
  methods: {
    openPlatformModal(index) {
      const platformIndex = Number(index);
      const currentSelectedPlatform = this.$store.state.subHeaderSelectedPlatform;

      if (currentSelectedPlatform !== platformIndex) {
        this.shouldShowConfirmationModal = true;
        this.newPlatform = platformIndex;
      }
    },
    cancelChangePlatform() {
      this.shouldShowConfirmationModal = false;
    },
    changePlatform() {
      this.shouldShowConfirmationModal = false;
      this.$store
        .dispatch('changeRestaurantPlatform', { newPlatform: this.newPlatform })
        .catch(() =>
          this.$toast.error(this.$translations.errors.changePlatform[this.currentLanguage])
        );
    },
    handleHintOnClick() {
      this.$emit('showModal');
    },
  },
  watch: {
    '$store.getters.criticalErrors'(errors) {
      if (errors.filters) {
        this.hasCriticalErrors = true;
      }
    },
  },
});
