

















import Vue from 'vue';
import { MetricCardComponent } from './DashboardCard';

export default (Vue as MetricCardComponent).extend({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
});
