






































import Vue from 'vue';
import Card from '@/components/Cards/CardComponent.vue';
import { MenuProgressComponent, MenuQualityMetrics } from './MenuEngineering';

import {
  checkMenuQualityCriteria,
  hasGoodOrBadInsight,
  ensureIsNumber,
} from './menuEngineering.config';

export default (Vue as MenuProgressComponent).extend({
  components: {
    Card,
  },
  props: {
    menuMetrics: {
      type: Array,
      required: true,
    },
    selectedMetricName: {
      type: String,
      required: true,
    },
    selectedMetricValue: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.metrics = this.menuMetrics.map(([metricName, metricValue]) => {
      const metricNumericValue = {
        ...metricValue,
        metrics: {
          self: ensureIsNumber(metricValue.metrics.self),
          market: ensureIsNumber(metricValue.metrics.market),
        },
      };

      /**
       * An enum would be better here. But since these are backend values
       * ensuring that the metricName is a keyOf MEnuQuality is irrelevant
       */
      const metricKey = metricName as keyof MenuQualityMetrics;
      const isFulfilled = checkMenuQualityCriteria(metricKey, metricNumericValue);

      if (isFulfilled) {
        this.fulfilledMetrics.push(metricKey);
      }

      return [metricName, { ...metricNumericValue, isFulfilled }];
    });
  },
  data() {
    return {
      metrics: [],
      fulfilledMetrics: [],
    };
  },
  computed: {
    isSelectedMetricFulfilled() {
      return this.fulfilledMetrics.includes(this.selectedMetricName);
    },
    insightValue() {
      const { self, market } = this.selectedMetricValue.metrics;

      if (!this.selectedMetricValue.isBarChart) {
        return [Math.round((self * 100) / market)];
      }

      return [];
    },
    selectedMetricText() {
      const isGoodOrBadInsight = hasGoodOrBadInsight(
        this.selectedMetricName as keyof MenuQualityMetrics
      );

      if (isGoodOrBadInsight) {
        if (this.isSelectedMetricFulfilled) {
          return this.$t(`menuMetrics.${this.selectedMetricName}.insights.good`, this.insightValue);
        } else {
          return this.$t(`menuMetrics.${this.selectedMetricName}.insights.bad`, this.insightValue);
        }
      }

      return this.$t(`menuMetrics.${this.selectedMetricName}.insights`, this.insightValue);
    },
  },
});
