




















import Vue from 'vue';
import ButtonDefault from '@/components/Buttons/ButtonDefault.vue';

import { CheckEmailComponent } from './CheckEmail';
import mixin from './mixin';

export default (Vue as CheckEmailComponent).extend({
  components: { ButtonDefault },
  mixins: [mixin],
  props: {
    propEmail: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.email = this.propEmail;
  },
  methods: {
    handleSendAgainClick() {
      this.sendEmail();
    },
  },
});
