var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forgot-password" }, [
    _c(
      "div",
      { staticClass: "forgot-password__header" },
      [
        _c(
          "div",
          {
            staticClass: "forgot-password__go-back",
            on: { click: _vm.goBack },
          },
          [
            _c(
              "span",
              { staticClass: "forgot-password__icon" },
              [_c("ChevronIcon")],
              1
            ),
            _vm._v(" " + _vm._s(_vm.$t("shared.back")) + " "),
          ]
        ),
        _c("PleezLogo", { staticClass: "forgot-header__logo" }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "forgot-password__content" },
      [
        _vm.step === 4
          ? _c(
              "div",
              { staticClass: "forgot-password__pulse" },
              [_c("PulseIcon")],
              1
            )
          : _vm._e(),
        _c("h1", { staticClass: "forgot-password__title" }, [
          _vm._v(" " + _vm._s(_vm.$t(_vm.titles[_vm.step])) + " "),
        ]),
        _vm.step > -1
          ? _c("forgot-step-" + _vm.step, {
              tag: "component",
              attrs: {
                name: "forgot-step-" + _vm.step,
                email: _vm.email,
                propEmail: _vm.email,
                token: _vm.token,
              },
              on: { nextStep: _vm.nextStep },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }