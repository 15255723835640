var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overview" },
    [
      _c("p", { staticClass: "overview__description" }, [
        _vm._v(" " + _vm._s(_vm.$t("overview.description")) + " "),
      ]),
      _c(
        _vm.isMobileViewPort ? "div" : "ul",
        { tag: "component", staticClass: "overview__overall" },
        [
          _vm.isMetricLoading
            ? _c("LoaderComponent")
            : _vm.isMobileViewPort
            ? _c("Slider", {
                attrs: {
                  id: "overview-slider",
                  sliderItems: _vm.overallMetrics,
                },
                scopedSlots: _vm._u([
                  {
                    key: "slider-item",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c("DashboardMetricCard", { attrs: { item: item } }),
                      ]
                    },
                  },
                ]),
              })
            : _vm._l(_vm.overallMetrics, function (item) {
                return _c(
                  "li",
                  [_c("DashboardMetricCard", { attrs: { item: item } })],
                  1
                )
              }),
        ],
        2
      ),
      _c("h3", { staticClass: "overview__platform-title" }, [
        _vm._v(" " + _vm._s(_vm.$t("overview.platformSectionTitle")) + " "),
      ]),
      _c("p", { staticClass: "overview__description" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("overview.platformSectionDescription")) + " "
        ),
      ]),
      _c(
        !_vm.isDesktop ? "div" : "ul",
        { tag: "component", staticClass: "overview__platforms-overall" },
        [
          _vm.isMetricLoading
            ? _c("LoaderComponent")
            : !_vm.isDesktop
            ? _c("Slider", {
                attrs: {
                  id: "platform-slider",
                  "slider-items": _vm.overallProviderMetrics,
                  offset: 1,
                },
                scopedSlots: _vm._u([
                  {
                    key: "slider-item",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c("PlatformCard", { attrs: { overviewData: item } }),
                      ]
                    },
                  },
                ]),
              })
            : _vm._l(_vm.overallProviderMetrics, function (item) {
                return _c(
                  "li",
                  [_c("PlatformCard", { attrs: { overviewData: item } })],
                  1
                )
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }