




import Vue from 'vue';
import { RestaurantAndAddressComponent } from '../CommonFilters';
export default (Vue as RestaurantAndAddressComponent).extend({
  props: {
    value: {
      type: Object,
      required: true,
    },
    extra: {
      type: Object,
      required: false,
    },
  },
  computed: {
    val() {
      return this.value[this.extra?.displayKey || 'label'] || '';
    },
    customStyle() {
      return this.extra?.style && !this.value.noCustomStyle ? this.extra?.style : {};
    },
  },
});
