import { render, staticRenderFns } from "./RestaurantCustomComponent.vue?vue&type=template&id=03b6f896&"
import script from "./RestaurantCustomComponent.vue?vue&type=script&lang=ts&"
export * from "./RestaurantCustomComponent.vue?vue&type=script&lang=ts&"
import style0 from "./RestaurantCustomComponent.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/thur/Documents/Projects/Pleez/optimizations-dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03b6f896')) {
      api.createRecord('03b6f896', component.options)
    } else {
      api.reload('03b6f896', component.options)
    }
    module.hot.accept("./RestaurantCustomComponent.vue?vue&type=template&id=03b6f896&", function () {
      api.rerender('03b6f896', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Filters/Custom/RestaurantCustomComponent.vue"
export default component.exports