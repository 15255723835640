import HttpRequest from '@/http/httpClass';
import {
  MENU,
  RESTAURANTS_USER,
  SALES,
  CHANGE_PASSWORD,
  PRICE_COMPARISON,
  AUTH,
  PLEEZ_REPORTS,
  METRICS,
  HOME,
  RESTAURANTS_PLATFORMS,
  CITIES_PLATFORMS,
} from '@/http/endpoints';
import { Session } from '@/globals';
import router from '@/routes/router';

import { Actions } from '../store';
import {
  ChangeRestaurantPlatformResponse,
  GetUserRestaurantOrdersResponse,
  GetUserRestaurantsResponse,
  RenewTokenResponse,
  LoginResponse,
  HomeResponse,
  InsightsResponse,
  CommonFiltersResponse,
  CityAndSourcesResponse,
  MarketTrendsResponse,
} from './actionsResponses';

import dashboardSalesActions from './dashboard/dashboardSalesActions';
import performanceActions from './performance/performanceActions';
import promotionsActions from './promotions/promotionsActions';
import termsAndConditionsActions from './termsAndConditions/termsAndConditionsActions';
import settingsActions from './settings/settingsActions';

import { Platform } from '@/components/Filters/Filters';

const runtimeCache: { getRestaurantsFromPlatform: Record<string, Promise<unknown>> | null } = {
  getRestaurantsFromPlatform: null,
};

const actions: Actions = {
  ...dashboardSalesActions,
  ...performanceActions,
  ...promotionsActions,
  ...termsAndConditionsActions,
  ...settingsActions,
  login(_, options) {
    const { data, rememberMe } = options;
    const request = new HttpRequest(AUTH, 'POST', false, { data });

    return request.send<LoginResponse>().then((response) => {
      const { accessToken, isSuperUser, idRestaurantSourceMenusdb } = response;

      Session.setSession('pleez-token', accessToken, rememberMe);
      Session.setSession('pleez-persistent', true, rememberMe);
      Session.setSession('isSuperUser', isSuperUser, rememberMe);
      Session.setSession(
        'pleez-id-restaurant-source-menus-db',
        idRestaurantSourceMenusdb,
        rememberMe
      );

      return { isSuperUser: isSuperUser };
    });
  },
  renewToken(_, options) {
    const { restaurantId, restaurantSourceId } = options;
    const request = new HttpRequest(`/auth/renewToken/${restaurantSourceId}`, 'POST', true);

    request.send<RenewTokenResponse>().then((response) => {
      const { accessToken, idRestaurantSourceMenusdb } = response;

      if (restaurantId) {
        Session.setSession('pleez-restaurant-id', restaurantId);
      }
      Session.setSession('pleez-token', accessToken, false);
      Session.setSession('pleez-persistent', true, false);
      Session.setSession('pleez-id-restaurant-source-menus-db', idRestaurantSourceMenusdb, false);

      router.go(0);
    });
  },
  downloadFile(_, options) {
    const { downloadLink, title } = options.file;

    fetch(downloadLink, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = title;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox

        a.click();
        a.remove(); //afterwards we remove the element again
      });
  },
  changeRestaurantPlatform({ commit, getters }, options) {
    const newPlatform = options.newPlatform;
    const platforms = getters.restaurantPlatforms;
    const restaurantPlatform = platforms.find(
      (platform: { id: number }) => platform.id === newPlatform
    );

    if (restaurantPlatform) {
      const request = new HttpRequest(
        `/auth/renewToken/${restaurantPlatform.idRestaurantSourceMenusdb}`,
        'POST',
        true
      );

      return request.send<ChangeRestaurantPlatformResponse>().then((response) => {
        Session.setSession('pleez-token', response.accessToken, false);
        Session.setSession('pleez-persistent', true, false);
        Session.setSession('pleez-selected-platform', newPlatform, false);
        Session.setSession(
          'pleez-id-restaurant-source-menus-db',
          response.idRestaurantSourceMenusdb,
          false
        );

        commit('setPlatform', newPlatform);

        router.go(0);
      });
    }
  },
  getRestaurantsFromPlatform({ getters }) {
    const currentPlatform: string = getters.platformById?.name;

    if (!runtimeCache.getRestaurantsFromPlatform?.[currentPlatform]) {
      runtimeCache.getRestaurantsFromPlatform = {
        ...runtimeCache.getRestaurantsFromPlatform,
        [currentPlatform]: new HttpRequest(
          `/restaurants/provider/${currentPlatform}`,
          'GET',
          true
        ).send(),
      };
    }

    return runtimeCache.getRestaurantsFromPlatform[currentPlatform];
  },
  getRestaurantsLabels({ state }) {
    const currentRestaurant = state.restaurantFilters.restaurants
      .map((restaurant) => restaurant.id)
      .join(',');

    const request = new HttpRequest(
      MENU.CATEGORIES_LABELS({
        idRestaurants: currentRestaurant,
      }),
      'GET',
      true
    );

    return request.send();
  },
  getUserRestaurants({ commit, getters }) {
    const request = new HttpRequest(RESTAURANTS_USER, 'GET', true);

    return request.send<GetUserRestaurantsResponse>().then((userRestaurants) => {
      const sessionRestaurantId = Number(Session.getSession('pleez-restaurant-id'));
      let selectedPlatform = Number(Session.getSession('pleez-selected-platform'));
      let selectedRestaurant = null;

      commit('setUserRestaurants', userRestaurants);

      if (sessionRestaurantId) {
        selectedRestaurant = userRestaurants.find(
          (restaurant) => restaurant.id === sessionRestaurantId
        );
      } else {
        selectedRestaurant = userRestaurants.find((restaurant) => {
          return restaurant.restaurants_sources.find((rs) => {
            //FIXME:
            return rs.id === Number(Session.getSession('pleez-id-restaurant-source-menus-db'));
          });
        });
      }

      if (selectedRestaurant) {
        commit('setSelectedRestaurant', selectedRestaurant);

        const restaurantDefaults = getters.restaurantDefaultPlatforms;
        const restaurantPlatforms = selectedRestaurant.restaurants_sources.map((restaurant) => {
          const defaultRestaurantMatch = restaurantDefaults.find(
            (platform: Platform) => platform.name === restaurant.source.label
          );

          return {
            idRestaurantSourceMenusdb: restaurant.id,
            name: restaurant.source.label,
            id: restaurant.source.id,
            color: defaultRestaurantMatch ? defaultRestaurantMatch.color : undefined,
          };
        });

        commit('setRestaurantPlatforms', restaurantPlatforms);

        if (!selectedPlatform) {
          selectedPlatform = restaurantPlatforms[0].id;
        }

        commit('setPlatform', selectedPlatform);
      }

      return { selectedRestaurant, restaurants: userRestaurants };
    });
  },
  getUserRestaurantOrders({ state, commit }) {
    const request = new HttpRequest(SALES.DATE_RANGE, 'GET', true);

    return request.send<GetUserRestaurantOrdersResponse>().then((dateRangeResponse) => {
      const dateRange = { fromDate: '', toDate: '' };

      if (!dateRangeResponse?.fromDate && !dateRangeResponse?.toDate) {
        const now = new Date();
        let month: number | string = now.getMonth() + 1;

        if (month < 10) {
          month = `0${month}`;
        }

        const today = `${now.getFullYear()}-${month}-${now.getDate()}`;

        dateRange.fromDate = today;
        dateRange.toDate = today;
      } else {
        const weekEnd = new Date(dateRangeResponse.toDate);
        const weekBeginning = new Date(
          weekEnd.getFullYear(),
          weekEnd.getMonth(),
          weekEnd.getDate() - 7
        );

        dateRange.toDate = dateRangeResponse.toDate;
        dateRange.fromDate = weekBeginning.toLocaleDateString('fr-CA'); //YYYY-MM-DD
      }

      commit('setUserOrdersDates', {
        selectedDate: `${dateRange.fromDate} - ${dateRange.toDate}`,
        orderDates: dateRangeResponse.distinctDates,
        dateRange: dateRange,
      });
      state.isDateAlreadySet = true;
    });
  },
  getUserReports({ getters }) {
    let parameters = '';

    if (getters.isAllPlatformsSelected) {
      parameters =
        '?sources=' +
        getters.currentUserRestaurant.restaurants_sources
          .map((source: { source: { id: string } }) => source.source.id)
          .join(',');
    }

    const request = new HttpRequest(PLEEZ_REPORTS + parameters, 'GET', true);

    return request.send();
  },
  getMenuUrl() {
    const request = new HttpRequest('/menus/url', 'GET', true);

    return request.send();
  },
  updatePassword(_, options) {
    const { newPassword } = options;
    const request = new HttpRequest(CHANGE_PASSWORD, 'PUT', true, {
      password: newPassword,
    });

    return request.send();
  },
  getPleezInsights({ commit }, options = { filter: '', sort: '', order: '', offset: 0, limit: 0 }) {
    const { filter, sort, order, limit } = options;
    let offset = options.offset;
    let params = `?order=${order}`;

    params += filter ? `&filter=${filter}` : '';
    params += sort ? `&sort=${sort}` : '';

    if (!offset) {
      offset = 0; // If offset is not set, it will be set to 0
    }

    params += `&offset=${offset}`;

    if (limit) {
      params += `&limit=${limit}`;
    }

    const request = new HttpRequest(`/insights${params}`, 'GET', true);

    return request.send<InsightsResponse>().then((response: InsightsResponse) => {
      commit('setPleezInsights', response.data);

      return response;
    });
  },
  getMenuChanges(_, options = { filter: '', sort: '', order: '', offset: 0, limit: 0 }) {
    const { filter, sort, order, limit, offset } = options;
    let params = `?order=${order}`;

    params += filter ? `&filter=${filter}` : '';
    params += sort ? `&sort=${sort}` : '';

    if (offset) {
      params += `&offset=${offset}`;
    }

    if (limit) {
      params += `&limit=${limit}`;
    }

    const request = new HttpRequest(`/functions/listUserFunctions${params}`, 'GET', true);

    return request.send();
  },
  createMenuChanges(_, payload) {
    const { deadline, changeDescription, deliveryPlatform, restaurantIds } = payload;

    const request = new HttpRequest(`/functions`, 'POST', true, {
      platforms: deliveryPlatform,
      deadline,
      restaurantIds,
      changeDescription,
    });

    return request.send();
  },
  updatePleezInsight(_, options) {
    const { id, approved } = options;

    const request = new HttpRequest(`/pleezInsights/${id}`, 'PUT', true, {
      approved,
    });

    return request.send();
  },
  getUserInvoices() {
    const request = new HttpRequest(`/payment/invoices?limit=5000`, 'GET', true);

    return request.send();
  },
  getMenuMetrics() {
    const request = new HttpRequest(METRICS.MENU_QUALITY, 'GET', true);

    return request.send();
  },
  getSalesPerCategory(_, options) {
    const request = new HttpRequest(
      PRICE_COMPARISON.SALES_ANALYSIS_PER_CATEGORY({ ...options }),
      'GET',
      true
    );

    return request.send();
  },
  getHomePageDetails() {
    const request = new HttpRequest(HOME, 'GET', true);

    return request.send<HomeResponse>().then((response) => {
      return {
        timeSaver: {
          insights: response.time_saver?.insights,
          menuChanges: response.time_saver?.menu_changes,
        },
        averageTicket: response.average_ticket_7d,
        variationPercentage: response.variation_14d,
        waitingRecommendations: response.waiting_recommendations,
      };
    });
  },
  async getCommonFilters({ commit, state }) {
    const isNotEmpty = Object.values(state.commonFilters).some((arr) => arr.length > 0);

    if (isNotEmpty) return;

    const request = new HttpRequest(RESTAURANTS_PLATFORMS, 'GET', true);

    const response = await request.send<CommonFiltersResponse>();

    if (response) {
      commit('setCommonFilters', response);
    }
  },

  async getCityAndPlatforms({ commit, state }) {
    const isNotEmpty = Object.values(state.cityAndPlatforms).some((arr) => arr.length > 0);

    if (isNotEmpty) return;

    const request = new HttpRequest(CITIES_PLATFORMS, 'GET', true);

    const response = await request.send<CityAndSourcesResponse>();

    const { sources: platforms, cities, defaultCity } = response;

    if (response) {
      commit('setCityAndPlatforms', {
        platforms,
        cities,
        defaultCity,
      });
    }
  },
  getMarketInsights(_, filter = '') {
    let params = ``;

    params += filter ? `?filter=${filter}` : '';

    const request = new HttpRequest(`/marketTrends/getEstimatedOrders${params}`, 'GET', true);

    return request.send<MarketTrendsResponse>().then((response: MarketTrendsResponse) => {
      const cardPeriods = response.periods?.map((period) => {
        let startDate;
        let endDate;

        if (period.period_type === 'last_month' && period.start_date) {
          const date = new Date(period.start_date);
          startDate = `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
        } else if (period.start_date && period.end_date) {
          startDate = new Date(period.start_date).toLocaleDateString();
          endDate = new Date(period.end_date).toLocaleDateString();
        }

        return {
          startDate,
          endDate,
          periodType: period.period_type,
          salesVariation: period.sales_variation,
          orderCount: period.orders_count,
        };
      });
      return { sampleSize: response.sample_size_restaurants_size, cardPeriods };
    });
  },
};

export default actions;
