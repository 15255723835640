import { MenuChanges } from '@/store/store';
import { ViewPort, CustomElement } from '@/utils/_enums/global';

import RestaurantCustomComponent from '@/components/Filters/Custom/RestaurantCustomComponent.vue';

import { TableMenuChangesFormated } from './TableMenuChanges';

import { MenuChangesFilter } from './MenuChanges';

export const filtersData = (platforms: string[], addresses: string[]): MenuChangesFilter => ({
  deliveryPlatform: {
    value: platforms,
    label: 'filters.restaurantPicker.deliveryPlatform',
    placeholder: 'filters.selectAllLabel',
    multiple: true,
  },
  restaurantIds: {
    value: addresses,
    displayKey: 'name',
    filterKey: 'id',
    label: 'filters.restaurantPicker.address',
    placeholder: 'filters.selectAllLabel',
    multiple: true,
    customBody: [
      {
        displayKey: 'name',
        component: RestaurantCustomComponent,
        style: {
          fontWeight: 600,
        },
      },
      {
        displayKey: 'address',
        component: RestaurantCustomComponent,
        style: {
          fontWeight: 100,
        },
      },
    ],
  },
});

export const modalFilterData = (platforms: string[], addresses: string[]): MenuChangesFilter => {
  const originalFilters = filtersData(platforms, addresses);

  originalFilters.deliveryPlatform.placeholder = 'shared.deliveryService';
  originalFilters.restaurantIds.placeholder = 'shared.selectRestaurantAddress';

  return originalFilters;
};

export const richTextToolbar = [
  ['bold', 'italic', 'underline'],
  [{ list: 'ordered' }, { list: 'bullet' }],
];

const formatMenuChanges = (insight: string) => {
  insight = insight
    .replace(/<\/p>/g, ' ')
    .replace(/<br>/g, ' ')
    .replace(/<\/li>/g, ' ');
  insight = insight.replace(/<[^>]*>/g, '');

  return insight;
};

export const sortingOptions = {
  delivery: {
    label: 'shared.delivery',
    value: 'deliveryPlatform',
    order: 'ASC',
  },
  restaurant: {
    label: 'shared.restaurant',
    value: 'restaurantName',
    order: 'ASC',
  },
  dateDesc: {
    label: 'pleezInsights.dateSortDesc',
    value: 'date',
    order: 'DESC',
  },
  dateAsc: {
    label: 'pleezInsights.dateSortAsc',
    value: 'date',
    order: 'ASC',
  },
  status: {
    label: 'shared.status',
    value: 'status',
    order: 'ASC',
  },
};

const fixedHeaders = [
  {
    label: sortingOptions.delivery.label,
    minViewPort: ViewPort.SmallDesktop,
    sortKey: sortingOptions.delivery.value,
  },
  {
    label: sortingOptions.restaurant.label,
    minViewPort: ViewPort.SmallDesktop,
    sortKey: sortingOptions.restaurant.value,
  },
  {
    label: 'sidebar.optimization.label',
    minViewPort: ViewPort.SmallDesktop,
  },
  {
    label: 'shared.date',
    minViewPort: ViewPort.SmallDesktop,
    sortKey: sortingOptions.dateAsc.value,
  },
  {
    label: 'shared.status',
    minViewPort: ViewPort.SmallDesktop,
    sortKey: sortingOptions.status.value,
  },
];

export const getTableData = (data: MenuChanges[]): TableMenuChangesFormated => {
  const body = data.map((dataItem, index) => {
    const date = dataItem.deadline ? dataItem.deadline : '-';

    return {
      id: dataItem.id,
      values: [
        {
          customElement: CustomElement.Platform,
          value: dataItem.deliveryPlatform,
          id: `${dataItem.deliveryPlatform}-platform-${index}`,
          minViewPort: ViewPort.SmallDesktop,
        },
        {
          customElement: CustomElement.Restaurant,
          rawData: dataItem,
          id: `${dataItem.id}-restaurant-${index}`,
          minViewPort: ViewPort.SmallDesktop,
        },
        {
          customElement: CustomElement.Description,
          value: formatMenuChanges(dataItem.description),
          rawData: dataItem,
          id: `${dataItem.id}-description-${index}`,
          minViewPort: ViewPort.SmallDesktop,
        },
        {
          value: new Date(date).toLocaleDateString(),
          id: `${dataItem.id}-date-${index}`,
          minViewPort: ViewPort.SmallDesktop,
        },
        {
          value: dataItem.status,
          id: `${dataItem.id}-status-${index}`,
          minViewPort: ViewPort.SmallDesktop,
        },
      ],
    };
  });

  return { headers: fixedHeaders, body };
};
