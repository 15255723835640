var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dropdown",
    {
      attrs: {
        "selected-filters": _vm.selectedFilters,
        "should-be-visible": _vm.hasSelectFilters,
      },
      on: { change: _vm.handleDropdownChange },
    },
    [
      _c(
        "div",
        { staticClass: "common-filters" },
        [
          _vm.filters
            ? _vm._l(_vm.filters, function (filter, key) {
                return _c(
                  "div",
                  {
                    key: "common-" + key + "-filter",
                    staticClass: "common-filters__filter",
                  },
                  [
                    _c(filter.componentType || _vm.defaultComponentType, {
                      tag: "component",
                      attrs: {
                        name: filter.componentType || _vm.defaultComponentType,
                        "is-label-visible": "",
                        isLoading: filter.value.length === 0,
                        values: filter.value,
                        "display-key": filter.displayKey,
                        label: _vm.$t(filter.label),
                        "default-value": _vm.selectedSelectorValue[key],
                        "has-select-all":
                          _vm.hasSelectAll && filter.value.length > 1,
                        placeholder: filter.placeholder
                          ? _vm.$t(filter.placeholder)
                          : "",
                        multiple: filter.multiple,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleSelectFilter(key, $event)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          filter.customBody
                            ? {
                                key: "custom-label",
                                fn: function (ref) {
                                  var value = ref.value
                                  return _vm._l(
                                    filter.customBody,
                                    function (customBody, customKey) {
                                      return _c(customBody.component, {
                                        key: "custom-" + customKey + "-body",
                                        tag: "component",
                                        attrs: {
                                          name: customBody.component,
                                          value: value,
                                          extra: customBody,
                                        },
                                      })
                                    }
                                  )
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                )
              })
            : _c("Select", {
                staticClass: "common-filters__filter",
                attrs: {
                  "is-label-visible": "",
                  label: _vm.$t("filters.selectDefaultValue"),
                  isLoading: true,
                  values: [],
                },
              }),
          _vm.hasSetButton
            ? _c(
                "ButtonDefault",
                {
                  staticClass: "common-filters__apply-button",
                  on: { click: _vm.handleSearchClick },
                },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.searchLabel)) + " ")]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }