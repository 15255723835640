import { Actions } from '@/store/store';
import HttpRequest from '@/http/httpClass';

const basicFetch = (url: string, header?: { authorization: string }) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers: header,
    })
      .then((response) => response?.text() || {})
      .then((result) => resolve(JSON.parse(result)))
      .catch((error) => reject(error));
  });
};

const actions: Pick<
  Actions,
  | 'searchLocation'
  | 'getUberPromotions'
  | 'getGlovoPromotions'
  | 'getJustEatPromotions'
  | 'getBoltPromotions'
> = {
  searchLocation(_, options) {
    const url = `https://node.bolt.eu/location-suggestions/deliveryClient/suggestDeliveryLocations?version=FA.1.14&deviceId=d92fd322ab81cbb6&deviceType=android&device_name=SM-G965N&device_os_version=7.1.2&language=pt-PT&session_id=47922107eater1648051413&lat=38.731&lng=-9.1373&search_string=${options}`;

    return basicFetch(url, {
      authorization: 'Basic KzM1MTkxMjUzOTY5NDoxNThhNjFkZC1hN2M4LTQzNTYtOTE4NC0zM2U1YzQ1OGY5MjI=',
    });
  },
  getGlovoPromotions(_, options) {
    const url = `https://europe-west6-concrete-list-346418.cloudfunctions.net/glovopromotions?address=${options}`;

    return basicFetch(url);
  },
  getUberPromotions(_, options) {
    const request = new HttpRequest(`/ubereats/promotions?location=${options}`, 'GET', false);
    return request.send();
  },
  getJustEatPromotions(_, options) {
    const request = new HttpRequest(`/justEat/promotions?address=${options}`, 'GET', false);
    return request.send();
  },
  getBoltPromotions(_, options) {
    const request = new HttpRequest(`/bolt/promotions?location=${options}`, 'GET', false);
    return request.send();
  },
};

export default actions;
