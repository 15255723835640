var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showTermsAndConditionModal
        ? _c("TermsAndConditionsComponent", {
            attrs: {
              "terms-and-conditions": _vm.termsAndConditions,
              "is-accepted": _vm.isTermsAccepter,
            },
            on: {
              closeClick: _vm.handleCloseClick,
              termsAgreed: _vm.handleTermsAgreed,
            },
          })
        : _vm._e(),
      _vm.isTermsAccepter
        ? [
            _c("Header", { on: { menuClick: _vm.handleMobileMenuClick } }),
            _c(
              "main",
              { staticClass: "content" },
              [
                _c("Sidebar", {
                  attrs: {
                    shouldShowOverlay: !_vm.isDesktop,
                    shouldShowSideBar: _vm.shouldShowSideBar,
                  },
                  on: { close: _vm.handleMobileOverlayClick },
                }),
                _c(
                  "div",
                  { staticClass: "content__body" },
                  [
                    _c("Breadcrumb", { staticClass: "content__bread-crumbs" }),
                    _c("router-view", {
                      staticClass: "content__authenticated-layout",
                    }),
                    _c("Footer", {
                      staticClass: "content__footer",
                      on: {
                        termsAndConditionsClick: function ($event) {
                          _vm.showTermsAndConditionModal = true
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }