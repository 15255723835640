var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dropdown",
    {
      attrs: { "selected-filters": _vm.selectedFilters },
      on: { change: _vm.handleDropdownChange },
    },
    [
      _c(
        "form",
        {
          staticClass: "restaurant-filter",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.handleFormSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "Select",
            {
              staticClass: "restaurant-filter__item",
              attrs: {
                "filter-key": "idRestaurantSourceMenusdb",
                "display-key": "name",
                multiple: "",
                "has-divider": "",
                "is-loading": _vm.isLoading,
                "is-label-visible": !_vm.noLabel,
                values: _vm.restaurants,
                placeholder: _vm.$t("filters.restaurantPicker.competitor"),
                label: _vm.$t("filters.restaurantPicker.competitorLabel"),
                "default-value": _vm.selectedRestaurants,
              },
              on: { change: _vm.handleSelectedRestaurantChanged },
              scopedSlots: _vm._u([
                {
                  key: "custom-label",
                  fn: function (ref) {
                    var value = ref.value
                    return [
                      _c("p", { staticClass: "restaurant-filter__name" }, [
                        _vm._v(_vm._s(value.name)),
                      ]),
                      _c("p", { staticClass: "restaurant-filter__address" }, [
                        _vm._v(_vm._s(value.address)),
                      ]),
                    ]
                  },
                },
                {
                  key: "custom-no-options",
                  fn: function () {
                    return [
                      _vm.restaurants.length === 0
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "filters.restaurantPicker.noRestaurantsError"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("filters.restaurantPicker.noRestaurants")
                              )
                            ),
                          ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("HouseIcon")],
            1
          ),
          !_vm.noFilters
            ? [
                _c(
                  "Select",
                  {
                    staticClass: "restaurant-filter__item",
                    attrs: {
                      multiple: "",
                      allowNewOptions: "",
                      placeholder: _vm.$t("filters.restaurantPicker.keywords"),
                      label: _vm.$t("filters.restaurantPicker.keywordsLabel"),
                      "is-label-visible": !_vm.noLabel,
                      disabled:
                        _vm.isKeywordsDisabled ||
                        _vm.selectedRestaurants.length === 0,
                      "default-value": _vm.selectedKeywords,
                    },
                    on: { change: _vm.handleSelectedKeywords },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "custom-no-options",
                          fn: function () {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "filters.restaurantPicker.keywordsHint"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1508171818
                    ),
                  },
                  [_c("PoundIcon")],
                  1
                ),
                _c(
                  "Select",
                  {
                    key: "labels",
                    staticClass: "restaurant-filter__item",
                    attrs: {
                      multiple: "",
                      "display-key": "name",
                      placeholder: _vm.$t(
                        "filters.restaurantPicker.categories"
                      ),
                      label: _vm.$t("filters.restaurantPicker.categoriesLabel"),
                      "is-label-visible": !_vm.noLabel,
                      "is-loading": _vm.isLabelsLoading,
                      values: _vm.labels,
                      disabled: _vm.isLabelDisabled,
                      "default-value": _vm.selectedLabels,
                    },
                    on: { change: _vm.handleSelectedLabelsChanged },
                  },
                  [_c("TagIcon")],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "ButtonDefault",
            {
              staticClass: "restaurant-filter__button",
              attrs: { type: "submit" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("shared.labels.applyFilter")) + " ")]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }