

































import Vue from 'vue';
import { CardComponent } from './Card';

import LoaderComponent from '../Loader/LoaderComponent.vue';

export default (Vue as CardComponent).extend({
  components: {
    LoaderComponent,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isArticle: {
      type: Boolean,
      default: true,
    },
    isCenterTitle: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noHeaderDivider: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasCustomHeader() {
      return Boolean(this.$scopedSlots['custom-header']);
    },
  },
});
