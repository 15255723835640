var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "not-authenticated" }, [
    _c("span", { staticClass: "not-authenticated__padding" }),
    _c(
      "div",
      { staticClass: "not-authenticated__content" },
      [_c("router-view")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }