var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "menu-change-mobile" }, [
    _c(
      "div",
      { class: ["menu-change-mobile__platform"] },
      [
        _c("PlatformIcon", {
          staticClass: "menu-change-mobile__platform-icon",
          attrs: { platform: _vm.menuChange.deliveryPlatform },
        }),
        _c("p", [_vm._v(_vm._s(_vm.menuChange.deliveryPlatform))]),
      ],
      1
    ),
    _c(
      "div",
      { ref: "insightInfoBox", staticClass: "menu-change-mobile__info-box" },
      [
        _c("p", { staticClass: "menu-change-mobile__restaurant-name" }, [
          _vm._v(_vm._s(_vm.menuChange.restaurantName)),
        ]),
        _c(
          "p",
          {
            ref: "insightRestaurant",
            class: [
              "menu-change-mobile__restaurant-address",
              {
                "menu-change-mobile__restaurant-address--with-ellipsis":
                  _vm.hasEllipsis,
              },
            ],
          },
          [_vm._v(" " + _vm._s(_vm.menuChange.restaurantAddress) + " ")]
        ),
        _c("p", {
          ref: "insightDescription",
          class: ["menu-change-mobile__insight-description"],
          domProps: { innerHTML: _vm._s(_vm.menuChange.description) },
        }),
      ]
    ),
    _vm.hasOverflow
      ? _c(
          "button",
          {
            staticClass: "menu-change-mobile__see-more",
            on: { click: _vm.handleSeeMoreClick },
          },
          [
            _vm.hasEllipsis
              ? _c("span", [
                  _vm._v("+ " + _vm._s(_vm.$t("pleezInsights.seeMore"))),
                ])
              : _c("span", [
                  _vm._v("- " + _vm._s(_vm.$t("pleezInsights.hide"))),
                ]),
          ]
        )
      : _vm._e(),
    _c("p", { staticClass: "menu-change-mobile__date" }, [
      _vm._v(" " + _vm._s(_vm.$t("shared.date")) + ": "),
      _c("span", { staticClass: "menu-change-mobile__date--value" }, [
        _vm._v(
          " " +
            _vm._s(new Date(_vm.menuChange.deadline).toLocaleDateString()) +
            " "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }