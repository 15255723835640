import { Insight } from '@/store/store';
import { CustomElement, ViewPort } from '@/utils/_enums/global';

import RestaurantCustomComponent from '@/components/Filters/Custom/RestaurantCustomComponent.vue';

import { TableInsightFormatted } from './TableInsights';

const formatInsight = (insight: string) => {
  insight = insight.replace(/<\/p>/g, ' ').replace(/<br>/g, ' ');
  insight = insight.replace(/<[^>]*>/g, '');

  return insight;
};

export const filtersData = (
  platforms: string[],
  addresses: { id: number; address: string; name: string }[]
) => ({
  deliveryPlatform: {
    value: platforms,
    label: 'filters.restaurantPicker.deliveryPlatform',
    placeholder: 'filters.selectAllLabel',
    multiple: true,
  },
  restaurantIds: {
    value: addresses,
    displayKey: 'name',
    filterKey: 'id',
    label: 'filters.restaurantPicker.address',
    placeholder: 'filters.selectAllLabel',
    multiple: true,
    customBody: [
      {
        displayKey: 'name',
        component: RestaurantCustomComponent,
        style: {
          fontWeight: 600,
        },
      },
      {
        displayKey: 'address',
        component: RestaurantCustomComponent,
        style: {
          fontWeight: 100,
        },
      },
    ],
  },
});

export const sortingOptions = {
  delivery: {
    label: 'shared.delivery',
    value: 'deliveryPlatform',
    order: 'ASC',
  },
  restaurant: {
    label: 'shared.restaurant',
    value: 'restaurantName',
    order: 'ASC',
  },
  dateDesc: {
    label: 'pleezInsights.dateSortDesc',
    value: 'date',
    order: 'DESC',
  },
  dateAsc: {
    label: 'pleezInsights.dateSortAsc',
    value: 'date',
    order: 'ASC',
  },
};

const fixedHeaders = [
  {
    label: sortingOptions.delivery.label,
    minViewPort: ViewPort.SmallDesktop,
    sortKey: sortingOptions.delivery.value,
  },
  {
    label: sortingOptions.restaurant.label,
    minViewPort: ViewPort.SmallDesktop,
    sortKey: sortingOptions.restaurant.value,
  },
  {
    label: 'pleezInsights.newInsights',
    minViewPort: ViewPort.SmallDesktop,
  },
  {
    label: 'shared.date',
    minViewPort: ViewPort.SmallDesktop,
    sortKey: sortingOptions.dateAsc.value,
  },
  {
    label: 'shared.status',
    minViewPort: ViewPort.SmallDesktop,
  },
];

export const getTableData = (data: Insight[]): TableInsightFormatted => {
  const body = data.map((dataItem, index) => {
    const date = dataItem.date ? dataItem.date : '-';

    return {
      id: dataItem.idInsight,
      values: [
        {
          customElement: CustomElement.Platform,
          value: dataItem.deliveryPlatform,
          id: `${dataItem.deliveryPlatform}-platform-${index}`,
          minViewPort: ViewPort.SmallDesktop,
        },
        {
          customElement: CustomElement.Restaurant,
          rawData: dataItem,
          id: `${dataItem.idInsight}-restaurant-${index}`,
          minViewPort: ViewPort.SmallDesktop,
        },
        {
          customElement: CustomElement.Description,
          value: formatInsight(dataItem.description),
          rawData: dataItem,
          id: `${dataItem.idInsight}-description-${index}`,
          minViewPort: ViewPort.SmallDesktop,
        },
        {
          value: new Date(date).toLocaleDateString(),
          id: `${dataItem.idInsight}-date-${index}`,
          minViewPort: ViewPort.SmallDesktop,
        },
        {
          customElement: CustomElement.Button,
          rawData: dataItem,
          id: `${dataItem.idInsight}-approve-disapprove-${index}`,
          minViewPort: ViewPort.SmallDesktop,
        },
      ],
    };
  });

  return { headers: fixedHeaders, body };
};
