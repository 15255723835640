var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar__wrapper" },
    [
      _c("Transition", { attrs: { name: "slide-in", appear: "" } }, [
        _vm.shouldShowSideBar
          ? _c(
              "nav",
              { key: "sidebar", staticClass: "sidebar" },
              [
                _c("p", { staticClass: "sidebar__mobile-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$translations.sidebar.label[
                          _vm.$store.state.selectedLanguage
                        ]
                      ) +
                      " "
                  ),
                ]),
                _vm._l(_vm.categoriesList, function (list) {
                  return _c("div", { key: list.title }, [
                    _c("h3", { staticClass: "sidebar__title" }, [
                      _vm._v(_vm._s(list.title)),
                    ]),
                    _c(
                      "ul",
                      { staticClass: "sidebar__links" },
                      _vm._l(list.links, function (link) {
                        return _c(
                          "li",
                          {
                            key: link.title,
                            staticClass: "sidebar-link-wrapper",
                          },
                          [
                            !_vm.isSuperUserLocked(link) || _vm.isSuperUser
                              ? _c(
                                  "SidebarLink",
                                  {
                                    attrs: {
                                      url: link.url,
                                      label: link.title,
                                      link: link,
                                    },
                                    on: {
                                      close: function () {
                                        return _vm.$emit("close")
                                      },
                                    },
                                  },
                                  [_c(link.icon, { tag: "component" })],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm.shouldShowSideBar
        ? _c("div", {
            class: [
              "sidebar__overlay",
              { "sidebar__overlay--active": _vm.isOverlayVisible },
            ],
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }