





































































































import Vue from 'vue';

import Select from '@/components/Inputs/Select.vue';
import { CommonFiltersComponent } from './CommonFilters';
import ButtonDefault from '@/components/Buttons/ButtonDefault.vue';
import Dropdown from './Dropdown.vue';

export default (Vue as CommonFiltersComponent).extend({
  props: {
    filters: {
      type: Object,
      required: false,
    },
    searchLabel: {
      type: String,
      default: 'filters.search',
    },
    hasSelectAll: {
      type: Boolean,
      default: true,
    },
    hasSetButton: {
      type: Boolean,
      default: true,
    },
    hasSelectFilters: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filterData: {},
      selectedPlatform: [],
      selectedAddress: [],
      selectedSelectorValue: {},
      selectedFilters: {},
      defaultComponentType: 'Select',
    };
  },
  watch: {
    filters: {
      handler() {
        if (this.filters) {
          Object.entries(this.filters).forEach(([key]) => {
            if (!this.selectedFilters[key]) {
              // Get default value
              let value: string | { [key: string]: string } | undefined = undefined;
              if (this.filters[key].defaultValue) {
                // Has default value
                value = this.filters[key].defaultValue;
              } else if (this.filters[key].firstAsDefault) {
                // Use first as default
                value = this.filters[key].value[0];
              }

              // selectedFilters[key] = []
              this.$set(this.selectedFilters, key, []);
              if (value) {
                // selectedSelectorValue[key] = []
                this.$set(this.selectedSelectorValue, key, [value]);
              }
            }

            if (key === Object.keys(this.filters)[Object.keys(this.filters).length - 1]) {
              this.handleSearchClick();
            }
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    mapFilterValues(arr, key) {
      return arr.map((value) => {
        if (typeof value === 'object') {
          return value[key];
        } else {
          return value;
        }
      });
    },
    handleSelectFilter(key, value) {
      const filterKey = this.filters[key].filterKey;
      const selectedFilters = structuredClone(this.selectedSelectorValue);

      this.selectedSelectorValue[key] = value;

      if (filterKey) {
        if (!value) {
          return;
        }
        selectedFilters[key] = this.mapFilterValues(value, filterKey);
      } else {
        selectedFilters[key] = value;
      }

      this.$emit('selectChange', selectedFilters);
    },
    handleDropdownChange(dropdownItem) {
      let cleaned = (this.selectedFilters[dropdownItem.key] as string[]).filter(
        (filter: string) => filter !== dropdownItem.value
      );

      this.$set(this.selectedFilters, dropdownItem.key, cleaned);
      this.$set(this.selectedSelectorValue, dropdownItem.key, cleaned);
    },
    handleSearchClick() {
      let filters = '';

      Object.entries(this.selectedSelectorValue).forEach(([key, value]) => {
        const filterKey = this.filters[key].filterKey;
        const displayKey = this.filters[key].displayKey;
        const isMultiple = this.filters[key].multiple;

        if (value.length > 0) {
          if (!isMultiple) {
            if (typeof value === 'string') {
              value = [value];
            }
          }
          if (filterKey && displayKey) {
            filters += `${key}:${this.mapFilterValues(value, filterKey).join(',')};`;
          } else {
            filters += `${key}:${value.join(',')};`;
          }
        }

        if (isMultiple) {
          if (filterKey && displayKey) {
            this.selectedFilters[key] = this.mapFilterValues(value, displayKey);
          } else {
            this.selectedFilters[key] = value;
          }
        }
      });

      if (filters.endsWith(';')) {
        filters = filters.slice(0, -1);
      }

      this.$emit('filtersApplied', { filtersStr: filters, filtersObj: this.selectedFilters });
    },
  },
  components: {
    Select,
    ButtonDefault,
    Dropdown,
  },
});
