var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardCard",
    {
      attrs: {
        title: _vm.$t("salesDashboard.nonSelling.title"),
        description: _vm.$t("salesDashboard.nonSelling.description"),
        caption: _vm.$t("salesDashboard.tableCaption"),
        "table-data": _vm.tableData,
        "download-data": _vm.nonSellers,
        "is-loading": _vm.isLoading || _vm.isRequestLoading,
        "footer-text": _vm.footerText,
        "download-data-file-name": "nonsellers.csv",
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "non-sellers__icon",
          attrs: { slot: "custom-icon" },
          slot: "custom-icon",
        },
        [_c("ArrowIcon")],
        1
      ),
      _c("div", { attrs: { slot: "custom-actions" }, slot: "custom-actions" }, [
        _c("p", { staticClass: "non-sellers__percentage-of-menu" }, [
          _c("span", { staticClass: "non-sellers__percentage" }, [
            _vm._v(_vm._s(_vm.nonSellersPercentage) + "%"),
          ]),
          _vm._v(
            " " +
              _vm._s(_vm.$t("salesDashboard.nonSelling.percentageNonSold")) +
              " "
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }