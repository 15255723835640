var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "platform-card" },
    [
      _c(
        "button",
        { staticClass: "platform-card__info" },
        [
          _c("InfoIcon", {
            on: {
              click: function ($event) {
                _vm.shouldShowHint = true
              },
            },
          }),
        ],
        1
      ),
      _c("PlatformIcon", {
        staticClass: "platform-card__icon",
        attrs: { "is-big": "", platform: _vm.platform },
      }),
      _c(
        "ul",
        { staticClass: "platform-card__content" },
        _vm._l(_vm.platformValues, function (option, optionIndex) {
          return _c(
            "li",
            { key: optionIndex, class: ["platform-card__content-block"] },
            [
              optionIndex !== _vm.platformValues.length - 1
                ? [
                    _c("div", { staticClass: "platform-card__content-row" }, [
                      _c("p", [_vm._v(_vm._s(option.label))]),
                      _c(
                        "span",
                        { staticClass: "platform-card__content-value" },
                        [_vm._v(" " + _vm._s(option.value) + " ")]
                      ),
                    ]),
                    option.footerValue
                      ? _c(
                          "div",
                          { staticClass: "platform-card__content-row" },
                          [
                            _c("p", [_vm._v(_vm._s(option.footerLabel))]),
                            _c(
                              "span",
                              { staticClass: "platform-card__content-value" },
                              [_vm._v(" " + _vm._s(option.footerValue) + " ")]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                : [
                    _vm.notAvailable
                      ? _c(
                          "div",
                          { staticClass: "platform-card__not-available" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("overview.notAvailable")) +
                                " "
                            ),
                          ]
                        )
                      : _c("div", { staticClass: "platform-card__footer" }, [
                          _c(
                            "span",
                            { staticClass: "platform-card__content-value" },
                            [_vm._v(" " + _vm._s(option.value) + " ")]
                          ),
                          _c("p", [_vm._v(_vm._s(option.label))]),
                        ]),
                  ],
            ],
            2
          )
        }),
        0
      ),
      _c(
        "p-modal",
        {
          attrs: {
            "modal-style": "width: 600px;min-height: 100px;",
            "is-absolute": "",
          },
          model: {
            value: _vm.shouldShowHint,
            callback: function ($$v) {
              _vm.shouldShowHint = $$v
            },
            expression: "shouldShowHint",
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("h2", { staticClass: "platform-card__info-title" }, [
              _vm._v(" " + _vm._s(_vm.platform) + " "),
            ]),
          ]),
          _c("template", { slot: "body" }, [
            _c("div", { staticClass: "platform-card__info-body" }, [
              _vm._v(" " + _vm._s(_vm.info) + " "),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }