var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "radio-group" },
    _vm._l(_vm.values, function (radioItem) {
      return _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.radioValue,
            expression: "radioValue",
          },
        ],
        key: _vm.groupName + radioItem.value,
        staticClass: "radio-group__item",
        attrs: {
          type: "radio",
          name: _vm.groupName,
          "aria-label": _vm.groupName + " " + _vm.$t(radioItem.label),
          disabled: _vm.isDisabled,
        },
        domProps: {
          value: radioItem.value,
          checked: _vm._q(_vm.radioValue, radioItem.value),
        },
        on: {
          change: [
            function ($event) {
              _vm.radioValue = radioItem.value
            },
            _vm.handleRadioClick,
          ],
        },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }