





































































































































import Vue from 'vue';

import Card from '@/components/Cards/CardComponent.vue';
import Filters from '@/components/Filters/FiltersComponent.vue';
import ButtonDefault from '@/components/Buttons/ButtonDefault.vue';
import TableMenuChangesComponent from './TableMenuChangesComponent.vue';
import Modal from '@/components/Modal/ModalComponent.vue';
import DatePicker from '@/components/Inputs/DatePicker/DatePickerComponent.vue';

import { modalFilterData, richTextToolbar } from './MenuChanges.config';

import { MenuChangesComponent, MenuChangeResponse } from './MenuChanges';

import CommonFilters from '@/components/Filters/CommonFiltersComponent.vue';
import { filtersData } from './MenuChanges.config';

import { isSuperUser } from '@/utils/superUser';

export default (Vue as MenuChangesComponent).extend({
  components: {
    Card,
    Filters,
    ButtonDefault,
    Modal,
    DatePicker,
    TableMenuChangesComponent,
    CommonFilters,
  },
  data() {
    return {
      totalMenuChanges: 0,
      filters: '',
      filtersData: null,
      modalFiltersData: null,
      isLoading: true,
      showModalMenuChanges: false,
      showModalMenuChangesCreate: false,
      viewingMenuChanges: null,
      menuChanges: [],
      requestingChanges: '',
      dueDate: '',
      richTextToolbar,
      platforms: {
        1: 'Uber Eats',
        2: 'Glovo',
        3: 'Bolt Food',
      },
      isRequestLoading: false,
      menuChangesSorting: 'id',
      menuChangesOrder: 'DESC',
      shouldShowRequestResults: false,
      selectedModalFields: null,
      menuChangeRequestResponse: null,
    };
  },
  async mounted() {
    await this.$store.dispatch('getCommonFilters');

    const commonFilters = this.$store.getters.getCommonFilters;

    this.filtersData = filtersData(commonFilters.platforms, commonFilters.addresses);
    this.modalFiltersData = modalFilterData(commonFilters.platforms, commonFilters.addresses);

    this.isLoading = false;
  },
  computed: {
    isSuperUser,
    isCreateButtonDisabled() {
      const hasAnyEmptyField =
        !this.selectedModalFields ||
        Object.values(this.selectedModalFields).some((filter) => filter.length === 0);

      return this.requestingChanges.length <= 0 || Boolean(!this.dueDate || hasAnyEmptyField);
    },
    changeModalDescription() {
      return this.shouldShowRequestResults
        ? this.$t('menuChanges.modal.requestDoneDescription')
        : this.$t('menuChanges.modal.description');
    },
    menuChangeResponseMatch() {
      return this.buildRequestResponse(true);
    },
    menuChangeResponseUnMatch() {
      return this.buildRequestResponse(false);
    },
  },
  methods: {
    async handleLoadMore() {
      await this.getMenuChanges(this.menuChanges.length);
    },
    async getMenuChanges(offset = 0) {
      if (this.isSuperUser && !this.filters) {
        return;
      }

      const response = await this.$store.dispatch('getMenuChanges', {
        sort: this.menuChangesSorting,
        order: this.menuChangesOrder,
        filter: this.filters,
        offset,
      });

      if (offset > 0) {
        this.menuChanges = [...this.menuChanges, ...response.data];
      } else {
        this.menuChanges = response.data;
      }
      this.totalMenuChanges = response.total;
    },
    async handleSaveChange() {
      this.shouldShowRequestResults = true;
      this.isRequestLoading = true;

      this.$store
        .dispatch('createMenuChanges', {
          changeDescription: this.requestingChanges,
          deadline: this.dueDate,
          ...this.selectedModalFields,
        })
        .then((response: MenuChangeResponse) => {
          this.isRequestLoading = false;
          this.shouldShowRequestResults = true;
          this.menuChangeRequestResponse = response;

          this.$toast.success(this.$t('menuChanges.changeRequested'));

          this.getMenuChanges();

          if (
            !this.menuChangeRequestResponse.unmatched &&
            !this.menuChangeRequestResponse.matched
          ) {
            this.handleCancelCreateMenuChangesModal();
          }
        })
        .catch((errorResponse: { error: { status?: number } }) => {
          // this is the way the error is returned from the endpoint :(
          if (errorResponse?.error?.status === 400) {
            this.$toast.error(this.$t('errors.menuChangeNotOwned'));
          } else {
            this.$toast.error(this.$t('errors.menuChange'));
          }

          this.handleCancelCreateMenuChangesModal();
        });
    },
    handleOnFilter(filters) {
      this.filters = filters.filtersStr;
      this.getMenuChanges();
    },
    handleDateSelected(selectedDate) {
      selectedDate?.replaceAll('/', '-');

      this.dueDate = selectedDate;
    },
    handleCancelCreateMenuChangesModal() {
      this.showModalMenuChangesCreate = false;
      this.shouldShowRequestResults = false;
      this.isRequestLoading = false;

      this.requestingChanges = '';
      this.dueDate = '';
    },
    handleModalFiltersChange(filters) {
      this.selectedModalFields = { ...filters };
    },
    openMenuChangesModal(menuChanges) {
      this.viewingMenuChanges = menuChanges;
      this.showModalMenuChanges = true;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    openCreateMenuChangesModal() {
      let bufferDays = new Date();

      bufferDays.setDate(bufferDays.getDate() + 2);

      this.dueDate = bufferDays.toISOString().split('T')[0];

      this.showModalMenuChangesCreate = true;
    },
    buildRequestResponse(isMatched) {
      if (this.menuChangeRequestResponse) {
        const responseArray: string[] = [];
        const responseType = isMatched
          ? this.menuChangeRequestResponse.matched
          : this.menuChangeRequestResponse.unmatched;

        responseType?.forEach((matchedRestaurant) => {
          matchedRestaurant.plataforms?.forEach((platform) =>
            responseArray.push(
              this.$t('menuChanges.modal.responsePreposition', [
                matchedRestaurant.restaurant.name,
                platform,
              ])
            )
          );
        });

        return responseArray;
      }

      return [];
    },
    handleSort(sortKey) {
      if (this.menuChangesSorting === sortKey) {
        this.menuChangesOrder = this.menuChangesOrder === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.menuChangesSorting = sortKey;
        this.menuChangesOrder = 'ASC';
      }

      this.getMenuChanges();
    },
  },
  watch: {
    '$store.getters.selectedDateRange.fromDate': {
      handler(date) {
        if (date?.fromDate) {
          const initialDate = new Date(date.fromDate);
          initialDate.setDate(initialDate.getDate() + 2);

          this.handleDateSelected(initialDate.toLocaleDateString('fr-CA'));
        }
      },
      immediate: true,
    },
  },
});
