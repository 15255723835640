var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "optimizations-dashboard" },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(
            _vm.layout,
            {
              tag: "component",
              class: [
                "optimizations-dashboard__layout",
                {
                  "optimizations-dashboard__layout--authenticated":
                    _vm.isAuthenticated,
                },
              ],
            },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }