
























import Vue from 'vue';
import DashboardCard from './DashboardCardComponent.vue';
import ApexChart from 'vue-apexcharts';

import { analysisChartOptions } from './dashboardCard.config';
import { secondaryColor, tabletMaxWidth, desktopMinWidth } from '@/utils/constants';
import { SalesAnalysisPerCategoryComponent } from './DashboardCard';

export default (Vue as SalesAnalysisPerCategoryComponent).extend({
  components: {
    DashboardCard,
    ApexChart,
  },
  props: {
    categoriesBreakdown: {
      type: Array,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      rawItemData: [],
      chartSeries: [],
      chartOptions: {
        ...analysisChartOptions,
        legend: {
          position: 'left',
          formatter: this.chartLegendFormatted,
        },
        tooltip: {
          custom: this.chartTooltipFormatted,
        },
        responsive: [
          {
            breakpoint: tabletMaxWidth,
            options: {
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: desktopMinWidth,
            options: {
              chart: {
                height: '100%',
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    colorBasedOnPercentage() {
      const percentageChange = 0.9 / this.chartSeries.length;
      let currentPercentage = 1 + percentageChange;

      return this.chartSeries.map(() => {
        currentPercentage -= percentageChange;

        return `rgba(${secondaryColor}, ${currentPercentage})`;
      });
    },
  },
  methods: {
    setupChart(salesAnalysisPercentage) {
      this.chartSeries = [];
      this.chartOptions.labels = [];
      this.rawItemData = [];

      salesAnalysisPercentage.forEach((item) => {
        if (item.sales > 0) {
          let newSeriesItemIndex = 0;

          this.chartSeries.find((currentItem, index) => {
            const isSmaller = item.sales < currentItem;
            newSeriesItemIndex = isSmaller ? index - 1 : index;

            return !isSmaller;
          });

          this.chartSeries.splice(newSeriesItemIndex, 0, item.sales);
          this.chartOptions.labels?.splice(newSeriesItemIndex, 0, item.category);

          this.rawItemData.splice(newSeriesItemIndex, 0, item);
        }
      });

      this.chartOptions.colors = this.colorBasedOnPercentage;
    },
    chartLegendFormatted(categoryName, opts) {
      const percentage = opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(2);
      const finalString = `${categoryName} - ${percentage}%`;

      return finalString;
    },
    chartTooltipFormatted(opts) {
      const { seriesIndex } = opts;
      const item = this.rawItemData[seriesIndex];

      if (!item) {
        return '';
      }

      return `
        <ul class="sales-analysis__tooltip">
          <li><strong>${item.category}</strong></li>
          <li>Average price:
            <strong class="sales-analysis__tooltip-item">€${item.avgPrice.toFixed(2)}</strong>
          </li>
          <li>Sales:
            <strong class="sales-analysis__tooltip-item">
              €${item.sales.toLocaleString('de-DE')}
            </strong>
          </li>
          <li>Items:<strong class="sales-analysis__tooltip-item">${item.dishesNumber}</strong></li>
        </ul>
      `;
    },
  },
  watch: {
    categoriesBreakdown: {
      handler(categories) {
        if (categories) {
          this.setupChart(categories);
        }
      },
      immediate: true,
    },
  },
});
