var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-view" },
    [
      _c(
        "Card",
        {
          staticClass: "settings-view_invoices-card",
          attrs: { isLoading: _vm.isLoading },
          scopedSlots: _vm._u([
            {
              key: "custom-header",
              fn: function () {
                return [
                  _c("div", { staticClass: "invoices-card__card-header" }, [
                    _c(
                      "div",
                      { staticClass: "invoices-card__card-title-wrapper" },
                      [
                        _c("h1", { staticClass: "invoices-card__card-title" }, [
                          _vm._v(_vm._s(_vm.$t("settings.invoices"))),
                        ]),
                      ]
                    ),
                    _c(
                      "p",
                      { staticClass: "invoices-card__card-description" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("settings.invoices_description")) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "invoices-card invoices-card__card-content" },
            [
              _c("Table", {
                key: "invoices-" + Math.round(Math.random() * 1000),
                staticClass: "invoices-card__table",
                attrs: {
                  "is-embedded": "",
                  "is-custom-body": "",
                  tableData: _vm.tableData,
                  caption: _vm.$t("reporting.caption"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var rowBody = ref.rowBody
                      return [
                        !rowBody.customElement
                          ? _c("p", [_vm._v(" " + _vm._s(rowBody.value) + " ")])
                          : _vm._e(),
                        rowBody.customElement === _vm.CustomElement.Description
                          ? _c(
                              "p",
                              {
                                class: {
                                  "invoices-card--not-paid":
                                    rowBody.rowData.status === "NOT_PAID",
                                },
                              },
                              [_vm._v(" " + _vm._s(rowBody.value) + " ")]
                            )
                          : _vm._e(),
                        rowBody.customElement === _vm.CustomElement.Button
                          ? _c("div", { staticClass: "invoices-card__view" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "invoices-card__button-view",
                                  attrs: {
                                    href: rowBody.rowData,
                                    target: "_blank",
                                  },
                                },
                                [_c("EyeIcon")],
                                1
                              ),
                            ])
                          : _vm._e(),
                        rowBody.customElement === _vm.CustomElement.Status
                          ? [
                              _c(
                                "p",
                                { staticClass: "invoices-card__status" },
                                [
                                  _vm._v(
                                    _vm._s(rowBody.value.replace(/_/, " "))
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }