import { ApexOptions } from 'apexcharts';

import { TableDataProp } from '@/components/Table/Table';
import { ViewPort } from '@/utils/_enums/global';
import { NonSellers, TopSellers, BreakdownSellers } from './DashboardCard';

export const getTopSellingTableData = (data: TopSellers): TableDataProp => {
  const topSellers = data.topSellers || [];
  const total = data.total;

  return {
    headers: [
      {
        label: 'salesDashboard.tables.products',
        id: 'products-header-top-sellers',
        minViewPort: ViewPort.Mobile,
      },
      {
        label: 'salesDashboard.tables.totalSales',
        id: 'total-sales-header-top-sellers',
        minViewPort: ViewPort.Mobile,
      },
      {
        label: 'salesDashboard.tables.totalItems',
        id: 'total-sales-header-total-items',
        minViewPort: ViewPort.Tablet,
      },
      {
        label: 'salesDashboard.tables.percentageSales',
        id: 'total-sales-header-percentage-sales',
        minViewPort: ViewPort.Tablet,
      },
    ],
    body: topSellers.map((dataItem, index) => {
      const totalSales = `€ ${Math.trunc(dataItem.totalSales) || 0}`;

      return {
        id: dataItem.title + index,
        values: [
          {
            label: dataItem.title,
            id: `${dataItem.title}-item-${index}`,
            minViewPort: ViewPort.Mobile,
          },
          {
            label: totalSales,
            id: `${dataItem.title}-item-value-${index}`,
            minViewPort: ViewPort.Mobile,
          },
          {
            label: dataItem.totalItems,
            id: `${dataItem.title}-total-items-${index}`,
            minViewPort: ViewPort.Tablet,
          },
          {
            label: dataItem.salesPercentage,
            id: `${dataItem.title}-sales-percentage-${index}`,
            minViewPort: ViewPort.Tablet,
          },
        ],
      };
    }),
    footer: total
      ? {
          header: 'salesDashboard.tables.totalLabel',
          values: [
            {
              label: `€${total.totalSales?.toFixed(2) || 0}`,
              id: 'total-sales-top-sellers',
              minViewPort: ViewPort.Mobile,
            },
            {
              label: total.totalItems,
              id: 'total-items-top-sellers',
              minViewPort: ViewPort.Tablet,
            },
            {
              label: `${total.totalSalesPercentage?.toFixed(2)}%`,
              id: 'total-sales-percentage-top-sellers',
              minViewPort: ViewPort.Tablet,
            },
          ],
        }
      : undefined,
  };
};

export const getNonSellingTableData = (data: NonSellers): TableDataProp => {
  const nonSellers = data.nonSellingsData || [];

  return {
    headers: [
      {
        label: 'salesDashboard.tables.products',
        id: 'products-non-sellers',
        minViewPort: ViewPort.Mobile,
      },
      {
        label: 'salesDashboard.tables.price',
        id: 'price-non-sellers',
        minViewPort: ViewPort.Mobile,
      },
      {
        label: 'salesDashboard.tables.averagePriceCategory',
        id: 'avg-price-non-sellers',
        minViewPort: ViewPort.Tablet,
      },
      {
        label: 'salesDashboard.tables.picture',
        id: 'picture-non-sellers',
        minViewPort: ViewPort.Tablet,
      },
      {
        label: 'salesDashboard.tables.description',
        id: 'non-sellers-description',
        minViewPort: ViewPort.Tablet,
      },
    ],
    body: nonSellers.map((dataItem, index) => {
      const averagePrice = dataItem.averagePriceCategory?.toFixed(2) || '-';

      return {
        id: dataItem.title + index,
        values: [
          {
            label: dataItem.title,
            id: `${dataItem.title}-item-${index}`,
            minViewPort: ViewPort.Mobile,
          },
          {
            label: `€${dataItem.price}`,
            id: `${dataItem.title}-item-price-${index}`,
            minViewPort: ViewPort.Mobile,
          },
          {
            label: `€${averagePrice}`,
            id: `${dataItem.title}-item-average-value-${index}`,
            minViewPort: ViewPort.Tablet,
          },
          {
            label: dataItem.hasPicture ? '✓' : 'X',
            id: `${dataItem.title}-item-has-picture${index}`,
            minViewPort: ViewPort.Tablet,
          },
          {
            label: dataItem.hasDescription ? '✓' : 'X',
            id: `${dataItem.title}-item-has-description-${index}`,
            minViewPort: ViewPort.Tablet,
          },
        ],
      };
    }),
  };
};

export const getBreakdownTableData = (data: BreakdownSellers[]): TableDataProp => {
  const breakdownSellers = data || [];
  const idSuffix = 'breakdown-sellers';

  return {
    headers: [
      {
        label: 'salesDashboard.tables.categories',
        id: `category-${idSuffix}`,
        minViewPort: ViewPort.Mobile,
      },
      {
        label: 'salesDashboard.tables.numberOfDishes',
        id: `items-${idSuffix}`,
        minViewPort: ViewPort.Tablet,
      },
      {
        label: 'salesDashboard.tables.avgPrice',
        id: `avgPrice-${idSuffix}`,
        minViewPort: ViewPort.Tablet,
      },
      {
        label: 'salesDashboard.tables.totalSales',
        id: `total-sales-${idSuffix}`,
        minViewPort: ViewPort.Mobile,
      },
      {
        label: 'salesDashboard.tables.percentageProductsNotOrdered',
        id: `percentage-not-ordered-${idSuffix}`,
        minViewPort: ViewPort.Tablet,
      },
    ],
    body: breakdownSellers
      .sort((item1, item2) => item1.sales - item2.sales)
      .map((dataItem, index) => {
        const averagePrice = dataItem.avgPrice?.toFixed(2) || '-';
        const notSoldPercentage = dataItem.notSoldPercentage?.toFixed(2) || '-';

        return {
          id: dataItem.category + index,
          values: [
            {
              label: dataItem.category,
              id: `${dataItem.category}-item-category-${index}`,
              minViewPort: ViewPort.Mobile,
            },
            {
              label: dataItem.dishesNumber,
              id: `${dataItem.category}-item-number-${index}`,
              minViewPort: ViewPort.Tablet,
            },
            {
              label: `€${averagePrice}`,
              id: `${dataItem.category}-item-average-price-${index}`,
              minViewPort: ViewPort.Tablet,
            },
            {
              label: `€${dataItem.sales.toFixed(2)}`,
              id: `${dataItem.category}-item-sales-${index}`,
              minViewPort: ViewPort.Mobile,
            },
            {
              label: `${notSoldPercentage}%`,
              id: `${dataItem.category}-item-not-ordered-${index}`,
              minViewPort: ViewPort.Tablet,
            },
          ],
        };
      }),
  };
};

export const analysisChartOptions: ApexOptions = {
  chart: {
    type: 'pie',
  },
  colors: [],
  labels: [],
  stroke: {
    show: false,
  },
};

export const getWeekday = (weekDayIndex: number) => {
  if (weekDayIndex >= 0 && weekDayIndex <= 6) {
    const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    return 'shared.daysOfTheWeek.' + weekDays[weekDayIndex];
  }

  return '';
};

export const footerTextForUberOrGlovo = (currentPlatform: string) => {
  if (currentPlatform === 'UberEats') {
    return 'footerUberEats';
  } else if (currentPlatform === 'Glovo') {
    return 'footerGlovo';
  }

  return '';
};
