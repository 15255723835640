import { render, staticRenderFns } from "./PleezInsightsListMobile.vue?vue&type=template&id=9985d6a4&scoped=true&"
import script from "./PleezInsightsListMobile.vue?vue&type=script&lang=ts&"
export * from "./PleezInsightsListMobile.vue?vue&type=script&lang=ts&"
import style0 from "./PleezInsightsListMobile.vue?vue&type=style&index=0&id=9985d6a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9985d6a4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/thur/Documents/Projects/Pleez/optimizations-dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9985d6a4')) {
      api.createRecord('9985d6a4', component.options)
    } else {
      api.reload('9985d6a4', component.options)
    }
    module.hot.accept("./PleezInsightsListMobile.vue?vue&type=template&id=9985d6a4&scoped=true&", function () {
      api.rerender('9985d6a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/insights/pleez/PleezInsightsListMobile.vue"
export default component.exports