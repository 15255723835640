var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filters-platform__wrapper" }, [
    _c("h2", { staticClass: "filters-platform__title" }, [
      _vm._v(" " + _vm._s(_vm.$t("shared.deliveryService")) + " "),
    ]),
    _c(
      "ul",
      { staticClass: "filters-platform" },
      [
        _vm.availablePlatforms.length === 0
          ? _c(
              "li",
              { staticClass: "filters-platform__item" },
              [
                _c("PlatformIcon", {
                  staticClass: "filters-platform__loader",
                  attrs: { isLoading: "" },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._l(_vm.availablePlatforms, function (platform) {
          return _c(
            "li",
            { key: platform.id, staticClass: "filters-platform__item" },
            [
              _c(
                "button",
                {
                  class: [
                    _vm.isCurrentlyActivePlatform(platform.id)
                      ? "filters-platform__button--active"
                      : "",
                    "filters-platform__button",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.$emit("click", platform.id)
                    },
                  },
                },
                [_c("PlatformIcon", { attrs: { platform: platform.name } })],
                1
              ),
            ]
          )
        }),
        _vm.showAllPlatforms
          ? _c(
              "li",
              { staticClass: "filters-platform__all-platforms" },
              [
                _c("Checkbox", {
                  attrs: {
                    label:
                      _vm.$translations.filters.platforms.selectAllPlatforms[
                        _vm.currentLanguage
                      ],
                  },
                  on: { change: _vm.toggleAllPlatforms },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }