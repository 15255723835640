




























import Vue from 'vue';
import Input from '@/components/Inputs/InputComponent.vue';

import HttpRequest from '@/http/httpClass';
import { NewPasswordComponent, NewPasswordResponse } from './NewPassword';
import ButtonDefault from '@/components/Buttons/ButtonDefault.vue';

export default (Vue as NewPasswordComponent).extend({
  props: {
    email: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  components: {
    Input,
    ButtonDefault,
  },
  data: () => ({
    newPassword: '',
    newPasswordConfirmation: '',
    passwordInputType: 'password',
    passwordConfirmInputType: 'password',
  }),
  computed: {
    isPasswordValid() {
      if (this.newPassword.length > 0 && this.newPasswordConfirmation.length > 0) {
        return this.newPassword === this.newPasswordConfirmation;
      }

      return false;
    },
    shouldShowPassword() {
      return this.passwordInputType === 'text';
    },
    shouldShowConfirmPassword() {
      return this.passwordConfirmInputType === 'text';
    },
  },
  methods: {
    async resetPassword() {
      const request = new HttpRequest('/users/resetPassword', 'POST', false, {
        email: this.email,
        password: this.newPassword,
        token: this.token,
      });

      await request
        .send<NewPasswordResponse>()
        .then((response) => {
          if (response.message) {
            this.$emit('nextStep', {});
          }
        })
        .catch(() => {
          this.$toast.error(this.$t('errors.defaultHttpError'));
        });
    },
    handlePasswordInputChange(newInput, isConfirmPassword) {
      if (isConfirmPassword) {
        this.newPasswordConfirmation = newInput;
      } else {
        this.newPassword = newInput;
      }
    },
  },
});
