

















































































































































































import Vue from 'vue';

import { SettingsAccountComponent } from './SettingsAccount';

import Card from '@/components/Cards/CardComponent.vue';
import Input from '@/components/Inputs/InputComponent.vue';
import Select from '@/components/Inputs/Select.vue';
import SettingsRadioGroup from '@/views/settings/SettingsRadioGroup.vue';

import { AccountDetails, BusinessDetails } from './SettingsAccount';
import { countryCodes } from '@/utils/constants';
import SettingsCardHeader from './SettingsCardHeader.vue';

export default (Vue as SettingsAccountComponent).extend({
  components: {
    Card,
    Input,
    Select,
    SettingsCardHeader,
    SettingsRadioGroup,
  },
  data() {
    return {
      email: '',
      password: '',
      phoneNumber: '',
      newPassword: '',
      confirmPassword: '',
      phone: {
        prefix: '',
        number: '',
      },
      phoneErrorMessage: '',
      /**
       * There's a dependency between this
       * and notificationsSettings inside SettingsRadioGroup.vue
       * Ensure that the values match between them
       */
      notificationsSettings: {
        email: null,
        whatsApp: null,
      },
      savedPhone: null,
      savedNotificationsSettings: null,
      savedEmail: null,
      accountInEditMode: false,
      businessInEditMode: false,
      isLoadingUserDetails: true,
      isLoadingBusinessDetails: true,
      hasPhoneChange: false,
      hasNotificationsChange: false,
      countryCodes,
    };
  },
  mounted() {
    this.$store
      .dispatch('getUserInfo')
      .then((accountDetails: AccountDetails) => {
        this.email = accountDetails.email;
        this.savedEmail = this.email;
      })
      .finally(() => {
        this.isLoadingUserDetails = false;
      });

    this.$store
      .dispatch('getUserSettingsInfo')
      .then((businessDetails: BusinessDetails) => {
        if (businessDetails.phoneNumber) {
          const phoneNumber = businessDetails.phoneNumber;

          this.phone = {
            prefix: phoneNumber?.substring(phoneNumber.length - 9, 0),
            number: phoneNumber?.slice(-9),
          };
        }

        this.notificationsSettings = {
          email: businessDetails.emailFrequency,
          whatsApp: businessDetails.whatsappFrequency,
        };

        this.savedPhone = structuredClone(this.phone);
        this.savedNotificationsSettings = structuredClone(this.notificationsSettings);
      })
      .finally(() => {
        this.isLoadingBusinessDetails = false;
      });
  },
  computed: {
    isPasswordValid() {
      return this.newPassword === this.confirmPassword;
    },
    isPhoneValid() {
      const isNumberValid = Boolean(Number(this.phone.number));
      const isPrefixValid = Boolean(Number(this.phone.prefix));
      const noPhoneProvided = this.phone.number === '' && this.phone.prefix === '';

      let isLengthAboveMax = false;

      if (this.phone.number) {
        isLengthAboveMax = this.phone.number.length + this.phone.prefix.length > 15;
      }

      if (!isNumberValid && !noPhoneProvided) {
        this.phoneErrorMessage = this.$t('errors.phoneNotValid');
      } else if (isLengthAboveMax) {
        this.phoneErrorMessage = this.$t('errors.phoneAboveMaxLength');
      } else if (isNumberValid && !isPrefixValid) {
        this.phoneErrorMessage = this.$t('errors.phonePrefixMissing');
      } else {
        this.phoneErrorMessage = '';
      }

      return noPhoneProvided || (isPrefixValid && isNumberValid && !isLengthAboveMax);
    },
    shouldShowMismatchError() {
      return (
        this.newPassword.length > 0 && this.confirmPassword.length > 0 && !this.isPasswordValid
      );
    },
    passwordInputDefaultValue() {
      return this.accountInEditMode ? '' : '*'.repeat(this.password.length) || '*******';
    },
    countryPhoneCodes() {
      return Object.entries(countryCodes).map(([key, value]) => `${key}: +${value}`);
    },
    visualPhonePrefix() {
      if (this.phone.prefix) {
        return `+${this.phone.prefix}`;
      }

      return '';
    },
    emailDescription() {
      return this.businessInEditMode ? this.$t('settings.emailDescription') : '';
    },
    emailInfoMessage() {
      return this.businessInEditMode ? this.$t('settings.emailInfo') : '';
    },
    emailErrorMessage() {
      return this.email === '' || !this.email.includes('@') ? this.$t('errors.emailNotValid') : '';
    },
  },
  methods: {
    saveAccountDetails() {
      this.isLoadingUserDetails = true;

      let payload: {
        email: string;
        password?: string;
      } = {
        email: this.email ?? this.savedEmail,
      };

      if (this.newPassword && this.isPasswordValid) {
        payload = {
          ...payload,
          password: this.newPassword,
        };

        this.newPassword = '';
        this.confirmPassword = '';
        this.password = this.newPassword;
      }

      this.$store
        .dispatch('updateUserInfo', payload)
        .then(() => {
          this.accountInEditMode = false;
          this.$toast.success(this.$t('settings.settingsUpdateSuccess'));
        })
        .catch(() => this.$toast.error(this.$t('errors.settingsUpdateFailed')))
        .finally(() => (this.isLoadingUserDetails = false));
    },
    saveBusinessDetails() {
      this.isLoadingBusinessDetails = true;

      let payload = {};

      if (this.hasPhoneChange) {
        payload = { phoneNumber: this.phone.number ? this.phone.prefix + this.phone.number : '' };
      }

      if (this.hasNotificationsChange) {
        payload = {
          ...payload,
          whatsappFrequency: this.notificationsSettings.whatsApp,
          emailFrequency: this.notificationsSettings.email,
        };
      }

      this.$store
        .dispatch('updateUserSettingsInfo', payload)
        .then(() => {
          this.businessInEditMode = false;
          this.hasPhoneChange = false;
          this.hasNotificationsChange = false;

          this.$toast.success(this.$t('settings.settingsUpdateSuccess'));
        })
        .catch(() => this.$toast.error(this.$t('errors.settingsUpdateFailed')))
        .finally(() => (this.isLoadingBusinessDetails = false));
    },
    handlePasswordInputChange(newInput, isConfirmPassword) {
      if (isConfirmPassword) {
        this.confirmPassword = newInput;
      } else {
        this.newPassword = newInput;
      }
    },
    handleEmailChange(email) {
      //Ensures proper error handling for emailErrorMessage
      this.email = email;

      if (email.trim()) {
        this.email = email;
      }
    },
    handlePrefixChange(phonePrefix) {
      if (phonePrefix) {
        const prefixSections = phonePrefix.split('+');

        this.phone.prefix = prefixSections[prefixSections.length - 1];
        this.hasPhoneChange = true;

        /**
         * Since Input is a custom component ref
         * gives us a VueComponent instead of an HTMLElement
         * hence the *.$el.focus()
         */
        this.$refs.phoneInput.$el.focus();
      }
    },
    handlePhoneChange(phoneNumber) {
      this.phone.number = phoneNumber;
      this.hasPhoneChange = true;
    },
    handleNotificationChange(notification) {
      this.notificationsSettings[notification.group] = notification.value;
      this.hasNotificationsChange = true;
    },
    handleAccountEdit(isEditing) {
      this.accountInEditMode = isEditing;

      if (!isEditing) {
        this.email = this.savedEmail ?? this.email;
        this.newPassword = '';
        this.confirmPassword = '';
      }
    },
    handleBusinessEdit(isEditing) {
      this.businessInEditMode = isEditing;

      /**
       * If the cancel button was clicked
       * values need to be reset
       */
      if (!isEditing) {
        this.phone = this.savedPhone ?? this.phone;
        this.notificationsSettings = this.savedNotificationsSettings ?? this.notificationsSettings;

        this.hasPhoneChange = false;
        this.hasNotificationsChange = false;
        this.phoneErrorMessage = '';
      }
    },
  },
});
