import { TableDataProp } from '@/components/Table/Table';
import { CustomElement, ViewPort } from '@/utils/_enums/global';
import { Promotion } from './Promotion';

const fixedHeaders = [
  {
    label: 'promotions.promotion',
    id: 'promotion-type',
    minViewPort: ViewPort.Mobile,
  },
  {
    label: 'promotions.restaurantName',
    id: 'promotion-name',
    minViewPort: ViewPort.Mobile,
  },
  {
    label: 'promotions.menu',
    id: 'promotion-menu',
    minViewPort: ViewPort.Tablet,
  },
];

export const getTableData = (data: Promotion[]): TableDataProp => {
  const body = data.map((dataItem, index) => {
    return {
      id: `promotions-table-${index}`,
      values: [
        {
          customElement: CustomElement.Icon,
          rawData: dataItem,
          label: dataItem.promotionLabel,
          id: `${dataItem.iconName}-icon-${index}`,
          minViewPort: ViewPort.Mobile,
        },
        {
          label: dataItem.restaurant,
          id: `${dataItem.restaurant}-restaurant-${index}`,
          minViewPort: ViewPort.Mobile,
        },
        {
          label: '-',
          id: `menu-${index}`,
          minViewPort: ViewPort.Tablet,
        },
      ],
    };
  });

  return { headers: fixedHeaders, body };
};
