var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-modal-wrapper" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.value
          ? _c(
              "div",
              {
                staticClass: "p-modal-overlay",
                class: { "p-modal-overlay--absolute": _vm.isAbsolute },
                on: { click: _vm.closeModal },
              },
              [
                _c("div", { staticClass: "p-modal", style: _vm.modalStyle }, [
                  _c(
                    "div",
                    { staticClass: "p-modal-header" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "p-modal-close-icon",
                          on: { click: _vm.toggleModal },
                        },
                        [_c("CloseIcon")],
                        1
                      ),
                      _vm._t("title"),
                    ],
                    2
                  ),
                  _vm.hasBody
                    ? _c(
                        "div",
                        { staticClass: "p-modal-body" },
                        [_vm._t("body")],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "p-modal-footer" },
                    [_vm._t("footer")],
                    2
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }