<template>
  <Card :title="$t('priceComparison.price_table.title')">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            {{ $t('shared.restaurant') }}
          </th>
          <th scope="col">
            {{ $t('priceComparison.price_table.columns.product') }}
          </th>
          <th scope="col">
            {{ $t('priceComparison.price_table.columns.category') }}
          </th>
          <th scope="col">
            {{ $t('priceComparison.price_table.columns.price') }}
          </th>
        </tr>
      </thead>
      <tbody style="height: 240px">
        <tr
          v-if="brandMetricsResponse.length > 0"
          v-for="(metric, metricIndex) in brandMetricsResponse"
          :key="metricIndex"
        >
          <td data-label="Restaurant">{{ metric.restaurant }}</td>
          <td data-label="Product" style="word-wrap: break-word">
            {{ metric.product }}
          </td>
          <td data-label="Category">{{ metric.category }}</td>
          <td data-label="Price">€{{ metric.price ? metric.price.toFixed(2) : 0 }}</td>
        </tr>
      </tbody>
    </table>
  </Card>
</template>

<script>
import Card from '@/components/Cards/CardComponent.vue';

export default {
  components: {
    Card,
  },
  data: () => ({
    brandMetricsResponse: [],
    typingTimeout: null,
  }),
  watch: {
    '$store.getters.restaurantFilters'(restaurantFilters) {
      this.getData(restaurantFilters);
    },
  },
  methods: {
    async getData(selectedRestaurantFilters) {
      const { labels, keywords, restaurants } = selectedRestaurantFilters;
      const additionalParams = {};

      if (labels) {
        additionalParams.Label = labels.map((label) => encodeURIComponent(label.name)).join(',');
      }

      if (
        restaurants.length <= 0 ||
        restaurants.filter((restaurant) => restaurant.id == 0).length > 0
      ) {
        this.brandMetricsResponse = [];
        return;
      }

      additionalParams.keywords = keywords;

      if (typeof additionalParams.keywords === 'string') {
        additionalParams.keywords = additionalParams.keywords.split(',');
      }

      additionalParams.keywords = additionalParams.keywords.map((word) => word.trim()).join(',');

      const requestBrandMetrics = new this.request();
      requestBrandMetrics.setMethod('GET');
      requestBrandMetrics.setUrl(
        requestBrandMetrics.endpoints.PRICE_COMPARISON.ROOT({
          idRestaurant: restaurants.map((restaurant) => restaurant.id).join(','),
          ...additionalParams,
        })
      );
      requestBrandMetrics.setBaseUrl(process.env.VUE_APP_API_BASE_URL);
      requestBrandMetrics.setAuthentication({
        type: 'Bearer',
        token: this.$session.getSession('pleez-token'),
      });
      requestBrandMetrics.setHeaders(
        { 'Content-Type': 'application/json' },
        { 'Access-Control-Allow-Origin': '*' }
      );

      const brandMetricsResponse = await requestBrandMetrics.send();

      if (brandMetricsResponse) this.brandMetricsResponse = brandMetricsResponse;
    },
  },
};
</script>
