/**
 * Here is defined all routes of the application with their respective layouts & views.
 * And exported to be used at the router.js file.
 * Example:
 *  {
 *     path: '/',
 *     name: 'home',
 *     component: Views.Home,
 *     meta: {
 *        layout: 'blank-layout'
 *     }
 *  }
 */

import Translations from '@/plugins/i18n/translations.json';

import { Route } from './routesInterface';

const HomePage = () => import('@/views/home/HomePage.vue');
const MarketInsightsView = () => import('@/views/insights/MarketInsightsView.vue');
const LoginView = () => import('@/views/login/LoginView.vue');
const ForgotPasswordView = () => import('@/views/forgotPassword/ForgotPasswordView.vue');
const PleezInsightsView = () => import('@/views/insights/pleez/PleezInsightsView.vue');
const OverviewView = () => import('@/views/analytics/OverviewView.vue');
const DashboardView = () => import('@/views/dashboard/DashboardView.vue');
const MenuEngineeringView = () =>
  import('@/views/analytics/menuEngineering/MenuEngineeringView.vue');
const ReportsView = () => import('@/views/reports/ReportsView.vue');
const MenuChangesView = () => import('@/views/menuChanges/MenuChangesView.vue');
const PromotionsView = () => import('@/views/market/PromotionsView.vue');
const CompetitorTrackerView = () => import('@/views/market/CompetitorTrackerView.vue');
const LogoutView = () => import('@/views/others/LogoutView.vue');
const PerformanceView = () => import('@/views/performance/PerformanceView.vue');
const SettingsAccountView = () => import('@/views/settings/SettingsAccountView.vue');
const SettingsPaymentsView = () => import('@/views/settings/SettingsPaymentsView.vue');
const ParentLayout = () => import('@/layouts/ParentLayout.vue');

const NotFoundView = () => import('@/views/notFound/NotFoundView.vue');
const { market, analytics, optimization, settings, home } = Translations.sidebar;

export enum RouteRoot {
  HOME = 'home',
  MARKET = 'market',
  ANALYTICS = 'analytics',
  OPTIMIZATION = 'optimization',
  MENU = 'menu',
  SETTINGS = 'settings',
}

const routes: Route[] = [
  {
    path: '/',
    component: LoginView,
    meta: {
      layout: 'not-authenticated-layout',
    },
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordView,
    meta: {
      layout: 'not-authenticated-layout',
    },
  },
  {
    path: '/logout',
    component: LogoutView,
    meta: {
      layout: 'blank-layout',
      breadcrumbs: {
        page: 'Logout',
        subPage: [],
      },
    },
  },

  // =============================================================================
  // HOME
  // =============================================================================
  /**
   * This can be done with guards. When/if the reload on
   * platform switch is removed, change this to guard
   */
  {
    path: '/home',
    component: HomePage,
    meta: {
      title: home.label,
      layout: 'authenticated-layout',
      icon: 'HomeIcon',
      isSuperUserLocked: false,
      root: RouteRoot.HOME,
    },
  },
  // =============================================================================
  // MARKET
  // =============================================================================

  {
    path: '/insights/competitor-tracker',
    redirect: '/market/competitor-tracker',
    meta: { layout: 'blank-layout' },
  },
  // REDIRECT TEMPORARY FOR TESTS OF CHILDREN
  {
    path: '/market/competitor-tracker',
    redirect: '/market/price-comparison',
    meta: { layout: 'blank-layout' },
  },
  {
    path: '/market/',
    component: ParentLayout,
    children: [
      {
        path: 'price-comparison',
        meta: {
          title: market.priceComparison,
          breadcrumbs: {
            page: market.label,
            subPage: [market.priceComparison, market.competitorPromotions],
            activeSubPageIndex: 0,
            subPagesIdentifier: ['price-comparison', 'competitor-promotions'],
          },
          root: RouteRoot.MARKET,
          layout: 'authenticated-layout',
        },
        component: CompetitorTrackerView,
      },
      {
        path: 'competitor-promotions',
        meta: {
          title: market.competitorPromotions,
          breadcrumbs: {
            page: market.label,
            subPage: [market.priceComparison, market.competitorPromotions],
            activeSubPageIndex: 1,
            subPagesIdentifier: ['price-comparison', 'competitor-promotions'],
          },
          root: RouteRoot.MARKET,
          layout: 'authenticated-layout',
        },
        component: PromotionsView,
      },
    ],
    meta: {
      title: market.competitorTracker,
      icon: 'CrosshairIcon',
      isSuperUserLocked: false,
      root: RouteRoot.MARKET,
      layout: 'blank-layout',
    },
  },
  {
    path: '/market-insights',
    redirect: '/market/trends',
    meta: { layout: 'blank-layout' },
  },
  {
    path: '/market/trends',
    component: MarketInsightsView,
    meta: {
      title: market.marketTrends,
      icon: 'MagnifyingGlassIcon',
      isSuperUserLocked: false,
      root: RouteRoot.MARKET,
      layout: 'authenticated-layout',
      breadcrumbs: {
        page: market.label,
        subPage: [market.marketTrends],
      },
    },
  },

  // =============================================================================
  // ANALYTICS
  // =============================================================================

  {
    path: '/analytics/overview',
    component: OverviewView,
    meta: {
      title: analytics.overview,
      icon: 'MaximizeIcon',
      isSuperUserLocked: false,
      root: RouteRoot.ANALYTICS,
      layout: 'authenticated-layout',
      hasDatePicker: true,
      breadcrumbs: {
        page: analytics.label,
        subPage: [analytics.overview],
      },
    },
  },

  {
    path: '/dashboard',
    redirect: '/analytics/sales',
    meta: { layout: 'blank-layout' },
  },
  {
    path: '/analytics/sales',
    component: DashboardView,
    meta: {
      title: analytics.sales,
      icon: 'CoinIcon',
      isSuperUserLocked: false,
      root: RouteRoot.ANALYTICS,
      layout: 'authenticated-layout',
      hasDatePicker: true,
      breadcrumbs: {
        page: analytics.label,
        subPage: [analytics.sales],
      },
    },
  },
  {
    path: '/analytics/performance',
    component: PerformanceView,
    meta: {
      title: analytics.performance,
      icon: 'ChartIcon',
      isSuperUserLocked: true,
      root: RouteRoot.ANALYTICS,
      layout: 'authenticated-layout',
      hasDatePicker: true,
      breadcrumbs: {
        page: analytics.label,
        subPage: [analytics.performance],
      },
    },
  },
  {
    path: '/menu-engineering',
    redirect: '/analytics/menu-engineering',
    meta: { layout: 'blank-layout' },
  },
  {
    path: '/analytics/menu-engineering',
    component: MenuEngineeringView,
    meta: {
      title: analytics.menuEngineering,
      icon: 'PieChartIcon',
      isSuperUserLocked: true,
      root: RouteRoot.ANALYTICS,
      layout: 'authenticated-layout',
      breadcrumbs: {
        page: analytics.label,
        subPage: [analytics.menuEngineering],
      },
    },
  },
  {
    path: '/reports',
    redirect: '/analytics/reports',
    meta: { layout: 'blank-layout' },
  },
  {
    path: '/analytics/reports',
    component: ReportsView,
    meta: {
      title: analytics.reporting,
      icon: 'ReportIcon',
      isSuperUserLocked: false,
      root: RouteRoot.ANALYTICS,
      layout: 'authenticated-layout',
      breadcrumbs: {
        page: analytics.label,
        subPage: [analytics.reporting],
      },
    },
  },

  // =============================================================================
  // OPTMIZATION
  // =============================================================================

  {
    path: '/menu-changes',
    redirect: '/optimization/menu-changes',
    meta: { layout: 'blank-layout' },
  },
  {
    path: '/optimization/menu-changes',
    component: MenuChangesView,
    meta: {
      title: optimization.menuChanges,
      icon: 'SquaresIcon',
      isSuperUserLocked: false,
      layout: 'authenticated-layout',
      root: RouteRoot.OPTIMIZATION,
      breadcrumbs: {
        page: optimization.label,
        subPage: [optimization.menuChanges],
      },
    },
  },

  {
    path: '/insights/pleez',
    redirect: '/optimization/pleez-insights',
    meta: { layout: 'blank-layout' },
  },
  {
    path: '/optimization/pleez-insights',
    component: PleezInsightsView,
    meta: {
      title: optimization.pleezInsight,
      icon: 'PleezIcon',
      isSuperUserLocked: false,
      root: RouteRoot.OPTIMIZATION,
      layout: 'authenticated-layout',
      breadcrumbs: {
        page: optimization.label,
        subPage: [optimization.pleezInsight],
      },
    },
  },
  // =============================================================================
  // SETTINGS
  // =============================================================================
  {
    path: '/settings/account',
    component: SettingsAccountView,
    meta: {
      title: settings.account,
      icon: 'SettingsIcon',
      isSuperUserLocked: [false, false],
      root: RouteRoot.SETTINGS,
      layout: 'authenticated-layout',
      breadcrumbs: {
        page: settings.label,
        subPage: [settings.account, settings.payments],
        activeSubPageIndex: 0,
        subPagesIdentifier: ['account', 'payments'],
      },
    },
  },
  {
    path: '/settings/payments',
    component: SettingsPaymentsView,
    meta: {
      title: settings.account,
      icon: 'SettingsIcon',
      isSuperUserLocked: [false, false],
      root: RouteRoot.SETTINGS,
      layout: 'authenticated-layout',
      breadcrumbs: {
        page: settings.label,
        subPage: [settings.account, settings.payments],
        activeSubPageIndex: 1,
        subPagesIdentifier: ['account', 'payments'],
      },
    },
  },

  // =============================================================================
  // SETTINGS
  // =============================================================================

  {
    path: '*',
    component: NotFoundView,
    meta: {
      layout: 'authenticated-layout',
    },
  },
];

export default routes;
