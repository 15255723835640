













import Vue from 'vue';
import { CheckboxComponent } from './Inputs';

export default (Vue as CheckboxComponent).extend({
  props: {
    label: {
      type: String,
      required: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkboxValue: this.isChecked,
    };
  },
  methods: {
    handleCheckboxClick() {
      this.$emit('change', this.checkboxValue);
    },
  },
  watch: {
    isChecked() {
      this.checkboxValue = this.isChecked;
    },
  },
});
