export const AUTH = '/auth';

export const CHANGE_PASSWORD = '/users/password';

export const RESTAURANTS_USER = '/restaurants/user';

export const PLEEZ_REPORTS = '/pleezReports';

/**
 * Endpoints to get metrics
 */
export const METRICS = {
  RESTAURANT_OVERVIEW: ({ fromDate = '', toDate = '' }) => {
    let queryString = '';

    if (fromDate) queryString += `?fromDate=${fromDate}`;

    if (toDate) queryString += `${queryString ? '&' : '?'}toDate=${toDate}`;

    return `/metrics/restaurantOverview${queryString}`;
  },
  HEADLINES: ({ fromDate = '', toDate = '' }) => {
    let queryString = '';

    if (fromDate) queryString += `?fromDate=${fromDate}`;

    if (toDate) queryString += `${queryString ? '&' : '?'}toDate=${toDate}`;

    return `/metrics/headlines${queryString}`;
  },
  CATEGORIES_BREAKDOWN: ({ fromDate = '', toDate = '' }) => {
    let queryString = '';

    if (fromDate) queryString += `${queryString ? '&' : '?'}fromDate=${fromDate}`;

    if (toDate) queryString += `${queryString ? '&' : '?'}toDate=${toDate}`;

    return `/metrics/categoriesBreakdown${queryString}`;
  },
  WEEKDAY_SALES: ({ fromDate = '', toDate = '', categoryName = '', productName = '' }) => {
    let queryString = '';

    if (fromDate) queryString += `?fromDate=${fromDate}`;

    if (toDate) queryString += `${queryString ? '&' : '?'}toDate=${toDate}`;

    if (categoryName) queryString += `${queryString ? '&' : '?'}CategoryName=${categoryName}`;

    if (productName) queryString += `${queryString ? '&' : '?'}ProductName=${productName}`;

    return `/metrics/weekdaySales${queryString}`;
  },
  NON_SELLING: ({ fromDate = '', toDate = '' }) => {
    let queryString = '';

    if (fromDate) queryString += `?fromDate=${fromDate}`;

    if (toDate) queryString += `${queryString ? '&' : '?'}toDate=${toDate}`;

    return `/metrics/nonSelling${queryString}`;
  },
  TOP_SELLERS: ({ fromDate = '', toDate = '' }) => {
    let queryString = '';

    if (fromDate) queryString += `?fromDate=${fromDate}`;

    if (toDate) queryString += `${queryString ? '&' : '?'}toDate=${toDate}`;

    return `/metrics/topSellers${queryString}`;
  },
  UPDATE_MATCH: () => {
    return `/metrics/updateMatch`;
  },
  RESTAURANT_ORDERS: ({ fromDate, toDate, periodType }) => {
    const queryString = `?fromDate=${fromDate}&toDate=${toDate}&periodType=${periodType}`;

    return `/metrics/orders${queryString}`;
  },
  RESTAURANT_SALES: ({ fromDate, toDate, periodType }) => {
    const queryString = `?fromDate=${fromDate}&toDate=${toDate}&periodType=${periodType}`;

    return `/metrics/sales${queryString}`;
  },
  RESTAURANT_ITEMS: ({ fromDate, toDate, periodType }) => {
    const queryString = `?fromDate=${fromDate}&toDate=${toDate}&periodType=${periodType}`;

    return `/metrics/items${queryString}`;
  },
  RESTAURANT_AVERAGE_TICKET: ({ fromDate, toDate, periodType }) => {
    const queryString = `?fromDate=${fromDate}&toDate=${toDate}&periodType=${periodType}`;

    return `/metrics/averageTicket${queryString}`;
  },
  MENU_QUALITY: '/menuEng',
  VISIBILITY: ({ fromDate, toDate, periodType }) => {
    const queryString = `?fromDate=${fromDate}&toDate=${toDate}&periodType=${periodType}`;

    return `/metrics/visibility${queryString}`;
  },
  CONVERSION_RATE: ({ fromDate, toDate, periodType }) => {
    const queryString = `?fromDate=${fromDate}&toDate=${toDate}&periodType=${periodType}`;

    return `/metrics/conversion${queryString}`;
  },
};

export const MENU = {
  CATEGORIES: ({ idRestaurants = '' }) => {
    let queryString = '';

    if (idRestaurants) queryString += `?idRestaurants=${idRestaurants}`;

    return `/categories${queryString}`;
  },
  CATEGORIES_LABELS: ({ idRestaurants = '' }) => {
    let queryString = '';

    if (idRestaurants) queryString += `?idRestaurants=${idRestaurants}`;

    return `/categories/labels${queryString}`;
  },
  PRODUCTS: ({ categoryName, idMenu = '' }) => {
    let queryString = '';

    if (idMenu) queryString += `?idMenu=${idMenu}`;

    return `/products/${categoryName}${queryString}`;
  },
  PRODUCTS_ROOT: ({ idMenu = '' }) => {
    let queryString = '';

    if (idMenu) queryString += `?idMenu=${idMenu}`;

    return `/products/${queryString}`;
  },
  FUNCTIONS: '/functions',
  MENU_FUNCTIONS: () => {
    return `/functions/listFunctions`;
  },
};

export const PRICE_COMPARISON = {
  ROOT: ({ idRestaurant, categoryLabel = '', Label = '', keywords = '' }) => {
    let queryString = '';

    if (idRestaurant) queryString += `?idRestaurants=${idRestaurant}`;

    if (categoryLabel) queryString += `${queryString ? '&' : '?'}categoryLabel=${categoryLabel}`;

    if (Label) queryString += `${queryString ? '&' : '?'}Label=${Label}`;

    if (keywords) queryString += `${queryString ? '&' : '?'}keywords=${keywords}`;

    return `/priceComparison/${queryString}`;
  },
  SALES_ANALYSIS_PER_CATEGORY: ({
    idRestaurant,
    categoryLabel = '',
    Label = '',
    keywords = '',
  }) => {
    let queryString = '';

    if (idRestaurant) queryString += `?idRestaurants=${idRestaurant}`;

    if (categoryLabel) queryString += `${queryString ? '&' : '?'}categoryLabel=${categoryLabel}`;

    if (Label) queryString += `${queryString ? '&' : '?'}Label=${Label}`;

    if (keywords) queryString += `${queryString ? '&' : '?'}keywords=${keywords}`;

    return `/priceComparison/categorySalesAnalysis/${queryString}`;
  },
};

export const SALES = {
  DATE_RANGE: '/reports/dateRange',
};

export const HOME = '/home';

export const RESTAURANTS_PLATFORMS = '/users/restaurantsAndPlatforms';
export const CITIES_PLATFORMS = '/users/citiesAndPlatforms';
