




































import Vue from 'vue';
import LoaderComponent from '@/components/Loader/LoaderComponent.vue';

import { PlatformIconComponent } from './PlatformIcon';

export default (Vue as PlatformIconComponent).extend({
  name: 'PlatformIcon',
  components: {
    LoaderComponent,
  },
  props: {
    platform: {
      type: String,
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    platforms() {
      return this.$store.getters.platforms;
    },
  },
});
