// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/preview-blur-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* Brand Colors */\n/* Secondary Colors */\n/* Grey Colors */\n/**\n* Mixins so that breakpoints can be\n* added if need be\n*/\n.terms-of-conditions__overlay {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n}\n.terms-of-conditions__overlay--is-new-user {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.terms-of-conditions__modal-content {\n  overflow: auto;\n}\n.terms-of-conditions__modal-content .docx {\n  width: 100%;\n  padding: var(--spacing-xs);\n}\n.terms-of-conditions__modal-footer {\n  display: flex;\n  justify-content: space-around;\n  width: 100%;\n  margin-top: var(--spacing-m);\n}", ""]);
// Exports
module.exports = exports;
