// @ts-nocheck
import Vue from 'vue';
import App from './App.vue';

import router from './routes/router';
import store from '@/store';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import './globals';
import '@/layouts/';

Vue.config.productionTip = false;

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

import VueToast from 'vue-toast-notification';

Vue.use(VueToast, {
  position: 'bottom',
  duration: 5000,
  queue: true,
});

import http from './http/httpClassOld';
Vue.prototype.request = http;

import '@/plugins/i18n';
import '@/components';

import Vue2Editor from 'vue2-editor';
Vue.use(Vue2Editor);

/**
 * v-select component
 */
import vSelect from 'vue-select';
import ChevronRight from '@/assets/icons/chevron-right.svg?inline';

vSelect.props.components.default = () => ({
  Deselect: {
    render: (createElement) => createElement('span', '✕'),
  },
  OpenIndicator: ChevronRight,
});
Vue.component('v-select', vSelect);

/**
 * Global component registration
 */

import '@/components';
import '@/utils/_icons/iconComponentList';

import JsonCSV from 'vue-json-csv';
Vue.component('downloadCsv', JsonCSV);

import VueSplide from '@splidejs/vue-splide';

Vue.use(VueSplide);

import 'vue-select/dist/vue-select.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import '@splidejs/splide/dist/css/splide.min.css';
import 'flag-icons/css/flag-icons.min.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

const { format } = require('date-fns');

function getIsoDateFormatted(date) {
  return format(date, 'yyyy-MM-dd');
}

window.getIsoDateFormatted = getIsoDateFormatted;

//FIXME: Vue object shouldn't be altered
Vue.prototype.getIsoDateFormatted = getIsoDateFormatted;
Vue.prototype.$session = window.session;

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: 'https://25cd4959046647e2bf9f6efe2e7e92e7@o1162067.ingest.sentry.io/6248845',
    trackComponents: true, //FIXME: Check if our rate-limit supports this
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['core.trypleez.com'],
        environment: process.env.NODE_ENV,
        enabled: true,
      }),
      new Sentry.Replay(),
    ],
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 0.1,
  });
}
export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
