




































import Vue from 'vue';
import Select from '@/components/Inputs/Select.vue';
import MenuChangesMobile from './MenuChangesMobile.vue';

import { MobileMenuChangesListComponent } from './TableMenuChanges';
import ButtonDefault from '@/components/Buttons/ButtonDefault.vue';
import { sortingOptions } from './MenuChanges.config';

export default (Vue as MobileMenuChangesListComponent).extend({
  components: {
    Select,
    MenuChangesMobile,
    ButtonDefault,
  },
  props: {
    menuChanges: {
      type: Array,
      required: true,
    },
    hasLoadMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasEllipsis: true,
      /**
       * Hardcoded value that should be enough to show at least a bit
       * of the content for both address and insight info
       */
      maxInsightDescriptionHeight: 112,
    };
  },
  computed: {
    sortOptions() {
      return Object.values(sortingOptions).map((sortOption) => {
        return {
          ...sortOption,
          label: this.$t(sortOption.label),
        };
      });
    },
  },
  methods: {
    handleSortOption(sortBy) {
      this.$emit('handleSortClick', sortBy.value);
    },
    handleInsightActionClick(insight, isApprove) {
      this.$emit('handleInsightActionClick', insight, isApprove);
    },
  },
});
