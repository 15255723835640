import { MenuMetric, MenuQualityMetrics } from './MenuEngineering';

const menuQualityConfigs = {
  descriptions: {
    metric: (self: number, market: number) => Math.round((self * 100) / market) >= 100,
    hasGoodBadInsight: true,
  },
  pictures: {
    metric: (self: number, market: number) => Math.round((self * 100) / market) >= 100,
    hasGoodBadInsight: true,
  },
  brandRating: {
    metric: (self: number, market: number) => self >= market,
    hasGoodBadInsight: true,
  },
  reviews: {
    metric: (self: number, market: number) => self >= market,
    hasGoodBadInsight: true,
  },
  menuItems: {
    metric: (value: number) => value >= 15 && value <= 20,
    hasGoodBadInsight: false,
  },
  menuCategories: {
    metric: (value: number) => value === 6,
    hasGoodBadInsight: false,
  },
};

export const ensureIsNumber = (value: string | number) => {
  if (typeof value === 'string') {
    return Number(value.replace(/[^\d.-]/g, ''));
  }

  return value;
};

export const checkMenuQualityCriteria = (
  metricName: keyof MenuQualityMetrics,
  metric: MenuMetric
) => {
  return menuQualityConfigs[metricName].metric(metric.metrics.self, metric.metrics.market);
};

export const hasGoodOrBadInsight = (metricName: keyof MenuQualityMetrics) =>
  menuQualityConfigs[metricName].hasGoodBadInsight;
