var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("h2", { staticClass: "home__title" }, [
      _vm._v(_vm._s(_vm.$t("home.title")) + ","),
    ]),
    _c("p", { staticClass: "home__sub-title" }, [
      _vm._v(_vm._s(_vm.$t("home.subTitle"))),
    ]),
    _c("div", { staticClass: "home__banner home__referral" }, [
      _c("h3", { staticClass: "home__banner-title home__referral-title" }, [
        _c("span", { staticClass: "home__banner-icon" }, [_c("PleezIcon")], 1),
        _vm._v(" " + _vm._s(_vm.$t("home.referralTitle")) + ": "),
      ]),
      _c("p", { staticClass: "home__banner-text home__referral-text" }, [
        _vm._v(" " + _vm._s(_vm.$t("home.referralText")) + " "),
        _c(
          "a",
          {
            staticClass: "home__referral-text--link",
            attrs: { href: _vm.$t("home.referralLink") },
          },
          [_vm._v(" " + _vm._s(_vm.$t("home.referralLinkLabel")) + ". ")]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "home__banner" },
      [
        _c("h3", { staticClass: "home__banner-title" }, [
          _c(
            "span",
            { staticClass: "home__banner-icon" },
            [
              _c("BellIcon"),
              _vm.newInsights
                ? _c("span", { class: { "home__banner-icon--active": true } })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" " + _vm._s(_vm.$t("shared.recommendations")) + ": "),
        ]),
        _vm.newInsights === null
          ? _c("LoaderComponent", { attrs: { "is-mini": "" } })
          : _vm.newInsights
          ? _c("p", {
              staticClass: "home__banner-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("home.recommendations", [_vm.newInsights])
                ),
              },
            })
          : _c("p", { staticClass: "home__banner-text" }, [
              _vm._v(_vm._s(_vm.$t("home.noRecommendations"))),
            ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "home__card-group" },
      [
        _c(
          "Card",
          {
            staticClass: "home__card",
            attrs: {
              "no-padding": "",
              "no-header-divider": "",
              title: _vm.$t("timeSavedCalculator.title"),
              "is-loading": !_vm.timeSaver,
            },
          },
          [
            _vm.timeSaver
              ? _c("TimeSavedCalculator", {
                  attrs: {
                    insights: _vm.timeSaver.insights,
                    menuChanges: _vm.timeSaver.menuChanges,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "Card",
          {
            staticClass: "home__card",
            attrs: { "no-header-divider": "", "no-padding": "" },
            scopedSlots: _vm._u([
              {
                key: "custom-header",
                fn: function () {
                  return [
                    _c("div", { staticClass: "home__market-title-wrapper" }, [
                      _c("p", { staticClass: "home__market-title" }, [
                        _vm._v(_vm._s(_vm.$t("home.market"))),
                      ]),
                      _c("img", {
                        staticClass: "home__market-image",
                        attrs: {
                          src: require("@/assets/images/marketGradient.png"),
                          alt: "",
                        },
                      }),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "custom-footer",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "home__card-footer" },
                      [
                        _c("ChartDownIcon", {
                          staticClass: "home__card-market-icon",
                        }),
                        _c(
                          "router-link",
                          {
                            staticClass: "home__card-footer-link",
                            attrs: { to: "/market/trends" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("sidebar.market.marketTrends")) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c("p", {
              staticClass: "home__content home__market",
              domProps: { innerHTML: _vm._s(_vm.$t("home.marketContent")) },
            }),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "home__card-group" },
      _vm._l(_vm.shortcutCards, function (linkCard) {
        return _c(
          "Card",
          {
            key: linkCard.title,
            staticClass: "home__card home__link-card",
            attrs: {
              "no-header-divider": "",
              "no-padding": "",
              "is-center-title": "",
              title: _vm.$t(linkCard.title),
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "custom-footer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "home__card-footer" },
                        [
                          _c(linkCard.icon, { tag: "component" }),
                          _c(
                            "router-link",
                            {
                              staticClass: "home__card-footer-link",
                              attrs: { to: linkCard.url },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t(linkCard.urlLabel)) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          [
            _c("p", {
              staticClass: "home__content",
              domProps: { innerHTML: _vm._s(_vm.$t(linkCard.content)) },
            }),
          ]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }