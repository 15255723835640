


































import Vue from 'vue';
import Loader from '../Loader/LoaderComponent.vue';
import { ModalComponent } from './Modal';

export default (Vue as ModalComponent).extend({
  components: { Loader },
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    isEmbedded: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    hasOverflowVisible: {
      type: Boolean,
      default: false,
    },
  },
});
