import HttpRequest from '@/http/httpClass';
import { Actions } from '@/store/store';

const actions: Pick<
  Actions,
  'getUserInfo' | 'getUserSettingsInfo' | 'updateUserInfo' | 'updateUserSettingsInfo'
> = {
  getUserInfo() {
    const request = new HttpRequest(`/users/userInfo`, 'GET', true);
    return request.send();
  },
  updateUserInfo(_, payload) {
    const request = new HttpRequest(`/users/userInfo`, 'PUT', true, payload);
    return request.send();
  },
  getUserSettingsInfo() {
    const request = new HttpRequest(`/users/userNotificationSettings`, 'GET', true);
    return request.send();
  },
  updateUserSettingsInfo(_, payload) {
    const request = new HttpRequest(`/users/userNotificationSettings`, 'PUT', true, payload);
    return request.send();
  },
};

export default actions;
