var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loader" }, [
    _c(
      "div",
      { staticClass: "loader__circle" },
      [
        _c("div", {
          class: [
            _vm.isMini ? "loader__circle-icon--mini" : "",
            "loader__circle-icon",
          ],
          attrs: {
            "aria-label":
              _vm.$translations.shared.inputs.loading[
                _vm.$store.getters.currentLanguage
              ],
          },
        }),
        !_vm.isMini
          ? _c("PleezIcon", { staticClass: "loader__icon" })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }