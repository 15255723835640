import Vue from 'vue';
import { SideBarRoute } from '@/components/Sidebar/Sidebar';
import { RouteMeta } from 'vue-router';

export const isSuperUserLocked = (
  link: SideBarRoute | RouteMeta
): boolean | boolean[] | undefined => {
  // If is an array, must have activeSubPageIndex, means is a subpage inside a page with a tab
  if (
    typeof link.isSuperUserLocked == 'object' &&
    link?.breadcrumbs?.activeSubPageIndex != undefined
  ) {
    return link.isSuperUserLocked[link?.breadcrumbs.activeSubPageIndex];
  }
  return link?.isSuperUserLocked;
};

export const isSuperUser = (): boolean => {
  return Vue.prototype.$session.getSession('isSuperUser') === 'true';
};
