























































































import Vue from 'vue';
import FiltersComponent from '@/components/Filters/FiltersComponent.vue';
import Select from '@/components/Inputs/Select.vue';

import { accountDropdownOptions } from './Header.config';
import { HeaderComponent, UserRestaurantsResponse } from './Header';

export default (Vue as HeaderComponent).extend({
  components: {
    Select,
    FiltersComponent,
  },
  data() {
    return {
      menuUrl: '',
      selectedOptionAccount: '',
      restaurants: [],
      selectedRestaurant: null,
      accountDropdownOptions,
    };
  },
  async mounted() {
    this.$store
      .dispatch('getUserRestaurants')
      .then((userData: UserRestaurantsResponse) => {
        this.restaurants = userData.restaurants;
        this.selectedRestaurant = userData.selectedRestaurant;

        this.$store
          .dispatch('getUserRestaurantOrders')
          .catch(() => this.$toast.warning(this.$t('warnings.userOrders')));
      })
      .catch(() => this.$toast.error(this.$t('errors.sessionExpired')));

    this.$store.dispatch('getMenuUrl').then((menu: { url: string }) => {
      if (menu?.url) {
        this.menuUrl = menu.url;
        if (this.menuUrl.includes('glovoapp') && this.menuUrl.includes('api')) {
          //FIXME: this is extremely sketchy
          this.menuUrl = `https://glovoapp.com/pt/pt/lisboa/${this.menuUrl.split('/')[5]}`;
        }
      }
    });
  },
  computed: {
    currentLanguage() {
      return this.$store.getters.currentLanguage;
    },
    shouldShowMenu() {
      return Boolean(this.menuUrl && this.$route.path === '/analytics/sales');
    },
  },
  methods: {
    handleRestaurantChanged(selectedRestaurant) {
      if (selectedRestaurant.id !== this.selectedRestaurant?.id) {
        this.selectedRestaurant = selectedRestaurant;

        let currentRestaurant = selectedRestaurant.restaurants_sources.find(
          (restaurant) => restaurant.source.id === this.$store.getters.subHeaderSelectedPlatform
        );

        if (!currentRestaurant) {
          currentRestaurant = selectedRestaurant.restaurants_sources[0];
          this.$session.setSession('pleez-selected-platform', currentRestaurant.source.id);
        }

        this.$store.dispatch('renewToken', {
          id: selectedRestaurant.id,
          restaurantSourceId: currentRestaurant.id,
        });
      }
    },
    handleAccountOptionClick(option) {
      const value = option?.value;

      switch (value) {
        case 'logout':
          this.handleLogout();
          break;
        case 'settings':
          this.handleSettings();
          break;
        default:
          break;
      }
    },
    handleLogout() {
      this.$router.push('/logout');
    },
    handleSettings() {
      this.$router.push('/settings/account');
    },
  },
});
