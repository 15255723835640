/**
 * Here is defined all layouts of the application.
 */

import Vue from 'vue';

import BlankLayout from './BlankLayout';
import AuthenticatedLayout from './AuthenticatedLayout';
import NotAuthenticatedLayout from './NotAuthenticatedLayout';
import ParentLayout from './ParentLayout';

Vue.component('blank-layout', BlankLayout);
Vue.component('authenticated-layout', AuthenticatedLayout);
Vue.component('not-authenticated-layout', NotAuthenticatedLayout);
Vue.component('parent-layout', ParentLayout);
