import HttpRequest from '@/http/httpClass';

import { METRICS } from '@/http/endpoints';
import { Actions } from '@/store/store';

const actions: Pick<
  Actions,
  | 'getUserRestaurantTopSellers'
  | 'getUserRestaurantNonSellers'
  | 'getUserRestaurantSellersBreakdown'
  | 'getUserRestaurantOverallMetrics'
  | 'getUserRestaurantMetric'
  | 'getUseOverallMetrics'
  | 'updateMetrics'
> = {
  getUserRestaurantTopSellers(_, options) {
    const { fromDate, toDate } = options;
    const request = new HttpRequest(
      METRICS.TOP_SELLERS({
        fromDate: fromDate.replaceAll('/', '-'),
        toDate: toDate.replaceAll('/', '-'),
      }),
      'GET',
      true
    );

    return request.send();
  },
  getUserRestaurantNonSellers(_, options) {
    const { fromDate, toDate } = options;
    const request = new HttpRequest(
      METRICS.NON_SELLING({
        fromDate: fromDate.replaceAll('/', '-'),
        toDate: toDate.replaceAll('/', '-'),
      }),
      'GET',
      true
    );

    return request.send();
  },
  getUserRestaurantSellersBreakdown(_, options) {
    const { fromDate, toDate } = options;
    const request = new HttpRequest(
      METRICS.CATEGORIES_BREAKDOWN({
        fromDate: fromDate.replaceAll('/', '-'),
        toDate: toDate.replaceAll('/', '-'),
      }),
      'GET',
      true
    );

    return request.send();
  },
  getUseOverallMetrics(_, options) {
    const { fromDate, toDate } = options;
    const request = new HttpRequest(
      METRICS.HEADLINES({
        fromDate: fromDate.replaceAll('/', '-'),
        toDate: toDate.replaceAll('/', '-'),
      }),
      'GET',
      true
    );

    return request.send();
  },
  getUserRestaurantOverallMetrics(_, options) {
    const { fromDate, toDate } = options;
    const request = new HttpRequest(
      METRICS.RESTAURANT_OVERVIEW({
        fromDate: fromDate.replaceAll('/', '-'),
        toDate: toDate.replaceAll('/', '-'),
      }),
      'GET',
      true
    );

    return request.send();
  },
  getUserRestaurantMetric(_, options) {
    const { fromDate, toDate } = options;
    const periodType = options.periodType;
    const metricType = options.metricType;
    let metricUrl;

    switch (metricType) {
      case 'orders':
        metricUrl = METRICS.RESTAURANT_ORDERS;
        break;
      case 'items':
        metricUrl = METRICS.RESTAURANT_ITEMS;
        break;
      case 'averageTicket':
        metricUrl = METRICS.RESTAURANT_AVERAGE_TICKET;
        break;
      default:
        metricUrl = METRICS.RESTAURANT_SALES;
    }

    const request = new HttpRequest(
      metricUrl({
        fromDate: fromDate.replaceAll('/', '-'),
        toDate: toDate.replaceAll('/', '-'),
        periodType,
      }),
      'GET',
      true
    );

    return request.send();
  },
  updateMetrics() {
    const request = new HttpRequest(METRICS.UPDATE_MATCH(), 'GET', true);

    return request.send();
  },
};

export default actions;
