var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "time-saver" }, [
    _c("div", { staticClass: "time-saver__row" }, [
      _c("p", [_vm._v(_vm._s(_vm.$t("timeSavedCalculator.recommendations")))]),
      _c("p", { staticClass: "time-saver__row-value" }, [
        _vm._v(_vm._s(_vm.timeSavedInsights)),
      ]),
    ]),
    _c("div", { staticClass: "time-saver__row" }, [
      _c("p", [
        _vm._v(_vm._s(_vm.$t("timeSavedCalculator.menuChangesAndPromotions"))),
      ]),
      _c("p", { staticClass: "time-saver__row-value" }, [
        _vm._v(_vm._s(_vm.timeSavedMenuChanges)),
      ]),
    ]),
    _c("div", { staticClass: "time-saver__row time-saver__row--total" }, [
      _c("p", [_vm._v(_vm._s(_vm.$t("timeSavedCalculator.total")))]),
      _c("p", { staticClass: "time-saver__row-value" }, [
        _vm._v(_vm._s(_vm.totalTimeSaved)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }