var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    {
      staticClass: "table-insights",
      attrs: { isLoading: _vm.isLoading, "no-padding": "" },
      scopedSlots: _vm._u([
        {
          key: "custom-header",
          fn: function () {
            return [
              _c("div", { staticClass: "table-insights__card-header" }, [
                _c("h3", { staticClass: "table-insights__title" }, [
                  _vm._v(" " + _vm._s(_vm.title) + " "),
                ]),
                _c("p", { staticClass: "table-insights__description" }, [
                  _vm._v(" " + _vm._s(_vm.description) + " "),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "PleezInsightsListMobile",
        _vm._g(
          {
            staticClass: "table-insights__mobile",
            attrs: {
              "is-new-insights": _vm.isNewInsights,
              insights: _vm.insights,
              approveEDText: _vm.approveEDText,
              rejectEDText: _vm.rejectEDText,
              "has-load-more": _vm.hasLoadMore,
            },
            on: { handleInsightActionClick: _vm.handleActionClick },
          },
          _vm.$listeners
        )
      ),
      _c(
        "Table",
        _vm._g(
          {
            key: "insight-" + Math.round(Math.random() * 1000),
            staticClass: "table-insights__table",
            attrs: {
              "is-embedded": "",
              "is-custom-body": "",
              tableData: _vm.tableData,
              "no-data": _vm.$t("pleezInsights.noInsights"),
              caption: _vm.$t("reporting.caption"),
              "has-load-more": _vm.hasLoadMore,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var rowBody = ref.rowBody
                  return [
                    rowBody.customElement === _vm.CustomElement.Platform
                      ? _c(
                          "div",
                          {
                            class: [
                              "table-insights__platform",
                              {
                                "table-insights__platform--new":
                                  _vm.isNewInsights,
                              },
                            ],
                          },
                          [
                            _c("PlatformIcon", {
                              attrs: { platform: rowBody.value },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    rowBody.customElement === _vm.CustomElement.Restaurant &&
                    rowBody.rawData
                      ? _c(
                          "div",
                          { staticClass: "table-insights__restaurant" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "table-insights__insight-with-ellipsis",
                              },
                              [_vm._v(_vm._s(rowBody.rawData.restaurantName))]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "table-insights__restaurant-address table-insights__insight-with-ellipsis",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(rowBody.rawData.restaurantAddress) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    rowBody.customElement === _vm.CustomElement.Button &&
                    rowBody.rawData
                      ? [
                          _c(
                            "div",
                            { staticClass: "table-insights__buttons" },
                            [
                              _vm.isNewInsights ||
                              rowBody.rawData.status === "approved"
                                ? _c("ActionsButton", {
                                    attrs: {
                                      actionColorType: "approve",
                                      "is-disabled":
                                        rowBody.rawData.status === "approved" &&
                                        !_vm.isNewInsights,
                                      "is-filled": _vm.isNewInsights,
                                      label: _vm.approveEDText,
                                    },
                                    on: {
                                      handleClick: function ($event) {
                                        return _vm.handleActionClick(
                                          rowBody.rawData,
                                          true
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.isNewInsights ||
                              _vm.isExpiredOrRejected(rowBody.rawData.status)
                                ? _c("ActionsButton", {
                                    attrs: {
                                      actionColorType: "disapprove",
                                      "is-disabled":
                                        _vm.isExpiredOrRejected(
                                          rowBody.rawData.status
                                        ) && !_vm.isNewInsights,
                                      "is-filled": _vm.isNewInsights,
                                      label: _vm.expiredOrRejectedText(
                                        rowBody.rawData.status
                                      ),
                                    },
                                    on: {
                                      handleClick: function ($event) {
                                        return _vm.handleActionClick(
                                          rowBody.rawData,
                                          false
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    rowBody.customElement === _vm.CustomElement.Description
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "table-insights__insight-description-wrapper",
                          },
                          [
                            _c("p", {
                              staticClass:
                                "table-insights__insight-description table-insights__insight-with-ellipsis",
                              domProps: {
                                innerHTML: _vm._s(_vm.parseText(rowBody)),
                              },
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "table-insights__see-more",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSeeMoreClick(
                                      rowBody.rawData
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " + " +
                                    _vm._s(_vm.$t("pleezInsights.seeMore")) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    !rowBody.customElement
                      ? _c("p", {
                          domProps: {
                            innerHTML: _vm._s(_vm.parseText(rowBody)),
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          },
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }