var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      class: ["settings-group", { "settings-group--disabled": _vm.isDisabled }],
    },
    [
      _c(
        "ul",
        { staticClass: "settings-group__options-horizontal" },
        _vm._l(_vm.optionsGroup, function (option) {
          return _c(
            "li",
            { staticClass: "settings-group__option-horizontal" },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t(option.label)))]),
              option.tooltip && !_vm.isDisabled
                ? _c("Tooltip", {
                    staticClass: "settings-group__option-tooltip",
                    attrs: { message: _vm.$t(option.tooltip) },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "ul",
        { staticClass: "settings-group__options-vertical" },
        _vm._l(_vm.settingsGroup, function (setting) {
          return _c("li", { staticClass: "settings-group__option-vertical" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t(setting.label)))]),
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "settings-group__radio-group" },
        _vm._l(_vm.numberOfOptions, function (_, index) {
          return _c("RadioGroup", {
            key: "notifications" + index,
            staticClass: "settings-group__radio",
            attrs: {
              "group-name": _vm.getNotificationGroup(index),
              values: _vm.notificationFrequency,
              "selected-value": _vm.currentlyActiveInGroup(index),
              "is-disabled": _vm.isDisabled,
            },
            on: { change: _vm.handleRadioButtonClick },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }