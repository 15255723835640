<template>
  <div class="time-saver">
    <div class="time-saver__row">
      <p>{{ $t('timeSavedCalculator.recommendations') }}</p>
      <p class="time-saver__row-value">{{ timeSavedInsights }}</p>
    </div>
    <div class="time-saver__row">
      <p>{{ $t('timeSavedCalculator.menuChangesAndPromotions') }}</p>
      <p class="time-saver__row-value">{{ timeSavedMenuChanges }}</p>
    </div>

    <div class="time-saver__row time-saver__row--total">
      <p>{{ $t('timeSavedCalculator.total') }}</p>
      <p class="time-saver__row-value">{{ totalTimeSaved }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    insights: {
      type: Number,
      required: true,
    },
    menuChanges: {
      type: Number,
      required: true,
    },
  },
  computed: {
    timeSavedMenuChanges() {
      if (this.menuChanges) {
        return `${this.menuChanges.toFixed(2)}h`;
      }

      return '-';
    },
    timeSavedInsights() {
      if (this.insights) {
        return `${this.insights.toFixed(2)}h`;
      }

      return '-';
    },
    totalTimeSaved() {
      const totalInvested = this.insights + this.menuChanges;

      if (totalInvested) {
        return `${totalInvested.toFixed(2)}h`;
      }

      return '-';
    },
  },
};
</script>

<style lang="scss" scoped>
.time-saver {
  &__row {
    @include brand-font-m;

    display: grid;
    grid-template-columns: 1fr min-content;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-xxs);

    margin: 0 var(--spacing-xxs) var(--spacing-xs) var(--spacing-xxs);
    padding: 0 var(--spacing-xs) var(--spacing-xs) var(--spacing-xs);

    color: var(--text-color);

    border-bottom: 1px solid var(--grey-lines);

    &--total {
      margin-bottom: var(--spacing-s);

      font-weight: bold;
    }
    &:last-child {
      border: none;
    }
  }
}
</style>
