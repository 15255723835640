var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: [
        "action-button",
        "action-button--" + _vm.actionColorType,
        {
          "action-button--filled": _vm.isFilled,
          "action-button--disabled": _vm.isDisabled,
        },
      ],
      on: {
        click: function ($event) {
          return _vm.$emit("handleClick")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "action-button__icon" },
        [
          _vm.actionColorType === "approve"
            ? _c("ApproveIcon")
            : _c("DisapproveIcon"),
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "action-button__text" },
        [
          _vm.label
            ? [_vm._v(" " + _vm._s(_vm.label) + " ")]
            : [_vm._v(" " + _vm._s(_vm.actionLabel) + " ")],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }