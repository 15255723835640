




















































import Vue from 'vue';

import { SettingsPaymentsComponent } from './SettingsPayments';
import { getTableData } from './SettingsPayments.config';
import { InvoicesResponse } from './SettingsPayments';

import Card from '@/components/Cards/CardComponent.vue';
import Table from '@/components/Table/TableComponent.vue';

import resizeMixin from '@/mixins/resize';
import { CustomElement } from '@/utils/_enums/global';

export default (Vue as SettingsPaymentsComponent).extend({
  mixins: [resizeMixin],
  components: {
    Card,
    Table,
  },
  mounted() {
    this.isLoading = true;
    this.$store
      .dispatch('getUserInvoices')
      // TODO: this should be in the .d.ts file
      .then((invoicesResponse: InvoicesResponse) => {
        if (invoicesResponse) {
          this.invoices = invoicesResponse.items;
        }
      })
      .catch(() => this.$toast.error(this.$t('errors.getReports')))
      .finally(() => (this.isLoading = false));
  },
  computed: {
    tableData() {
      return getTableData(this.invoices);
    },
  },
  data() {
    return {
      isLoading: false,
      invoices: [],
      CustomElement,
    };
  },
});
