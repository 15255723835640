var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PerformanceGraphs", {
    attrs: {
      "dispatch-action": "getUserConversionRate",
      "data-key": "conversion_rate",
      downloadableFileName: "conversionRate.csv",
      "graph-type": "bar",
      title: _vm.$t("performancePage.conversionChart.title"),
      description: _vm.$t("performancePage.conversionChart.description"),
      "current-date-range": _vm.currentDateRange,
      "chart-period-type": _vm.chartPeriodType,
      "is-loading": _vm.isLoading,
      "chart-colors": _vm.chartColors,
      "chart-data-labels": _vm.chartDataLabels,
      "label-formatter": _vm.labelFormatter,
      "downloadable-data-formatter": _vm.downloadableDataFormatter,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }