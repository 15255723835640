var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "market-trends" },
    [
      _c("h3", { staticClass: "market-trends__title" }, [
        _vm._v(_vm._s(_vm.$t("marketTrends.title"))),
      ]),
      _c("p", { staticClass: "market-trends__description" }, [
        _vm._v(_vm._s(_vm.$t("marketTrends.description"))),
      ]),
      _vm.filtersData
        ? _c("CommonFilters", {
            staticClass: "market-trends__filters",
            attrs: {
              filters: _vm.filtersData,
              "search-label": "shared.labels.filter",
              "has-select-all": false,
              "first-as-default": "",
            },
            on: { filtersApplied: _vm.handleOnFilter },
          })
        : _vm._e(),
      _c(
        "Card",
        {
          staticClass: "market-trends__card",
          attrs: {
            "no-header-divider": "",
            "no-padding": "",
            "is-loading": _vm.isLoading,
          },
        },
        [
          _vm.currentPlatform && _vm.currentCity
            ? _c("p", {
                staticClass: "market-trends__disclaimer",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("marketTrends.disclaimer", [
                      _vm.currentPlatform,
                      _vm.currentCity,
                    ])
                  ),
                },
              })
            : _c("p", { staticClass: "market-trends__disclaimer" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("marketTrends.disclaimerFallback")) + " "
                ),
              ]),
          _vm.orderEstimation
            ? _c("p", {
                staticClass: "market-trends__disclaimer",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("marketTrends.disclaimerOrders", [
                      _vm.orderEstimation,
                    ])
                  ),
                },
              })
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "market-trends__cards" },
        _vm._l(_vm.cardData, function (data) {
          return _c(
            "Card",
            {
              key: data.periodType,
              staticClass: "market-trends__card",
              attrs: { "no-header-divider": "", "no-padding": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "custom-header",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "market-trends__market-image-wrapper",
                          },
                          [
                            _c("img", {
                              staticClass: "market-trends__market-image",
                              attrs: {
                                src: require("@/assets/images/marketGradientB&W.png"),
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            },
            [
              _c("div", { staticClass: "market-trends__card-content" }, [
                data.startDate
                  ? _c("p", { staticClass: "market-trends__card-date" }, [
                      data.endDate
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("marketTrends.fromDate", [
                                    data.startDate,
                                    data.endDate,
                                  ])
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [
                            _vm._v(" " + _vm._s(data.startDate) + " "),
                          ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    class: [
                      "market-trends__card-trend-block",
                      {
                        "market-trends__card-trend-block--positive":
                          _vm.isPositive(data.salesVariation),
                      },
                    ],
                  },
                  [
                    _c("span", { staticClass: "market-trends__card-trend" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            data.salesVariation
                              ? _vm.getVariation(data.salesVariation)
                              : "-"
                          ) +
                          "% "
                      ),
                    ]),
                    _c(
                      "span",
                      { staticClass: "market-trends__card-comparison" },
                      [_vm._v(_vm._s(_vm.$t("marketTrends.vsPeriod")))]
                    ),
                  ]
                ),
                data.orderCount
                  ? _c("p", { staticClass: "market-trends__card-estimation" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("marketTrends.estimatedOrders")) +
                          " "
                      ),
                      _c(
                        "span",
                        { staticClass: "market-trends__card-estimation--bold" },
                        [_vm._v(_vm._s(data.orderCount))]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }