<template>
  <Modal :title="$t('promotions.calculators.adTitle')" @close="$emit('close')">
    <div class="body-container">
      <div class="icon-container">
        <img class="top" src="@/assets/images/icons/hourglass-top.svg" />
        <img class="bottom" src="@/assets/images/icons/hourglass-bottom.svg" />
      </div>
      <h4>
        {{ $t('promotions.calculators.adDescription') }}
      </h4>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal/ModalComponent.vue';

export default {
  name: 'AdReturnCalculatorModal',
  components: {
    Modal,
  },
};
</script>

<style lang="scss" scoped>
.top {
  animation-name: fade1;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-direction: alternate;
}
.bottom {
  animation-name: fade2;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-direction: alternate;
}

@keyframes fade1 {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade2 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.calculator-modal ::v-deep .p-modal {
  height: 384px;
}

.calculator-modal::v-deep .p-modal-body {
  height: 200px;
  padding: unset;
}

.body-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  .icon-container {
    position: relative;
    display: block;
    height: 25px;

    img {
      position: absolute;
    }
  }
}

.title-container > div {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 32px;
}

h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #232859;
  display: flex;
  justify-content: center;
  padding-left: 16px;
}

h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #232859;
}
</style>
