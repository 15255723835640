









































import Vue from 'vue';
import { InputComponent } from './Inputs';

import EyeIcon from '@/assets/icons/eye.svg?inline';
import EyeClosedIcon from '@/assets/icons/eye-close.svg?inline';

export default (Vue as InputComponent).extend({
  components: {
    EyeIcon,
    EyeClosedIcon,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      required: false,
    },
    infoMessage: {
      type: String,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    defaultValue: {
      type: String,
      default: '',
    },
    isLabelVisible: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isPlaceholderVisible: {
      type: Boolean,
      required: false,
    },
    hasWarning: {
      type: Boolean,
      default: false,
    },
    hasDefaultSize: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
  },
  data() {
    return {
      inputValue: '',
      warningMessage: '',
      inputType: this.type,
    };
  },
  computed: {
    hasCustomIcon() {
      return Boolean(!this.isDisabled && (this.type === 'password' || this.$slots['custom-icon']));
    },
    placeholderLabel() {
      return this.isPlaceholderVisible && this.label ? this.label : '';
    },
    shouldShowPassword() {
      return this.inputType === 'text';
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', this.inputValue);
    },
    handleKeyDown(event) {
      const capsLockIsOn = event.getModifierState?.('CapsLock');

      this.warningMessage = capsLockIsOn
        ? this.$translations.warnings.capsLock[this.$store.getters.currentLanguage]
        : '';
    },
    handleEyeIconClick(event) {
      if (document.activeElement === this.$refs.eyeButton) {
        event.preventDefault();

        const isPasswordHidden = this.inputType === 'text';
        this.inputType = isPasswordHidden ? 'password' : 'text';
      }
    },
  },
  watch: {
    defaultValue: {
      handler() {
        this.inputValue = this.defaultValue;
      },
      immediate: true,
    },
  },
});
