















































import Vue from 'vue';
import Filters from '@/components/Filters/FiltersComponent.vue';
import Datepicker from '@/components/Inputs/DatePicker/DatePickerComponent.vue';
import TopSellers from '@/views/dashboard/cards/TopSellers.vue';
import NonSelling from '@/views/dashboard/cards/NonSelling.vue';
import BreakdownOfCategories from '@/views/dashboard/cards/BreakdownOfCategories.vue';
import SalesAnalysisPerCategory from '@/views/dashboard/cards/SalesAnalysisPerCategory.vue';
import MenuQuality from '@/views/analytics/menuEngineering/MenuQuality.vue';

import { MenuEngineeringComponent, MenuMetricsResponse } from './MenuEngineering';
import { BreakdownSellers } from '@/views/dashboard/cards/DashboardCard';

export default (Vue as MenuEngineeringComponent).extend({
  components: {
    BreakdownOfCategories,
    Filters,
    Datepicker,
    SalesAnalysisPerCategory,
    TopSellers,
    NonSelling,
    MenuQuality,
  },
  mounted() {
    this.$store
      .dispatch('getMenuMetrics')
      .then((response: MenuMetricsResponse) => (this.menuMetrics = response.menuQuality))
      .catch(() => this.$toast.error('errors.menuMetrics'))
      .finally(() => (this.isLoading = false));
  },
  data() {
    return {
      categoriesBreakdown: [],
      currentDateRange: null,
      menuMetrics: null,
      isBreakdownLoading: true,
      isLoading: true,
    };
  },
  watch: {
    '$store.getters.selectedDateRange': {
      async handler(newDate) {
        if (newDate.fromDate) {
          try {
            this.isBreakdownLoading = true;

            await this.$store.dispatch('updateMetrics');
          } catch (error) {
            this.$toast.warning(this.$t('warnings.updateMatch'));
          } finally {
            this.currentDateRange = { fromDate: newDate.fromDate, toDate: newDate.toDate };

            this.$store
              .dispatch('getUserRestaurantSellersBreakdown', this.currentDateRange)
              .then((response: BreakdownSellers[]) => (this.categoriesBreakdown = response))
              .finally(() => (this.isBreakdownLoading = false));
          }
        }
      },
      immediate: true,
    },
  },
});
