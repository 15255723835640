var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        mouseover: function ($event) {
          _vm.isHovering = true
        },
        mouseleave: function ($event) {
          _vm.isHovering = false
        },
      },
    },
    [
      _c(
        "button",
        { staticClass: "sidebar-link__button" },
        [
          _c(
            "router-link",
            {
              class: [
                "sidebar-link",
                "router-link",
                "teste",
                {
                  "router-link-active": _vm.isActive,
                  "sidebar-link--root-active": _vm.isActive,
                  "sidebar-link--root-active--no-child":
                    !_vm.hasChildren && _vm.isActive,
                },
              ],
              attrs: { to: _vm.redirectTo },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleLinkClick.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "sidebar-link__label-wrapper" }, [
                _c(
                  "span",
                  {
                    staticClass: "sidebar-link__icon",
                    class: {
                      "sidebar-link__icon--has-child": _vm.hasChildren,
                    },
                  },
                  [_vm._t("default")],
                  2
                ),
                _c("p", { staticClass: "sidebar-link__label" }, [
                  _vm._v(_vm._s(_vm.label)),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "ul",
        _vm._l(_vm.link.children, function (ch) {
          return _vm.hasChildren &&
            (_vm.currentPathIsChildrenParent || _vm.isHovering)
            ? _c(
                "router-link",
                {
                  key: ch.path,
                  class: [
                    "sidebar-link",
                    "router-link",
                    "sidebar-link--child",
                    {
                      "sidebar-link--child-active": _vm.isActiveChildren(ch),
                    },
                  ],
                  attrs: { tag: "li", to: "/" + _vm.link.root + "/" + ch.path },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleLinkClick.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(ch.translatedTitle) + " ")]
              )
            : _vm._e()
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }