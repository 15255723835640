var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    {
      staticClass: "dashboard-card",
      attrs: { isLoading: _vm.isLoading },
      scopedSlots: _vm._u(
        [
          {
            key: "custom-header",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "dashboard-card__header-wrapper" },
                  [
                    _c("div", { staticClass: "dashboard-card__header" }, [
                      _c("div", [
                        _c(
                          "h3",
                          { staticClass: "dashboard-card__title" },
                          [
                            _vm._v(" " + _vm._s(_vm.title) + " "),
                            _vm._t("custom-icon"),
                          ],
                          2
                        ),
                        _vm.description
                          ? _c(
                              "p",
                              { staticClass: "dashboard-card__description" },
                              [_vm._v(" " + _vm._s(_vm.description) + " ")]
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "dashboard-card__actions" },
                        [_vm._t("custom-actions")],
                        2
                      ),
                    ]),
                    _vm.downloadData
                      ? _c(
                          "download-csv",
                          {
                            staticClass: "dashboard-card__download",
                            attrs: {
                              name: _vm.downloadDataFileName,
                              data: _vm.downloadData,
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                class: [
                                  "dashboard-card__download-icon",
                                  {
                                    "dashboard-card__download-icon--disabled":
                                      _vm.downloadData.length === 0,
                                  },
                                ],
                              },
                              [_c("DownloadIcon")],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _c(
        "div",
        { staticClass: "dashboard-card__body" },
        [
          _vm._t("default"),
          !_vm.isLoading && _vm.isTableCard
            ? _c("Table", {
                attrs: {
                  "is-embedded": "",
                  "is-fixed-size": "",
                  caption: _vm.caption,
                  "table-data": _vm.tableData,
                  "no-data": _vm.$t("salesDashboard.noDataAvailable"),
                },
              })
            : _vm._e(),
          _vm.footerText
            ? _c("p", { staticClass: "dashboard-card__footer" }, [
                _vm._v(_vm._s(_vm.footerText)),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }