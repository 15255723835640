var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    {
      staticClass: "table-menu-changes",
      attrs: { isLoading: _vm.isLoading, "no-padding": "" },
      scopedSlots: _vm._u([
        {
          key: "custom-header",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "table-menu-changes__custom-card-header-wrapper",
                },
                [
                  _c(
                    "div",
                    { staticClass: "table-menu-changes__card-header" },
                    [
                      _c("h3", { staticClass: "table-menu-changes__title" }, [
                        _vm._v(" " + _vm._s(_vm.title) + " "),
                      ]),
                      _c(
                        "p",
                        { staticClass: "table-menu-changes__description" },
                        [_vm._v(" " + _vm._s(_vm.description) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "ButtonDefault",
                    {
                      staticClass: "table-menu-changes__create-button",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("handleOpenCreateMenuChangesModal")
                        },
                      },
                    },
                    [
                      _c("CirclePlusIcon"),
                      _vm._v(
                        " " + _vm._s(_vm.$t("menuChanges.requestAChange")) + " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "MenuChangesListMobile",
        _vm._g(
          {
            staticClass: "table-menu-changes__mobile",
            attrs: {
              menuChanges: _vm.menuChanges,
              "has-load-more": _vm.hasLoadMore,
            },
          },
          _vm.$listeners
        )
      ),
      _c(
        "Table",
        _vm._g(
          {
            key: "insight-" + Math.round(Math.random() * 1000),
            staticClass: "table-menu-changes__table",
            attrs: {
              "is-embedded": "",
              "is-custom-body": "",
              tableData: _vm.tableData,
              "no-data": _vm.$t("menuChanges.noChanges"),
              caption: _vm.$t("reporting.caption"),
              "has-load-more": _vm.hasLoadMore,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var rowBody = ref.rowBody
                  return [
                    rowBody.customElement === _vm.CustomElement.Platform
                      ? _c(
                          "div",
                          { class: ["table-menu-changes__platform"] },
                          [
                            _c("PlatformIcon", {
                              attrs: { platform: rowBody.value },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    rowBody.customElement === _vm.CustomElement.Restaurant &&
                    rowBody.rawData
                      ? _c(
                          "div",
                          { staticClass: "table-menu-changes__restaurant" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "table-menu-changes__insight-with-ellipsis",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(rowBody.rawData.restaurantName) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "table-menu-changes__restaurant-address table-menu-changes__insight-with-ellipsis",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(rowBody.rawData.restaurantAddress) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    rowBody.customElement === _vm.CustomElement.Description
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "table-menu-changes__insight-description-wrapper",
                          },
                          [
                            _c("p", {
                              staticClass:
                                "table-menu-changes__insight-description table-menu-changes__insight-with-ellipsis",
                              domProps: {
                                innerHTML: _vm._s(_vm.parseText(rowBody)),
                              },
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "table-menu-changes__see-more",
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit(
                                      "handleViewMenuChanges",
                                      rowBody.rawData
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " + " +
                                    _vm._s(_vm.$t("pleezInsights.seeMore")) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    !rowBody.customElement
                      ? _c("p", {
                          domProps: {
                            innerHTML: _vm._s(_vm.parseText(rowBody)),
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          },
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }