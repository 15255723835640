export const shortcutCards = [
  {
    title: 'home.competitors',
    content: 'home.competitorsContent',
    icon: 'CrosshairIcon',
    url: '/market/price-comparison',
    urlLabel: 'sidebar.market.competitorTracker',
  },
  {
    title: 'home.promotions',
    content: 'home.promotionsContent',
    icon: 'PercentageIcon',
    url: '/market/competitor-promotions',
    urlLabel: 'home.promotions',
  },
  {
    title: 'home.requestAChange',
    content: 'home.requestAChangeContent',
    icon: 'SquaresIcon',
    url: '/optimization/menu-changes',
    urlLabel: 'home.optimizations',
  },
];
