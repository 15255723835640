var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["tooltip", { "tooltip--absolute": !_vm.isDeconstructed }],
      attrs: { "data-tip": _vm.message },
    },
    [
      _vm.isDeconstructed
        ? _c("p", [_vm._v(_vm._s(_vm.message))])
        : _c("InfoIcon", { staticClass: "tooltip__icon" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }