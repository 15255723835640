<template>
  <Card class="price-comparison" :title="$t('priceComparison.analysisPerCategory.title')">
    <div class="price-comparison__chart-wrapper">
      <apexchart
        class="price-comparison__chart"
        height="100%"
        :options="chartOptions"
        :series="series"
      />

      <Modal
        v-if="!showChart"
        is-embedded
        has-overflow-visible
        :has-header="false"
        :is-loading="isLoading"
      >
        <p class="price-comparison__prompt-description">
          {{ $t('priceComparison.analysisPerCategory.chartPrompt') }}:
        </p>
        <RestaurantSelectFilter no-filters no-label />
      </Modal>
    </div>

    <div v-if="showChart" class="price-comparison__chart-legend">
      <span v-html="lowestAndHighest" />

      <div class="price-comparison__chart-legend-right">
        {{ $t('priceComparison.analysisPerCategory.ordersEstimateLast7Days') }}:

        <div>
          <p>
            {{ $t('priceComparison.analysisPerCategory.you') }} -
            <span style="color: #ff6248; font-weight: bolder">{{ $t('shared.comingSoon') }}</span>
          </p>

          <span v-html="competitorsSalesEstimate" />
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Cards/CardComponent.vue';
import Modal from '@/components/Modal/ModalComponent.vue';
import RestaurantSelectFilter from '@/components/Filters/RestaurantSelectFilter.vue';

import { tertiaryColorHex, secondaryColorHex } from '@/utils/constants';

export default {
  components: {
    Card,
    Modal,
    RestaurantSelectFilter,
  },
  data() {
    return {
      isLoading: false,
      yourSalesEstimate: '',
      competitorsSalesEstimate: '',
      showChart: false,
      salesAnalysisPerCategory: [],
      series: [],
      chartOptions: {
        colors: [secondaryColorHex, tertiaryColorHex],
        fill: {
          opacity: 1,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'bottom',
          color: '#64748B',
          fontSize: '15px',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 500,
          formatter: this.legendFormatter,
        },
        chart: {
          type: 'bubble',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            formatter: this.tooltipFormatter,
            title: {
              formatter: function (_) {
                _;
                return '';
              },
            },
          },
          x: {
            show: false,
          },
          z: {
            formatter: function () {
              return '';
            },
            title: '',
          },
        },
        title: {
          text: this.$t('priceComparison.price_table.columns.price'),
          position: 'bottom',
          style: {
            color: '#474E68',
            fontSize: '12px',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 400,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: [],
          type: 'category',
          title: {
            text: '',
            style: {
              color: '#969DBB',
              fontSize: '12px',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-title',
            },
          },
        },
        yaxis: {
          tickAmount: 4,
          forceNiceScale: true,
          labels: {
            formatter: function (value) {
              return '€' + value.toFixed(2);
            },
          },
        },
      },
    };
  },
  computed: {
    lowestAndHighest() {
      const { restaurants } = this.$store.getters.restaurantFilters;
      let str = '';

      const prices = this.series.map((s) => {
        const highest = Math.max(...s.data.map((d) => d[1]));
        const lowest = Math.min(...s.data.map((d) => d[1]));
        return {
          highest: highest,
          lowest: lowest,
          range: highest - lowest,
          name: s.name,
        };
      });

      let me1 = false;
      let me2 = false;
      let me3 = false;
      let me4 = false;

      const lowestRange = Math.min(...prices.map((p) => p.range));
      const highestRange = Math.max(...prices.map((p) => p.range));
      const lowestPrice = Math.min(...prices.map((p) => p.lowest));
      const highestPrice = Math.max(...prices.map((p) => p.highest));

      str += '• ';

      const filter = prices
        .map((p) => {
          if (p.range == lowestRange) {
            if (p.name == restaurants.name) {
              me1 = true;
              return this.$t('priceComparison.analysisPerCategory.you');
            } else {
              return p.name.match(/\b(\w)/g).join('');
            }
          }
          return null;
        })
        .filter((p) => p);

      str += filter.join(', ');

      if (filter.length == 1 && filter[0] == this.$t('priceComparison.analysisPerCategory.you'))
        str += ` ${this.$t('priceComparison.analysisPerCategory.have')} `;
      else str += ` ${this.$t('priceComparison.analysisPerCategory.has')} `;

      const c1 = me1 ? '#ff6248' : '#9d44fc';

      str += `${this.$t(
        'priceComparison.analysisPerCategory.the'
      )} <span style='color: ${c1};font-weight: bolder;'>${this.$t(
        'priceComparison.analysisPerCategory.lowest_price_range'
      )}</span><br>`;

      // 2

      str += '• ';

      const filter2 = prices
        .map((p) => {
          if (p.range == highestRange) {
            if (p.name == restaurants.name) {
              me2 = true;
              return this.$t('priceComparison.analysisPerCategory.you');
            } else {
              return p.name.match(/\b(\w)/g).join('');
            }
          }
          return null;
        })
        .filter((p) => p);

      str += filter2.join(', ');

      if (filter2.length == 1 && filter2[0] == this.$t('priceComparison.analysisPerCategory.you'))
        str += ` ${this.$t('priceComparison.analysisPerCategory.have')} `;
      else str += ` ${this.$t('priceComparison.analysisPerCategory.has')} `;

      const c2 = me2 ? '#ff6248' : '#9d44fc';

      str += `${this.$t(
        'priceComparison.analysisPerCategory.the'
      )} <span style='color:  ${c2};font-weight: bolder;'>${this.$t(
        'priceComparison.analysisPerCategory.highest_price_range'
      )}</span><br>`;

      // 3

      str += '• ';

      const filter3 = prices
        .map((p) => {
          if (p.lowest == lowestPrice) {
            if (p.name == restaurants.name) {
              me3 = true;
              return this.$t('priceComparison.analysisPerCategory.you');
            } else {
              return p.name.match(/\b(\w)/g).join('');
            }
          }
          return null;
        })
        .filter((p) => p);

      str += filter3.join(', ');

      if (filter3.length == 1 && filter3[0] == this.$t('priceComparison.analysisPerCategory.you'))
        str += ` ${this.$t('priceComparison.analysisPerCategory.have')} `;
      else str += ` ${this.$t('priceComparison.analysisPerCategory.has')} `;

      const c3 = me3 ? '#ff6248' : '#9d44fc';

      str += `${this.$t(
        'priceComparison.analysisPerCategory.the'
      )} <span style='color: ${c3};font-weight: bolder;'>${this.$t(
        'priceComparison.analysisPerCategory.lowest_price'
      )}</span><br>`;

      // 4

      str += '• ';

      const filter4 = prices
        .map((p) => {
          if (p.highest == highestPrice) {
            if (p.name == restaurants.name) {
              me4 = true;
              return this.$t('priceComparison.analysisPerCategory.you');
            } else {
              return p.name.match(/\b(\w)/g).join('');
            }
          }
          return null;
        })
        .filter((p) => p);

      str += filter4.join(', ');

      if (filter4.length == 1 && filter4[0] == this.$t('priceComparison.analysisPerCategory.you'))
        str += ` ${this.$t('priceComparison.analysisPerCategory.have')} `;
      else str += ` ${this.$t('priceComparison.analysisPerCategory.has')} `;

      const c4 = me4 ? '#ff6248' : '#9d44fc';

      str += `${this.$t(
        'priceComparison.analysisPerCategory.the'
      )} <span style='color: ${c4};font-weight: bolder;'>${this.$t(
        'priceComparison.analysisPerCategory.highest_price'
      )}</span><br>`;

      return str;
    },
    userRestaurantName() {
      return this.$store.getters.currentUserRestaurant?.name;
    },
  },
  watch: {
    async '$store.getters.restaurantFilters'(selectedRestaurantFilters) {
      const shouldShowChart = selectedRestaurantFilters.restaurants.length !== 0;

      this.showChart = false;
      this.isLoading = true;

      if (shouldShowChart) {
        await this.getData(selectedRestaurantFilters);

        this.setupChart();
      }

      this.showChart = shouldShowChart;
      this.isLoading = false;
    },
  },
  methods: {
    async getData(selectedRestaurantFilters) {
      const { labels, keywords, restaurants } = selectedRestaurantFilters;
      const additionalParams = {};
      const restaurantIds = restaurants.map((restaurant) => restaurant.id);

      if (restaurantIds.length <= 0) {
        this.salesAnalysisPerCategory = [];
        return;
      }

      additionalParams.idRestaurant = restaurantIds;

      if (labels) {
        additionalParams.Label = labels.map((label) => encodeURIComponent(label.name)).join(',');
      }

      if (keywords) {
        additionalParams.keywords = keywords;

        if (typeof additionalParams.keywords === 'string') {
          additionalParams.keywords = additionalParams.keywords.split(',');
        }

        additionalParams.keywords.map((word) => word.trim()).join(',');
      }

      const salesAnalysisPerCategoryResponse = await this.$store.dispatch(
        'getSalesPerCategory',
        additionalParams
      );

      if (salesAnalysisPerCategoryResponse) {
        this.salesAnalysisPerCategory = salesAnalysisPerCategoryResponse.data;
        this.yourSalesEstimate = `${
          salesAnalysisPerCategoryResponse.yourSalesEstimate
            ? salesAnalysisPerCategoryResponse.yourSalesEstimate
            : 0
        }`;
        this.competitorsSalesEstimate = salesAnalysisPerCategoryResponse.data.values
          .map((value) => {
            if (value.restaurant != restaurants.name) {
              return `${value.restaurant
                .match(/\b(\w)/g)
                .join('')} - <span style="color: #9d44fc;font-weight: bolder;">${
                !isNaN(value.estimatedNbOrders) ? '' : ''
              }${value.estimatedNbOrders.replace(
                'unavailable',
                this.$t('shared.unavailable')
              )}</span>`;
            }
          })
          .filter((v) => v)
          .join('<br>');
      }
    },
    setupChart() {
      let userRestaurantIndex = -1;

      const chartCategories = [];

      if (this.salesAnalysisPerCategory?.values?.length === 0) {
        this.series = [];

        this.chartOptions = {
          ...this.chartOptions,
          noData: {
            text: this.$t('shared.noData'),
          },
        };

        return;
      }

      this.series = this.salesAnalysisPerCategory.values.map((competitor, index) => {
        const competitorName = competitor.restaurant;

        const coords = competitor.coords.map((coord) => {
          return [coord.x, coord.y, 15];
        });
        const products = competitor.coords.map((coord) => {
          return coord.productName;
        });

        if (this.userRestaurantName === competitorName) {
          userRestaurantIndex = index;
        }

        return {
          name: competitorName,
          products,
          data: coords,
        };
      });

      /**
       * Push current restaurant to the end of the list
       * so that the graph colors match (user restaurant should
       * be orange)
       * */
      if (userRestaurantIndex !== -1) {
        this.series.push(this.series.splice(userRestaurantIndex, 1)[0]);
      }

      for (
        let i = this.salesAnalysisPerCategory.x.min;
        i <= this.salesAnalysisPerCategory.x.max;
        i++
      ) {
        chartCategories.push(i);
      }

      const minX = Math.min(...chartCategories);
      const maxX = Math.max(...chartCategories);
      const colors = [];
      const tickAmount = chartCategories.length;

      colors.push(...Array(this.series.length - 1).fill(secondaryColorHex));
      colors.push(tertiaryColorHex);

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          min: minX - 1,
          max: maxX + 1,
          categories: chartCategories,
          tickAmount,
        },
        yaxis: { min: 0, max: Math.ceil(this.salesAnalysisPerCategory.y.max / 5) * 5 },
        colors,
      };
    },
    legendFormatter(seriesName, opts) {
      const restaurantName = seriesName.match(/\b(\w)/g).join('') + ' - ' + seriesName;

      if (!opts.w.globals.series[opts.seriesIndex][0]) {
        return [restaurantName, ' - ', 'HIDDEN'];
      } else {
        return [restaurantName];
      }
    },
    tooltipFormatter(_, opts) {
      return (
        this.series[opts.seriesIndex].products[opts.dataPointIndex] +
        ' - ' +
        this.series[opts.seriesIndex].data[opts.dataPointIndex][1] +
        '€'
      );
    },
  },
};
</script>

<style lang="scss">
.price-comparison {
  --chart-height: 360px;

  &__chart {
    width: 100%;
    height: var(--chart-height);
  }
  &__chart-legend {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: var(--spacing-xxs);

    margin: var(--spacing-m) var(--spacing-m) 0 var(--spacing-m);
  }
  &__chart-legend-right {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
  }
  &__prompt-description {
    margin-bottom: var(--spacing-xs);
  }
}
</style>
