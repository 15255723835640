




























































import Vue from 'vue';

import { InsightsResponse } from '@/store/actions/actionsResponses';

import TableInsightsComponent from '@/views/insights/pleez/TableInsightsComponent.vue';
import ActionsButton from '@/components/Buttons/ActionsButton.vue';
import Modal from '@/components/Modal/ModalComponent.vue';
import CommonFiltersComponent from '@/components/Filters/CommonFiltersComponent.vue';

import { PleezInsightComponent } from './PleezInsight';

import { filtersData } from './PleezInsights.config';

import { isSuperUser } from '@/utils/superUser';

export default (Vue as PleezInsightComponent).extend({
  components: {
    TableInsightsComponent,
    ActionsButton,
    Modal,
    CommonFiltersComponent,
  },
  data() {
    return {
      filtersData: {},
      isNewInsightsLoading: true,
      isOldInsightsLoading: true,
      shouldViewInsightModal: false,
      actionsButton: false,
      insights: [],
      historicInsights: [],
      totalOldInsights: 0,
      platforms: [],
      addresses: [],
      filtersNewInsights: '',
      filtersHistoryInsights: 'status:nonpending',
      viewingInsight: null,
      //Default sort on the BE
      insightsSorting: 'date',
      insightsOrder: 'DESC',
    };
  },
  async mounted() {
    this.getInsights();
    this.getOldInsights();

    await this.$store.dispatch('getCommonFilters');

    const commonFilters = this.$store.getters.getCommonFilters;

    this.addresses = commonFilters.addresses;
    this.platforms = commonFilters.platforms;

    this.filtersData = filtersData(this.platforms, this.addresses);
  },
  computed: {
    isSuperUser,
  },
  methods: {
    async handleLoadMore() {
      await this.getOldInsights(this.historicInsights.length);
    },
    handleOnFilter(filters) {
      this.filtersNewInsights = filters.filtersStr;
      this.filtersHistoryInsights = `status:nonpending`;

      if (filters) {
        this.filtersHistoryInsights += `;${filters.filtersStr}`;
      }

      this.getInsights();
      this.getOldInsights();
    },
    getOldInsights(offset = 0) {
      if (this.isSuperUser && !this.filtersHistoryInsights) {
        this.isOldInsightsLoading = false;

        return;
      }

      this.isOldInsightsLoading = true;

      this.$store
        .dispatch('getPleezInsights', {
          filter: this.filtersHistoryInsights,
          sort: this.insightsSorting,
          order: this.insightsOrder,
          offset,
          limit: 10, // As described at the load more ticket, insights history should be loaded in chunks of 10
        })
        .then((insightsResponse: InsightsResponse) => {
          if (offset > 0) {
            this.historicInsights = [...this.historicInsights, ...insightsResponse.data];
          } else {
            this.historicInsights = insightsResponse.data;
          }
          this.totalOldInsights = insightsResponse.total;
        })
        .finally(() => {
          // TODO: If error show an error message
          this.isOldInsightsLoading = false;
        });
    },
    getInsights() {
      if (this.isSuperUser && !this.filtersNewInsights) {
        this.isNewInsightsLoading = false;

        return;
      }

      this.isNewInsightsLoading = true;

      this.$store
        .dispatch('getPleezInsights', {
          sort: this.insightsSorting,
          order: this.insightsOrder,
          filter: this.filtersNewInsights,
        })
        .then((insightsResponse: InsightsResponse) => {
          this.insights = insightsResponse.data;

          if (this.$route.query.insight) {
            const insight = this.insights.find(
              (insight) => insight.id === Number(this.$route.query.insight)
            );

            if (insight) {
              if (this.$route.query.action) {
                this.updateInsight(insight, this.$route.query.action === 'approve');
                this.$router.replace({ query: {} });
                return;
              }
              this.openViewInsightModal(insight, true);
            }
          }
        })
        .finally(() => {
          // TODO: If error show an error message
          this.isNewInsightsLoading = false;
        });
    },
    openViewInsightModal(insight, isNewInsight = false) {
      this.viewingInsight = insight;
      this.actionsButton = isNewInsight;
      this.shouldViewInsightModal = true;
    },
    updateInsight(insight, approved) {
      this.$store
        .dispatch('updatePleezInsight', { id: insight.id, approved })
        .then(() => {
          this.getInsights();
          this.getOldInsights();

          this.$toast.success(
            approved
              ? this.$t('pleezInsights.insight_approved')
              : this.$t('pleezInsights.insight_disapproved')
          );
          this.shouldViewInsightModal = false;

          if (this.$route.query.insight) {
            this.$router.replace({ query: {} });
          }
        })
        .catch(() => {
          this.$toast.error(this.$t('errors.insightAction'));
        });
    },
    handleSort(sortKey) {
      if (this.insightsSorting === sortKey) {
        this.insightsOrder = this.insightsOrder === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.insightsSorting = sortKey;
        this.insightsOrder = 'ASC';
      }

      this.getInsights();
      this.getOldInsights();
    },
  },
});
