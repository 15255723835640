var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DashboardCard", {
    attrs: {
      title: _vm.$t("salesDashboard.breakdownOfCategories.title"),
      description: _vm.$t("salesDashboard.breakdownOfCategories.description"),
      caption: _vm.$t("salesDashboard.tableCaption"),
      "table-data": _vm.tableData,
      "download-data": _vm.breakdownSellers,
      "is-loading": _vm.isLoading,
      "download-data-file-name": "nonsellers.csv",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }