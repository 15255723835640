












































import Vue from 'vue';
import Input from '@/components/Inputs/InputComponent.vue';
import Checkbox from '@/components/Inputs/Checkbox.vue';
import { LoginComponent } from './Login';

import { Session, encryptData } from '@/globals';
import LoaderComponent from '@/components/Loader/LoaderComponent.vue';
import ButtonDefault from '@/components/Buttons/ButtonDefault.vue';

const initialRoute = '/home';
let routeToRedirect: string | null = null;

export default (Vue as LoginComponent).extend({
  components: {
    Input,
    Checkbox,
    LoaderComponent,
    ButtonDefault,
  },
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      isLoading: false,
    };
  },
  mounted() {
    // check if any token on url param
    if (this.$route.query.token) {
      Session.setSession('pleez-token', this.$route.query.token, false);
      Session.setSession('pleez-persistent', false, false);
      Session.setSession(
        'pleez-id-restaurant-source-menus-db',
        this.$route.query.idRestaurantSourceMenusdb,
        false
      );

      const redirect = this.$route.query.redirect;

      delete this.$route.query.token;
      delete this.$route.query.idRestaurantSourceMenusdb;
      delete this.$route.query.redirect;

      // check if any redirect param
      if (redirect) {
        this.$router.push({ path: redirect as string, query: this.$route.query });
      } else {
        this.$router.push('/insights/competitor-tracker');
      }
    }
  },
  computed: {
    isLoginDisabled() {
      return this.email.length <= 0 || this.password.length <= 0 || this.isLoading;
    },
  },
  methods: {
    async login() {
      this.isLoading = true;

      const encryptedData = encryptData({
        email: this.email,
        password: this.password,
      });

      this.$store
        .dispatch('login', { data: encryptedData, rememberMe: this.rememberMe })
        .then(() => {
          this.$router.push(routeToRedirect ? routeToRedirect : initialRoute);
          this.$toast.success('', { message: this.$t('login.welcomeBack'), duration: 1000 });

          routeToRedirect = null;
        })
        .catch(this.handleFailedLogin)
        .finally(() => (this.isLoading = false));
    },
    handleEmailInputChange(emailInput) {
      this.email = emailInput;
    },
    handlePasswordInputChange(passwordInput) {
      this.password = passwordInput;
    },
    handleRememberMe(rememberMeInput) {
      this.rememberMe = rememberMeInput;
    },
    handleFailedLogin(errorObject) {
      const { error } = errorObject;

      if (error?.status === 401) {
        this.$toast.error(this.$t('errors.invalidCredentials'));
      } else if (error.status === 400) {
        this.$toast.error(this.$t('errors.userNotFound'));
      } else {
        this.$toast.error(this.$t('errors.login'));
      }
    },
  },
  beforeRouteEnter(_, from, next) {
    if (from.path !== '/' && from.path !== '/forgot-password') {
      routeToRedirect = from.path;
    }
    next();
  },
});
