var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-picker", on: { click: _vm.handleDatePickerClick } },
    [
      _c(
        "label",
        { staticClass: "date-picker__label", attrs: { for: "date-picker" } },
        [_vm._v(" " + _vm._s(_vm.$t("datepicker.label")) + ": ")]
      ),
      _c(
        "div",
        {
          staticClass: "date-picker__input-wrapper",
          on: { click: _vm.togglePicker },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedDate,
                expression: "selectedDate",
              },
            ],
            staticClass: "date-picker__input",
            attrs: { id: "date-picker" },
            domProps: { value: _vm.selectedDate },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.selectedDate = $event.target.value
              },
            },
          }),
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "date-picker__loading" },
                [_c("LoaderComponent", { attrs: { isMini: "" } })],
                1
              )
            : _c(
                "div",
                { staticClass: "date-picker__chevron-icon" },
                [_c("ChevronIcon")],
                1
              ),
        ]
      ),
      _vm.selectableRange && _vm.shouldShowPicker
        ? _c(
            "div",
            { staticClass: "date-picker__calendar" },
            [
              _c("Calendar", {
                attrs: {
                  "highlight-dates": _vm.highlightDates,
                  "selectable-range": _vm.selectableRange,
                  "is-range-calendar": _vm.isRangeCalendar,
                  "is-sales-calendar": _vm.isSalesCalendar,
                  "is-inside-modal": _vm.isInsideModal,
                  "allow-only-forward": _vm.allowOnlyForward,
                },
                on: { close: _vm.closePicker },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }