var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    {
      staticClass: "price-comparison",
      attrs: { title: _vm.$t("priceComparison.analysisPerCategory.title") },
    },
    [
      _c(
        "div",
        { staticClass: "price-comparison__chart-wrapper" },
        [
          _c("apexchart", {
            staticClass: "price-comparison__chart",
            attrs: {
              height: "100%",
              options: _vm.chartOptions,
              series: _vm.series,
            },
          }),
          !_vm.showChart
            ? _c(
                "Modal",
                {
                  attrs: {
                    "is-embedded": "",
                    "has-overflow-visible": "",
                    "has-header": false,
                    "is-loading": _vm.isLoading,
                  },
                },
                [
                  _c(
                    "p",
                    { staticClass: "price-comparison__prompt-description" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "priceComparison.analysisPerCategory.chartPrompt"
                            )
                          ) +
                          ": "
                      ),
                    ]
                  ),
                  _c("RestaurantSelectFilter", {
                    attrs: { "no-filters": "", "no-label": "" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showChart
        ? _c("div", { staticClass: "price-comparison__chart-legend" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.lowestAndHighest) },
            }),
            _c("div", { staticClass: "price-comparison__chart-legend-right" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "priceComparison.analysisPerCategory.ordersEstimateLast7Days"
                    )
                  ) +
                  ": "
              ),
              _c("div", [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("priceComparison.analysisPerCategory.you")
                      ) +
                      " - "
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#ff6248",
                        "font-weight": "bolder",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("shared.comingSoon")))]
                  ),
                ]),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.competitorsSalesEstimate) },
                }),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }