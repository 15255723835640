var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      class: ["modal__overlay", { "modal__overlay--embedded": _vm.isEmbedded }],
      on: {
        click: function ($event) {
          return _vm.$emit("close")
        },
      },
    }),
    _c("div", { class: ["modal", { "modal--embedded": _vm.isEmbedded }] }, [
      _vm.hasHeader
        ? _c("div", { staticClass: "modal__header" }, [
            _vm.icon
              ? _c(
                  "span",
                  { staticClass: "modal__title-icon" },
                  [_c(_vm.icon, { tag: "component" })],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "modal__title-wrapper" }, [
              _vm.title
                ? _c("h2", { staticClass: "modal__title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ])
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "modal__close-icon",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_c("CloseIcon")],
                1
              ),
            ]),
            _vm.description
              ? _c("p", { staticClass: "modal__description" }, [
                  _vm._v(_vm._s(_vm.description)),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "div",
            {
              class: [
                "modal__body",
                { "modal__body--overflow-visible": _vm.hasOverflowVisible },
              ],
            },
            [_vm._t("default")],
            2
          )
        : _c("div", { staticClass: "modal__loader" }, [_c("Loader")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }