import Store from '@/store';

const filters = [
  {
    filter: /Article gratuit \(commande sup/gm,
    filterEnglish: /Free item \(spend/gm,
    result: 'Free Item (order above %0%€)',
    numbersFilter: /\d+/gm,
    type: 'special_offers/promotions',
  },
  {
    filter: /Plus que|commandes pour gagner/gm,
    filterEnglish: /orders until|reward/gm,
    numbersFilter: /[0-9]+/gm,
    result: 'Only %0% orders remaining to earn %1%€',
    type: 'rewards',
  },
  {
    filter: /Économisez sur certains plats/gm,
    filterEnglish: /Save on selected items|Save on Select Items|Save in selected products/gm,
    numbersFilter: /[0-9]+/gm,
    result: 'Save in selected products',
    type: 'save',
  },
  {
    filter: /1acheté\(s\)=1offert\(s\)/gm,
    filterEnglish: /Buy 1 get 1 free|Buy 1, Get 1 Free/gm,
    numbersFilter: /[0-9]+/gm,
    result: 'Buy 1, Get 1 Free',
    type: 'special_offers/promotions',
  },
  {
    filter: /Livraison à|\(pour toute commande de/gm,
    filterEnglish: /Delivery Fee\(Spend|Delivery Fee/gm,
    numbersFilter: /[0-9]+/gm,
    result: 'Delivery at %0%€ (Any order above %1%€)',
    type: 'free_delivery',
  },
  {
    filter: /Dépensez|économisez/gm,
    filterEnglish: /Spend|save/gm,
    result: 'Spend %0%€, save %1%€',
    numbersFilter: /\d+/gm,
    type: 'special_offers/promotions',
  },
];

//FIXME: typing
const filterPromotions = (promotions: { promotion: string; type: string }[]) => {
  const filter = (promo: { promotion: string; type: string }) => {
    let currentPromotions = null;

    promo.promotion = promo.promotion.replace(/&nbsp;/gm, '');

    filters.forEach((filter) => {
      const filterEnglish = promo.promotion.match(filter.filterEnglish);
      const filteredNumbers = promo.promotion.match(filter.numbersFilter);

      promo.type = filterEnglish ? filter.type : promo.type;
      currentPromotions = filterEnglish ? filter.result : null;

      if (filteredNumbers) {
        currentPromotions = filteredNumbers.map((number: string, index: number) =>
          promo.promotion.replace(`%${index}%`, number)
        );
      }
    });
    return currentPromotions;
  };

  return promotions.filter(filter);
};

export const getPromotionsForPlatform = async (location: string) => {
  const currentPlatform = Store.getters.subHeaderSelectedPlatform;
  let parsedPromotions = [];

  if (currentPlatform === 2) {
    const glovoPromotions = await Store.dispatch('getGlovoPromotions', location);

    glovoPromotions.forEach(
      (promotion: {
        restaurant: string;
        tags: string[];
        promotions: {
          title: string;
          type: string;
        }[];
      }) => {
        promotion.promotions.forEach((restaurantPromotions) => {
          parsedPromotions.push({
            restaurant: promotion.restaurant,
            promotion: restaurantPromotions.title,
            tags: promotion.tags,
            type: restaurantPromotions.type,
          });
        });
      }
    );
  } else if (currentPlatform === 3) {
    parsedPromotions = await Store.dispatch('getBoltPromotions', location);
  } else if (currentPlatform === 4) {
    parsedPromotions = await Store.dispatch('getJustEatPromotions', location);
  } else {
    parsedPromotions = await Store.dispatch('getUberPromotions', location);
    parsedPromotions = filterPromotions(parsedPromotions);
  }

  return parsedPromotions;
};
