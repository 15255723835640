

























import Vue from 'vue';
import { ActionButtonComponent } from './Button';

export default (Vue as ActionButtonComponent).extend({
  props: {
    actionColorType: {
      type: String,
      default: 'approve',
    },
    isFilled: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  computed: {
    actionLabel() {
      return this.actionColorType === 'approve'
        ? this.$t('pleezInsights.approve')
        : this.$t('pleezInsights.reject');
    },
  },
});
