var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "settings-view__header" }, [
    _c("div", { staticClass: "settings-view__title-wrapper" }, [
      _c("h1", { staticClass: "settings-view__title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c("div", { staticClass: "settings-view__edit-wrapper" }, [
        !_vm.isEditMode
          ? _c("div", { staticClass: "settings-view__actions" }, [
              _c(
                "button",
                {
                  staticClass: "settings-view__edit-button",
                  attrs: { disabled: _vm.isLoading },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("editing", true)
                    },
                  },
                },
                [
                  _c("EditIcon", { staticClass: "settings-view__icon" }),
                  _vm._v(" " + _vm._s(_vm.$t("settings.edit")) + " "),
                ],
                1
              ),
            ])
          : _c("div", { staticClass: "settings-view__actions" }, [
              _c(
                "button",
                {
                  staticClass: "settings-view__action-cancel",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("editing", false)
                    },
                  },
                },
                [
                  _c("CloseIcon", { staticClass: "settings-view__icon" }),
                  _vm._v(" " + _vm._s(_vm.$t("shared.cancel")) + " "),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "settings-view__action-save",
                  attrs: {
                    type: "submit",
                    form: _vm.formId,
                    disabled: !_vm.isAbleToSave,
                  },
                },
                [
                  _c("CheckmarkIcon", { staticClass: "settings-view__icon" }),
                  _vm._v(" " + _vm._s(_vm.$t("settings.save")) + " "),
                ],
                1
              ),
            ]),
      ]),
    ]),
    _c("p", { staticClass: "settings-view__description" }, [
      _vm._v(" " + _vm._s(_vm.description) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }