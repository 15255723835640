<template>
  <div class="calculator-modal">
    <p-modal :value="openCalculatorModal" @input="$emit('input', $event)">
      <template slot="title">
        <div class="title-container">
          <slot name="title"></slot>
        </div>
        <div class="bar"></div>
      </template>

      <template slot="body">
        <slot name="body"></slot>
      </template>

      <template slot="footer">
        <slot name="footer"></slot>
      </template>
    </p-modal>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'openCalculatorModal',
    event: 'input',
  },
  props: {
    openCalculatorModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.calculator-modal {
  &::v-deep .p-modal-body {
    margin-top: 16px;
  }

  &::v-deep .p-modal-footer {
    right: 20px;
    justify-content: flex-end;
  }

  &::v-deep .p-modal {
    width: 511px;
    padding: unset;
  }

  &::v-deep .p-modal-header {
    height: 62px;
    position: relative;
    padding: unset;
  }

  &::v-deep .p-modal-close-icon {
    color: #5f25e0;
    margin: 16px;
  }

  .bar {
    width: 100%;
    height: 1px;
    background: #f2f2f2;
    position: absolute;
    bottom: 0;
  }

  h3 {
    width: 181px;
    height: 22px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #232859;
    display: flex;
    justify-content: center;
    padding-left: 16px;
  }

  .title-container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .subtitle-container {
    display: flex;
    align-items: center;
    gap: 10px;
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #232859;
    }
    small {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #969dbb;
    }
  }

  .form-group {
    margin-top: 40px;
    display: flex;
    height: auto;
    gap: 40px;
  }
  span {
    height: 32px;
    align-items: center;
    display: flex;
    gap: 15px;
  }

  input {
    background: #ffffff;
    border: 1.00139px solid #d6dfe8;
    box-shadow: 0px 15px 35px rgba(122, 137, 254, 0.05);
    border-radius: 10px;
    box-sizing: border-box;
    height: 32px;
    outline: none;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #232859;
  }

  .total-container {
    display: flex;
    gap: 15px;
    margin-top: 25px;
    transform: translateX(112px);
  }

  .button {
    width: 136px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 24px;
    margin-right: 1em;
  }

  .btn-clear-form-active {
    background: #ff5e00 !important;
    cursor: pointer !important;
  }

  .btn-calculate-active {
    background: #5f25e0 !important;
    cursor: pointer !important;
  }

  .platforms-container {
    display: flex;
    align-items: center;
    gap: 151px;

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #232859;
    }
  }
}
</style>
