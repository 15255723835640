<template>
  <div>
    <div class="competitor-tracker">
      <Filters :filters="{ platform: true }" />
      <PriceComparisonView></PriceComparisonView>
    </div>
  </div>
</template>

<script>
import Filters from '@/components/Filters/FiltersComponent.vue';
import PriceComparisonView from './priceComparison/PriceComparisonView.vue';

export default {
  data: () => ({
    currentActiveTab: 0,
  }),
  components: {
    Filters,
    PriceComparisonView,
  },
};
</script>
