<template>
  <div>
    <div class="reports">
      <Filters class="reports__filters" :filters="{ platform: true, allPlatforms: true }" />

      <div class="reports__content">
        <h2 class="reports__title">
          {{ $t('sidebar.analytics.reporting') }}
        </h2>
        <p class="reports__description">
          {{ $t('reporting.description') }}
        </p>
        <button
          class="reports__bulk-button"
          :disabled="!isAnyCheckboxSelected"
          @click="downloadSelected"
        >
          <span class="reports__bulk-button-icon">
            <DownloadIcon />
          </span>
          {{ $t('reporting.bulkDownload') }}
        </button>

        <div class="reports__table-wrapper">
          <LoaderComponent v-if="isLoading" />

          <p v-else-if="reports.length === 0" class="reports--no-reports">
            {{ $t('reporting.noReports') }}
          </p>

          <Table
            v-else
            class="reports__table"
            is-custom-body
            :caption="$t('reporting.caption')"
            :tableData="tableData"
          >
            <template #custom-header-item>
              <Checkbox :isChecked="isAllCheckboxSelected" @change="toggleAllCheckboxes" />
            </template>

            <template #default="{ rowBody, index }">
              <Checkbox
                v-if="rowBody.customElement === CustomElement.Checkbox"
                :isChecked="selectedCheckboxes[index]"
                @change="toggleCheckbox($event, index)"
              />

              <PlatformIcon
                v-if="rowBody.customElement === CustomElement.Platform"
                :platform="rowBody.value"
              />

              <button
                v-else-if="rowBody.customElement === CustomElement.Button"
                :class="[
                  'reports__button',
                  { 'reports__button--disabled': !isVideo(rowBody.value) },
                ]"
                @click="viewVideo(rowBody.value)"
              >
                <EyeIcon />
              </button>

              <button
                v-else-if="rowBody.customElement === CustomElement.Download"
                class="reports__button-download"
                @click="downloadFile(reports[index])"
              >
                <DownloadIcon />
              </button>

              <div
                v-else-if="rowBody.value"
                :class="{ reports__text: rowBody.customElement === CustomElement.Description }"
              >
                {{ rowBody.value }}
              </div>
            </template>
          </Table>
        </div>
      </div>
    </div>

    <Modal v-if="shouldSeeReportView" @close="shouldSeeReportView = false">
      <video controls autoplay style="width: 100%; height: 650px">
        <source :src="videoUrl" type="video/mp4" />
      </video>
    </Modal>
  </div>
</template>

<script>
import Filters from '@/components/Filters/FiltersComponent.vue';
import Table from '@/components/Table/TableComponent.vue';
import Checkbox from '@/components/Inputs/Checkbox.vue';
import PlatformIcon from '@/components/PlatformIcon/PlatformIconComponent.vue';
import LoaderComponent from '@/components/Loader/LoaderComponent.vue';
import Modal from '@/components/Modal/ModalComponent.vue';

import resizeMixin from '@/mixins/resize';
import { CustomElement } from '@/utils/_enums/global';
import { getTableData } from './reports.utils';

const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'webm', 'mkv'];

export default {
  components: {
    Filters,
    Table,
    Checkbox,
    PlatformIcon,
    LoaderComponent,
    Modal,
  },
  mixins: [resizeMixin],
  data() {
    return {
      videoUrl: '',
      shouldSeeReportView: false,
      selectedCheckboxes: [],
      reports: [],
      isLoading: true,
      CustomElement,
    };
  },
  watch: {
    '$store.state.allPlatformsSelected': {
      async handler() {
        this.isLoading = true;

        this.$store
          .dispatch('getUserReports')
          .then((reportsResponse) => {
            if (reportsResponse) {
              this.reports = reportsResponse;
              this.selectedCheckboxes = Array(this.reports.length).fill(false);
            }

            this.isLoading = false;
          })
          .catch(() => this.$toast.error(this.$t('errors.getReports')));
      },
      immediate: true,
    },
  },
  methods: {
    toggleAllCheckboxes(isChecked) {
      //Array.fill() won't work since it is not reactive

      this.selectedCheckboxes.forEach((_, index) => this.toggleCheckbox(isChecked, index));
    },
    toggleCheckbox(isChecked, index) {
      this.$set(this.selectedCheckboxes, index, isChecked);
    },
    viewVideo(link) {
      this.shouldSeeReportView = true;
      this.videoUrl = link;
    },
    isVideo(fileName) {
      const fileExtension = fileName.split('.').pop();

      return videoExtensions.includes(fileExtension);
    },
    downloadSelected() {
      this.selectedCheckboxes.forEach((isChecked, index) => {
        if (isChecked) {
          const report = this.reports[index];

          this.downloadFile(report);
        }
      });
    },
    downloadFile(file) {
      this.$store.dispatch('downloadFile', { file });
    },
  },
  computed: {
    tableData() {
      return getTableData(this.currentViewPort, this.reports);
    },
    isAnyCheckboxSelected() {
      return Boolean(this.selectedCheckboxes.some((checkboxValue) => checkboxValue));
    },
    isAllCheckboxSelected() {
      return Boolean(this.selectedCheckboxes.every((checkboxValue) => checkboxValue));
    },
  },
};
</script>

<style lang="scss" scoped>
.reports {
  color: var(--brand-primary);

  &__content {
    padding: var(--spacing-m) var(--spacing-s);

    background-color: var(--color-white);

    border-radius: var(--spacing-3xs);
  }
  &__title {
    margin-bottom: var(--spacing-xs);
  }
  &__description {
    margin-bottom: var(--spacing-xs);

    line-height: var(--spacing-s);
  }
  &__bulk-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: var(--spacing-xs);

    color: var(--color-white);
    background-color: var(--brand-black);

    border-radius: var(--spacing-xxs);

    stroke: var(--color-white);

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &__bulk-button-icon {
    margin-right: var(--spacing-xxs);
  }
  &__table-wrapper {
    position: relative;
    min-height: var(--spacing-xxl);

    margin-top: var(--spacing-m);
  }
  &__table {
    margin-top: var(--spacing-s);
  }
  &__button {
    padding: var(--spacing-3xs) var(--spacing-xs);
    background-color: var(--brand-secondary);

    border-radius: var(--spacing-xxs);

    stroke: var(--color-white);

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &__button-download {
    stroke: var(--brand-primary);
  }
  &__text {
    word-break: break-all;
  }
  &--no-reports {
    font-weight: 600;
  }

  ::v-deep {
    td {
      &:last-child {
        padding-right: var(--spacing-s);
      }
    }
    th,
    td {
      &:nth-child(3) {
        text-align: left;
        .table-component__header-item-content {
          justify-content: flex-start;
        }
      }
      &:last-child {
        padding-right: var(--spacing-s);
      }
    }
  }

  @include breakpoint-from('tablet') {
    &__bulk-button {
      width: fit-content;
    }
  }
}
</style>
