export const accountDropdownOptions = [
  {
    label: 'shared.navbar.settings',
    value: 'settings',
    icon: 'CogIcon',
  },
  {
    label: 'shared.navbar.logout',
    value: 'logout',
    icon: 'LogoutIcon',
  },
];
