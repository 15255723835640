var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "not-found",
      attrs: { "aria-label": _vm.$t("errors.notFound") },
    },
    [
      _c("div", { attrs: { "aria-hidden": "true" } }, [
        _c("h1", { staticClass: "not-found__status" }, [_vm._v("404")]),
        _c("h2", { staticClass: "not-found__message" }, [
          _vm._v(_vm._s(_vm.$t("errors.notFound"))),
        ]),
        _c("h3", { staticClass: "not-found__random-text" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "notFoundMessages.message" +
                    Math.floor(Math.random() * _vm.messageNumber)
                )
              ) +
              " "
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }