var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "login", attrs: { "aria-label": "Login" } },
    [
      _c("div", { staticClass: "login__banner" }, [
        _vm._v(_vm._s(_vm.$t("maintenance"))),
      ]),
      _c(
        "h1",
        { staticClass: "login__title" },
        [
          _vm._v(" " + _vm._s(_vm.$t("login.welcome")) + " "),
          _c("PleezLogo", { staticClass: "login__logo" }),
        ],
        1
      ),
      _c(
        "form",
        {
          staticClass: "login__form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.login.apply(null, arguments)
            },
          },
        },
        [
          _c("Input", {
            staticClass: "login__input",
            attrs: {
              "has-warning": "",
              "default-value": _vm.email,
              label: "E-mail",
            },
            on: { change: _vm.handleEmailInputChange },
          }),
          _c("Input", {
            staticClass: "login__input",
            attrs: {
              type: "password",
              "has-warning": "",
              "default-value": _vm.password,
              label: _vm.$t("login.password"),
            },
            on: { change: _vm.handlePasswordInputChange },
          }),
          _c(
            "div",
            { staticClass: "login__actions" },
            [
              _c(
                "router-link",
                {
                  staticClass: "login__link",
                  attrs: { to: "/forgot-password" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("login.forgotPassword")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "ButtonDefault",
            {
              class: [
                "login__button",
                { "login__button--loading": _vm.isLoading },
              ],
              attrs: {
                type: "submit",
                "is-round": "",
                "is-disabled": _vm.isLoginDisabled,
              },
            },
            [
              _vm.isLoading
                ? _c("LoaderComponent", { attrs: { "is-mini": "" } })
                : _c("span", [_vm._v(" Login ")]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }