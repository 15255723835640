var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "input-component" }, [
    _vm.isLabelVisible
      ? _c("p", { staticClass: "input-component__label" }, [
          _vm._v(_vm._s(_vm.label)),
        ])
      : _vm._e(),
    _vm.description
      ? _c("p", { staticClass: "input-component__description" }, [
          _vm._v(_vm._s(_vm.description)),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        class: [
          "input-component__input-wrapper",
          {
            "input-component__input-wrapper--default-size": _vm.hasDefaultSize,
          },
        ],
      },
      [
        _vm.inputType === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
              ],
              class: [
                "input-component__input",
                { "input-component__input--has-icon": _vm.hasCustomIcon },
              ],
              attrs: {
                placeholder: _vm.placeholderLabel,
                disabled: _vm.isDisabled,
                id: _vm.id,
                autocomplete: _vm.autocomplete,
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.inputValue)
                  ? _vm._i(_vm.inputValue, null) > -1
                  : _vm.inputValue,
              },
              on: {
                input: _vm.handleChange,
                keydown: _vm.handleKeyDown,
                change: function ($event) {
                  var $$a = _vm.inputValue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.inputValue = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.inputValue = $$c
                  }
                },
              },
            })
          : _vm.inputType === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
              ],
              class: [
                "input-component__input",
                { "input-component__input--has-icon": _vm.hasCustomIcon },
              ],
              attrs: {
                placeholder: _vm.placeholderLabel,
                disabled: _vm.isDisabled,
                id: _vm.id,
                autocomplete: _vm.autocomplete,
                type: "radio",
              },
              domProps: { checked: _vm._q(_vm.inputValue, null) },
              on: {
                input: _vm.handleChange,
                keydown: _vm.handleKeyDown,
                change: function ($event) {
                  _vm.inputValue = null
                },
              },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
              ],
              class: [
                "input-component__input",
                { "input-component__input--has-icon": _vm.hasCustomIcon },
              ],
              attrs: {
                placeholder: _vm.placeholderLabel,
                disabled: _vm.isDisabled,
                id: _vm.id,
                autocomplete: _vm.autocomplete,
                type: _vm.inputType,
              },
              domProps: { value: _vm.inputValue },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.inputValue = $event.target.value
                  },
                  _vm.handleChange,
                ],
                keydown: _vm.handleKeyDown,
              },
            }),
        _vm.hasCustomIcon
          ? _c(
              "span",
              { staticClass: "input-component__icon" },
              [
                _vm._t("custom-icon"),
                _vm.type === "password"
                  ? _c(
                      "button",
                      {
                        ref: "eyeButton",
                        on: { click: _vm.handleEyeIconClick },
                      },
                      [
                        _vm.shouldShowPassword
                          ? _c("EyeClosedIcon")
                          : _c("EyeIcon"),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]
    ),
    _vm.hasWarning
      ? _c(
          "p",
          { staticClass: "input-component__message input-component__warning" },
          [_vm._v(" " + _vm._s(_vm.warningMessage) + " ")]
        )
      : _vm._e(),
    _vm.errorMessage
      ? _c(
          "p",
          { staticClass: "input-component__message input-component__error" },
          [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
        )
      : _vm._e(),
    _vm.infoMessage
      ? _c(
          "p",
          { staticClass: "input-component__message input-component__info" },
          [_vm._v(" " + _vm._s(_vm.infoMessage) + " ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }