



















import Vue from 'vue';
import LoaderComponent from '../Loader/LoaderComponent.vue';
import { ButtonDefaultComponent } from './Button';

export default (Vue as ButtonDefaultComponent).extend({
  components: {
    LoaderComponent,
  },
  props: {
    type: {
      type: String,
      default: 'button',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRound: {
      type: Boolean,
      default: false,
    },
    isPrimary: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (!this.isLoading) {
        this.$emit('click');
      }
    },
  },
});
