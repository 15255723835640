





















































































import Vue from 'vue';
import Select from '@/components/Inputs/Select.vue';

import { CalendarComponent } from './DatePicker';

export default (Vue as CalendarComponent).extend({
  components: {
    Select,
  },
  props: {
    isRangeCalendar: {
      type: Boolean,
      default: false,
    },
    isSalesCalendar: {
      type: Boolean,
      default: false,
    },
    isInsideModal: {
      type: Boolean,
      default: false,
    },
    allowOnlyForward: {
      type: Boolean,
      default: false,
    },
    highlightDates: {
      type: Array,
      default: () => [],
    },
    selectableRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedMonth: new Date().getMonth(),
      selectedYear: new Date().getFullYear(),
      calendar: [],
      daysOfTheWeek: [
        this.$t('shared.daysOfTheWeek.monday'),
        this.$t('shared.daysOfTheWeek.tuesday'),
        this.$t('shared.daysOfTheWeek.wednesday'),
        this.$t('shared.daysOfTheWeek.thursday'),
        this.$t('shared.daysOfTheWeek.friday'),
        this.$t('shared.daysOfTheWeek.saturday'),
        this.$t('shared.daysOfTheWeek.sunday'),
      ],
      months: [
        this.$t('shared.months.january'),
        this.$t('shared.months.february'),
        this.$t('shared.months.march'),
        this.$t('shared.months.april'),
        this.$t('shared.months.may'),
        this.$t('shared.months.june'),
        this.$t('shared.months.july'),
        this.$t('shared.months.august'),
        this.$t('shared.months.september'),
        this.$t('shared.months.october'),
        this.$t('shared.months.november'),
        this.$t('shared.months.december'),
      ],
      selectedDate1: null,
      selectedDate2: null,
    };
  },
  mounted() {
    this.selectedDate1 = this.selectableRange.fromDate;
    this.selectedDate2 = this.selectableRange.toDate;

    this.selectedMonth = Number(this.selectedDate1.split('-')[1]) - 1;
    this.selectedYear = Number(this.selectedDate1.split('-')[0]);

    this.calendar = this.createCalendar(this.selectedYear, this.selectedMonth);
  },
  methods: {
    isDateInOrdersRange(currentDate) {
      if (!this.selectedDate1 || !this.selectedDate2) {
        return false;
      }

      return currentDate <= this.selectedDate2 && currentDate >= this.selectedDate1;
    },
    isDaySelected(day) {
      return Boolean(day) && (this.selectedDate1 === day || this.selectedDate2 === day);
    },
    isDayWithSales(date) {
      return this.highlightDates.includes(date);
    },
    isDateAvailable(date) {
      return this.allowOnlyForward ? date >= this.todaysDate : date <= this.todaysDate;
    },
    createCalendar(year, month) {
      const date = new Date(year, month);
      const dateMonth = month + 1;
      const daysInAMonth = new Date(year, dateMonth, 0).getDate();

      /**
       * Spaces for the first row from Monday till the
       * first day of the month _ _ _ 1 2 3 4
       **/
      const table = Array(this.getWeekDay(date)).fill('');

      for (let i = 1; i <= daysInAMonth; ++i) {
        table.push(
          `${year}-${dateMonth < 10 ? '0' + dateMonth : dateMonth}-${i < 10 ? '0' + i : i}`
        );
      }

      return table;
    },
    getWeekDay(date) {
      const day = date.getDay(); // get day number from 0 (monday) to 6 (sunday)

      return day === 0 ? 6 : day - 1;
    },
    handleChangeMonth(side) {
      if (side === 'left') {
        if (this.selectedMonth === 0) {
          this.selectedMonth = 11;
          this.selectedYear -= 1;
        } else {
          this.selectedMonth -= 1;
        }
      } else {
        if (this.selectedMonth === 11) {
          this.selectedMonth = 0;
          this.selectedYear += 1;
        } else {
          this.selectedMonth += 1;
        }
      }
      this.calendar = this.createCalendar(this.selectedYear, this.selectedMonth);
    },
    handleThisYearClick() {
      const dateNow = new Date();

      this.selectedYear = dateNow.getFullYear();

      this.selectedDate1 = new Date(dateNow.getFullYear(), 0, 1).toLocaleDateString('fr-CA');
      this.selectedDate2 = new Date(dateNow.getFullYear(), 12, 0).toLocaleDateString('fr-CA');

      this.handleClose(true);
    },
    handleMonthClick(isCurrent) {
      const dateNow = new Date();
      let dateFrom = null;
      let dateTo = null;

      if (isCurrent) {
        dateFrom = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
        dateTo = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
      } else {
        dateFrom = new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 1);
        dateTo = new Date(dateNow.getFullYear(), dateNow.getMonth(), 0);
      }

      this.selectedDate1 = dateFrom.toLocaleDateString('fr-CA');
      this.selectedDate2 = dateTo.toLocaleDateString('fr-CA');

      this.handleClose(true);
    },
    showOnlyDayFromDate(date) {
      return date.split('-')[2];
    },
    handleCalendarDayClick(date) {
      //This shouldn't be needed but touch-events is not working for some reason.
      if (!this.isDateAvailable(date)) {
        return;
      }

      if (!this.isRangeCalendar) {
        this.selectedDate1 = date;
        this.selectedDate2 = date;

        this.handleClose(true);
      }

      if (this.selectedDate2) {
        this.selectedDate1 = date;
        this.selectedDate2 = null;

        return;
      }

      if (this.selectedDate1) {
        if (this.selectedDate1 > date) {
          this.selectedDate2 = this.selectedDate1;
          this.selectedDate1 = date;
        } else {
          this.selectedDate2 = date;
        }

        this.$refs.calendar.scrollTo({ top: this.calendarBodyHeight, behavior: 'smooth' });
        return;
      }

      this.selectedDate1 = date;
    },
    handleClose(shouldSubmit = false) {
      if (shouldSubmit) {
        this.$emit('close', { fromDate: this.selectedDate1, toDate: this.selectedDate2 });
      }

      this.$emit('close');
    },
    formatDate(date) {
      return date.replaceAll('-', '/');
    },
  },
  computed: {
    parsedDaysOfTheWeek() {
      return this.daysOfTheWeek.map((weekDay) => weekDay.substring(0, 2));
    },
    todaysDate() {
      return this.allowOnlyForward
        ? this.selectableRange.fromDate
        : new Date().toLocaleDateString('fr-CA');
    },
    availableYears() {
      return Array.from({ length: new Date().getFullYear() + 3 - 2020 }, (_, i) => 2020 + i);
    },
    buttonPresets() {
      return [
        {
          label: this.$t('shared.inputs.calendar.pastMonth'),
          event: this.handleMonthClick.bind(undefined, false),
        },
        {
          label: this.$t('shared.inputs.calendar.thisMonth'),
          event: this.handleMonthClick.bind(undefined, true),
        },
        {
          label: this.$t('shared.inputs.calendar.thisYear'),
          event: this.handleThisYearClick,
        },
      ];
    },
    calendarBodyHeight() {
      return this.$refs.calendar.scrollHeight;
    },
  },
  watch: {
    selectedMonth() {
      this.calendar = this.createCalendar(this.selectedYear, this.selectedMonth);
    },
    selectedYear() {
      this.calendar = this.createCalendar(this.selectedYear, this.selectedMonth);
    },
  },
});
