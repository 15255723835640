<template>
  <div>
    <div class="card" :style="`max-height: ${maxHeight}px;min-height: ${minHeight}px;`">
      <div v-if="hasHeaderSlot" class="card-header">
        <slot name="header"></slot>
      </div>
      <div
        v-if="hasBodySlot && !isLoading"
        class="card-content"
        :class="{ invisible: scroll == 'invisible' }"
        :style="`height: ${height};max-height: ${contentMaxHeight}px;max-width: ${maxWidth}%;min-width: ${minWidth}%;${scrollType}`"
      >
        <slot name="body"></slot>
      </div>
      <LoaderComponent v-else />
    </div>
  </div>
</template>

<script>
import LoaderComponent from '@/components/Loader/LoaderComponent.vue';

export default {
  components: {
    LoaderComponent,
  },
  /* eslint-disable */
  props: [
    'scroll',
    'maxHeight',
    'minHeight',
    'maxWidth',
    'minWidth',
    'contentMaxHeight',
    'height',
    'loading',
  ],
  /* eslint-enable */
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    hasBodySlot() {
      return !!this.$slots.body;
    },
    isLoading() {
      return Boolean(this.loading);
    },
    scrollType() {
      if (!this.scroll) return '';
      return this.scroll == 'horizontal'
        ? 'overflow-x: overlay;'
        : this.scroll == 'both' || this.scroll == 'invisible'
        ? 'overflow: overlay;'
        : 'overflow-y: overlay;';
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  filter: drop-shadow(0px 10px 30px rgba(122, 137, 254, 0.1));
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(122, 137, 254, 0.04);
  border-radius: 10px;
  width: auto;
  min-height: 200px;
  height: 100%;
}

.card-header {
  color: #232859;
  padding: 1em;
  border-bottom: 1px solid #e6e6e6;

  overflow: auto; //TODO: Temporary
}

.card-content {
  text-align: unset;
  padding: 24px;
}

.invisible::-webkit-scrollbar {
  width: 0px; /* width of the entire scrollbar */
}
.invisible::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
.invisible::-webkit-scrollbar-thumb {
  background-color: transparent; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

@media screen and (min-width: 1401px) {
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-height: unset !important;
  }
}
</style>
