






































































import Vue from 'vue';
import Card from '@/components/Cards/CardComponent.vue';
import ApexChart from 'vue-apexcharts';

import { MenuQualityComponent } from './MenuEngineering';
import { primaryColorHex, secondaryColorHex, tertiaryColorHex } from '@/utils/constants';
import MenuProgressIndicator from './MenuProgressIndicator.vue';

export default (Vue as MenuQualityComponent).extend({
  components: {
    Card,
    ApexChart,
    MenuProgressIndicator,
  },
  props: {
    menuQualityMetrics: {
      type: Object,
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeItem: 0,
      chartOptions: {
        chart: {
          fontFamily: 'Poppins, sans-serif',
        },
        fill: {
          opacity: 1,
        },
        plotOptions: {
          bar: {
            distributed: true,
            columnWidth: '45%',
            borderRadius: 3,
            dataLabels: {
              position: 'top',
            },
          },
          radialBar: {
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                fontSize: '24px',
                fontWeight: 600,
                color: secondaryColorHex,
                offsetY: 5,
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            colors: [primaryColorHex],
          },
        },
        yaxis: {
          max: this.currentChartMax,
        },
      },
    };
  },
  computed: {
    isBarChart() {
      return Boolean(this.metrics[this.activeItem][1].isBarChart);
    },
    metrics() {
      const barMetrics = ['menuItems', 'menuCategories', 'brandRating', 'reviews'];

      if (this.menuQualityMetrics) {
        let metrics = Object.entries(this.menuQualityMetrics);

        return metrics.map(([metricName, metricValue]) => {
          if (barMetrics.includes(metricName)) {
            metricValue.isBarChart = true;
          }

          return [metricName, metricValue];
        });
      }

      return [];
    },
    currentMetricValue() {
      return this.metrics[this.activeItem][1];
    },
    currentMetricTitle() {
      return this.metrics[this.activeItem][0];
    },
    currentMetricChart() {
      this.chartOptions.colors = this.isBarChart
        ? [tertiaryColorHex, secondaryColorHex]
        : [secondaryColorHex];
      this.chartOptions.labels = [this.$t('menuMetrics.self'), this.$t('menuMetrics.market')];

      return this.isBarChart ? 'bar' : 'radialBar';
    },
    currentSeriesData() {
      const { self, market } = this.currentMetricValue.metrics;
      const dataStructure = this.isBarChart
        ? { data: [self, market] }
        : Math.round((self * 100) / market);

      return [dataStructure];
    },
  },
  methods: {
    /**
     * Leave space for the dataLabels which have a
     * negative offset and would be almost clipped
     * on some cases
     **/
    currentChartMax(max) {
      return max + 1;
    },
    setChartLabels() {
      /**
       * Updating only the value doesn't seem to work
       * even when using Vue.set(). The whole object needs to change.
       * Probably an issue with the library
       * */
      if (this.isBarChart) {
        this.chartOptions = {
          ...this.chartOptions,
          labels: [this.$t('menuMetrics.self'), this.$t('menuMetrics.market')],
        };
      }
    },
  },
  watch: {
    '$store.state.selectedLanguage'() {
      this.setChartLabels();
    },
  },
});
