/**
 * Use this file to register any variables or functions that should be available globally
 * ideally you should make it available via the window object
 * as well as the Vue prototype for access throughout the app
 * (register globals with care, only when it makes since to be accessible app wide)
 */
const CRYPTO_JS_KEY = 'lik3xapC9JTS5Pq7dBzVYro2unONWMt8';

export function encryptData(data) {
  let encryptedData = window.CryptoJS.AES.encrypt(JSON.stringify(data), CRYPTO_JS_KEY);
  return encryptedData.toString();
}

function decryptData(encryptedData) {
  return window.CryptoJS.AES.decrypt(encryptedData.replace(/\s/g, '+'), CRYPTO_JS_KEY).toString(
    window.CryptoJS.enc.Utf8
  );
}

const { format } = require('date-fns');

export function getIsoDateFormatted(date) {
  return format(date, 'yyyy-MM-dd');
}

function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

function getCookie(name) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

class SessionClass {
  constructor() {
    if (sessionStorage.getItem('sessions')) {
      try {
        this.sessions = JSON.parse(sessionStorage.getItem('sessions'));
      } catch (error) {
        this.sessions = [];
      }
    } else {
      this.sessions = [];
    }
  }
  setSession(key, value, persistent = false) {
    // if persistent set on cookie, if not set on sessionStorage
    this.sessions.push({ key, value, persistent });
    if (persistent) {
      setCookie(key, value, 365);
    } else {
      window.sessionStorage.setItem(key, value);
    }
    sessionStorage.setItem('sessions', JSON.stringify(this.sessions));
  }
  getSession(key, persistent = false) {
    // if persistent get from cookie, if not get from sessionStorage
    if (getCookie('pleez-persistent')) persistent = true;
    if (persistent) {
      return getCookie(key);
    } else {
      return window.sessionStorage.getItem(key);
    }
  }
  clearAll() {
    this.sessions.forEach((session) => {
      if (session.persistent) {
        setCookie(session.key, '', -1);
      } else {
        window.sessionStorage.removeItem(session.key);
      }
    });
    window.sessionStorage.removeItem('sessions');

    //Get a list of all cookies as a semicolon+space-separated string
    const list = document.cookie
      .split(';')
      //Filter determines if an element should remain in the array.  Here we check if a search string appears at the beginning of the string
      .filter(function (c) {
        return c.trim().indexOf('pleez') === 0;
      })
      //Map applies a modifier to all elements in an array, here we trim spaces on both sides of the string
      .map(function (c) {
        return c.trim();
      });
    if (list.length > 0) {
      list.forEach((cookie) => {
        document.cookie = cookie + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      });
    }
  }
}

export const Session = new SessionClass();

//FIXME: Probably can be replaced with normal classes with no window pollution
window.getCookie = getCookie;
window.setCookie = setCookie;
window.session = Session;
window.encryptData = encryptData;
window.decryptData = decryptData;
