







import Vue from 'vue';

import PromotionView from './promotions/PromotionsComponent.vue';
import Filters from '@/components/Filters/FiltersComponent.vue';
export default Vue.extend({
  name: 'PromotionsView',
  components: {
    PromotionView,
    Filters,
  },
});
