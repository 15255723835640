var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "menu-engineering" },
    [
      _c("Filters", {
        staticClass: "sales__filters",
        attrs: { filters: { platform: true } },
      }),
      _c("p", { staticClass: "menu-engineering__description" }, [
        _vm._v(_vm._s(_vm.$t("menuMetrics.description"))),
      ]),
      _c("MenuQuality", {
        staticClass: "menu-engineering__quality",
        attrs: {
          "menu-quality-metrics": _vm.menuMetrics,
          "is-loading": _vm.isLoading,
        },
      }),
      _c(
        "div",
        { staticClass: "menu-engineering__date-picker-wrapper" },
        [
          _c(
            "p",
            {
              staticClass:
                "menu-engineering__description menu-engineering__description--no-margin menu-engineering__description--center-vertically",
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("menuMetrics.periodDescription")) + " "
              ),
            ]
          ),
          _c("Datepicker", {
            staticClass: "menu-engineering__date-picker",
            attrs: { "is-range-calendar": "", "is-sales-calendar": "" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "menu-engineering__cards" }, [
        _c(
          "div",
          {
            staticClass:
              "menu-engineering__card menu-engineering__analysis-chart",
          },
          [
            _c("SalesAnalysisPerCategory", {
              attrs: {
                categoriesBreakdown: [].concat(_vm.categoriesBreakdown),
                isLoading: _vm.isBreakdownLoading,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "menu-engineering__card" },
          [
            _c("BreakdownOfCategories", {
              attrs: {
                categoriesBreakdown: [].concat(_vm.categoriesBreakdown),
                isLoading: _vm.isBreakdownLoading,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "menu-engineering__card" },
          [
            _c("TopSellers", {
              attrs: {
                isLoading: _vm.isBreakdownLoading,
                currentDateRange: _vm.currentDateRange,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "menu-engineering__card" },
          [
            _c("NonSelling", {
              attrs: {
                isLoading: _vm.isBreakdownLoading,
                currentDateRange: _vm.currentDateRange,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }