var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "table-component__wrapper",
        { "table-component__wrapper--with-scroll": _vm.isFixedSize },
      ],
    },
    [
      _vm.hasNoError
        ? _c("table", { staticClass: "table-component" }, [
            _c(
              "caption",
              {
                class: {
                  "table-component__caption--hidden": _vm.isCaptionHidden,
                },
              },
              [_vm._v(" " + _vm._s(_vm.caption) + " ")]
            ),
            _vm.shouldShowHeader
              ? _c("thead", { staticClass: "table-component__header" }, [
                  _c(
                    "tr",
                    [
                      _vm.hasCustomHeaderItem
                        ? _c(
                            "th",
                            {
                              class: [
                                "table-component__header-item",
                                {
                                  "table-component__header-item--is-child":
                                    _vm.isEmbedded,
                                },
                              ],
                              attrs: { scope: "col" },
                            },
                            [_vm._t("custom-header-item")],
                            2
                          )
                        : _vm._e(),
                      _vm._l(_vm.tableHeader, function (header) {
                        return _vm.tableHeader
                          ? _c(
                              "th",
                              {
                                class: [
                                  "table-component__header-item",
                                  {
                                    "table-component__header-item--is-child":
                                      _vm.isEmbedded,
                                  },
                                ],
                                attrs: {
                                  colspan: header.columnLength,
                                  scope: "col",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "table-component__header-item-content",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t(header.label)) + " "
                                    ),
                                    header.sortKey
                                      ? _c(
                                          "button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.$emit(
                                                  "handleSortClick",
                                                  header.sortKey
                                                )
                                              },
                                            },
                                          },
                                          [_c("SortIcon")],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _vm.tableBody
              ? _c(
                  "tbody",
                  _vm._l(_vm.tableBody, function (row, index) {
                    return _c(
                      "tr",
                      { key: row.id, staticClass: "table-component__row" },
                      _vm._l(row.values, function (bodyData) {
                        return _c(
                          row.isHeader ? "th" : "td",
                          {
                            key: bodyData.id,
                            tag: "component",
                            class: [
                              "table-component__row-item",
                              {
                                "table-component__row-item--is-child":
                                  _vm.isEmbedded,
                              },
                            ],
                            attrs: { scope: "row" },
                          },
                          [
                            _vm.isCustomBody
                              ? _vm._t("default", null, {
                                  rowBody: bodyData,
                                  index: index,
                                })
                              : [_vm._v(" " + _vm._s(bodyData.label) + " ")],
                          ],
                          2
                        )
                      }),
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.tableFooter
              ? _c("tfoot", { staticClass: "table-component__footer" }, [
                  _c(
                    "tr",
                    [
                      _vm.tableFooter.header
                        ? _c(
                            "th",
                            {
                              staticClass: "table-component__footer-header",
                              attrs: {
                                colspan: _vm.tableFooter.columnLength,
                                scope: "row",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t(_vm.tableFooter.header)) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._l(_vm.tableFooter.values, function (footerData) {
                        return _c(
                          "td",
                          {
                            staticClass: "table-component__footer-item",
                            attrs: { id: footerData.id },
                          },
                          [_vm._v(" " + _vm._s(footerData.label) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ])
        : _c("p", { staticClass: "table-component__no-data" }, [
            _vm._v(_vm._s(_vm.noDataWarning)),
          ]),
      _vm.hasLoadMore
        ? _c(
            "div",
            { staticClass: "table-component__load-more" },
            [
              _c(
                "ButtonDefault",
                {
                  staticClass: "table-component__load-more-button",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleLoadMore")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("shared.loadMore")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }