var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "promotion-calculator-modal" },
    [
      _c(
        "p-modal",
        {
          attrs: { value: _vm.openPromotionCalculator },
          on: {
            input: function ($event) {
              _vm.resetForm()
              _vm.$emit("input", $event)
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "title-container" }, [
              _c("h3", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$translations.promotions.calculators.promotion.modal
                        .calculator.title[_vm.$store.state.selectedLanguage]
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "bar" }),
          ]),
          _c("template", { slot: "body" }, [
            _c("div", { staticClass: "subtitle-container" }, [
              _c("h4", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$translations.promotions.calculators.promotion.modal
                        .calculator.selected_promotion[
                        _vm.$store.state.selectedLanguage
                      ]
                    ) +
                    " "
                ),
              ]),
              _c("small", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$translations.promotions.calculators.promotion.modal
                        .calculator.warn[_vm.$store.state.selectedLanguage]
                    )
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "platforms-container" },
              [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$translations.promotions.calculators.promotion.modal
                          .calculator.select_platform[
                          _vm.$store.state.selectedLanguage
                        ]
                      ) +
                      " "
                  ),
                ]),
                _c("Platforms"),
              ],
              1
            ),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    gap: "15px",
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$translations.promotions.calculators.promotion
                            .modal.calculator["sale_price_of_the_menu_item_€"][
                            _vm.$store.state.selectedLanguage
                          ]
                        ) +
                        " "
                    ),
                  ]),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$translations.promotions.calculators.promotion
                            .modal.calculator["comission_delivery_platform_%"][
                            _vm.$store.state.selectedLanguage
                          ]
                        ) +
                        " "
                    ),
                  ]),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$translations.promotions.calculators.promotion
                            .modal.calculator["cost_of_the_menu_item_€"][
                            _vm.$store.state.selectedLanguage
                          ]
                        ) +
                        " "
                    ),
                  ]),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$translations.promotions.calculators.promotion
                            .modal.calculator["vat_of_this_item_%"][
                            _vm.$store.state.selectedLanguage
                          ]
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    gap: "15px",
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.labelItemPrice,
                        expression: "labelItemPrice",
                      },
                    ],
                    domProps: { value: _vm.labelItemPrice },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.labelItemPrice = $event.target.value
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.labelItemComission,
                        expression: "labelItemComission",
                      },
                    ],
                    domProps: { value: _vm.labelItemComission },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.labelItemComission = $event.target.value
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.labelItemCost,
                        expression: "labelItemCost",
                      },
                    ],
                    domProps: { value: _vm.labelItemCost },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.labelItemCost = $event.target.value
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.labelItemVAT,
                        expression: "labelItemVAT",
                      },
                    ],
                    domProps: { value: _vm.labelItemVAT },
                    on: {
                      keypress: function ($event) {
                        return _vm.isNumber($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.labelItemVAT = $event.target.value
                      },
                    },
                  }),
                ]
              ),
            ]),
            _c("div", { staticClass: "total-container" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$translations.promotions.calculators.promotion.modal
                      .calculator["total_you’ll_receive_€"][
                      _vm.$store.state.selectedLanguage
                    ]
                  )
                ),
              ]),
              _c("input", {
                attrs: { readonly: "" },
                domProps: { value: _vm.total },
              }),
            ]),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "p-button",
                {
                  class: { "btn-clear-form-active": _vm.canClear },
                  staticStyle: {
                    width: "136px",
                    "border-radius": "8px",
                    "font-size": "14px",
                    "line-height": "24px",
                    "margin-right": "1em",
                    color: "#ffffff",
                    background: "#e6e6e6",
                    cursor: "default",
                  },
                  attrs: { label: "Cancel" },
                  on: { click: _vm.resetForm },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$translations.promotions.calculators.promotion.modal
                        .calculator.clear_form[
                        _vm.$store.state.selectedLanguage
                      ]
                    )
                  ),
                ]
              ),
              _c(
                "p-button",
                {
                  class: { "btn-calculate-active": _vm.canCalc },
                  staticStyle: {
                    width: "136px",
                    "border-radius": "8px",
                    "font-size": "14px",
                    "line-height": "24px",
                    color: "#ffffff",
                    background: "#e6e6e6",
                    cursor: "default",
                  },
                  attrs: { label: "Calculate" },
                  on: { click: _vm.calc },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$translations.promotions.calculators.promotion.modal
                        .calculator.calculate[_vm.$store.state.selectedLanguage]
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }