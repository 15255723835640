var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    {
      staticClass: "menu-progress",
      attrs: { title: _vm.$t("menuMetrics.progressIndicator") },
    },
    [
      _c(
        "ul",
        { staticClass: "menu-progress__list" },
        _vm._l(_vm.metrics, function (ref) {
          var metricName = ref[0]
          var metricValue = ref[1]
          return _c("li", { staticClass: "menu-progress__item" }, [
            _c("div", { staticClass: "menu-progress__icon-wrapper" }, [
              _c("hr", {
                staticClass:
                  "menu-progress__line menu-progress__line--horizontal-aux",
              }),
              _c(
                "span",
                {
                  class: [
                    "menu-progress__icon",
                    {
                      "menu-progress__icon--is-fulfilled":
                        metricValue.isFulfilled,
                    },
                  ],
                },
                [
                  metricValue.isFulfilled
                    ? _c("CheckmarkIcon")
                    : _c("DangerIcon"),
                ],
                1
              ),
              _c("hr", { staticClass: "menu-progress__line" }),
            ]),
            _c("p", { staticClass: "menu-progress__name" }, [
              _vm._v(
                _vm._s(_vm.$t("menuMetrics." + metricName + ".shortName"))
              ),
            ]),
          ])
        }),
        0
      ),
      _c("h3", { staticClass: "menu-progress__insight-title" }, [
        _vm._v(_vm._s(_vm.$t("shared.insights"))),
      ]),
      _c("p", { staticClass: "menu-progress__insight-text" }, [
        _c("span", {
          class: _vm.isSelectedMetricFulfilled
            ? "menu-progress__insight-text--positive"
            : "menu-progress__insight-text--negative",
          domProps: { innerHTML: _vm._s(_vm.selectedMetricText) },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }