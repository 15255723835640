





































import Vue from 'vue';
import Datepicker from '@/components/Inputs/DatePicker/DatePickerComponent.vue';
import { BreadCrumbComponent } from './BreadCrumb';
import { isSuperUserLocked, isSuperUser } from '@/utils/superUser';

export default (Vue as BreadCrumbComponent).extend({
  components: {
    Datepicker,
  },
  methods: {
    isSuperUserLocked,
    isSubPageActive(index) {
      return index === this.$route.meta?.breadcrumbs.activeSubPageIndex;
    },
  },
  computed: {
    isSuperUser,
    shouldShowDatePicker() {
      return Boolean(this.$route.meta?.hasDatePicker);
    },
    page() {
      return this.$route.meta?.breadcrumbs?.page[this.$store.state.selectedLanguage];
    },
    subPage() {
      return this.$route.meta?.breadcrumbs?.subPage[0][this.$store.state.selectedLanguage] || '-';
    },
    pageMeta() {
      return this.$route.meta;
    },
  },
});
