



















import Vue from 'vue';
import { DropdownComponent } from './Filters';

/**
 * Added it here so that it is the
 * same for all instances
 */
let idOffset = 0;

export default (Vue as DropdownComponent).extend({
  props: {
    selectedFilters: {
      type: Object,
      required: false,
    },
    shouldBeVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      slotCount: 0,
    };
  },
  mounted() {
    this.slotCount = Object.keys(this.$slots).length || 0;
  },
  computed: {
    parsedSelectedValues() {
      return Object.entries(this.selectedFilters)
        .filter(([, values]) => values.length > 0)
        .flatMap(([key, value]) => value.map((filterValue) => ({ key, value: filterValue })));
    },
  },
  methods: {
    slotKey(currentIndex) {
      return currentIndex + idOffset++;
    },
    handleFilterItemClick(filterItem) {
      this.$emit('change', filterItem);
    },
  },
});
