<template>
  <div></div>
</template>


<script>
export default {
  mounted() {
    this.$session.clearAll();
    window.location.href = '/';
  },
};
</script>