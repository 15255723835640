var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardCard",
    {
      attrs: {
        title: _vm.$t("salesDashboard.topSellers.title"),
        description: _vm.$t("salesDashboard.topSellers.description"),
        caption: _vm.$t("salesDashboard.tableCaption"),
        "table-data": _vm.tableData,
        "download-data": _vm.filteredTopSellers,
        "is-loading": _vm.isRequestLoading || _vm.isLoading,
        "footer-text": _vm.footerText,
        "download-data-file-name": "topsellers.csv",
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "top-sellers__icon",
          attrs: { slot: "custom-icon" },
          slot: "custom-icon",
        },
        [_c("ArrowIcon")],
        1
      ),
      _c(
        "div",
        {
          staticClass: "top-sellers__actions",
          attrs: { slot: "custom-actions" },
          slot: "custom-actions",
        },
        [
          _c("Input", {
            attrs: {
              label: _vm.$t("salesDashboard.topSellers.searchPlaceholder"),
              "is-label-visible": false,
              "is-placeholder-visible": "",
            },
            on: { change: _vm.handleSearchInputChange },
            scopedSlots: _vm._u([
              {
                key: "custom-icon",
                fn: function () {
                  return [_c("MagnifyingGlassIcon")]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }