var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slider" },
    [
      _c(
        "Splide",
        { ref: "slider", attrs: { options: _vm.sliderOptions } },
        _vm._l(_vm.sliderItems, function (item, index) {
          return _c(
            "SplideSlide",
            { key: _vm.id + "-" + index },
            [_vm._t("slider-item", null, { item: item })],
            2
          )
        }),
        1
      ),
      _c("div", { staticClass: "slider__pagination" }, [
        _c(
          "button",
          {
            class: [
              "slider__arrow",
              "slider__arrow--prev",
              { "slider__arrow--disabled": _vm.isArrowPrevDisabled },
            ],
            on: {
              click: function ($event) {
                return _vm.handleArrowClick()
              },
            },
          },
          [_c("ArrowIcon")],
          1
        ),
        _c(
          "ul",
          { staticClass: "slider__dots" },
          _vm._l(_vm.sliderItems.length, function (index) {
            return _c("li", { staticClass: "slider__dot-wrapper" }, [
              _c("button", {
                class: [
                  "slider__dot",
                  { "slider__dot--active": index === _vm.currentSliderIndex },
                ],
                on: {
                  click: function ($event) {
                    return _vm.handleDotClick(index)
                  },
                },
              }),
            ])
          }),
          0
        ),
        _c(
          "button",
          {
            class: [
              "slider__arrow",
              { "slider__arrow--disabled": _vm.isArrowNextDisabled },
            ],
            on: {
              click: function ($event) {
                return _vm.handleArrowClick(true)
              },
            },
          },
          [_c("ArrowIcon")],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }