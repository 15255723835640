










































































































import Vue from 'vue';
import { TableComponent, TableRowData } from './Table';

import ButtonDefault from '../Buttons/ButtonDefault.vue';

import resizeMixin from '@/mixins/resize';

export default (Vue as TableComponent).extend({
  components: {
    ButtonDefault,
  },
  props: {
    hasLoadMore: {
      type: Boolean,
      default: false,
    },
    caption: {
      type: String,
      required: true,
    },
    isCaptionHidden: {
      type: Boolean,
      default: true,
    },
    isEmbedded: {
      type: Boolean,
      default: false,
    },
    isFixedSize: {
      type: Boolean,
      default: false,
    },
    isCustomBody: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Object,
      required: false,
    },
    noData: {
      type: String,
      required: false,
    },
  },
  mixins: [resizeMixin],
  computed: {
    hasCustomHeaderItem() {
      return Boolean(this.$slots['custom-header-item']);
    },
    hasNoError() {
      const body = this.tableData?.body;

      return Boolean(body && body.length > 0);
    },
    shouldShowHeader() {
      return (this.tableHeader && this.tableHeader.length > 0) || this.hasCustomHeaderItem;
    },
    tableHeader() {
      const header = this.tableData?.headers;
      let tableHeader = null;

      if (header) {
        tableHeader = header.reduce((currentHeaders: TableRowData[], header) => {
          if (header.minViewPort >= this.currentViewPort) {
            currentHeaders.push({
              ...header,
              columnLength: header.columnLength ? header.columnLength : 1,
            });
          }

          return currentHeaders;
        }, []);
      }

      return tableHeader;
    },
    tableBody() {
      const body = this.tableData?.body;
      let tableBody = null;

      if (body) {
        tableBody = body.map((bodyItem) => {
          return {
            id: bodyItem.id,
            values: this.filterTableDataForViewPort(bodyItem.values),
          };
        });
      }
      return tableBody;
    },
    tableFooter() {
      const footer = this.tableData?.footer;
      let tableFooter = null;

      if (footer) {
        tableFooter = {
          header: footer.header,
          values: this.filterTableDataForViewPort(footer.values),
          columnLength: footer.columnLength ? footer.columnLength : 1,
        };
      }
      return tableFooter;
    },
    noDataWarning() {
      return this.noData ? this.noData : this.$t('shared.noData');
    },
  },
  methods: {
    filterTableDataForViewPort(dataToParse) {
      return dataToParse.filter((dataItem) => dataItem.minViewPort >= this.currentViewPort);
    },
  },
});
