var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hasCriticalErrors
    ? _c(
        "div",
        { staticClass: "filters" },
        [
          _vm.shouldShowConfirmationModal
            ? _c(
                "Modal",
                {
                  attrs: {
                    title: _vm.$t(
                      "filters.platforms.changePlatformModal.title"
                    ),
                  },
                  on: { close: _vm.cancelChangePlatform },
                },
                [
                  _c(
                    "div",
                    { staticClass: "filters__confirmation-modal" },
                    [
                      _c(
                        "p-button",
                        {
                          staticClass:
                            "filters__modal-button filters__modal-button--cancel",
                          attrs: { label: "Cancel" },
                          on: { click: _vm.cancelChangePlatform },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("shared.cancel")) + " ")]
                      ),
                      _c(
                        "p-button",
                        {
                          staticClass:
                            "filters__modal-button filters__modal-button--submit",
                          attrs: { label: "Confirm" },
                          on: { click: _vm.changePlatform },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "filters.platforms.changePlatformModal.confirm"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "filters__group" }, [
            _vm.shouldShowPlatforms
              ? _c(
                  "div",
                  { staticClass: "filters__platforms" },
                  [
                    _c("PlatformFilter", {
                      attrs: { showAllPlatforms: _vm.filters.allPlatforms },
                      on: { click: _vm.openPlatformModal },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                class: [
                  { "filters__dropdowns--full-width": _vm.isFullWidth },
                  { "filters__dropdowns--is-sub-header": _vm.isStandAlone },
                  "filters__dropdowns",
                ],
              },
              [
                _vm.filters.custom
                  ? _c(
                      "div",
                      { staticClass: "filters__custom" },
                      [
                        _vm._t("default"),
                        _vm.filters.shouldShowHint
                          ? _c(
                              "button",
                              {
                                staticClass: "filters__hint",
                                on: { click: _vm.handleHintOnClick },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "filters__hint-label" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$translations.filters.hint[
                                            _vm.$store.getters.currentLanguage
                                          ]
                                        )
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "filters__hint-icon" },
                                  [_c("InfoIcon")],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.filters.restaurantToCompare
                  ? _c("RestaurantSelectFilter", {
                      attrs: { "no-label": _vm.noLabels },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }