var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    { attrs: { title: _vm.$t("priceComparison.price_table.title") } },
    [
      _c("table", { staticClass: "table" }, [
        _c("thead", [
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v(" " + _vm._s(_vm.$t("shared.restaurant")) + " "),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("priceComparison.price_table.columns.product")
                  ) +
                  " "
              ),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("priceComparison.price_table.columns.category")
                  ) +
                  " "
              ),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("priceComparison.price_table.columns.price")) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c(
          "tbody",
          { staticStyle: { height: "240px" } },
          _vm._l(_vm.brandMetricsResponse, function (metric, metricIndex) {
            return _vm.brandMetricsResponse.length > 0
              ? _c("tr", { key: metricIndex }, [
                  _c("td", { attrs: { "data-label": "Restaurant" } }, [
                    _vm._v(_vm._s(metric.restaurant)),
                  ]),
                  _c(
                    "td",
                    {
                      staticStyle: { "word-wrap": "break-word" },
                      attrs: { "data-label": "Product" },
                    },
                    [_vm._v(" " + _vm._s(metric.product) + " ")]
                  ),
                  _c("td", { attrs: { "data-label": "Category" } }, [
                    _vm._v(_vm._s(metric.category)),
                  ]),
                  _c("td", { attrs: { "data-label": "Price" } }, [
                    _vm._v(
                      "€" + _vm._s(metric.price ? metric.price.toFixed(2) : 0)
                    ),
                  ]),
                ])
              : _vm._e()
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }