var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.isArticle ? "article" : "div",
    { tag: "component", staticClass: "card" },
    [
      _c(
        "h3",
        {
          class: [
            "card__header",
            { "card__header--no-divider": _vm.noHeaderDivider },
            { "card__header--is-center": _vm.isCenterTitle },
            { "card__header--padding": !_vm.noPadding },
          ],
        },
        [
          _vm.title
            ? _c("span", { staticClass: "card__title" }, [
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ])
            : _vm._e(),
          _vm.hasCustomHeader ? [_vm._t("custom-header")] : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { class: ["card__body", { "card__body--no-padding": _vm.noPadding }] },
        [
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "card__body--is-loading" },
                [_c("LoaderComponent")],
                1
              )
            : _vm._t("default"),
        ],
        2
      ),
      _c("footer", [_vm._t("custom-footer")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }