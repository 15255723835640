var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardCard",
    {
      staticClass: "sales-per-period",
      attrs: {
        title: _vm.chartTitle,
        description: _vm.$t("performancePage.salesChart.description"),
        "is-table-card": false,
        "is-loading": _vm.isLoading || _vm.isRequestLoading,
      },
    },
    [
      _c(
        "h3",
        {
          staticClass: "sales-per-period__total",
          attrs: { slot: "custom-actions" },
          slot: "custom-actions",
        },
        [_vm._v(" " + _vm._s(_vm.isDataAvailable ? _vm.chartTotal : "") + " ")]
      ),
      _vm.isDataAvailable
        ? [
            _c("p", { staticClass: "sales-per-period__drag-hint" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("salesDashboard.dragAvailable")) + " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "sales-per-period__chart" },
              [
                _c("ApexChart", {
                  class: [
                    "sales-per-period__chart-svg",
                    {
                      "sales-per-period__chart-svg--extended":
                        _vm.isExtendedChart,
                    },
                  ],
                  attrs: {
                    height: "100%",
                    options: _vm.chartOptions,
                    series: [_vm.chartSeries],
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "sales-per-period__chart-legend" }, [
              _c("hr", {
                staticClass: "sales-per-period__chart-legend-border",
              }),
              _c("p", [
                _vm._v(
                  " " + _vm._s(_vm.$t("salesDashboard.averageLegend")) + " "
                ),
              ]),
            ]),
          ]
        : _c("p", { staticClass: "sales-per-period--no-data" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("salesDashboard.noDataAvailable")) + " "
            ),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }